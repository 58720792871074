import config from '../constants/config';
import { DRAUGIEM_PLATFORM, DWEB_PLATFORM, FACEBOOK_PLATFORM, INBOX_PLATFORM, MWEB_PLATFORM } from "../constants/constants"

export const getCurrentPlatform = () => {
    if (config.isInDraugiemAppFrame()) {
        return DRAUGIEM_PLATFORM;
    } else if (config.isInFacebookAppFrame()) {
        return FACEBOOK_PLATFORM;
    } else if (config.isInInboxAppFrame()) {
        return INBOX_PLATFORM;
    } else if (window.innerWidth <= 1280) {
        return MWEB_PLATFORM;
    }
    return DWEB_PLATFORM;
}