import React from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import { withTranslation } from 'react-i18next';

const ChooseGameType = React.memo(({
  chooseTypeClicked, chooseType, t, smallGame, show,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="select-type select-type-mobile-landscape d-none">
      <div className="select-type-header">
        <div className="modal-title">
          <div className="default-modal-title choose-game-type-header">
            {t('selectAction')}
          </div>
        </div>
      </div>
      <div className="select-type-body">
        <Row>
          <Col xs="12" className="select-type-text mb-2">
            {t('selectGameTypeContent')}
          </Col>
        </Row>
        <Row className='select-type-body-mobile-landscape'>
          <Col xs={smallGame ? 3 : 4}>
            <Button
              className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
              disabled={chooseTypeClicked}
              onClick={() => chooseType('zole')}
            >
              {t('zole')}
            </Button>
          </Col>
          {smallGame && (
            <Col xs={3}>
              <Button
                className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
                disabled={chooseTypeClicked}
                onClick={() => chooseType('maza')}
              >
                {t('smallZole')}
              </Button>
            </Col>
          )}
        {/* </Row>
        <Row> */}
          <Col xs={smallGame ? 3 : 4}>
            <Button
              className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
              disabled={chooseTypeClicked}
              onClick={() => chooseType('parasta')}
            >
              {t('takeTable')}
            </Button>
          </Col>
          <Col xs={smallGame ? 3 : 4}>
            <Button
              className="layout-default-button select-type-button position-relative room-select-action-modal-btn"
              disabled={chooseTypeClicked}
              onClick={() => chooseType('garam')}
            >
              {t('pass')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});

ChooseGameType.propTypes = {
  chooseTypeClicked: PropTypes.bool,
  smallGame: PropTypes.bool,
  show: PropTypes.bool,
  chooseType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ChooseGameType.defaultProps = {
  chooseTypeClicked: null,
  smallGame: null,
  show: null,
};

export default withTranslation('game')(ChooseGameType);
