import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import Avatar from 'react-avatar-edit';
import classNames from 'classnames';
// import { filter, isValidDate } from 'lodash';
import * as EmailValidator from 'email-validator';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
} from 'reactstrap';
import ReactGA from 'react-ga';

// import { get } from 'lodash';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import CustomBirthday from './CustomBirthday';

import ProfileAvatarEdit from './ProfileAvatarEdit';
// import pencilImg from '../../../images/icons/pencil.svg';
import closeImg from '../../../images/icons/close.png';
// import checkImg from '../../../images/icons/check.png';
// import cancelImg from '../../../images/icons/close_old.png';
import defaultImage from '../../../images/Game/defaultImage.webp';
import pencil from '../../../images/icons/pencilEdit.svg';
import cancel from '../../../images/icons/deleteBucket.svg';

import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';

//import config from '../../../constants/config';

//const isInAppFrame = config.isInAppFrame();

class UpdateProfile extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    onFormSubmitPhoto: PropTypes.func.isRequired,
    requestDeleteUser: PropTypes.func.isRequired,
    sendVerify: PropTypes.func.isRequired,
    photo: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.number,
    contactEmail: PropTypes.string,
    verifiedEmail: PropTypes.bool,
    loggedInUserDataFetched: PropTypes.bool,
    socProvider: PropTypes.string,
    toggle: PropTypes.func.isRequired,
    alertVisible: PropTypes.bool,
    updateAvatarFileUpload: PropTypes.func.isRequired,
    deleteStatus: PropTypes.bool,
    exitDeleteFunc: PropTypes.func.isRequired,
    deleteCount: PropTypes.number,
    signOut: PropTypes.func.isRequired,
    closeAlertFunc: PropTypes.func.isRequired,
    run: PropTypes.bool,
    newVersion: PropTypes.string,
    openModal: PropTypes.bool,
    holidayModalOpen: PropTypes.bool,
    bonusModalOpen: PropTypes.bool,
    weeklyDataOpen: PropTypes.bool,
    fontMode: PropTypes.string,
    msgType: PropTypes.string,
    msgTypes: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    error: null,
    success: null,
    photo: '',
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    contactEmail: '',
    verifiedEmail: '',
    loggedInUserDataFetched: false,
    socProvider: '',
    alertVisible: false,
    deleteStatus: false,
    deleteCount: 10,
    run: false,
    newVersion: '',
    openModal: false,
    holidayModalOpen: false,
    bonusModalOpen: false,
    weeklyDataOpen: false,
    birthday: null,
    fontMode: constants.FONT_MODE.normal,
    msgType: null,
    msgTypes: [],
  }

  constructor(props) {
    super(props);
    console.log('set photo 2', props);

    this.state = {
      photo: props.photo || null,
      firstName: props.firstName || props.name.split(' ')[0] || '',
      lastName: props.lastName || props.name.split(' ')[1] || '',
      email: props.email || '',
      contactEmail: props.contactEmail || '',
      verifiedEmail: props.verifiedEmail || false,
      password: '',
      password2: '',
      socProvider: props.socProvider || '',
      //  changeEmail: !props.verifiedEmail,
      changeEmail: false,
      changePassword: false,
      preview: null,
      showCrop: false,
      ableToSave: true,
      modalType: constants.PROFILE_MODAL_TYPE.edit,
      alertType: null,
      alertMessage: null,
      alertStatus: false,
      verifiedFlag: false,
      mbirthday: props.birthday ? new Date(props.birthday) : null,
      minDate: null,
      maxDate: null,
      validateFields: [],
      devMode: false,
      customDay: null,
      customMonth: null,
      customYear: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
    this.clearTime = React.createRef(null);
    this.dayRef = React.createRef(null);
    this.monthRef = React.createRef(null);
    this.yearRef = React.createRef(null);
  }

  componentDidMount() {
    const {
      firstName, lastName, email, name, photo, contactEmail, verifiedEmail, socProvider, toggle, birthday, loggedInUserDataFetched, modalOpen,
    } = this.props;

    const { verifyModalShow } = this.state;

    this.setState({
      firstName: firstName || (name && name.split(' ')[0] ? name.split(' ')[0] : ''),
      lastName: lastName || (name && name.split(' ')[1] ? name.split(' ')[1] : ''),
      email,
      photo,
      socProvider,
      contactEmail,
      verifiedEmail,
    });
    if (birthday) {
      const fDate = moment(new Date(birthday), 'YYYY/MM/DD');
      const day = fDate.format('DD');
      const month = fDate.format('MM');
      const year = fDate.format('YYYY');

      this.setState({
        customDay: day,
        customMonth: month,
        customYear: year,
      });
    }

    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({
      devMode,
    });

    try {
      {/* Stop for production mode */ }
      console.log('test loggedInUserDataFetched', localStorage.getItem('justLoggedIn'), loggedInUserDataFetched, email, socProvider, localStorage.getItem('cancelAddEmailModal'));

      if (devMode && loggedInUserDataFetched && localStorage.getItem('justLoggedIn')) {
        if (!localStorage.getItem('cancelAddEmailModal') && socProvider !== constants.EMAIL_PROVIDERS && email === constants.NO_EMAIL_USER) {
          this.setState({ modalType: constants.PROFILE_MODAL_TYPE.promotion });
          toggle();
        } else if (!verifyModalShow && socProvider !== constants.EMAIL_PROVIDERS && email !== constants.NO_EMAIL_USER && !verifiedEmail) {
          console.log('set modalType verify', verifyModalShow, socProvider, email, verifiedEmail, modalOpen);
          this.setState({ verifyModalShow: true, modalType: constants.PROFILE_MODAL_TYPE.verify });
          if (!modalOpen) {
            toggle();
          }
        }
        localStorage.removeItem('justLoggedIn');
      }
    } catch (err) {
      console.log(err);
    }

    /* --- */

    const minDate = new Date(new Date().getFullYear() - 100, 1, 1, 0, 0, 0);
    const maxDate = new Date();
    this.setState({
      minDate,
      maxDate,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      alertVisible,
      birthday,
      toggle,
      socProvider,
      email,
      verifiedEmail,
      loggedInUserDataFetched,
      firstName,
      lastName,
      name,
      photo,
      contactEmail,
      modalOpen,
    } = this.props;

    const { modalType, verifyModalShow, customDay, devMode, email: curEmail, photo: curPhoto, contactEmail: curContactEmail } = this.state;

    try {
      {/* Stop for production mode */ }
      console.log('test loggedInUserDataFetched', localStorage.getItem('justLoggedIn'), loggedInUserDataFetched, email, socProvider, localStorage.getItem('cancelAddEmailModal'));

      if (devMode && loggedInUserDataFetched) { // devMode && localStorage.getItem('justLoggedIn') && 
        if (!localStorage.getItem('cancelAddEmailModal') && socProvider !== constants.EMAIL_PROVIDERS && email === constants.NO_EMAIL_USER) {
          if (modalType !== constants.PROFILE_MODAL_TYPE.promotion && socProvider !== 'inbox') {
            this.setState({ modalType: constants.PROFILE_MODAL_TYPE.promotion });
            toggle();
          }
        } else if (!verifyModalShow && socProvider !== constants.EMAIL_PROVIDERS && email !== constants.NO_EMAIL_USER && !verifiedEmail) {
          console.log('set modalType verify', verifyModalShow, socProvider, email, verifiedEmail, modalOpen);
          this.setState({ verifyModalShow: true, modalType: constants.PROFILE_MODAL_TYPE.verify });
          if (!modalOpen) {
            toggle();
          }
        }
        localStorage.removeItem('justLoggedIn');
      }
    } catch (err) {
      // console.log(err);
    }

    /*
    console.log("userdata to test", email, curEmail, photo, curPhoto, contactEmail, curContactEmail);

    if (email !== curEmail || photo !== curPhoto || contactEmail !== curContactEmail) {
      console.log('incorect user data, refresh');

      this.setState({
        firstName: firstName || (name && name.split(' ')[0] ? name.split(' ')[0] : ''),
        lastName: lastName || (name && name.split(' ')[1] ? name.split(' ')[1] : ''),
        email,
        photo,
        socProvider,
        contactEmail,
        verifiedEmail,
      });
    } */

    if (birthday && !customDay) {
      const fDate = moment(new Date(birthday), 'YYYY/MM/DD');
      const day = fDate.format('DD');
      const month = fDate.format('MM');
      const year = fDate.format('YYYY');
      console.log(day, month, year, "=changeBirthday");

      this.setState({
        customDay: day,
        customMonth: month,
        customYear: year,
      });
    }

    console.log(alertVisible, prevProps.alertVisible, "alertVisible===");

    if (alertVisible && !prevProps.alertVisible) {
      this.setState({
        validateFields: [],
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clearTime);
  }

  handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { email: preEmail, verifiedEmail } = this.props;
    const { email } = this.state;
    this.setState({ [e.target.name]: value });
    if (e.target.name === 'changeEmail') {
      if (email === preEmail && verifiedEmail) {
        this.setState({
          verifiedFlag: true,
        });
      } else {
        this.setState({
          verifiedFlag: false,
        });
      }
    } else if (e.target.name === 'email') {
      if (preEmail === value && verifiedEmail) {
        this.setState({
          verifiedFlag: true,
        });
      } else {
        this.setState({
          verifiedFlag: false,
        });
      }
    }
  };

  timer = () => {
    setTimeout(() => {
      this.setState({
        alertStatus: false,
        alertType: null,
        alertMessage: null,
      });
    }, 10000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      onFormSubmit, socProvider, firstName: preFirstName, lastName: preLastName, photo: prePhoto, email: preEmail, birthday, contactEmail: preContactEmail, t,
    } = this.props;
    const {
      customDay, customMonth, customYear, email, showCrop, firstName, lastName, photo, changeEmail, password, password2, changePassword, curPassword, mbirthday,
    } = this.state;

    console.log('handleSubmit', this.state);

    const validFields = [];

    let addEmailFlag = false;
    let changedEmail = false;

    if ((changeEmail && socProvider !== constants.EMAIL_PROVIDERS && !preContactEmail) || (changeEmail && socProvider !== constants.EMAIL_PROVIDERS && preContactEmail === constants.NO_EMAIL_USER)) {
      addEmailFlag = true;
    } else {
      addEmailFlag = false;
      if (changeEmail && email === preEmail) {
        this.setState({
          changeEmail: false,
        });
        changedEmail = false;
      } else if (changeEmail && email !== preEmail) {
        changedEmail = true;
      }
    }
    console.log(mbirthday, 'check birthday');

    if (!addEmailFlag && !changedEmail && !changePassword) {
      if (firstName === preFirstName && lastName === preLastName && photo === prePhoto && email === preEmail && ((birthday === mbirthday) && (!customDay && !customMonth && !customYear))) {
        this.setState({
          alertMessage: t('support.noChangeData'),
          alertType: constants.FROUNT_SIDE_STATUS.warning,
          alertStatus: true,
        });
        this.clearTime = this.timer();

        return;
      }
    }

    if (!firstName) { validFields.push({ field: constants.PROFILE_FIELDS.surname, msg: errorMessages.missingFirstName }); }
    if (!lastName) { validFields.push({ field: constants.PROFILE_FIELDS.name, msg: errorMessages.missingLastName }); }
    if (!email) { validFields.push({ field: constants.PROFILE_FIELDS.email, msg: errorMessages.missingEmail }); }
    if (changeEmail) {
      if (!EmailValidator.validate(email)) { validFields.push({ field: constants.PROFILE_FIELDS.email, msg: errorMessages.wrongEmail }); }
    }

    /* 
    if (!mbirthday){
      if (customDay || customMonth || customYear) {
        validFields.push({field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.wrongDate});
      } else {
        validFields.push({field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.missingBirthday});
      }
    } */

    if (changePassword) {
      if (!curPassword) { validFields.push({ field: constants.PROFILE_FIELDS.password, msg: errorMessages.missingPassword }); }
      if (!password) { validFields.push({ field: constants.PROFILE_FIELDS.password2, msg: errorMessages.missingPassword }); }
      if (!password2) { validFields.push({ field: constants.PROFILE_FIELDS.password3, msg: errorMessages.missingPassword }); }
      if (password !== password2) { validFields.push({ field: constants.PROFILE_FIELDS.password2, msg: errorMessages.passwordsDontMatch }) };
      if (password.length < 6) { validFields.push({ field: constants.PROFILE_FIELDS.password2, msg: errorMessages.wrongPassword }) };
    }

    if (validFields.length > 0) {
      this.setState({
        validateFields: validFields,
      });
      //  console.log("validFields", {validFields});
      return;
    }


    const data = {
      email,
      showCrop,
      firstName,
      lastName,
      photo,
      changeEmail: changedEmail,
      password,
      password2,
      changePassword,
      curPassword,
      birthday: mbirthday,
    };

    this.setState({ contactEmail: email });
    onFormSubmit(data, addEmailFlag).then(() => {
      this.setState({
        validateFields: [],
      });
    }).catch(() => {
      this.setState({ showCrop: !showCrop, validateFields: [] });
    });
  }

  onBeforeFileLoad = (elem) => {
    try {
      const { t } = this.props;
      const target = elem.target.files[0];

      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      if (elem.target.files[0] && elem.target.files[0].type && elem.target.files[0].size) {
        if (!typeList.includes(elem.target.files[0]?.type) || elem.target.files[0]?.type === '') {
          // showNotification(t('support.error'), t('support.unsupportedFile'), 'danger');
          this.setState({
            alertMessage: t('support.unsupportedFile'),
            alertType: constants.FROUNT_SIDE_STATUS.warning,
            alertStatus: true,
          });
          return elem.target.value = '';
        }

        if (elem.target.files[0].size / 1024 > 3072) {
          // showNotification(t('support.warning'), t('support.fileUploadWarning'), 'warning');
          this.setState({
            alertMessage: t('support.fileUploadWarning'),
            alertType: constants.FROUNT_SIDE_STATUS.warning,
            alertStatus: true,
          });
          elem.target.value = '';
        }
      } else {
        this.setState({
          alertMessage: t('support.unsupportedFile'),
          alertType: constants.FROUNT_SIDE_STATUS.warning,
          alertStatus: true,
        });
        elem.target.value = '';
      }
    } catch (e) {
      this.setState({
        alertMessage: e.code,
        alertType: constants.FROUNT_SIDE_STATUS.warning,
        alertStatus: true,
      });
      return elem.target.value = '';
    }
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  toggle = () => {
    const {
      toggle, photo, firstName, lastName, name, email, socProvider, verifiedEmail, contactEmail, closeAlertFunc, birthday
    } = this.props;
    const { modalType } = this.state;

    if (modalType === constants.PROFILE_MODAL_TYPE.promotion) {
      try {
        localStorage.setItem('cancelAddEmailModal', JSON.stringify(true));
      } catch (err) {
        console.log(err);
      }
    }

    if (email && verifiedEmail && email !== constants.NO_EMAIL_USER) {
      this.setState({
        verifiedFlag: true,
      });
    }
    this.setState({
      preview: null,
      showCrop: false,
      photo,
      firstName: firstName || (name && name.split(' ')[0] ? name.split(' ')[0] : ''),
      lastName: lastName || (name && name.split(' ')[1] ? name.split(' ')[1] : ''),
      verifiedEmail,
      email: email || '',
      contactEmail: contactEmail || '',
      alertMessage: null,
      alertType: null,
      alertStatus: false,
      validateFields: [],
      customDay: null,
      customMonth: null,
      customYear: null,
      mbirthday: birthday ? new Date(birthday) : null,
    });
    closeAlertFunc();
    toggle();
    this.clearTime = this.timer2();
  }

  timer2 = () => {
    setTimeout(() => {
      this.setState({
        modalType: constants.PROFILE_MODAL_TYPE.edit,
      });
    }, 1000);
  }

  onClose = () => {
    const { photo } = this.state;
    this.setState({
      preview: null,
      alertMessage: null,
      alertStatus: false,
      alertType: null,
      validateFields: [],
      customYear: null,
      customMonth: null,
      customDay: null,
    });
  }

  handleEditPhoto = (value) => {
    const { closeAlertFunc } = this.props;
    this.setState({
      showCrop: !value,
      alertMessage: null,
      alertType: null,
      alertStatus: false,
    });
    closeAlertFunc();
  }

  uploadAvatarFile = () => {
    const { updateAvatarFileUpload, onFormSubmitPhoto, t } = this.props;
    const { preview, showCrop } = this.state;
    this.setState({
      ableToSave: false,
    });
    if (preview !== null) {
      updateAvatarFileUpload(preview).then((res) => {
        console.log('set photo 3');
        const data = {
          photo: res.url,
          preview: res.url,
        };

        onFormSubmitPhoto(data).then((res) => {
          this.setState({
            validateFields: [],
          })
        }).catch((err) => {
          this.setState({ showCrop: !showCrop, validateFields: [] });
        });
        this.toggleShowCrop();
        this.setState({
          photo: res.url,
          ableToSave: true,
          alertStatus: true,
          alertType: constants.FROUNT_SIDE_STATUS.success,
          alertMessage: t('support.attachedSuccess'),
        });
      });
    } else {
      this.setState({
        ableToSave: true,
      });
    }
  }

  deleteAccount = () => {
    const { requestDeleteUser } = this.props;

    requestDeleteUser();
  }

  sendVerify = () => {
    const { sendVerify } = this.props;
    sendVerify();
    this.toggle();
  }

  closeDeleteFunc = () => {
    const { exitDeleteFunc } = this.props;
    exitDeleteFunc();
    this.handleModalType(constants.PROFILE_MODAL_TYPE.edit);
  }

  handleModalType = (type) => {
    const {
      firstName, lastName, photo, modalType, preview, showCrop,
    } = this.state;
    const { closeAlertFunc } = this.props;
    if (type !== modalType) {
      this.setState({
        modalType: type,
        firstName,
        lastName,
        preview: null,
        showCrop: false,
        photo,
        alertMessage: null,
        alertType: null,
        alertStatus: false,
        validateFields: [],
      });
      closeAlertFunc();
    }
  }

  toggleShowCrop = () => {
    const { photo, showCrop } = this.state;

    if (!showCrop) {
      ReactGA.event({
        category: 'Menu',
        action: 'Edit Profile - Photo',
      });
    }

    this.setState(prevState => ({
      showCrop: !prevState.showCrop,
      preview: null,
      alertStatus: false,
      alertType: '',
      alertMessage: '',
    }));
  }

  cancelAddEmailModal = () => {
    try {
      localStorage.setItem('cancelAddEmailModal', JSON.stringify(true));
    } catch (err) {
      console.log(err);
    }

    this.toggle();
  }

  changeBirthday = (date, dateString) => {
    console.log('changeBirthday', date, dateString);
    const fDate = moment(date, 'YYYY/MM/DD');
    const day = fDate.format('DD');
    const month = fDate.format('MM');
    const year = fDate.format('YYYY');

    console.log(day, month, year, '=changeBirthday');

    this.setState({
      customDay: day,
      customMonth: month,
      customYear: year,
    });

    this.checkValidDate(day, month, year);
  }

  checkValidDate = (day, month, year) => {
    const { minDate, maxDate } = this.state;

    console.log('checkValidDate', day, month, year);

    if (day && month && year) {
      const newDate = new Date(`${year}/${month}/${day}`);

      if (constants.SMALL_DAY_MONTHS.includes(month)) {
        if (day > 30) {
          this.setState({
            mbirthday: null,
          });
        } else if (month === '02') {
          let leapYear = false;
          if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
          if ((!leapYear && (day >= 29)) || (leapYear && day > 29)) {
            this.setState({
              mbirthday: null,
            });
          } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
            this.setState({
              mbirthday: null,
            });
          } else {
            this.setState({
              mbirthday: newDate,
            });
          }
        } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
          this.setState({
            mbirthday: null,
          });
        } else {
          this.setState({
            mbirthday: newDate,
          });
        }
      } else if (Date.parse(newDate) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(newDate) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
        this.setState({
          mbirthday: null,
        });
      } else {
        this.setState({
          mbirthday: newDate,
        });
      }
    }
  }

  handleKeyDay = (e) => {
    const { customDay, customMonth, customYear } = this.state;
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      this.setState({
        customDay: null,
        mbirthday: null,
      });
    } else if (!isNaN(value)) {
      if (!customDay && parseInt(value, 10) === 0) return;
      if (!customDay && parseInt(value, 10) > 3 && parseInt(value, 10) !== 0) {
        newChat = `0${value}`;
        this.monthRef.current.focus();
      } else if (customDay && customDay.length > 1) {
        const firstChat = customDay.slice(0, 1);
        const secondChat = customDay.slice(1, 2);
        if (
          (parseInt(firstChat, 10) !== 0 && parseInt(value, 10) === 0) ||
          (!customDay && parseInt(value, 10) === 0) ||
          (parseInt(firstChat, 10) === 0 && parseInt(secondChat, 10) > 3 && parseInt(value, 10) === 0)
        ) {
          newChat = customDay;
          e.target.blur();
          this.monthRef.current.focus();
        } else if (parseInt(secondChat, 10) > 3) {
          if (value > 3) {
            this.monthRef.current.focus();
            e.target.blur();
          }
          newChat = `0${value}`;
        } else if (parseInt(secondChat, 10) === 3) {
          if (parseInt(firstChat, 10) === 0) {
            if (value > 1) {
              newChat = `0${value}`;
            } else {
              newChat = secondChat + value;
            }
          } else {
            newChat = `0${value}`;
          }
          this.monthRef.current.focus();
          e.target.blur();
        } else if (parseInt(firstChat, 10) === 0) {
          newChat = secondChat + value;
          this.monthRef.current.focus();
          e.target.blur();
        } else {
          newChat = `0${value}`;
        }
      } else {
        newChat = `0${value}`;
      }

      this.setState({
        customDay: newChat,
      });
    }
    console.log(customMonth, customYear, 'checkDay');
    this.checkValidDate(newChat, customMonth, customYear);
  };

  handleKeyMonth = (e) => {
    const { customMonth, customDay, customYear } = this.state;
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      this.setState({
        customMonth: null,
        mbirthday: null,
      });
    } else if (!isNaN(value)) {
      if (!customMonth && parseInt(value, 10) === 0) return;
      if (!customMonth && parseInt(value, 10) > 1 && parseInt(value, 10) !== 0) {
        newChat = `0${value}`;
        this.yearRef.current.focus();
      } else if (customMonth && customMonth.length > 1) {
        const firstChat = customMonth.slice(0, 1);
        const secondChat = customMonth.slice(1, 2);
        if (
          (parseInt(firstChat, 10) !== 0 && parseInt(value, 10) === 0)
          || (!customMonth && parseInt(value, 10) === 0)
          || (parseInt(firstChat, 10) === 0 && parseInt(secondChat, 10) > 1 && parseInt(value, 10) === 0)
        ) {
          newChat = customMonth;
          e.target.blur();
          this.yearRef.current.focus();
        } else if (parseInt(secondChat, 10) > 1) {
          if (parseInt(value, 10) > 1) {
            this.yearRef.current.focus();
            e.target.blur();
          }
          newChat = `0${value}`;
        } else if (parseInt(secondChat, 10) === 1) {
          if (parseInt(value, 10) < 3) {
            newChat = secondChat + value;
          } else {
            newChat = `0${value}`;
          }
          this.yearRef.current.focus();
          e.target.blur();
        } else if (parseInt(firstChat, 10) === 0) {
          if (parseInt(value, 10) < 3) {
            newChat = secondChat + value;
          } else {
            newChat = `0${value}`;
            if (parseInt(value, 10) > 1) {
              this.yearRef.current.focus();
              e.target.blur();
            }
          }
        } else {
          newChat = `0${value}`;
        }
      } else {
        newChat = `0${value}`;
      }

      this.setState({
        customMonth: newChat,
      });
    }

    this.checkValidDate(customDay, newChat, customYear);
  }

  handleKeyYear = (e) => {
    const { customYear, customDay, customMonth } = this.state;
    const value = e.key;
    let newChat = '';

    if (value.toString() === 'Backspace' || value.toString() === 'Escape' || value.toString() === 'Delete') {
      this.setState({
        customYear: null,
        mbirthday: null,
      });
    } else if (!isNaN(value)) {
      if (!customYear && parseInt(value, 10) === 0) return;
      if (!customYear && parseInt(value, 10) !== 0) {
        newChat = `000${value}`;
      } else if (customYear && customYear.length === 4) {
        const firstChat = customYear.slice(0, 1);
        const secondChat = customYear.slice(1, 2);
        const thirdChat = customYear.slice(2, 3);
        const fourthChat = customYear.slice(3, 4);
        if (customYear.length === 4 && parseInt(value, 10) === 0 && parseInt(firstChat, 10) !== 0) {
          newChat = customYear;
        } else if (customYear.length === 4 && parseInt(value, 10) !== 0 && parseInt(firstChat, 10) !== 0) {
          newChat = `000${value}`;
        } else if (parseInt(secondChat, 10) !== 0 && parseInt(firstChat, 10) === 0) {
          newChat = `${secondChat}${thirdChat}${fourthChat}${value}`;
        } else if (parseInt(thirdChat, 10) !== 0 && parseInt(secondChat, 10) === 0) {
          newChat = `0${thirdChat}${fourthChat}${value}`;
        } else if (parseInt(fourthChat, 10) !== 0 && parseInt(thirdChat, 10) === 0) {
          newChat = `00${fourthChat}${value}`;
        }
      } else {
        newChat = `000${value}`;
      }

      this.setState({
        customYear: newChat,
      });
    }

    this.checkValidDate(customDay, customMonth, newChat);
  }

  handleFocusSelect = (e) => {
    // this.inputRef.current.focus()
    e.target.select();
  }

  handleSelect = (e) => {
    e.target.select();
  }

  handleChangeRaw = (date) => {
    const newRaw = new Date(date.currentTarget.value);
    if (newRaw && !isNaN(newRaw)) {
      this.setState({
        startDate: newRaw,
      });
    }
  };

  render() {
    const {
      msgTypes,
      msgType,
      fontMode,
      t,
      i18n,
      loading,
      modalOpen,
      success,
      error,
      alertVisible,
      deleteStatus,
      deleteCount,
      birthday,
      signOut,
      history,
      run,
      newVersion,
      openModal,
      holidayModalOpen,
      bonusModalOpen,
      weeklyDataOpen,
      isInAppFrame,
      verifiedEmail,
      loggedInUserDataFetched,
    } = this.props;

    const {
      devMode,
      customDay,
      customMonth,
      customYear,
      validateFields,
      minDate,
      maxDate,
      verifiedFlag,
      alertStatus,
      alertType,
      alertMessage,
      firstName,
      lastName,
      changeEmail,
      email,
      changePassword,
      curPassword,
      password,
      password2,
      photo,
      preview,
      showCrop,
      ableToSave,
      socProvider,
      modalType,
      contactEmail,
      mbirthday,
    } = this.state;

    console.log('firstNamefirstName', firstName, lastName, contactEmail, birthday, email, changeEmail);
    console.log('photo', photo);

    const PENCIL_STATUS_STYLE = classNames({
      'menu-player-edit': '',
      'success-pencil-position': success,
      'default-pencil-position': !success,
    });
    const CANCEL_STATUS_STYLE = classNames({
      'menu-player-edit': '',
      'success-cancel-position': success,
      'default-cancel-position': !success,
    });

    if (deleteCount <= 0 && deleteStatus) {
      signOut();
      history.push('/landing');
    }

    console.log('modalType', modalType, modalOpen, verifiedEmail, email, loggedInUserDataFetched);

    console.log('changeEmail && email && email !== props.contactEmail', changeEmail && email && email !== this.props.contactEmail);

    return (
      !run && newVersion >= constants.NEW_VERSION && (
        <div>
          <Row>
            <Col lg={{ size: 6, offset: 3 }}>
              <Modal container={'div > div'} isOpen={modalOpen} toggle={this.toggle} className={`update-profile notification old-modal root-font-${fontMode} ${isInAppFrame ? 'modal-is-app-frame' : ''}`}>
                <ModalHeader
                  className="notification-header"
                  close={<Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggle} />}
                >
                  {modalType === constants.PROFILE_MODAL_TYPE.edit && (t('home.updateProfile'))}
                  {modalType === constants.PROFILE_MODAL_TYPE.delete && (t('home.deleteAccount'))}
                  {modalType === constants.PROFILE_MODAL_TYPE.promotion && t('home.addEmail')}
                  {modalType === constants.PROFILE_MODAL_TYPE.verify && t('home.verifyEmail')}
                </ModalHeader>
                <ModalBody className="update-profile-body">
                  {/* {!!error && <Alert color="danger">{error}</Alert>} */}
                  {(success && error && alertVisible && !msgType) && (
                    <Fragment>
                      {(success === constants.FROUNT_SIDE_STATUS.success && changeEmail && email && email !== this.props.contactEmail) ? (
                        <Alert color="success">t('support.changeSuccessEmail')</Alert>
                      ):(
                      <Alert color={success} isOpen={alertVisible}>
                        <h5 className="alert-heading alert-heading-message">{success === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                        <p className="alert-heading alert-heading-body">{error}</p>
                      </Alert>
                      )}
                    </Fragment>
                  )}
                  {(!alertVisible && alertStatus && alertType && alertMessage) && (
                    <Fragment>
                      {/*  {changeEmail ? (
                        <Alert color="success">{`${success} - Lai piekļūtu spēlei lūdzu apstipriniet jauno epasta adresi`}</Alert>
                      ):(  */}
                      <Alert color={alertType} isOpen={alertStatus}>
                        <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                        <p className="alert-heading alert-heading-body">{alertMessage}</p>
                      </Alert>
                      {/*    )} */}
                    </Fragment>
                  )}
                  {modalType === constants.PROFILE_MODAL_TYPE.edit && (
                    <Form onSubmit={this.handleSubmit} className="updateProfile-modal">
                      <FormGroup style={{ marginTop: 20 }}>
                        <img className="menu-player-avatar" src={preview || (photo || defaultImage)} alt="Preview" />
                        {!showCrop && (
                          <>
                            <div className={PENCIL_STATUS_STYLE}>
                              <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencil} className="menu-player-edit-modal-image" />
                            </div>
                            <div className={CANCEL_STATUS_STYLE}>
                              <Media onClick={() => { this.setState({ photo: null, preview: null }); }} src={cancel} className="menu-player-edit-modal-image" />
                            </div>
                          </>
                        )}
                        {showCrop && (
                          <>
                            <ProfileAvatarEdit ableToSave={ableToSave} onClose={this.onClose} alertStatus={alertStatus} alertMessage={alertMessage} alertType={alertType} t={t} toggleShowCrop={this.toggleShowCrop} onCrop={this.onCrop} onBeforeFileLoad={this.onBeforeFileLoad} showCrop={showCrop} photo={photo} preview={preview} handleEditPhoto={this.handleEditPhoto} uploadAvatarFile={this.uploadAvatarFile} fontMode={fontMode} />
                            <div className={PENCIL_STATUS_STYLE}>
                              <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadAvatarFile(); }} src={pencil} className="menu-player-edit-modal-image" />
                            </div>
                            <div className={CANCEL_STATUS_STYLE}>
                              <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancel} className="menu-player-edit-modal-image" />
                            </div>
                            {/* <div className="active-image-style">
                              <Avatar
                                width={150}
                                height={150}
                                imageWidth={150}
                                imageHeight={150}
                                onCrop={this.onCrop}
                                onBeforeFileLoad={this.onBeforeFileLoad}
                                label={t('home.uploadPhoto')}
                                labelStyle={{ fontSize: 15, fontVariant: 'all-petite-caps' }}
                              />
                            </div> */}
                          </>
                        )}
                        <Input
                          type="text"
                          name="photo"
                          id="photo"
                          placeholder=""
                          hidden
                          value={photo}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="firstName">{t('home.firstName')}</Label>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder=""
                          disabled={loading}
                          value={firstName}
                          maxlength="25"
                          onChange={this.handleChange}
                        />
                        {
                          validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.surname)[0] && (
                            <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.surname)[0]?.msg}`)}</Alert>
                          )
                        }
                      </FormGroup>
                      <FormGroup>
                        <Label for="lastName">{t('home.lastName')}</Label>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder=""
                          disabled={loading}
                          value={lastName}
                          maxlength="25"
                          onChange={this.handleChange}
                        />
                        {
                          validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.name)[0] && (
                            <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.name)[0]?.msg}`)}</Alert>
                          )
                        }
                      </FormGroup>
                      <FormGroup style={{ position: 'relative' }}>
                        <Label for="birthday">{t('home.birthday')}</Label>
                        <DateTimePicker
                          format={constants.FORMAT_DATE_TYPE.slashFullDate}
                          culture={i18n.language}
                          time={false}
                          onChange={(date, dateString) => this.changeBirthday(date, dateString)}
                          placeholder={t('home.datePlaceholder')}
                          value={mbirthday}
                          disabled={birthday}
                          min={minDate}
                          max={maxDate}
                          dropUp={isInAppFrame ? true : false}
                          containerClassName="update-profile-date"
                        />
                        <CustomBirthday
                          handleKeyDay={this.handleKeyDay}
                          handleKeyMonth={this.handleKeyMonth}
                          handleKeyYear={this.handleKeyYear}
                          customDay={customDay}
                          customMonth={customMonth}
                          customYear={customYear}
                          dRef={this.dayRef}
                          mRef={this.monthRef}
                          yRef={this.yearRef}
                          mbirthday={mbirthday}
                          birthday={birthday}
                          fontMode={fontMode}
                          dPlaceholder={i18n.language === 'ru' ? 'ДД' : 'DD'}
                          mPlaceholder={'MM'}
                          yPlaceholder={i18n.language === 'en' ? 'YYYY' : (i18n.language === 'lv' ? 'GGGG' : 'ГГГГ')}
                        />
                        {
                          validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday)[0] && (
                            <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.birthday)[0]?.msg}`)}</Alert>
                          )
                        }
                      </FormGroup>
                      {/* Stop for production mode */}
                      {devMode ? (
                        <>
                          {
                            (socProvider === constants.EMAIL_PROVIDERS || (socProvider !== constants.EMAIL_PROVIDERS && email !== constants.NO_EMAIL_USER)) ? (
                              <FormGroup check style={{ marginTop: 20 }}>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name="changeEmail"
                                    checked={changeEmail}
                                    onChange={this.handleChange}
                                  />
                                  {' '}
                                  {t('home.changeEmail')}
                                </Label>
                                <Label style={{ marginLeft: 10, fontSize: 14 }}>{changeEmail ? (verifiedFlag ? t('home.verified') : t('home.notVerified')) : (null)}</Label>
                              </FormGroup>
                            ) : ((socProvider !== constants.EMAIL_PROVIDERS && email === constants.NO_EMAIL_USER) ? (
                              <FormGroup check style={{ marginTop: 20 }}>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name="changeEmail"
                                    checked={changeEmail}
                                    onChange={this.handleChange}
                                  />
                                  {' '}
                                  {t('home.addEmail')}
                                </Label>
                                <Label style={{ marginLeft: 10, fontSize: 14 }}>{changeEmail ? (verifiedFlag ? t('home.verified') : t('home.notVerified')) : (null)}</Label>
                              </FormGroup>
                            ) : (null))
                          }
                          {changeEmail && (
                            <FormGroup>
                              <Input
                                type="text"
                                name="email"
                                id="email"
                                placeholder="john@doe.corp"
                                disabled={loading}
                                value={email !== constants.NO_EMAIL_USER ? email : (contactEmail || '')}
                                onChange={this.handleChange}
                                maxLength={100}
                              />
                              {
                                validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email)[0] && (
                                  <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.email)[0]?.msg}`)}</Alert>
                                )
                              }
                            </FormGroup>
                          )}
                        </>
                      ) : (null)}

                      {socProvider === constants.SOC_PROVIDERS.password && (
                        <FormGroup check style={{ marginTop: 20 }}>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="changePassword"
                              checked={changePassword}
                              onChange={this.handleChange}
                            />
                            {' '}
                            {t('home.changePass')}
                          </Label>
                        </FormGroup>
                      )}
                      {changePassword && socProvider === constants.SOC_PROVIDERS.password && (
                        <div>
                          <FormGroup>
                            <Label for="password">{t('home.oldPass')}</Label>
                            <Input
                              type="password"
                              name="curPassword"
                              id="curPassword"
                              placeholder="••••••••"
                              disabled={loading}
                              value={curPassword}
                              onChange={this.handleChange}
                              maxLength={100}
                            />
                            {
                              validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password)[0] && (
                                <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password)[0]?.msg}`)}</Alert>
                              )
                            }
                          </FormGroup>
                          <FormGroup>
                            <Label for="password">{t('home.pass')}</Label>
                            <Input
                              type="password"
                              name="password"
                              id="password"
                              placeholder="••••••••"
                              disabled={loading}
                              value={password}
                              onChange={this.handleChange}
                              maxLength={100}
                            />
                            {
                              validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password2)[0] && (
                                <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password2)[0]?.msg}`)}</Alert>
                              )
                            }
                          </FormGroup>
                          <FormGroup>
                            <Label for="password2">{t('home.confirmPass')}</Label>
                            <Input
                              type="password"
                              name="password2"
                              id="password2"
                              placeholder="••••••••"
                              disabled={loading}
                              value={password2}
                              onChange={this.handleChange}
                              maxLength={100}
                            />
                            {
                              validateFields.length > 0 && validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password3)[0] && (
                                <Alert color="danger" isOpen>{t(`common:member.${validateFields.filter(msgT => msgT.field === constants.PROFILE_FIELDS.password3)[0]?.msg}`)}</Alert>
                              )
                            }
                          </FormGroup>
                        </div>
                      )}
                      <Button color="link" className="update-profile-button" style={{ marginTop: 20 }} disabled={loading || !ableToSave}>
                        {loading ? t('home.loading') : t('home.saveProfile')}
                      </Button>
                      <Button
                        color="link"
                        className="update-profile-button"
                        style={{
                          marginTop: 20, marginLeft: 20, width: 200,
                        }}
                        onClick={() => this.handleModalType(constants.PROFILE_MODAL_TYPE.delete)}
                        disabled={loading}
                      >
                        {loading ? t('home.loading') : t('home.deleteAccount')}
                      </Button>
                    </Form>
                  )}
                  {modalType === constants.PROFILE_MODAL_TYPE.delete && (
                    <div>
                      <Label>
                        {deleteStatus ? t('support.deleteSuccess', { deleteCount }) : t('home.deleteContent')}
                      </Label>
                      <div>
                        <Button color="link" className="update-profile-button" style={{ marginTop: 20 }} disabled={loading || !ableToSave} onClick={this.deleteAccount}>
                          {loading ? t('home.loading') : t('common.yes')}
                        </Button>
                        <Button color="link" className="update-profile-button" style={{ marginTop: 20, marginLeft: 20 }} disabled={loading || !ableToSave} onClick={this.closeDeleteFunc}>
                          {loading ? t('home.loading') : t('common.no')}
                        </Button>
                      </div>
                    </div>
                  )}
                  {modalType === constants.PROFILE_MODAL_TYPE.promotion && newVersion >= constants.NEW_VERSION && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && (
                    <div>
                      <Label>
                        {t('home.promotionBody')}
                      </Label>
                      <Row>

                        {/* {
                          !contactEmail
                            ? ( */}
                        <Col sm="6">
                          <Button color="link" className="update-profile-button" style={{ marginTop: 20, width: 220 }} disabled={loading || !ableToSave} onClick={() => { this.setState({ modalType: 'edit', changeEmail: true }); }}>
                            {loading ? t('home.loading') : t('home.addEmailButton')}
                          </Button>
                        </Col>
                        <Col sm="6">
                          <Button color="link" className="update-profile-button" style={{ marginTop: 20, width: 220 }} disabled={loading || !ableToSave} onClick={this.cancelAddEmailModal}>
                            {loading ? t('home.loading') : t('home.noThanks')}
                          </Button>
                        </Col>
                        {/* )
                        : !emailVerified
                          && (
                          <Button
                            color="link"
                            className="update-profile-button"
                            style={{
                              marginTop: 20, marginLeft: 20, paddingLeft: 15, width: 250,
                            }}
                            onClick={() => { this.setState({ modalType: 'verify', changeEmail: true }); }}
                          >
                            {loading ? t('home.loading') : t('home.verifyEmailButton')}
                          </Button>
                          )
                    } */}
                      </Row>
                    </div>
                  )}
                  {modalType === constants.PROFILE_MODAL_TYPE.verify && newVersion >= constants.NEW_VERSION && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && (
                    <>
                      <div>
                        <Label>
                          {t('home.VerifyEmailContent')}
                        </Label>
                      </div>
                      <Row>
                        <Col sm="12">
                          <Button color="link" className="update-profile-button" style={{ marginTop: 20, width: 450 }} disabled={loading || !ableToSave} onClick={this.sendVerify}>
                            {loading ? t('home.loading') : t('home.VerifyEmailButton')}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export default withTranslation('common')(UpdateProfile);
