import React, { Fragment, useCallback } from 'react';
import DateFormat from '../UI/DateFormat';
import { map, filter, size, uniqBy } from 'lodash';

import * as constants from '../../../constants/constants';

const CampaignStatistics = ({ statisticsCampaignId, campaignData, campaignHistory, campaignShowedHistory }) => {

  const totalCampaignStatistics = ((platform, design) => {
    let totalCount = campaignShowedHistory;
    if (platform && design) {
      totalCount = filter(campaignShowedHistory, item => item.showedLocation === campaignData?.selectedLocations[0].value && item.showedPlatform === platform.value && item.showedDesign === design.value);
    }
    return (<span style={{ fontWeight: 'bold', fontSize: '14px' }}>{size(totalCount)}</span>);
  });

  const uniqueCampaignStatistics = ((platform, design) => {
    if (!platform && !design) {
      const totalData = filter(campaignShowedHistory, item => item.showedLocation === campaignData?.selectedLocations[0].value);
      // console.log(totalData, uniqBy(totalData, 'uid'), platform, "c----------")
      const uniqueCount = size(totalData) > 0 ? uniqBy(totalData, 'uid').length : 0;

      return (<span style={{ fontWeight: 'bold', fontSize: '14px' }}>{uniqueCount}</span>);
    } else {
      const totalData = filter(campaignShowedHistory, item => item.showedLocation === campaignData?.selectedLocations[0].value && item.showedPlatform === platform.value && item.showedDesign === design.value);
      // console.log(totalData, uniqBy(totalData, 'uid'), platform, "c----------")
      const uniqueCount = size(totalData) > 0 ? uniqBy(totalData, 'uid').length : 0;

      return (<span style={{ fontWeight: 'bold', fontSize: '14px' }}>{uniqueCount}</span>);
    }
  });

  const clickCampaignStatistics = ((platform, design) => {
   //  const totalCount = filter(campaignHistory, item => item.clickedLocation === campaignData?.selectedLocations[0].value && item.clickedPlatform === platform.value && item.clickedDesign === design.value);

    let totalCount = filter(campaignHistory, item => item.clickedLocation === campaignData?.selectedLocations[0].value);
    if (platform && design) {
      totalCount = filter(campaignHistory, item => item.clickedLocation === campaignData?.selectedLocations[0].value && item.clickedPlatform === platform.value && item.clickedDesign === design.value);
    }

    return (<span style={{ fontWeight: 'bold', fontSize: '14px' }}>{size(totalCount)}</span>);
  });

  return (
    <Fragment>
      <table style={{ width: '100%', fontSize: 12 }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              Title
            </th>
            <th style={{ textAlign: 'center' }}>
              Start Date
            </th>
            <th style={{ textAlign: 'center' }}>
              End Date
            </th>
            <th style={{ textAlign: 'center' }}>
              Location
            </th>
            <th style={{ textAlign: 'center' }}>
              Total Impressions
            </th>
            <th style={{ textAlign: 'center' }}>
              Unique Impressions
            </th>
            <th style={{ textAlign: 'center' }}>
              Clicks Per Platforms
            </th>
            <th style={{ textAlign: 'center' }}>
              Designs
            </th>
            <th style={{ textAlign: 'center' }}>
              Environment
            </th>
            <th style={{ textAlign: 'center' }}>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={`allUsers-table-row`}>
            <td className="allUsers-table-col">
              {campaignData?.title}
            </td>
            <td className="allUsers-table-col">
              <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={campaignData?.startDate} />
            </td>
            <td className="allUsers-table-col">
              <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={campaignData?.endDate} />
            </td>
            <td className="allUsers-table-col ">
              <div className='d-flex flex-column'>
                {campaignData?.selectedLocations && map(campaignData?.selectedLocations, item => (
                  <span className='mb-2'>{item.label}</span>
                ))}
              </div>
            </td>
            <td className="allUsers-table-col ">
              <div className='d-flex flex-column align-items-center justify-center border-1'>
                {campaignData?.selectedPlatforms && map(campaignData?.selectedPlatforms, platformItem => {
                  return (
                    campaignData?.selectedDesigns && map(campaignData?.selectedDesigns, designItem => (
                        <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                        <span className='w-30 text-left'>{platformItem.label}</span>
                        <span className='w-40 text-left'>{designItem.label}</span>
                        <span className='w-30 text-left'>{totalCampaignStatistics(platformItem, designItem)}</span>
                        </div>
                    ))
                  )
                })}
                <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                  <span className='w-50 text-left'>Total</span>
                  <span className='w-50 text-left'>{totalCampaignStatistics(null, null)}</span>
                </div>
              </div>
            </td>
            <td className="allUsers-table-col">
              <div className='d-flex flex-column align-items-center justify-center border-1'>
                {campaignData?.selectedPlatforms && map(campaignData?.selectedPlatforms, platformItem => {
                  return (
                    campaignData?.selectedDesigns && map(campaignData?.selectedDesigns, designItem => (
                      <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                        <span className='w-30 text-left'>{platformItem.label}</span>
                        <span className='w-40 text-left'>{designItem.label}</span>
                        <span className='w-30 text-left'>{uniqueCampaignStatistics(platformItem, designItem)}</span>
                      </div>
                    ))
                  )
                })}
                <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                  <span className='w-50 text-left'>Total</span>
                  <span className='w-50 text-left'>{uniqueCampaignStatistics(null, null)}</span>
                </div>
              </div>
            </td>
            <td className="allUsers-table-col">
              <div className='d-flex flex-column align-items-center justify-center border-1'>
                {campaignData?.selectedPlatforms && map(campaignData?.selectedPlatforms, platformItem => {
                  return (
                    campaignData?.selectedDesigns && map(campaignData?.selectedDesigns, designItem => (
                      <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                        <span className='w-30 text-left'>{platformItem.label}</span>
                        <span className='w-40 text-left'>{designItem.label}</span>
                        <span className='w-30 text-left'>{clickCampaignStatistics(platformItem, designItem)}</span>
                      </div>
                    ))
                  )
                })}
                <div className='d-flex flex-row items-center justify-between w-100 border-b-1 p-2'>
                  <span className='w-50'>Total</span>
                  <span className='w-50 text-left'>{clickCampaignStatistics(null, null)}</span>
                </div>
              </div>
            </td>
            <td className="allUsers-table-col">
              <div className='d-flex flex-column'>
                {campaignData?.selectedDesigns && map(campaignData?.selectedDesigns, item => (
                  <span className='mb-2'>{item.label}</span>
                ))}
              </div>
            </td>
            <td className="allUsers-table-col">
              <div className='d-flex flex-column'>
                {campaignData?.selectedEnvs && map(campaignData?.selectedEnvs, item => (
                  <span className='mb-2'>{item.label}</span>
                ))}
              </div>
            </td>
            <td className="allUsers-table-col">
              {campaignData?.filterType === 'current' && 'Active'}
              {campaignData?.filterType === 'past' && 'Ended'}
            </td>
          </tr>
        </tbody>
      </table>

    </Fragment>
  );
};

export default CampaignStatistics;
