import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import Moment from 'react-moment';
import Spinner from 'reactstrap/lib/Spinner';

import RoomLogs from './RoomLogs';
import * as constants from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';
import CountDownDate from '../UI/CountDownDate';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class AdminLogsRooms extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    //  allRooms: PropTypes.shape({}),
    allRooms: PropTypes.arrayOf(PropTypes.shape({})),
    roomData: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchRoomLog: PropTypes.func.isRequired,
    fetchRoomLogsRange: PropTypes.func.isRequired,
    filteredRoomsLogs: PropTypes.shape({}),
    fetchFilteredRoomLogs: PropTypes.func.isRequired,
    roomsPlayedCount: PropTypes.number,
    bots: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    allRooms: [],
    roomData: {},
    filteredRoomsLogs: null,
    roomsPlayedCount: 0,
    bots: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      //  roomFilter: '',
      //  tournamentFilter: '',
      filteredRoomsLogs: null,
      filter: '',
      filterType: 'roomId',
      filterMinValue: '',
      showMinNumber: false,
      filterSpeed: '',
      filterBet: '',
      filterAmountOfPlayer: '',
      filterCloseReason: '',
      spinnerLoading: false,
      showBotRoom: false,
      startDate: new Date(),
      endDate: new Date(),
    };

    this.toggle = this.toggle.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    const nextFilteredRoomsLogs = nextProps.filteredRoomsLogs;
    const { filteredRoomsLogs } = this.props;

    if (filteredRoomsLogs && !nextFilteredRoomsLogs) {
      this.setState({ currentPage: 0, filteredRoomsLogs: null });
    } else if (nextFilteredRoomsLogs) {
      this.setState({
        filteredRoomsLogs: nextFilteredRoomsLogs,
        spinnerLoading: false,
      });
    }
  }

  componentWillUnmount = () => {
    const { fetchFilteredRoomLogs } = this.props;

    fetchFilteredRoomLogs('');
  }


  filter = () => {
    const { fetchFilteredRoomLogs } = this.props;
    const { filter, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, startDate, endDate } = this.state;
    
    console.log(filter, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, "filterCheckRooms")
    if (Date.parse(startDate) > Date.parse(endDate)) return ;

    this.setState({
      spinnerLoading: true,
    });

    this.setState({
      currentPage: 0,
    });

    fetchFilteredRoomLogs(filter, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, Date.parse(startDate), Date.parse(endDate));
  }

  selectType = (e) => {
    this.setState({ filterType: e.target.value, filter: '', filterMinValue: '', filterSpeed: '', filterBet: '', filterAmountOfPlayer: '', filterCloseReason: '', showBotRoom: false });
  }

  selectSpeed = (e) => {
    this.setState({ filterSpeed: e.target.value, filterMinValue: '', filterBet: '', filterAmountOfPlayer: '', filterCloseReason: '', showBotRoom: false });
  }

  selectBet = (e) => {
    this.setState({ filterBet: e.target.value, filterMinValue: '', filterSpeed: '', filterAmountOfPlayer: '', filterCloseReason: '', showBotRoom: false });
  }

  selectAmountOfPlayer = (e) => {
    this.setState({ filterAmountOfPlayer: e.target.value, filterMinValue: '', filterSpeed: '', filterBet: '', filterCloseReason: '', showBotRoom: false });
  }

  selectCloseReason = (e) => {
    this.setState({ filterCloseReason: e.target.value, filterMinValue: '', filterBet: '', filterAmountOfPlayer: '', filterSpeed: '', showBotRoom: false });
  }

  handleKeyPress = (target) => {
    if (target.charCode === constants.ENTERKEY) {
      this.filter();
    }
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
      filterSpeed: '',
      filterBet: '',
      filterAmountOfPlayer: '',
    });
  }

  toggle() {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  toggleShowMinNumber(showMinNumber) {
    this.setState({
      showMinNumber,
      filterMinValue: '',
      fitler: '',
    });
  }

  toggleBotRoom(showBotRoom) {
    this.setState({
      showBotRoom,
      filterMinValue: '',
      filterBet: '',
      filterAmountOfPlayer: '',
      filterSpeed: '',
      filterCloseReason: '',
    });

    if (showBotRoom) {
      this.setState({
        filterType: 'hasBots',
      });
    } else {
      this.setState({
        filterType: '',
      });
    }
  }

  openModal(room) {
    const { fetchRoomLog } = this.props;

    fetchRoomLog(room.roomId);
    this.setState({
      roomId: room.roomId,
      room,
      openModal: true,
    });
  }

  handleClick(e, index) {
    e.preventDefault();

    const { fetchRoomLogsRange, roomsPlayedCount, filteredRoomsLogs } = this.props;
    if (filteredRoomsLogs && filteredRoomsLogs.length > 0) {
      console.log('has filtered');
    } else {
      const end = roomsPlayedCount - (50 * index);
      const start = roomsPlayedCount - ((50 * index) + 50);

      fetchRoomLogsRange(start, end);
    }
    this.setState({
      currentPage: index,
    });
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }


  table() {
    const {
      allRooms,
      roomsPlayedCount,
      t,
    } = this.props;
    const {
      currentPage,
      pageSize,
      filteredRoomsLogs,
      spinnerLoading,
      filterCloseReason,
    } = this.state;

    let rooms = allRooms;
    let pagesCount = Math.ceil(roomsPlayedCount / pageSize);

    if (filteredRoomsLogs && (filteredRoomsLogs.length || filteredRoomsLogs.length === 0)) {
      rooms = filteredRoomsLogs.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize,
      );
      pagesCount = Math.ceil(filteredRoomsLogs.length / pageSize);
    }

    if (!allRooms.length) {
      return null;
    }
    if (spinnerLoading) {
      return (
        <Spinner type="grow" color="warning" style={{ width: '2rem', height: '2rem' }} />
      );
    }
    console.log(rooms, "checkGameErrorRooms")

    return (
      <Fragment>
        {rooms
          .map((room, index) => (
            <Fragment key={room.roomId}>
              <tr key={room.roomId} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {room.date && (
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecond} date={room.date} />
                  )}
                </td>
                <td className="allUsers-table-col">
                  {room.roomId}
                </td>
                <td className="allUsers-table-col">
                  {room.tournamentId}
                </td>
                <td style={{ color: (room.closeReason && room.closeReason === constants.FILTER_CLOSE_REASON.missedTurn) ? "red" : "#FFF" }} className="allUsers-table-col">
                  {room.closeReason === 'missedTurn' ? (
                    <Fragment>
                      {`${room.closeReason} (${room.isOnline ? 'online' : 'offline'}, ${room.triedToMove ? 'tried to move' : "didn't try to move"})`}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {room.closeReason || ''}
                    </Fragment>
                  )}

                </td>
                <td className="allUsers-table-col">
                  {room?.secondUserJoined ? (
                    <CountDownDate showOption="admin_report" date={room?.secondUserJoined} newDate={room.date} />
                  ) : ('-')}
                </td>
                <td className="allUsers-table-col">
                  {room?.firstRoundStart ? (
                    <CountDownDate showOption="admin_report" date={room?.firstRoundStart} newDate={room.date} />
                  ) : ('-')}
                </td>
                <td className="allUsers-table-col">
                  <CountDownDate showOption="admin_report" date={room.closeTime} newDate={room.date} />
                </td>
                <td className="allUsers-table-col">
                  {room.closeTime && (
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecond} date={room.closeTime} />
                  )}
                </td>
                <td className="allUsers-table-col">
                  {
                    filterCloseReason === constants.GAMEERROR ? (
                      room?.partyCount || 0
                    ) : (
                      room?.party || 0
                    )
                  }
                </td>
                <td className="allUsers-table-col">
                  <Button onClick={() => this.openModal(room)}>
                    View
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {roomsPlayedCount > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 4 && i < currentPage + 4) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      openModal, roomId, room, filterType, filter, filterMinValue, showMinNumber, showBotRoom, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, startDate, endDate
    } = this.state;

    const { roomData, t, bots } = this.props;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-admin-logs-rooms">
          <PopoverBody className="popover-body">
            A list of all finished and ongoing rooms with an option to view details of each of them. Note: Room details are only available for the last 14 days.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            Rooms
            <Button className="admin-help-button" id="admin-help-button-admin-logs-rooms">
              ?
            </Button>
          </h2>
          <Row>
            <Col sm="3">
              <Label for="filterType">
                Filter by
              </Label>
              <Input type="select" className="game-bet-select" value={filterType} onChange={this.selectType}>
                <option value="roomId" style={{ backgroundColor: '#222' }}>Room Id</option>
                <option value="tournamentId" style={{ backgroundColor: '#222' }}>Tournament Id</option>
                <option value="userId" style={{ backgroundColor: '#222' }}>Player Id</option>
                {/*  <option value="missedTurn" style={{ backgroundColor: '#222' }}>{t('missedTurn')}</option>  */}
              </Input>
            </Col>
            {!showMinNumber && (
              <Col sm="3">
                <Label for="filter">
                  Value
                </Label>
                <Input
                  type="text"
                  name="filter"
                  id="filter"
                  value={filter}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              </Col>
            )}
            <Col sm="3">
              <Label for="filterMinValueCheck" check>
                <Input id="filterMinValueCheck" type="checkbox" onClick={() => this.toggleShowMinNumber(!showMinNumber)} checked={showMinNumber} readOnly />
                <span className="checkmark" />
                Min number of games per room
              </Label>
              {showMinNumber && (
                <Input
                  type="number"
                  name="filterMinValue"
                  id="filterMinValue"
                  value={filterMinValue}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            </Col>
            <Col sm="2">
              <Label for="filterBotRoomCheck" check>
                <Input id="filterBotRoomCheck" type="checkbox" onClick={() => this.toggleBotRoom(!showBotRoom)} checked={showBotRoom} readOnly />
                <span className="checkmark" />
                Show the room with Bot players
              </Label>
            </Col>
            <Col sm="1">
              <Button onClick={this.filter} style={{ marginTop: 20 }}>
                {/* {t('filter')} */}
                Filter
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Label for="filterSpeed">
                {/* {t('filterBySpeed')} */}
                Filter By Speed
              </Label>
              <Input type="select" className="game-bet-select" value={filterSpeed} onChange={this.selectSpeed}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                <option value="lightning" style={{ backgroundColor: '#222' }}>Lightening</option>
                <option value="atra" style={{ backgroundColor: '#222' }}>Fast</option>
                <option value="parasta" style={{ backgroundColor: '#222' }}>Regular</option>
                <option value="unlimited" style={{ backgroundColor: '#222' }}>Unlimited</option>

              </Input>
            </Col>
            <Col sm="3">
              <Label for="filterBet">
                {/* {t('filterByBet')} */}
                Filter By Bet
              </Label>
              <Input type="select" className="game-bet-select" value={filterBet} onChange={this.selectBet}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                {/* <option value="1:1" style={{ backgroundColor: '#222' }}>{t('1:1')}</option>
                <option value="1:5" style={{ backgroundColor: '#222' }}>{t('1:5')}</option> */}
                <option value="1:10" style={{ backgroundColor: '#222' }}>{t('1:10')}</option>
                <option value="1:25" style={{ backgroundColor: '#222' }}>{t('1:25')}</option>
                <option value="1:50" style={{ backgroundColor: '#222' }}>{t('1:50')}</option>
                <option value="1:100" style={{ backgroundColor: '#222' }}>{t('1:100')}</option>
                <option value="1:250" style={{ backgroundColor: '#222' }}>{t('1:250')}</option>
                <option value="1:500" style={{ backgroundColor: '#222' }}>{t('1:500')}</option>
                <option value="1:1000" style={{ backgroundColor: '#222' }}>{t('1:1000')}</option>
                <option value="1:5000" style={{ backgroundColor: '#222' }}>{t('1:5000')}</option>
                <option value="1:10000" style={{ backgroundColor: '#222' }}>{t('1:10000')}</option>
              </Input>
            </Col>
            <Col sm="3">
              <Label for="filterSpeed">
                {/* {t('amountOfPlayer')} */}
                Amount Of Player
              </Label>
              <Input type="select" className="game-bet-select" value={filterAmountOfPlayer} onChange={this.selectAmountOfPlayer}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                <option value="null" style={{ backgroundColor: '#222' }}>3 Players</option>
                <option value="true" style={{ backgroundColor: '#222' }}>4 Players</option>
              </Input>
            </Col>
            <Col sm="3">
              <Label for="filterSpeed">
                {/* {t('closeReason')} */}
                Close Reason
              </Label>
              <Input type="select" className="game-bet-select" value={filterCloseReason} onChange={this.selectCloseReason}>
                <option value="" className="background-222" />
                {
                  constants.closeReason.map((item) => {
                    return (
                      <option className="background-222" value={item.id}>
                        {item.label}
                      </option>
                    );
                  })
                }
              </Input>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Label for="startDate">
                {/* {t('startDate')} */}
                From
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={this.changeStartDate}
                    value={startDate}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Label for="endDate">
                {/* {t('endDate')} */}
                To
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={this.changeEndDate}
                    value={endDate}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('date')} */}
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('roomId')} */}
                  Room Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('tournamentId')} */}
                  Tournament Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('closeReason')} */}
                  Close Reason
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('closeReason')} */}
                  Time between create and joining second player
                </th>
                <th style={{ textAlign: 'center' }}>
                  Time between create room and start to play
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('closeReason')} */}
                  Time between create and close
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('closeDate')} */}
                  Close Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('parties')} */}
                  Parties
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('view')} */}
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>
        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggle}>
            {room ? `Room: ${room.roomId}, Bet: ${room.bet}, Speed: ${constants.SPEED_TYPE[room.speed]}, ${room.minGames ? `Min Games: ${room.minGames}` : ''}, ${room.fourPRoom ? '4' : '3'} Players` : ''}
          </ModalHeader>
          <ModalBody>
            <RoomLogs roomData={roomData} roomId={roomId} roomParams={room} bots={bots} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation('admin')(AdminLogsRooms);
