import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import Lottie from 'react-lottie';


import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import Progress from 'reactstrap/lib/Progress';

// import closeImg from '../../../../../images/icons/close.png';
import winnerAnimImg from '../../../../../images/Game/winner.json';
import loserAnimImg from '../../../../../images/Game/loser.json';

import winSound from '../../../../../sounds/game_win.mp3';
import loseSound from '../../../../../sounds/game_lose.mp3';
import CustomModal from './Modal';
import { MENU_NAVIGATION } from '../../../../../constants/constants';

class Notification extends React.Component {
  static propTypes = {
    leaveRoom: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func,
    gameResult: PropTypes.shape(),
    memberUid: PropTypes.string,
    openModal: PropTypes.bool,
    modalType: PropTypes.string,
    openGameResultModal: PropTypes.bool,
    ignoredMessageName: PropTypes.string,
    t: PropTypes.func.isRequired,
    switchTabs: PropTypes.func,
    newLevel: PropTypes.string,
    buyMoney: PropTypes.func,
    closeLevelNotification: PropTypes.func.isRequired,
    setShowEndResultModal: PropTypes.func,
    soundOn: PropTypes.bool,
    resetError: PropTypes.func.isRequired
  }

  static defaultProps = {
    gameResult: null,
    modalType: '',
    ignoredMessageName: '',
    newLevel: '',
    memberUid: null,
    openModal: false,
    soundOn: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      playWinAnim: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeResultModal = this.closeResultModal.bind(this);
    this.closeLevelUpNotification = this.closeLevelUpNotification.bind(this);
    this.buyMoney = this.buyMoney.bind(this);
    this.toggle = this.toggle.bind(this);

    this.winAudio = new Audio(winSound);
    this.loseAudio = new Audio(loseSound);
  }

  closeModal() {
    const { closeModal, modalType, resetError } = this.props;
    console.log('closeModal');
    resetError();
    
    if (modalType === 'levelUp') {
      this.closeLevelUpNotification();
    } else {
      closeModal();
    }
  }

  closeLevelUpNotification() {
    const { closeLevelNotification, closeModal } = this.props;
    closeLevelNotification();
    closeModal();
  }

  closeResultModal() {
    console.log('closeResultModal');
    const { closeResultModal, closeModal, closeLevelNotification } = this.props;

    closeModal();
    closeResultModal();
  }

  buyMoney() {
    const { closeModal, switchTabs } = this.props;
    switchTabs(MENU_NAVIGATION.buyPageMoneyPacks);
    closeModal();
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      leaveRoom,
      t,
      gameResult,
      buyMoney,
      newLevel,
      modalType,
      openModal,
      ignoredMessageName,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      memberUid,
      openGameResultModal,
      soundOn,
      setShowEndResultModal,
      leavePenalty,
      minGames,
      rPlayed,
    } = this.props;
    const {
      activeTab,
      playWinAnim,
    } = this.state;

    if (!!gameResult && !playWinAnim) {
      setTimeout(() => {
        if (soundOn) {
          if (gameResult && gameResult.type !== 'pass') {
            if (gameResult.winnerUID && gameResult.winnerUID.includes(memberUid)) {
              const playPromise = this.winAudio.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                  });
              }
            } else {
              const playPromise = this.loseAudio.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                  });
              }
            }
          }
        }

        this.setState({ playWinAnim: true });
      }, 750);
    } else if (!gameResult && playWinAnim) {
      this.setState({ playWinAnim: false });
    }

    const getGameResultTitle = (gameResult) => {
      if (!gameResult) return '';
      if (gameResult.type === 'parasta') return `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))}`;
      if (gameResult.type === 'zole') return `${t('large')} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('zoli')}`;
      if (gameResult.type === 'mazaZole') return `${gameResult.largePlayer} ${gameResult.winner === 'large' ? (t('wins')) : (t('loses'))} ${t('mazoZoli')}`;
      if (gameResult.type === 'galdins') return `${gameResult.winner} ${t('loses')} ${t('table')}`;
      if (gameResult.type === 'pass') return `${t('allPassed')}`;
      return '';
    }

    return (
      <div>
        <CustomModal
          isOpen={(openModal && !gameResult) || (openModal && !openGameResultModal) || (openModal && modalType/* && modalType !== 'levelUp' */)}
          title={t('common:home.notifications')}
          verticalCentering={modalType && modalType === 'noBalanceMenu'}
          largeText={modalType && modalType === 'noBalanceMenu'}
          toggle={() => this.closeModal()}
          body={
            <>
              {/*  {modalType && modalType === 'noBalance' && (
                <div>
                  {t('noMoneyDescription')}
                </div>
              )}  */}

              {/*  {modalType && modalType === 'lowBalance' && (
                <div>
                  {`Spēlētajam ${lowBalPlayer} nepietiek naudas lai turpinātu, istaba tiks aizvērta`}
                </div>
              )} */}
              {modalType && modalType === 'multiAccountTournament' && (
                <div className="text-center">
                  {t('multiAccountTournament')}
                </div>
              )}

              {modalType && modalType === 'lowBalanceTournament' && (
                <div>
                  PIRKT
                </div>
              )}

              {modalType && modalType === 'noBalanceMenu' && (
                <div className="text-center">
                  {t('noMoneyMenuDescription', { amount: insufficientBalanceAmount, bet: insufficientBalanceBet })}
                </div>
              )}

              {modalType && modalType === 'noBalanceTournament' && (
                <div>
                  {t('noBalanceTournament')}
                </div>
              )}

              {modalType && modalType === 'proRoom' && (
                <div>
                  {t('onlyProDescription')}
                </div>
              )}

              {modalType && modalType === 'proRoomMenu' && (
                <div>
                  {t('onlyProDescription')}
                </div>
              )}

              {modalType && modalType === 'emailNotVerified' && (
                <div>
                  {t('emailNotVerified')}
                </div>
              )}

              {modalType && modalType === 'proBetMenu' && (
                <div>
                  {t('onlyProBet')}
                </div>
              )}

              {modalType && modalType === 'leaveRoom' && (
                <div>
                  {t('leaveRoomWarning', { leavePenalty })}
                </div>
              )}

              {modalType && modalType === 'leaveRoomNotStarted' && (
                <div>
                  {t('leaveRoomNotStarted')}
                </div>
              )}

              {modalType && modalType === 'tournamentEnd' && (
                <div>
                  {t('tournamentEnd')}
                </div>
              )}

              {modalType && modalType === 'gameError' && (
                <div>
                  {t('gameError')}
                </div>
              )}

              {modalType && modalType === 'youIgnoredPlayer' && (
                <div>
                  {t('youIgnoredPlayer', { player: ignoredMessageName })}
                </div>
              )}

              {modalType && modalType === 'playerIgnoredYou' && (
                <div>
                  {t('playerIgnoredYou', { player: ignoredMessageName })}
                </div>
              )}

              {modalType && modalType === 'lowBalanceGift' && (
                <div>
                  {t('lowBalanceGift')}
                </div>
              )}

              {modalType && modalType === 'levelUp' && (
                <Fragment>
                  <div className='text-center'>
                    {t('newLevel')}
                  </div>
                  <div className="levelProgress-old">
                    <Progress
                      color="success"
                      value={100}
                      className="levelProgress-old-bar"
                    />
                    <div className="levelProgress-old-level-wrapper" style={{ left: '45%' }}>
                      <div
                        className="levelProgress-old-level"
                        style={{ top: 10 }}
                      >
                        {newLevel}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </>
          }
          footer={
            <>
              {modalType && modalType === 'leaveRoom' && (
                <Fragment>
                  <Button onClick={leaveRoom}>{t('yes')}</Button>
                  <Button onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'leaveRoomNotStarted' && (
                <Fragment>
                  <Button onClick={leaveRoom}>{t('yes')}</Button>
                  <Button onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && (modalType === 'proRoomMenu' || modalType === 'proRoom' || modalType === 'proBetMenu' || modalType === 'emailNotVerified' || modalType === 'multiAccountTournament') && (
                <Button color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'noBalance' && (
                <Button color="primary" onClick={this.closeModal}>{t('buyMoney')}</Button>
              )}

              {modalType && modalType === 'lowBalance' && (
                <Fragment>
                  <Button color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'lowBalanceTournament' && (
                <Fragment>
                  <Button color="primary" onClick={() => buyMoney(false)}>{t('buyMoney')}</Button>
                  <Button color="primary" onClick={leaveRoom}>{t('common:common.leave')}</Button>
                </Fragment>
              )}

              {modalType && (modalType === 'noBalanceMenu' || modalType === 'noBalanceTournament') && (
                <Fragment>
                  <Button color="primary" onClick={this.buyMoney}>{t('buyMoney')}</Button>
                  <Button color="primary" onClick={this.closeModal}>{t('no')}</Button>
                </Fragment>
              )}

              {modalType && modalType === 'tournamentEnd' && (
                <Fragment>
                  <Link to="/" className="btn notification-footer-button">
                    {t('exit')}
                  </Link>
                </Fragment>
              )}

              {modalType && modalType === 'gameError' && (
                <Fragment>
                  <Button onClick={leaveRoom} className="btn notification-footer-button">
                    {t('exit')}
                  </Button>
                </Fragment>
              )}

              {modalType && modalType === 'lowBalanceGift' && (
                <Button color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'youIgnoredPlayer' && (
                <Button color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'playerIgnoredYou' && (
                <Button color="primary" onClick={this.closeModal}>{t('ok')}</Button>
              )}

              {modalType && modalType === 'levelUp' && (
                <Button color="primary" onClick={() => this.closeLevelUpNotification()}>{t('ok')}</Button>
              )}
            </>
          }
        />

        <CustomModal
          isOpen={openGameResultModal && !!gameResult/* && modalType !== 'lastRound' */}
          title={getGameResultTitle(gameResult)}
          toggle={() => this.closeResultModal()}
          body={
            <TabContent activeTab={activeTab}>
              <Fragment>
                {gameResult && gameResult.scoreType && (
                  <TabPane className="notification-body-tab" tabId="1">
                    <Row>
                      <Col sm="12">
                        <Fragment>
                          {(gameResult.type === 'parasta' || gameResult.type === 'zole') && (
                          <Fragment>
                            {gameResult.scoreType === 'parasts' && gameResult.winner === 'large' && (
                              `${t('largeWinsWith')} ${gameResult.largePoints} ${t('points')}`
                            )}
                            {gameResult.scoreType === 'parasts' && gameResult.winner === 'small' && (
                              `${t('largeLosesWith')} ${gameResult.largePoints} ${t('points')}`
                            )}
                            {gameResult.scoreType === 'jani' && gameResult.winner === 'large' && (
                              `${t('largeWinsWith')} ${gameResult.largePoints} ${t('points')}`
                            )}
                            {gameResult.scoreType === 'jani' && gameResult.winner === 'small' && (
                              `${t('largeLosesWith')} ${gameResult.largePoints} ${t('points')}`
                            )}
                            {gameResult.scoreType === 'bezstikis' && gameResult.winner === 'large' && (
                              `${t('largeWinsWith')} ${t('noTricks')}`
                            )}
                            {gameResult.scoreType === 'bezstikis' && gameResult.winner === 'small' && (
                              `${t('largeLosesWith')} ${t('noTricks')}`
                            )}
                          </Fragment>
                          )}
                          {gameResult.type === 'mazaZole' && (
                          <Fragment>
                            {gameResult.winner === 'large' && (
                              `${t('largeWins')}!`
                            )}
                            {gameResult.winner === 'small' && (
                              `${t('smallWin')}!`
                            )}
                          </Fragment>
                          )}
                          {gameResult.type === 'galdins' && (
                            `${gameResult.largeTricks} ${t('tricks')} (+${gameResult.largePoints})`
                          )}
                        </Fragment>

                      </Col>
                    </Row>
                  </TabPane>
                )}
                {gameResult && gameResult.type === 'pass' && (
                  <>
                    {(minGames && minGames > rPlayed) ? (
                      <>
                        {(minGames - rPlayed) === 1 ? (
                          <div>{`${t('allPassedBodyMinGamesOne')}`}</div>
                        ) : (
                          <div>{`${t('allPassedBodyMinGames', { parties: minGames - rPlayed })}`}</div>
                        )}
                      </>
                    ) : (
                      <div>{`${t('allPassedBody')}`}</div>
                    )}

                    {(gameResult.cardsOnTable && gameResult.cardsOnTable.cards) ? (
                      <div className="notification-cards mb-4 notification-cards-new-section">
                        <div className="notification-cards-text mb-4">
                          {' '}
                          {`${t('cardsOnTable')}`}
                          {' '}
                        </div>
                        <div className={`notification-cards-card notification-cards-card-${gameResult.cardsOnTable.cards[0]}  notification-cards-new-section-first-card`} />
                        <div className={`notification-cards-card notification-cards-card-${gameResult.cardsOnTable.cards[1]}  notification-cards-new-section-second-card`} />
                      </div>
                    ) : null}
                  </>
                )}
                {gameResult && gameResult.type !== 'pass' && (
                  <Fragment>
                    {(gameResult && gameResult.winnerUID && gameResult.winnerUID.includes(memberUid)) ? (
                      <div
                        className="winner-anim-image"
                      >
                        <Lottie
                          options={{
                            loop: false,
                            autoplay: false,
                            animationData: winnerAnimImg,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={202}
                          width={450}
                          isStopped={false}
                          isPaused={!playWinAnim}
                        />
                      </div>
                    ) : (
                      <div
                        className="winner-anim-image"
                      >
                        <Lottie
                          options={{
                            loop: false,
                            autoplay: false,
                            animationData: loserAnimImg,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={202}
                          width={450}
                          isStopped={false}
                          isPaused={!playWinAnim}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            </TabContent>
          }
          footer={
            <Button color="link" onClick={this.closeResultModal}>{t('ok')}</Button>
          }
        />
      </div>
    );
  }
}

export default withTranslation(['notifications', 'common'])(Notification);
