import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
// import { constant, map, filter } from 'lodash';

import Label from 'reactstrap/lib/Label';
// import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
// import Modal from 'reactstrap/lib/Modal';
// import ModalHeader from 'reactstrap/lib/ModalHeader';
// import ModalBody from 'reactstrap/lib/ModalBody';
// import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
// import Media from 'reactstrap/lib/Media';
// import Form from 'reactstrap/lib/Form';
// import FormGroup from 'reactstrap/lib/FormGroup';

// import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';

// import pencilImg from '../../../images/icons/pencil.svg';
// import checkImg from '../../../images/icons/check.png';
// import cancelImg from '../../../images/icons/close_old.png';
// import closeImg from '../../../images/icons/close.png';

import * as constants from '../../../constants/constants';

import {
  getAllReportedPlayers,
  checkAdminReportPlayers,
} from '../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class ReportedPlayers extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    allReportedPlayers: PropTypes.shape([]),
    fetchAllReportedPlayers: PropTypes.func.isRequired,
    toggleCheckAdmin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allShopDiscounts: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
    };
  }

  componentDidMount() {
    const { fetchAllReportedPlayers } = this.props;

    fetchAllReportedPlayers();
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  handleCheckAdmin = (key, reporterId, reporterName, reportedId, reportedName, reason) => {
    const { toggleCheckAdmin } = this.props;
    toggleCheckAdmin(key, reporterId, reporterName, reportedId, reportedName, reason);
  }

  table() {
    const {
      t,
      allReportedPlayers,
      i18n,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allReportedPlayers).length / pageSize);
    return (
      <Fragment>
        {Object.keys(allReportedPlayers).length > 0 && Object.keys(allReportedPlayers)
          .sort((a, b) => (allReportedPlayers[b].reportedTime - allReportedPlayers[a].reportedTime))
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={new Date(allReportedPlayers[key].reportedTime)} />
                </td>
                <td className="allUsers-table-col">
                  <div className="d-flex flex-column align-items-center">
                    <Label>{allReportedPlayers[key].reporterId}</Label>
                    <Label>{allReportedPlayers[key].reporterName}</Label>
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <div className="d-flex flex-column align-items-center">
                    <Label>{allReportedPlayers[key].reportedPlayerId}</Label>
                    <Label>{allReportedPlayers[key].reportedPlayerName}</Label>
                  </div>
                </td>
                <td className="allUsers-table-col">
                  {allReportedPlayers[key].reportReason}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" disabled={allReportedPlayers[key]?.adminView} onClick={allReportedPlayers[key]?.adminView ? null : () => this.handleCheckAdmin(key, allReportedPlayers[key].reporterId, allReportedPlayers[key].reporterName, allReportedPlayers[key].reportedPlayerId, allReportedPlayers[key].reportedPlayerName, allReportedPlayers[key].reportReason) }>
                    {allReportedPlayers[key]?.adminView ? 'Done' : 'View'}
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allReportedPlayers).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
    } = this.props;

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-report-players">
          <PopoverBody className="popover-body">
            A list of players that have been reported in the game by other players. Each report can be marked as Done once it has been checked.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="4">
              <h2>
                {/* {t('shopDiscounts')} */}
                Report Players
                <Button className="admin-help-button" id="admin-help-button-report-players">
                  ?
                </Button>
              </h2>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Id and Name of reporter
                </th>
                <th style={{ textAlign: 'center' }}>
                  Id and Name of reported player
                </th>
                <th style={{ textAlign: 'center' }}>
                  Reason for report
                </th>
                <th style={{ textAlign: 'center' }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allReportedPlayers: state.admin.allReportedPlayers || [],
});

const mapDispatchToProps = {
  fetchAllReportedPlayers: getAllReportedPlayers,
  toggleCheckAdmin: checkAdminReportPlayers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(ReportedPlayers));
