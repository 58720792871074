import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';


import Moment from 'react-moment';
// import moment from 'moment';

import RoomLogs from './RoomLogs';
import Statistics from './MissedRoomsTotals';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import {
  getRoomLog,
  getMissedRooms,
} from '../../../actions/admin';

class MissedRoomsStatistics extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    //  allRooms: PropTypes.shape({}),
    allRooms: PropTypes.arrayOf(PropTypes.shape({})),
    roomData: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchRoomLog: PropTypes.func.isRequired,
    fetchMissedRooms: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    allRooms: [],
    roomData: {},
    filteredRoomsLogs: null,
    roomsPlayedCount: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      //  roomFilter: '',
      //  tournamentFilter: '',
      filteredRoomsLogs: null,
      rooms: [],
      startDate: new Date(),
      endDate: new Date(),
      bets: [],
      states: [],
      partyEnded: [],
      handEnded: [],
      speeds: [],
      roomsCount: null,
    };

  //  this.toggle = this.toggle.bind(this);
  //  this.openModal = this.openModal.bind(this);
  //  this.handleChange = this.handleChange.bind(this);
  //  this.handleClick = this.handleClick.bind(this);
  }

/*  componentWillReceiveProps = (nextProps) => {
    const nextFilteredRoomsLogs = nextProps.filteredRoomsLogs;
    const { filteredRoomsLogs } = this.props;

    if (filteredRoomsLogs && !nextFilteredRoomsLogs) {
      this.setState({ currentPage: 0, filteredRoomsLogs: null });
    } else if (nextFilteredRoomsLogs) {
      this.setState({ filteredRoomsLogs: nextFilteredRoomsLogs });
    }
  } */

  componentWillUnmount = () => {
  //  const { fetchFilteredRoomLogs } = this.props;

  //  fetchFilteredRoomLogs('');
  }


  filter = () => {
    console.log('filter');
    const { fetchMissedRooms } = this.props;
    const { startDate, endDate } = this.state;

  //  console.log(startDate);

  //  console.log(Date.parse(startDate));

    if (startDate && endDate && Date.parse(startDate) && Date.parse(endDate)) {
      fetchMissedRooms(Date.parse(startDate), Date.parse(endDate)).then((res) => {
      //  console.log(res);
        if (res) {
          let rooms = [];

          let bets = {};
          let states = {};
          let partyEnded = {};
          let handEnded = {};
          let speeds = {};

          Object.keys(res).map((key) => {
            const { date, currentTurn, currentTable, globalParams, players, roomUsersEnv, minGames, bet, fourPRoom, cardsCount } = res[key];

            const { gameState, gameType, party, rPlayed, speed, talking, currentHand } = globalParams;

            let playersUpdated = {};

          //  console.log('cardsCount', { cardsCount });

            Object.keys(players).map((key2) => {
              playersUpdated[key2] = {
                ...players[key2],
                env: (roomUsersEnv && roomUsersEnv[players[key2].uid]) ? (roomUsersEnv[players[key2].uid].env) : null,
                cardsCount: (cardsCount && cardsCount[key2]) ? (cardsCount[key2]) : null,
              }
            })

            if (bet) {
              bets[bet] = (bets[bet] ? (bets[bet] + 1) : 1);
            }

            if (gameState) {
              states[gameState] = (states[gameState] ? (states[gameState] + 1) : 1);
            }

            if (party) {
              partyEnded[party] = (partyEnded[party] ? (partyEnded[party] + 1) : 1);
            }

            if (currentHand) {
              handEnded[currentHand] = (handEnded[currentHand] ? (handEnded[currentHand] + 1) : 1);
            }

            if (speed) {
              speeds[speed] = (speeds[speed] ? (speeds[speed] + 1) : 1);
            }

            rooms.push({
              roomId: key,
              players: playersUpdated,
              date,
              currentTurn,
              currentTable,
              minGames,
              bet,
              fourPRoom,
              gameState,
              gameType,
              party,
              rPlayed,
              speed,
              talking,
            })
          });

          rooms.sort((a, b) => {
            return b.date - a.date;
          });

          let betsArr = [];
          let statesArr = [];
          let partyEndedArr = [];
          let handEndedArr = [];
          let speedsArr = [];

          if (bets) {
            Object.keys(bets).map((key) => {
              betsArr.push({ key, value: bets[key] });
            })
          }

          if (states) {
            Object.keys(states).map((key) => {
              statesArr.push({ key, value: states[key] });
            })
          }

          if (partyEnded) {
            Object.keys(partyEnded).map((key) => {
              partyEndedArr.push({ key, value: partyEnded[key] });
            })
          }

          if (handEnded) {
            Object.keys(handEnded).map((key) => {
              handEndedArr.push({ key, value: handEnded[key] });
            })
          }

          if (speeds) {
            Object.keys(speeds).map((key) => {
              let keyVal = 'Parasta';
              if (key === "14") {
                keyVal = 'Atra'
              } else if (key === "6") {
                keyVal = 'Zibens'
              }
              speedsArr.push({ key, keyVal, value: speeds[key] });
            })
          }

          betsArr.sort((a, b) => {
            return b.value - a.value;
          });

          statesArr.sort((a, b) => {
            return b.value - a.value;
          });

          partyEndedArr.sort((a, b) => {
            return a.key - b.key;
          });

          handEndedArr.sort((a, b) => {
            return a.key - b.key;
          });

          speedsArr.sort((a, b) => {
            return b.value - a.value;
          });

          this.setState({ rooms, roomsCount: rooms.length, bets: betsArr, states: statesArr, partyEnded: partyEndedArr, handEnded: handEndedArr, speeds: speedsArr });
        }
      });
    }
  }

  changeFilterDate = (type, date) => {
    this.setState({ [type]: date });
  }

  openModal = (room) => {
    const { fetchRoomLog } = this.props;

    fetchRoomLog(room.roomId);

    this.setState({
      roomId: room.roomId,
      room,
      openModal: true,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  handleClick = (e, index) => {
    e.preventDefault();

  /*  const { fetchRoomLogsRange, roomsPlayedCount, filteredRoomsLogs } = this.props;
    if (filteredRoomsLogs && filteredRoomsLogs.length > 0) {
      console.log('has filtered');
    } else {
      const end = roomsPlayedCount - (50 * index);
      const start = roomsPlayedCount - ((50 * index) + 50);

      fetchRoomLogsRange(start, end);
    } */
    this.setState({
      currentPage: index,
    });
  }

  onHover = (roomId) => {
    this.setState({ popoverOpen: roomId });
  }

  onHoverLeave = () => {
    this.setState({ popoverOpen: null });
  }

  table = () => {
    const {
    //  allRooms,
      t,
    } = this.props;
    const {
      currentPage,
      pageSize,
      filteredRoomsLogs,
      rooms,
      popoverOpen,
    } = this.state;

    const roomsCount = rooms.length || 0;

    let roomsCopy = [...rooms];
    let pagesCount = Math.ceil(roomsCount / pageSize);

    if (roomsCopy && (roomsCopy.length || roomsCopy.length === 0)) {
      roomsCopy = roomsCopy.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize,
      );
    //  pagesCount = Math.ceil(rooms.length / pageSize);
    }

    if (!roomsCopy.length) {
      return null;
    }

    return (
      <Fragment>
        {roomsCopy
          .map((room, index) => (
            <Fragment key={room.roomId}>
              <tr key={room.roomId} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {room.date && (
                    <Moment format="DD-MM-YYYY, HH:mm:ss" locale="lv">
                      {room.date}
                    </Moment>
                  )}
                </td>
                <td className="allUsers-table-col">
                  {room.roomId}
                </td>
                <td className="allUsers-table-col">
                  <Button
                    id={`Popover-3-${room.roomId}`}
                    onMouseEnter={() => this.onHover(`Popover-3-${room.roomId}`)}
                    onMouseLeave={() => this.onHoverLeave()}
                  >
                    Room stats
                  </Button>
                  <Popover popperClassName="admin-statistics-table-popover" placement="bottom" isOpen={(popoverOpen && popoverOpen === `Popover-3-${room.roomId}`)} target={`Popover-3-${room.roomId}`}>
                    <PopoverBody>
                      <div>
                        <span>
                          Speed -
                        </span>
                        <span>
                          {room.speed === 24 ? (
                            "Normal"
                          ):null}
                          {room.speed === 14 ? (
                            "Fast"
                          ):null}
                          {room.speed === 6 ? (
                            "Lightening"
                          ):null}
                        </span>
                      </div>
                      <div>
                        <span>
                          Bet -
                        </span>
                        <span>
                          {room.bet}
                        </span>
                      </div>
                      <div>
                        <span>
                          Players -
                        </span>
                        <span>
                          {room.fourPRoom ? '4p' : '3p'}
                        </span>
                      </div>
                    </PopoverBody>
                  </Popover>
                  <div>
                  </div>
                </td>
              {/*  <td className="allUsers-table-col">
                  {room.speed === 24 ? (
                    "Parasta"
                  ):null}
                  {room.speed === 14 ? (
                    "Ātra"
                  ):null}
                  {room.speed === 6 ? (
                    "Zibens"
                  ):null}
                </td>
                <td className="allUsers-table-col">
                  {room.bet}
                </td>
                <td className="allUsers-table-col">
                  {room.fourPRoom ? '4p' : '3p'}
                </td> */}
                <td className="allUsers-table-col">
                  {room.party}
                </td>
                <td className="allUsers-table-col">
                  {room.currentTurn ? (`${room.players[room.currentTurn].name} (${room.players[room.currentTurn].env})`) : null}
                </td>
                <td className="allUsers-table-col">
                  <Button
                    id={`Popover-1-${room.roomId}`}
                    onMouseEnter={() => this.onHover(`Popover-1-${room.roomId}`)}
                    onMouseLeave={() => this.onHoverLeave()}
                  >
                    {`Game State (${room.gameState})`}
                  </Button>
                  <Popover popperClassName="admin-statistics-table-popover" placement="bottom" isOpen={(popoverOpen && popoverOpen === `Popover-1-${room.roomId}`)} target={`Popover-1-${room.roomId}`}>
                    <PopoverBody>
                    <div>
                      {`Game State - ${room.gameState}`}
                    </div>
                    <div>
                      <span>
                        {`Current Table - `}
                      </span>
                      {room.currentTable ? (
                        <>
                          {Object.keys(room.currentTable).map((key) => (
                            <span>
                              {`${room.currentTable[key].card}`}
                            </span>
                          ))}
                        </>
                      ):(null)}
                    </div>
                    </PopoverBody>
                  </Popover>
                  <div>
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <Button
                    id={`Popover-2-${room.roomId}`}
                    onMouseEnter={() => this.onHover(`Popover-2-${room.roomId}`)}
                    onMouseLeave={() => this.onHoverLeave()}
                  >
                    Players Data
                  </Button>
                  <Popover popperClassName="admin-statistics-table-popover" placement="bottom" isOpen={(popoverOpen && popoverOpen === `Popover-2-${room.roomId}`)} target={`Popover-2-${room.roomId}`}>
                    <PopoverBody>
                    {Object.keys(room.players).map((key) => (
                      <div>
                        {`${key} - ${room.players[key].name} - ${room.players[key].env} - ${room.players[key].cardsCount} Card`}
                      </div>
                    ))}
                    </PopoverBody>
                  </Popover>
                  <div>
                  </div>
                </td>

                <td className="allUsers-table-col">
                  <Button onClick={() => this.openModal(room)}>
                    {/* {t('view')} */}
                    View
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {roomsCount > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      openModal, roomId, room, filter, startDate, endDate, rooms, bets, states, partyEnded, handEnded, speeds, roomsCount,
    } = this.state;

    const { roomData, t } = this.props;

  //  console.log(this.state);

  //  console.log('roomData', { roomData });

    return (
      <Fragment>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            {/* {t('rooms')} */}
            Rooms
          </h2>
          <Row>
                <Col sm="4">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                  //  time={false}
                    onChange={(date) => this.changeFilterDate('startDate', date)}
                    value={startDate}
                    defaultValue={new Date()}
                  />
                </Col>
                <Col sm="4">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                  //  time={false}
                    onChange={(date) => this.changeFilterDate('endDate', date)}
                    value={endDate}
                    defaultValue={new Date()}
                  />
                </Col>
                <Col sm="4">
                  <Button color="primary" onClick={this.filter}>
                    {/* {t('filter')} */}
                    Filter
                  </Button>
                </Col>
          </Row>
          <Statistics bets={bets} states={states} partyEnded={partyEnded} handEnded={handEnded} speeds={speeds} roomsCount={roomsCount} />
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('date')} */}
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('roomId')} */}
                  Room Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  Room stats
                </th>
              {/*  <th style={{ textAlign: 'center' }}>
                  Speed
                </th>
                <th style={{ textAlign: 'center' }}>
                  Bet
                </th>
                <th style={{ textAlign: 'center' }}>
                  Player count
                </th> */}
                <th style={{ textAlign: 'center' }}>
                  Party
                </th>
                <th style={{ textAlign: 'center' }}>
                  Cur Turn
                </th>
                <th style={{ textAlign: 'center' }}>
                  Game State
                </th>
                <th style={{ textAlign: 'center' }}>
                  Players
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('view')} */}
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>
        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggle}>
            {room ? `Room: ${room.roomId}, Bet: ${room.bet}, ${room.minGames ? `Minimum number of rounds (games): ${room.minGames}` : ''}, ${room.fourPRoom ? '4' : '3'} Players` : ''}
          </ModalHeader>
          <ModalBody>
            <RoomLogs roomData={roomData} roomId={roomId} roomParams={room} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  roomData: state.admin.roomData || {},
});

const mapDispatchToProps = {
  fetchRoomLog: getRoomLog,
  fetchMissedRooms: getMissedRooms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(MissedRoomsStatistics));
