import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
  Media,
  PopoverBody,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { map, size, slice } from 'lodash';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TextEditor from '../TextEditor/TextEditor';

import DateFormat from '../UI/DateFormat';
import * as constants from '../../../constants/constants';

import closeImg from '../../../images/icons/close.png';

import {
  addEditerData,
  deleteEditor,
  getEditorData,
  updateEditorData,
} from '../../../actions/admin';
import { UncontrolledPopover } from 'reactstrap/lib';

moment.locale('lv');
momentLocalizer();

class NewVersionEditor extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    versionHistories: PropTypes.arrayOf(PropTypes.shape({})),
    showNotification: PropTypes.func.isRequired,
    deleteAEditor: PropTypes.func.isRequired,
  //  fetchTemplateData: PropTypes.func.isRequired,
  //  saveTemplateData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    versionHistories: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      openAddModal: false,
      versionDate: new Date(),
      editorState: EditorState.createEmpty(),
      editorStateSet: false,
      success: null,
      error: null,
      languages: ['lv', 'en', 'ru'],
      currentPage: 0,
      pageSize: 50,
      deleteModal: false,
      selectId: '',
      editModal: false,
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    const { languages } = this.state;

    languages.map((language) => {
      this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
      return null;
    });

    this.setState({ editorStateSet: true });

    fetchData();
    // fetchData().then((res) => {
    //   if (res) {
    //     languages.map((language) => {
    //       if (res[language]) {
    //         const blocksFromHtml = htmlToDraft(res[language]);
    //         const { contentBlocks, entityMap } = blocksFromHtml;
    //         const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    //         if (contentState) {
    //           this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createWithContent(contentState) } }));
    //         }
    //       } else {
    //       //  this.setState({ [language]: EditorState.createEmpty() });
    //         this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
    //       }
    //       return null;
    //     });

    //     this.setState({ editorStateSet: true });
    //   } else {
    //     languages.map((language) => {
    //       this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
    //       return null;
    //     });

    //     this.setState({ editorStateSet: true });
    //   }
    // });
  }

  toggleAdd = () => {
    constants.LANGUAGE_LIST.map((language) => {
      this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
    });
    this.setState(prevState => ({
      openAddModal: !prevState.openAddModal,
      editorStateSet: true,
    }));
  }

  handleDate = (date) => {
    this.setState({
      versionDate: date,
    });
  }

  handleEditState = (editorState, language) => {
    this.setState(prevState => ({
      success: null,
      error: null,
      editorState: { ...prevState.editorState, [language]: editorState },
    }));
  }

  editVersionHistory = () => {
    const { updateData, showNotification, t, fetchData } = this.props;
    const { editorState, versionDate, selectId } = this.state;

    if (selectId) {
      if (editorState) {
        if (editorState[constants.sendLanguage.lv] && editorState[constants.sendLanguage.en] && editorState[constants.sendLanguage.ru]) {
          const rawContentStateLV = convertToRaw(editorState[constants.sendLanguage.lv].getCurrentContent());
          const markupLV = draftToHtml(rawContentStateLV);

          const rawContentStateEN = convertToRaw(editorState[constants.sendLanguage.en].getCurrentContent());
          const markupEN = draftToHtml(rawContentStateEN);

          const rawContentStateRU = convertToRaw(editorState[constants.sendLanguage.ru].getCurrentContent());
          const markupRU = draftToHtml(rawContentStateRU);

          const data = {
            lv: markupLV,
            en: markupEN,
            ru: markupRU,
          };

          updateData(selectId, data, versionDate).then((res) => {
            if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
              showNotification('Success', 'Success', constants.FROUNT_SIDE_STATUS.success);
              fetchData();
            } else {
              showNotification('Error', 'Error', constants.FROUNT_SIDE_STATUS.danger);
            }
          }).catch((err) => {
            console.log(err);
            showNotification('Error', 'Error', constants.FROUNT_SIDE_STATUS.danger);
          });
        }
        return null;
      }
      return null;
    }
  }

  addVersionHistory = () => {
    const { addData, showNotification, t, fetchData } = this.props;
    const { editorState, versionDate } = this.state;


    if (editorState) {
      if (editorState[constants.sendLanguage.lv] && editorState[constants.sendLanguage.en] && editorState[constants.sendLanguage.ru]) {
        const rawContentStateLV = convertToRaw(editorState[constants.sendLanguage.lv].getCurrentContent());
        const markupLV = draftToHtml(rawContentStateLV);

        const rawContentStateEN = convertToRaw(editorState[constants.sendLanguage.en].getCurrentContent());
        const markupEN = draftToHtml(rawContentStateEN);

        const rawContentStateRU = convertToRaw(editorState[constants.sendLanguage.ru].getCurrentContent());
        const markupRU = draftToHtml(rawContentStateRU);

        const data = {
          lv: markupLV,
          en: markupEN,
          ru: markupRU,
        };

        addData(data, versionDate).then((res) => {
          if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
            showNotification('Success', 'Success', constants.FROUNT_SIDE_STATUS.success);
            fetchData();
          } else {
            showNotification('Error', 'Error', constants.FROUNT_SIDE_STATUS.danger);
          }
        }).catch((err) => {
          console.log(err);
          showNotification('Error', 'Error', constants.FROUNT_SIDE_STATUS.danger);
        });
      }
      return null;
    }
    return null;
  }

  toggleEditorSet = () => {
    this.setState({
      editorStateSet: true,
    });
  }

  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectId: key,
    }));
  }

  deleteEditor = () => {
    const { deleteAEditor, showNotification, t, fetchData } = this.props;
    const { selectId } = this.state;

    if (selectId !== '') {
      deleteAEditor(selectId).then((res) => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          showNotification('Success', 'Success', constants.FROUNT_SIDE_STATUS.success);
          fetchData();
        }

        if (res.status === constants.FROUNT_SIDE_STATUS.error) {
          return showNotification('Error', constants.FROUNT_SIDE_STATUS.error, constants.FROUNT_SIDE_STATUS.danger);
        }
        this.setState({
          deleteModal: false,
          selectId: '',
        });
      });
    }
  }

  editModal = (id) => {
    const { versionHistories } = this.props;
    const { languages } = this.state;
    const selectHistory = versionHistories[id];
    languages.map((language) => {
      if (selectHistory[language]) {
        const blocksFromHtml = htmlToDraft(selectHistory[language]);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createWithContent(contentState) } }));
        }
      } else {
      //  this.setState({ [language]: EditorState.createEmpty() });
        this.setState(prevState => ({ editorState: { ...prevState.editorState, [language]: EditorState.createEmpty() } }));
      }
    });

    this.setState({
      selectId: versionHistories[id].key,
      versionDate: new Date(selectHistory.versionDate) || null,
      editModal: true,
      deleteModal: false,
      openAddModal: false,
      editorStateSet: true,
    });
  }

  initialState = () => {
    this.setState({
      selectId: '',
      versionDate: new Date(),
      editModal: false,
      deleteModal: false,
      openAddModal: false,
      editorStateSet: false,
      editorState: EditorState.createEmpty(),
    });
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  table = () => {
    const {
      t,
      versionHistories,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = size(versionHistories) / pageSize;
    return (
      <Fragment>
        {size(versionHistories) > 0 && Object.keys(versionHistories)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className="texteditor-table-row">
                <td className="texteditor-table-col" style={{ width: '10%' }}>
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.pointsfullDate} date={versionHistories[key].versionDate}/>
                </td>
                <td className="texteditor-table-col">
                  <TextEditor viewEditorState={versionHistories[key].lv} readOnly />
                </td>
                <td className="texteditor-table-col">
                  <TextEditor viewEditorState={versionHistories[key].en} readOnly />
                </td>
                <td className="texteditor-table-col">
                  <TextEditor viewEditorState={versionHistories[key].ru} readOnly />
                </td>
                <td className="texteditor-table-col" style={{ width: '10%' }} onClick={() => this.editModal(key)}>
                  <Button className="allGiftCodes-table-col-button" color="primary">
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="texteditor-table-col" style={{ width: '10%' }}>
                  <Button className="texteditor-table-col-button" color="danger" onClick={() => this.toggleDeleteModal(versionHistories[key].key)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {size(versionHistories) > pageSize && (
        <div className="pagination-wrapper">
          <Pagination aria-label="Page navigation example">
            
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink onClick={e => this.handleClick(e, 0)} href="#">
                {`‹‹‹`}
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage - 1)}
                previous
                href="#"
              />
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => {
              if (i > currentPage - 3 && i < currentPage + 3) {
                return (
                  <PaginationItem active={i === currentPage} key={page}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              return null;
            })}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage + 1)}
                next
                href="#"
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                {`›››`}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { t, versionHistories } = this.props;
    const { openAddModal, versionDate, success, error, editorState, editorStateSet, deleteModal, selectId, editModal, } = this.state;
  console.log(editorState, 'check=========================');
  //  console.log({versionHistories});
    return (
      <>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-version-history">
          <PopoverBody className="popover-body">
            Once a new game version has been deployed, an admin should add a new entry of version history or changelog with features, changes, and bug fixes. The version history is displayed on the Landing page - Version History. Every changelog requires the body text in each of the listed languages, and also the featured image.
          </PopoverBody>
        </UncontrolledPopover>
        <Row className="mt-5">
          <Col md="6">
            <h2>
              Version History
              <Button className="admin-help-button" id="admin-help-button-version-history">
                ?
              </Button>
            </h2>
          </Col>
          <Col md="6">
            <Button onClick={() => this.toggleAdd()}>
              Add Version History
            </Button>
          </Col>
        </Row>
        <Row>
          <table className="textAlign" style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ width: '10%' }}>
                  {/* {t('versionDate')} */}
                  Version Date
                </th>
                <th>
                  {/* {t('lv')} */}
                  Latvian
                </th>
                <th>
                  {/* {t('en')} */}
                  English
                </th>
                <th>
                  {/* {t('ru')} */}
                  Russian
                </th>
                <th style={{ width: '10%' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
                <th style={{ width: '10%' }}>
                  {/* {t('delete')} */}
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </Row>
        {/* Edit Version History modal */}
        <Modal container={'div > div'} className="admin-modal" size="lg" isOpen={editModal} toggle={this.initialState}>
          <ModalHeader toggle={this.initialState}>
            {/* {t('edit')} */}
            Edit
          </ModalHeader>
          <ModalBody>
            <Label for="versionDate">
              {/* {t('versionDate')} */}
              Version Date
            </Label>
            <Row>
              <Col md="12">
                <DateTimePicker
                  time={false}
                  format={constants.FORMAT_DATE_TYPE.pointsfullDate}
                  culture={constants.sendLanguage.lv}
                  onChange={this.handleDate}
                  value={versionDate}
                  defaultValue={new Date()}
                />
                <TextEditor editorState={editorState} editorStateSet={editorStateSet} languages={['lv', 'en', 'ru']} readOnly={false} handleEditState={this.handleEditState} />

                {/* <Button onClick={() => this.saveText(language)}> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.editVersionHistory}>Save</Button>
            {' '}
            <Button color="secondary" onClick={this.initialState}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Version History modal */}
        <Modal container={'div > div'} className="admin-modal" size="lg" isOpen={openAddModal} toggle={this.initialState}>
          <ModalHeader toggle={this.initialState}>
            {/* {t('addVersionHistory')} */}
            Add Version History
          </ModalHeader>
          <ModalBody>
            {(!!success) && <Alert color="success">Update successful</Alert>}
            {(!!error) && <Alert color="danger">Update failed, please try again</Alert>}
            <Label for="versionDate">
              {/* {t('versionDate')} */}
              Version Date
            </Label>
            <Row>
              <Col md="12">
                <DateTimePicker
                  time={false}
                  format={constants.FORMAT_DATE_TYPE.pointsfullDate}
                  culture={constants.sendLanguage.lv}
                  onChange={this.handleDate}
                  value={versionDate}
                  defaultValue={new Date()}
                />
                <TextEditor editorState={editorState} editorStateSet={editorStateSet} languages={['lv', 'en', 'ru']} readOnly={false} handleEditState={this.handleEditState} />

                {/* <Button onClick={() => this.saveText(language)}> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addVersionHistory}>Add Version History</Button>
            {' '}
            <Button color="secondary" onClick={this.initialState}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Delete version history modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={this.toggleDeleteModal} className="notification">
          <ModalHeader
            toggle={this.toggleDeleteModal}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleDeleteModal} />
            }
          />
          <ModalBody className="notification-body">
            {/* {t('deleteQuiz')} */}
            Do you really want to delete the data?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteEditor}>
              {/* {t('yes')} */}
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={this.toggleDeleteModal}>
              {/* {t('no')} */}
              No
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  versionHistories: state.admin.versionHistories || {},
});

const mapDispatchToProps = {
  fetchData: getEditorData,
  updateData: updateEditorData,
  addData: addEditerData,
  deleteAEditor: deleteEditor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(NewVersionEditor));
