import React from 'react';
import PropTypes from 'prop-types';
import { map, filter } from 'lodash';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

// import Lottie from 'react-lottie';

import { withTranslation } from 'react-i18next';
// import PlayerInfo from '../../Players/Components/PlayerInfo';

import * as constants from '../../../../../../constants/constants';

// import winnerAnimImg from '../../../../../../images/Game/winner.json';
// import loserAnimImg from '../../../../../../images/Game/loser.json';

import defaultImage from '../../../../../../images/Game/defaultImage.webp';

const GameResultBody = React.memo(({ gameResult, t, minGames, rPlayed, memberUid, playWinAnim, players, lastRound }) => {
  // const gameResult = {}
  // gameResult.winner = 'small'
  // gameResult.type = 'galdins'//'galdins'//'zole'//'parasta'
  // gameResult.scoreType = constants.GMAE_WIN_TYPE.jani//constants.GMAE_WIN_TYPE.jani//constants.GMAE_WIN_TYPE.parasts//'parasts'//bezstikis
  // gameResult.largePoints = 20
  // gameResult.largeTricks = 20
  // gameResult.winnerUID = ['player1', 'player2']

  const playerWins = (gameResult && gameResult.type !== 'pass' && gameResult?.winnerUID) ? gameResult.winnerUID.includes(memberUid) : null;
  const losersUID = (gameResult && gameResult.type !== 'pass' && players?.playerList && gameResult.winnerUID) ? Object.keys(players?.playerList).filter(player => !gameResult.winnerUID.includes(player)) : [];

  const playerIsLarge = (playerWins && gameResult?.winner === constants.GMAE_WIN_TYPE.large) || (!playerWins && gameResult?.winner !== constants.GMAE_WIN_TYPE.large);

  const getPoints = (gameResult, playerIsLarge) => {
    if (playerIsLarge) {
      return gameResult?.largePoints;
    }
    return 120 - gameResult?.largePoints;
  }

  const getName = (playersObj, uid) => {
    for (let key of Object.keys(playersObj)) {
      if (playersObj[key].uid && playersObj[key].uid === uid) {
        return playersObj[key].name;
      }
    }
    return 'NOT_FOUND';
  }

  if (gameResult?.type === 'pass') {
    const remainingParties = minGames - rPlayed;
    return (
      <>
        { remainingParties === 1 
          ? lastRound
            ? <div className="mb-1">{`${t('allPassedBodyMinGamesOne')}`}</div>
            : <div className="mb-1">{`${t('allPassedBody')} ${t('allPassedLastGameNotPressed')}`}</div>
          : <div className="mb-1">{`${t('allPassedBody')}`}</div>
        }

        {(gameResult?.cardsOnTable && gameResult?.cardsOnTable.cards) ? (
          <div className="notification-cards mb-4 notification-cards-new-section">
            <div className="mb-4">
              {`${t('cardsOnTable')}:`}
            </div>
            <div className={`notification-cards-card notification-cards-card-${gameResult?.cardsOnTable.cards[0]} notification-cards-new-section-first-card`} />
            <div className={`notification-cards-card notification-cards-card-${gameResult?.cardsOnTable.cards[1]} notification-cards-new-section-second-card`} />
          </div>
        ) : null}
      </>
    )
  }

  let gameResultType = '';
  if (gameResult?.scoreType) {
    if (gameResult?.type === constants.GMAE_RESULT_TYPE.parasta || gameResult?.type === constants.GMAE_RESULT_TYPE.zole) {
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.parasts && gameResult?.winner === constants.GMAE_WIN_TYPE.large && gameResult?.type === constants.GMAE_RESULT_TYPE.parasta) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.parastaWinLarage;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.parasts && gameResult?.winner === constants.GMAE_WIN_TYPE.small && gameResult?.type === constants.GMAE_RESULT_TYPE.parasta) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.parastaWinSmall;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.parasts && gameResult?.winner === constants.GMAE_WIN_TYPE.large && gameResult?.type === constants.GMAE_RESULT_TYPE.zole) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.zoleWinLarage;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.parasts && gameResult?.winner === constants.GMAE_WIN_TYPE.small && gameResult?.type === constants.GMAE_RESULT_TYPE.zole) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.zoleWinSmall;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.jani && gameResult?.winner === constants.GMAE_WIN_TYPE.large) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.janiWinLarge;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.jani && gameResult?.winner === constants.GMAE_WIN_TYPE.small) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.janiWinSmall;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.bezstikis && gameResult?.winner === constants.GMAE_WIN_TYPE.large) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.bezstikisWinLarge;
      }
      if (gameResult?.scoreType === constants.GMAE_WIN_TYPE.bezstikis && gameResult?.winner === constants.GMAE_WIN_TYPE.small) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.bezstikisWinSmall;
      }
    }
    if (gameResult?.type === constants.GMAE_RESULT_TYPE.mazaZole) {
      if (gameResult?.winner === constants.GMAE_WIN_TYPE.large) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.mazaZoleWinLarge;
      }
      if (gameResult?.winner === constants.GMAE_WIN_TYPE.small) {
        gameResultType = constants.GMAE_RESULT_SCORE_TYPE.mazaZoleWinSmall;
      }
    }
    if (gameResult?.type === constants.GMAE_RESULT_TYPE.galdins) {
      gameResultType = constants.GMAE_RESULT_SCORE_TYPE.galdinsWin;
    }
  }

  const dontShowDecorations = [
    constants.GMAE_RESULT_SCORE_TYPE.zoleWinLarage,
    constants.GMAE_RESULT_SCORE_TYPE.zoleWinSmall,
    constants.GMAE_RESULT_SCORE_TYPE.janiWinLarge,
    constants.GMAE_RESULT_SCORE_TYPE.bezstikisWinLarge,
    constants.GMAE_RESULT_SCORE_TYPE.bezstikisWinSmall
  ].includes(gameResultType) && !playerWins;

  return (
    <div className="game-result-body-content">
      {gameResult?.scoreType && gameResult?.type !== 'pass' && (
        <Row>
          <Col sm="12">
            { playerWins ? (
              <>
                {(gameResult?.type === 'parasta' || gameResult?.type === 'zole') && (
                <>
                  {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeWin!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallWin!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'jani' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeWin!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'jani' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('smallWin!')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallWin!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${t('noTricks')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeWin!')}</div>
                      <div className="notification-points-section-points">{t('noTricks')}</div>
                      {/* <div className="notification-points-section-bottom">{t('points')}</div> */}
                    </div>
                  )}
                  {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${t('noTricks')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallWin!')}</div>
                      <div className="notification-points-section-points">{t('noTricks')}</div>
                      {/* <div className="notification-points-section-bottom">{t('points')}</div> */}
                    </div>
                  )}
                </>
                )}
                {gameResult?.type === 'mazaZole' && (
                <>
                  {gameResult?.winner === 'large' && (
                    // `${t('largeWins')}!`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeWin!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.winner === 'small' && (
                    // `${t('smallWin')}!`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallWin!')}</div>
                    </div>
                  )}
                </>
                )}
                {gameResult?.type === 'galdins' && (
                  // `${gameResult?.largeTricks} ${t('tricks')} (+${gameResult?.largePoints})`
                  <div className="notification-points-section d-flex align-items-center flex-column">
                    <div className="notification-points-section-title">{/*gameResult?.largeTricks + t('tricks')*/`${getName(players, losersUID[0])} ${t('losesTable')}`}</div>
                    <div className="notification-points-section-points">{/*getPoints(gameResult, playerIsLarge)*/`${gameResult?.largeTricks} ${t('tricks')}`}</div>
                    <div className="notification-points-section-bottom">{/*t('points')*/`${getPoints(gameResult, true)} ${t('points3')}`}</div>
                  </div>
                )}
              </>
            ) : (
              <>
                {(gameResult?.type === 'parasta' || gameResult?.type === 'zole') && (
                <>
                  {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallLose!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'parasts' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeLose!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'jani' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${gameResult?.largePoints} ${t('points')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallLose!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'jani' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${gameResult?.largePoints} ${t('smallWin!')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeLose!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'large' && (
                    // `${t('largeWinsWith')} ${t('noTricks')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallLose!')}</div>
                      <div className="notification-points-section-points">{t('noTricks')}</div>
                      {/* <div className="notification-points-section-bottom">{t('points')}</div> */}
                    </div>
                  )}
                  {gameResult?.scoreType === 'bezstikis' && gameResult?.winner === 'small' && (
                    // `${t('largeLosesWith')} ${t('noTricks')}`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeLose!')}</div>
                      <div className="notification-points-section-points">{t('noTricks')}</div>
                      {/* <div className="notification-points-section-bottom">{t('points')}</div> */}
                    </div>
                  )}
                </>
                )}
                {gameResult?.type === 'mazaZole' && (
                <>
                  {gameResult?.winner === 'large' && (
                    // `${t('largeWins')}!`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('smallLose!')}</div>
                      <div className="notification-points-section-points">{getPoints(gameResult, playerIsLarge)}</div>
                      <div className="notification-points-section-bottom">{t('points')}</div>
                    </div>
                  )}
                  {gameResult?.winner === 'small' && (
                    // `${t('smallWin')}!`
                    <div className="notification-points-section d-flex align-items-center flex-column">
                      <div className="notification-points-section-title">{t('largeLose!')}</div>
                    </div>
                  )}
                </>
                )}
                {gameResult?.type === 'galdins' && (
                  // `${gameResult?.largeTricks} ${t('tricks')} (+${gameResult?.largePoints})`
                  <div className="notification-points-section d-flex align-items-center flex-column">
                    <div className="notification-points-section-title">{/*gameResult?.largeTricks + t('tricks')*/`${getName(players, memberUid)} ${t('losesTable')}`}</div>
                    <div className="notification-points-section-points">{/*getPoints(gameResult, playerIsLarge)*/`${gameResult?.largeTricks} ${t('tricks')}`}</div>
                    <div className="notification-points-section-bottom">{/*t('points')*/`${getPoints(gameResult, playerIsLarge)} ${t('points3')}`}</div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      )}

      <div className="notification-winners d-flex align-items-center flex-row">
        <div className={`game-score-type-icon ${!dontShowDecorations && gameResultType}`} />
        {playerWins || gameResult?.type === 'pass' ? (
          <div className={classNames("player-info-wrapper-section d-flex align-items-center", {
            'player-info-wrapper-container': gameResult?.winner === constants.GMAE_WIN_TYPE.small,
            'player-info-wrapper-container-large': gameResult?.winner !== constants.GMAE_WIN_TYPE.small,
            'player-info-wrapper-container-4p': gameResult?.winnerUID.length === 3 && gameResult?.winner === constants.GMAE_WIN_TYPE.small,
            'player-info-wrapper-container-4p-large': gameResult?.winnerUID.length === 3 && gameResult?.winner !== constants.GMAE_WIN_TYPE.small,
            'player-info-wrapper-container-3p': gameResult?.winnerUID.length === 2 && gameResult?.winner === constants.GMAE_WIN_TYPE.small,
            'player-info-wrapper-container-3p-large': gameResult?.winnerUID.length === 2 && gameResult?.winner !== constants.GMAE_WIN_TYPE.small
          })}>
            {
              gameResult && gameResult?.winnerUID && players && (
                map(gameResult?.winnerUID, item => (
                  <>
                    <div className={classNames("player-info-wrapper", {'player-info-wrapper-large': gameResult?.winner !== constants.GMAE_WIN_TYPE.small})} >
                      <div className="player-info-player-image-background" />
                      <div style={{ backgroundImage: `url(${(players && filter(players, (player) => {return player.uid && player.uid === item;})[0]) ? filter(players, (player) => {return player.uid && player.uid === item;})[0].photo : defaultImage })`}} className="player-info-player-image" />
                      <div className="player-info-player-image-overlay" />
                      <div className={classNames({'player-info-player-image-lg-gift': gameResult?.winner !== constants.GMAE_WIN_TYPE.small, 'player-info-player-image-sm-gift': gameResult?.winner === constants.GMAE_WIN_TYPE.small})} />
                    </div>
                  </>
                ))
              )
            }
          </div>
        ) : (
          <div className={classNames("player-info-wrapper-section d-flex align-items-center")} >
            {
              gameResult && losersUID && players && (
                map(losersUID, item => (
                  <>
                    <div className={classNames("player-info-wrapper player-info-wrapper-loser", {'player-info-wrapper-large': gameResult?.winner === constants.GMAE_WIN_TYPE.small, 'player-info-wrapper-loser-large': gameResult?.winner === constants.GMAE_WIN_TYPE.small})}>
                      <div className="player-info-player-image-background" />
                      <div style={{ backgroundImage: `url(${(players && filter(players, (player) => {return player.uid && player.uid === item;})[0]) ? filter(players, (player) => {return player.uid && player.uid === item;})[0].photo : defaultImage })`}} className="player-info-player-image" />
                      <div className="player-info-player-image-overlay" />
                      <div className={classNames({'player-info-player-image-lg-crossout': gameResult?.winner === constants.GMAE_WIN_TYPE.small, 'player-info-player-image-sm-crossout': gameResult?.winner !== constants.GMAE_WIN_TYPE.small})} />
                    </div>
                  </>
                ))
              )
            }
          </div>
        )}
      </div>

      {/* {(gameResult && gameResult?.winnerUID && gameResult?.winnerUID.includes(memberUid)) ? (
        <div
          className="winner-anim-image"
        >
          <Lottie
            options={{
              loop: false,
              autoplay: false,
              animationData: winnerAnimImg,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={202}
            width={450}
            isStopped={false}
            isPaused={!playWinAnim}
          />
        </div>
      ) : (
        <div
          className="winner-anim-image"
        >
          <Lottie
            options={{
              loop: false,
              autoplay: false,
              animationData: loserAnimImg,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={202}
            width={450}
            isStopped={false}
            isPaused={!playWinAnim}
          />
        </div>
      )} */}
    </div>
  );
});

GameResultBody.propTypes = {
  gameResult: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  players: PropTypes.shape(),
  lastRound: PropTypes.bool,
};

GameResultBody.defaultProps = {
  gameResult: {},
  players: {},
  lastRound: false,
};

export default withTranslation('notifications')(GameResultBody);
