import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { map, size, includes, isNull } from 'lodash';
import ReactGA from "react-ga";

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';

import Toggle from '../../Components/Components/Toggle';

import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

import {
  changeNotificationSettings, toggleAllNotificationSettings, setDefaultSettings, changeGameSetting, toggleSound,
} from '../../../../../actions/userSettings';

import {
  USER_SYSTEM_SETTINGS, SCREEN_MODE_OPTIONS, SCREEN_MODE,
} from '../../../../../constants/constants';
import { getUserAutoPlay, toggleAutoPlay, toggleShowHint, getUserShowHint, setUserLoginStatistics, handleSetTheme, updateAutoMergeRooms, updateAutoMergeRooms222 } from '../../../../../actions/member';
import config from '../../../../../constants/config';
import { Media } from 'reactstrap';
import lvFlag from '../../../../../images/redesign/flags/lv.svg';
import ruFlag from '../../../../../images/redesign/flags/ru.svg';
import enFlag from '../../../../../images/redesign/flags/en.svg';
import IconPopover from '../../Components/Components/IconPopover';

import DarkThemeModeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-mode-icon.svg';
import NormalThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-mode-icon.svg';
import LightThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-mode-icon.svg';
import DarkSelectedThemeIcon from '../../../../../images/redesign/mobile/icons/dark-theme-selected-mode-icon.svg';
import NormalSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/normal-theme-selected-mode-icon.svg';
import LightSelectedThemeModeIcon from '../../../../../images/redesign/mobile/icons/light-theme-selected-mode-icon.svg';
import SoundButton from '../../Components/Layout/SoundButton';

class UserSettings extends React.Component {
  static propTypes = {
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    notificationSettings: PropTypes.shape({
      leaveRoom: PropTypes.bool,
      lastRound: PropTypes.bool,
      quitRound: PropTypes.bool,
      ignorePlayer: PropTypes.bool,
      quitAccount: PropTypes.bool,
      purchaseGift: PropTypes.bool,
      removeGift: PropTypes.bool,
      newDesign: PropTypes.bool,
      showAfterRound: PropTypes.bool,
      showAtRoomEnd: PropTypes.bool,
    }),
    gameSettings: PropTypes.shape({
      doubleClickPlay: PropTypes.bool,
    }),
    userSettings: PropTypes.shape({
      soundOn: PropTypes.bool,
    }),
    changeNotification: PropTypes.func.isRequired,
    setDefault: PropTypes.func.isRequired,
    toggleAllNotifications: PropTypes.func.isRequired,
    changeSetting: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    showUserSettingsModal: PropTypes.func.isRequired,
    fetchAutoPlay: PropTypes.func.isRequired,
    changeAutoPlay: PropTypes.func.isRequired,
    disabledAutoPlay: PropTypes.bool,
    showHint: PropTypes.bool,
    changeShowHint: PropTypes.func.isRequired,
    fetchShowHint: PropTypes.func.isRequired,
    isLandscapeMobile: PropTypes.bool,
    isPortraitMobile: PropTypes.bool,
    autoMergeRooms: PropTypes.func.isRequired,
    setAutoMergeRooms: PropTypes.func.isRequired,
  };

  static defaultProps = {
    uid: null,
    notificationSettings: {},
    gameSettings: {},
    userSettings: {},
    disabledAutoPlay: false,
    showHint: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      //  createRoomClicked: false,
      //  gameBetChoserOpen: false,
      beforeSettings: [],
      disabledAutoPlayMode: props.disabledAutoPlay,
      newDesignState: props.newDesign,
      autoMergeRoomsState: props.autoMergeRooms || false,
      devMode: false,
      showHintMode: props.showHint,
      curLang: props.language,
      darkTheme: props.screenMode || SCREEN_MODE.normal,
      lightThemePopoverOpen: false,
      normalThemePopoverOpen: false,
      darkThemePopoverOpen: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }
  setLightThemePopoverOpen = (value) => {
    this.setState({lightThemePopoverOpen: value});
  }
  setNormalThemePopoverOpen = (value) => {
    this.setState({normalThemePopoverOpen: value});
  }
  setDarkThemePopoverOpen = (value) => {
    this.setState({darkThemePopoverOpen: value});
  }

  setDarkTheme = (theme) => {
    this.setState({darkTheme: theme});
  }

  componentDidMount() {
    const { notificationSettings, userSettings, gameSettings, setDefault, uid, i18n, changeNotification, fetchAutoPlay, fetchShowHint, newDesign } = this.props;

    fetchAutoPlay();
    fetchShowHint();

    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }
    
    const curLang = i18n.language;

    this.setState({ devMode, curLang: curLang || 'lv' });

    let tempSettings = [];
    if (!notificationSettings || Object.keys(notificationSettings).length === 0) {
      setDefault(uid);
    }

    if (notificationSettings) {
      map(notificationSettings, (item, key) => {
        if (item) {
          tempSettings.push(key);
        } else {
          if (key === USER_SYSTEM_SETTINGS.newDesign && newDesign) {
            tempSettings.push(key);
          }

        }
      })
    }
    if (!notificationSettings.leaveRoom && !notificationSettings.lastRound && !notificationSettings.quitRound && !notificationSettings.ignorePlayer
      && !notificationSettings.quitAccount && !notificationSettings.purchaseGift && !notificationSettings.removeGift) {
      tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
    }

    if (gameSettings && gameSettings.doubleClickPlay) {
      tempSettings.push(USER_SYSTEM_SETTINGS.doubleClickPlay);
    }

    this.setState({
      beforeSettings: [...tempSettings],
    })
  }

  componentDidUpdate(nextProps) {
    const { disabledAutoPlay, autoMergeRooms } = this.props;
    console.log(disabledAutoPlay, nextProps.disabledAutoPlay, nextProps.autoMergeRooms, "get auto play update")
    if (disabledAutoPlay !== nextProps.disabledAutoPlay) {
      this.setState({
        disabledAutoPlayMode: disabledAutoPlay,
      });
    }
    if (autoMergeRooms !== nextProps.autoMergeRooms) {
      this.setState({
        autoMergeRoomsState: autoMergeRooms,
      });
    }
  }

  setCurLang = (lang) => {
    this.setState({curLang: lang});
  }

  toggleLanguage = (lang) => {
    this.setCurLang(lang);
    this.props.i18n.changeLanguage(lang);

    ReactGA.event({
      category: 'Language',
      action: 'Change Language',
    });

    try {
      if (window && window.localStorage && window.localStorage.setItem) { //x
        localStorage.setItem('language', lang); //x
      }
    } catch (err) {
      console.log(err);
    }

    this.props.setUserLoginStatisticsDispatch(false, null, lang, null);
  }

  beforeChangeSetting = (setting) => {
    const { beforeSettings } = this.state;

    let tempSettings = beforeSettings;
    if (setting === USER_SYSTEM_SETTINGS.dontShowAnyNotification) {
      // !notificationSettings.leaveRoom && !notificationSettings.lastRound && !notificationSettings.quitRound && !notificationSettings.ignorePlayer
      //     && !notificationSettings.quitAccount && !notificationSettings.purchaseGift && !notificationSettings.removeGift
      if (!tempSettings.includes(USER_SYSTEM_SETTINGS.dontShowAnyNotification)) {
        console.log("beforeSettingsPush", tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), setting, USER_SYSTEM_SETTINGS.dontShowAnyNotification, beforeSettings);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift), 1);
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift), 1);
        tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
      } else {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) !== -1 && beforeSettings.includes(USER_SYSTEM_SETTINGS.leaveRoom) && beforeSettings.includes(USER_SYSTEM_SETTINGS.lastRound) && beforeSettings.includes(USER_SYSTEM_SETTINGS.quitRound) && beforeSettings.includes(USER_SYSTEM_SETTINGS.ignorePlayer) && beforeSettings.includes(USER_SYSTEM_SETTINGS.quitAccount) && beforeSettings.includes(USER_SYSTEM_SETTINGS.purchaseGift) && beforeSettings.includes(USER_SYSTEM_SETTINGS.removeGift)) {
          console.log("beforeSettingsPop", tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), setting, USER_SYSTEM_SETTINGS.dontShowAnyNotification, beforeSettings);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift), 1);
          tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
        } else {
          tempSettings.splice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), 1);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.leaveRoom);;
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.lastRound);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.quitRound);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.ignorePlayer);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.quitAccount);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.purchaseGift);
          if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) === -1) tempSettings.push(USER_SYSTEM_SETTINGS.removeGift);
        }
      }
    } else {
      if (tempSettings.indexOf(setting) === -1) {
        tempSettings.push(setting);
      } else {
        tempSettings.splice(tempSettings.indexOf(setting), 1);
      }
      if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) !== -1) {
          tempSettings.slice(tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification), 1);
        }
      }
      if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) === -1 && tempSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) === -1) {
        if (tempSettings.indexOf(USER_SYSTEM_SETTINGS.dontShowAnyNotification) === -1) {
          tempSettings.push(USER_SYSTEM_SETTINGS.dontShowAnyNotification);
        }
      }
    }

    this.setState({
      beforeSettings: [...tempSettings],
    })
    console.log(tempSettings, "checkSettings");
  }

  changeSetting = (setting, data) => {
    const {
      i18n,
      changeNotification,
      changeShowHint,
      showHint,
      changeSetting,
      uid,
      gameSettings,
      notificationSettings,
      toggleNewDesign,
      toggleAllNotifications,
      showUserSettingsModal,
      disabledAutoPlay,
      changeAutoPlay,
      setAutoMergeRooms,
      autoMergeRooms,
    } = this.props;
    const { beforeSettings, disabledAutoPlayMode, newDesignState, showHintMode, autoMergeRoomsState } = this.state;

    // console.log('changeSetting', { data, setting, gameSettings });

    // if (data && setting === 'doubleClickPlay') {
    //   if (data.type === 'single' && gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   } else if (data.type === 'double' && !gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   } else if (data.value === true && !gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   } else if (data.value === false && !gameSettings.doubleClickPlay) {
    //     changeSetting(setting, uid);
    //   }
    // //  changeSetting(setting, uid);
    // } else {
    //   if (setting === 'newDesign' && notificationSettings.newDesign) {
    //     localStorage.removeItem('newDesign');
    //     toggleNewDesign(false);
    //   } else if (setting === 'newDesign' && !notificationSettings.newDesign) {
    //     localStorage.setItem('newDesign', JSON.stringify(true));
    //     toggleNewDesign(true);
    //   }
    //   changeNotification(setting, uid);
    //

    console.log('USER_SYSTEM_SETTINGS', USER_SYSTEM_SETTINGS, autoMergeRoomsState, autoMergeRooms);

    map(USER_SYSTEM_SETTINGS, (item) => {
      if (item === USER_SYSTEM_SETTINGS.doubleClickPlay) {
        if ((!gameSettings?.doubleClickPlay && beforeSettings.indexOf(item) !== -1) || (gameSettings?.doubleClickPlay && beforeSettings.indexOf(item) === -1)) {
          changeSetting(item, uid);
        }
      } else if (item === USER_SYSTEM_SETTINGS.newDesign) {
        /* if (beforeSettings.indexOf(item) === -1) {
           localStorage.removeItem('newDesign');
           toggleNewDesign(false);
         } else {
           localStorage.setItem('newDesign', true);
           toggleNewDesign(true);
         } */
        /* if (!newDesignState) {
          try {
            localStorage.removeItem('newDesign');
          } catch (err) {
            console.log(err);
          }
          toggleNewDesign(false);
        } else {
          try {
            localStorage.setItem('newDesign', true);
          } catch (err) {
            console.log(err);
          }
          toggleNewDesign(true);
        }
        changeNotification(USER_SYSTEM_SETTINGS.newDesign, uid); */
      } else if (item === USER_SYSTEM_SETTINGS.autoPlay) {
        if (disabledAutoPlayMode !== disabledAutoPlay) {

          changeAutoPlay(disabledAutoPlayMode);
        }
      } else if (item === USER_SYSTEM_SETTINGS.autoMergeRooms) {
        console.log('save autoMergeRooms', autoMergeRoomsState, autoMergeRooms);
        if (autoMergeRoomsState !== autoMergeRooms) {
          console.log('setAutoMergeRooms', autoMergeRoomsState);
          setAutoMergeRooms(autoMergeRoomsState);
        }
      } else if (item === USER_SYSTEM_SETTINGS.showHint) {
        if (showHintMode !== showHint) {

          changeShowHint(showHintMode);
        }
      } else {
        if ((!notificationSettings[item] && beforeSettings.indexOf(item) !== -1) || (notificationSettings[item] && beforeSettings.indexOf(item) === -1)) {
          changeNotification(item, uid);
        }
      }
    });

    showUserSettingsModal();
  }

  toggleAllNotifications = () => {
    const { toggleAllNotifications, uid } = this.props;

    toggleAllNotifications(uid);
  };

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
    }
  }

  toggleLanguageFunc = (lang) => {
    const { i18n } = this.props;

    this.setState({ curLang: lang });

    // i18n.changeLanguage(lang);

    // ReactGA.event({
    //   category: 'Language',
    //   action: 'Change Language'
    // });
  }

  handleChange = () => {
    this.setState(previuos => ({
      disabledAutoPlayMode: !previuos.disabledAutoPlayMode
    }));
  }

  handleChangeNewDesign = () => {
    this.setState(previuos => ({
      newDesignState: !previuos.newDesignState
    }));
  }

  handleHintChange = () => {
    this.setState(previous => ({
      showHintMode: !previous.showHintMode
    }));
  };

  handleAutoMergeChange = (event) => {
    this.setState(previous => ({
      autoMergeRoomsState: !previous.autoMergeRoomsState,
    }));
  };

  handleToggleTheme = (theme) => {
    theme === SCREEN_MODE.dark && this.setDarkTheme(SCREEN_MODE.dark);
    theme === SCREEN_MODE.light && this.setDarkTheme(SCREEN_MODE.light);
    theme === SCREEN_MODE.normal && this.setDarkTheme(SCREEN_MODE.normal);
    try {
      localStorage.setItem('screenMode', JSON.stringify(theme));
    } catch (err) {
      console.log(err);
    }
    this.props.handleSetThemeDispatch(theme);
  };

  render() {
    const {
      t,
      notificationSettings,
      gameSettings,
      uid,
      showUserSettingsModal,
      showHint,
      screenMode,
      isLandscapeMobile,
      isPortraitMobile,
      autoMergeRooms,
    } = this.props;

    const {
      beforeSettings,
      curLang,
      disabledAutoPlayMode,
      newDesignState,
      showHintMode,
      devMode,
      darkTheme,
      lightThemePopoverOpen,
      normalThemePopoverOpen,
      darkThemePopoverOpen,
      autoMergeRoomsState,
    } = this.state;
    let cardClickBtnSetting = (beforeSettings.indexOf(USER_SYSTEM_SETTINGS.doubleClickPlay) !== -1);
    console.log("get auto play", disabledAutoPlayMode, newDesignState);
    console.log("newDesignState", newDesignState);

    console.log('autoMergeRoomsState', autoMergeRoomsState, autoMergeRooms);

    const isInAppFrame = config.isInAppFrame();
    const options = SCREEN_MODE_OPTIONS;
    const selectedOption = darkTheme;

    const themePopoverSetters = {light: this.setLightThemePopoverOpen, normal: this.setNormalThemePopoverOpen, dark: this.setDarkThemePopoverOpen};
    const themePopoverVariables = {light: lightThemePopoverOpen, normal: normalThemePopoverOpen, dark: darkThemePopoverOpen};

    return (
      <>
        <div className={`${isInAppFrame ? 'user-settings-in-app-frame' : 'user-settings'}`}>
          <Row className={`${isInAppFrame ? 'user-settings-outer-row-wrapper' : 'user-settings-outer-row-wrapper-normal'}`}>
            <Col xs="6">
              <Row className={`user-settings-row-wrapper ${isInAppFrame ? 'user-settings-row-wrapper-in-app-frame' : ''}`}>
                <Col sm="12" className="user-settings-header">
                  {t('showConfirmationWindow')}
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.leaveRoom) !== -1} name="leaveRoom" onChange={() => this.beforeChangeSetting('leaveRoom')} text={t('leaveRoom')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.lastRound) !== -1} name="lastRound" onChange={() => this.beforeChangeSetting('lastRound')} text={t('lastRound')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.quitRound) !== -1} name="quitRound" onChange={() => this.beforeChangeSetting('quitRound')} text={t('quitRound')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.ignorePlayer) !== -1} name="ignorePlayer" onChange={() => this.beforeChangeSetting('ignorePlayer')} text={t('ignorePlayer')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.quitAccount) !== -1} name="quitAccount" onChange={() => this.beforeChangeSetting('quitAccount')} text={t('quitAccount')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.purchaseGift) !== -1} name="purchaseGift" onChange={() => this.beforeChangeSetting('purchaseGift')} text={t('purchaseGift')} inlineLabelClassName="switch-text-inline" />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.removeGift) !== -1} name="removeGift" onChange={() => this.beforeChangeSetting('removeGift')} text={t('removeGift')} inlineLabelClassName="switch-text-inline" />
                </Col>
                {/*  <Col sm="12" className="user-settings-row">
                  <Toggle checked={!newDesignState} name="newDesign" onChange={this.handleChangeNewDesign} text={t('newDesign')} />
                </Col> */}
                <div className="user-settings-divider" />
                <Col sm="12" className="user-settings-row">
                  <Toggle
                    checked={!beforeSettings.includes(USER_SYSTEM_SETTINGS.leaveRoom) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.lastRound) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.quitRound) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.ignorePlayer)
                      && !beforeSettings.includes(USER_SYSTEM_SETTINGS.quitAccount) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.purchaseGift) && !beforeSettings.includes(USER_SYSTEM_SETTINGS.removeGift)}
                    onChange={() => this.beforeChangeSetting(USER_SYSTEM_SETTINGS.dontShowAnyNotification)}
                    text={t('dontShowAnyNotification')}
                    inlineLabelClassName="switch-text-inline"
                  />
                </Col>
              </Row>
            </Col>

            <Col xs="6">
              <Row style={{ height: '100%', display: 'block' }}>
                <Col sm="12" className="user-settings-header">
                  {t('showResultWindow')}
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.showAfterRound) !== -1} onChange={() => this.beforeChangeSetting('showAfterRound')} text={t('showAfterRound')} />
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={beforeSettings.indexOf(USER_SYSTEM_SETTINGS.showAtRoomEnd) !== -1} onChange={() => this.beforeChangeSetting('showAtRoomEnd')} text={t('showAtRoomEnd')} />
                </Col>

                <Col sm="12" className="user-settings-header">
                  {t('others')}
                </Col>
                <Col sm="12" className="user-settings-row">
                  <Toggle checked={!disabledAutoPlayMode} name="autoPlay" onChange={this.handleChange} text={t('autoPlay')} />
                </Col>
                {devMode ? (
                  <Col sm="12">
                    <Toggle checked={autoMergeRoomsState} name="autoMergeRoomsState" onChange={this.handleAutoMergeChange} text={t('autoMergeRooms')} />
                  </Col>
                ) : (null)}
                {devMode ? (
                  <Col sm="12" className="user-settings-row">
                    <Toggle checked={!showHintMode} name="showHint" onChange={this.handleHintChange} text={t('showHint')} />
                  </Col>
                ) : (null)}

                <div className="user-settings-space" />
                <div className="user-settings-toggles">

                  <Col sm="12" className="user-settings-row">
                    <Row>
                      <Col sm="12">
                        <Label className="user-settings-label">
                          {t('cardPlay')}
                          :
                        </Label>
                      </Col>
                      {/* <Col sm="12">
                        <Toggle showAsEnabled checked={cardClickBtnSetting} onChange={() => this.beforeChangeSetting('doubleClickPlay', { type: gameSettings.doubleClickPlay ? 'single' : 'double' })} />

                      </Col> */}
                      { isPortraitMobile ? (
                        <>
                          {/* <Col sm="12" className="switch-text-wrapper user-settings-card-play-switch-wrapper d-flex justify-content-center">
                            <Toggle showAsEnabled checked={cardClickBtnSetting} onChange={() => this.beforeChangeSetting('doubleClickPlay', { type: gameSettings.doubleClickPlay ? 'single' : 'double' })} />
                          </Col>
                          <Col sm="12" className="switch-text-wrapper user-settings-card-play-switch-wrapper align-items-baseline">
                            <div className={`switch-text text-left ${cardClickBtnSetting ? 'disable' : 'active'}`}>{t('cardPlaySingle')}</div>
                            <div className={`switch-text text-right ${cardClickBtnSetting ? 'active' : 'disable'}`}>
                              <div>
                                {t('cardPlayDouble')}
                              </div>
                              <div className="switch-text-small">
                                {t('cardPlayDoubleSecond')}
                              </div>
                            </div>
                          </Col> */}
                          <Col sm="12" className="user-settings-card-play-switch-wrapper">
                            <div className={`d-block switch-text text-left ${cardClickBtnSetting ? 'disable' : 'active'}`}>{t('cardPlaySingle')}</div>
                            {/* <Toggle showAsEnabled checked={cardClickBtnSetting} onChange={() => this.beforeChangeSetting('doubleClickPlay', { type: gameSettings.doubleClickPlay ? 'single' : 'double' })} /> */}

                            <Col sm="12" className="switch-text-wrapper user-settings-card-play-switch-wrapper d-flex p-0">
                              <Toggle showAsEnabled checked={cardClickBtnSetting} onChange={() => this.beforeChangeSetting('doubleClickPlay', { type: gameSettings.doubleClickPlay ? 'single' : 'double' })} />
                            </Col>
                            <div className={`d-block switch-text text-left ${cardClickBtnSetting ? 'active' : 'disable'}`}>
                              <div>
                                {t('cardPlayDouble')}
                              </div>
                              <div className="switch-text-small">
                                {t('cardPlayDoubleSecond')}
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <Col sm="12" className="switch-text-wrapper user-settings-card-play-switch-wrapper">
                          <div className={`switch-text ${cardClickBtnSetting ? 'disable' : 'active'}`}>{t('cardPlaySingle')}</div>
                          <Toggle showAsEnabled checked={cardClickBtnSetting} onChange={() => this.beforeChangeSetting('doubleClickPlay', { type: gameSettings.doubleClickPlay ? 'single' : 'double' })} />
                          <div className={`switch-text ${cardClickBtnSetting ? 'active' : 'disable'}`}>
                            <div>
                              {t('cardPlayDouble')}
                            </div>
                            <div className="switch-text-small">
                              {t('cardPlayDoubleSecond')}
                            </div>
                          </div>
                        </Col>
                      )}

                    </Row>
                  </Col>
                </div>
              </Row>
            </Col>

            { isLandscapeMobile && 
              <Col xs="12" style={{marginTop: curLang === 'ru' ? -38 : -13, marginBottom: 8}}>
                <p className="user-settings-header">
                  <div className="bottom-part-title">{t('language')}:</div>
                  <div className="bottom-part-title">{t('theme')}:</div>
                  <div className="bottom-part-title">{t('sound')}:</div>
                </p>
                <Row className="user-settings-row">
                  <Col xs="2" className="user-settings-language">
                    <Media onClick={() => this.toggleLanguage('lv')} src={lvFlag} className="language-image" style={{opacity: curLang === 'lv' ? 1 : 0.5}} />
                    <Media onClick={() => this.toggleLanguage('en')} src={enFlag} className="language-image" style={{opacity: curLang === 'en' ? 1 : 0.5}} />
                    <Media onClick={() => this.toggleLanguage('ru')} src={ruFlag} className="language-image" style={{opacity: curLang === 'ru' ? 1 : 0.5}}/>
                  </Col>
                  <Col xs="2" className="user-settings-theme">
                    <div className="switch-select-wrapper">
                      <div className="switch-select">
                        {(options && options.length) ? options.map((option, index) => (
                          <>
                            <div
                              onClick={(() => this.handleToggleTheme(option.value))}
                              key={index}
                              className={`switch-select-option switch-select-option-${screenMode} ${index === 0 ? 'switch-select-option-first' : ''} ${(index === options.length - 1) ? 'switch-select-option-last' : ''} ${selectedOption === option.value ? 'switch-select-option-active' : ''}`}
                              id={`${option.value}-theme-button-popover`}
                              onMouseEnter={() => themePopoverSetters[option.value](true)}
                              onMouseLeave={() => themePopoverSetters[option.value](false)}
                            >

                              <div className="switch-select-option-img">
                                {
                                  (option.name === 'dark') ? <Media src={(selectedOption === option.value) ? DarkSelectedThemeIcon : DarkThemeModeIcon} className='dark-image' alt='dark-image' /> : (option.name === 'normal') ? <Media src={(selectedOption === option.value) ? NormalSelectedThemeModeIcon : NormalThemeModeIcon} className='normal-image' alt='normal-image' /> : <Media src={(selectedOption === option.value) ? LightSelectedThemeModeIcon : LightThemeModeIcon} className='light-image' alt='light-image' />
                                }
                              </div>
                            </div>
                            <IconPopover text={t(`common:popovers.${option.value}ThemeSetting`)} placement="bottom" targetId={`${option.value}-theme-button-popover`} popoverOpen={themePopoverVariables[option.value]}/>
                          </>
                        )) : (null)}
                      </div>
                    </div>
                  </Col>
                  <Col xs="2" className="user-settings-sound">
                    <SoundButton uid={uid} screenMode={screenMode} t={t}/>
                  </Col>

                </Row>
              </Col>
            }

          </Row>
        </div>
        <ModalFooter>
          <Button color="secondary" onClick={this.changeSetting}>
            {t('common:common.save')}
          </Button>
          <Button color="secondary" onClick={showUserSettingsModal}>
            {t('common:common.close')}
          </Button>
        </ModalFooter>
      </>
    );

  }
}

const mapStateToProps = state => ({
  notificationSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {}),
  gameSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].gameSettings) : (state.userSettings.default ? state.userSettings.default.gameSettings : {}),
  soundOn: state.userSettings.soundOn,
  uid: state.member.uid,
  disabledAutoPlay: state.member.disabledAutoPlay || false,
  newDesign: state.member?.newDesign || false,
  showHint: state.member?.showHint || false,
  autoMergeRooms: state.member.autoMergeRooms || null,
});

// const mapDispatchToProps = {
//   changeNotification: changeNotificationSettings,
//   changeSetting: changeGameSetting,
//   toggleAllNotifications: toggleAllNotificationSettings,
//   setDefault: setDefaultSettings,
//   fetchAutoPlay: getUserAutoPlay,
//   changeAutoPlay: toggleAutoPlay,
//   changeShowHint: toggleShowHint,
//   fetchShowHint: getUserShowHint,
//   setUserLoginStatisticsDispatch: (param1, param2, param3, param4) => dispatch(setUserLoginStatistics(param1, param2, param3, param4)),
// };
const mapDispatchToProps = (dispatch) => {
  return {
    changeNotification: (setting, userId) => {
      dispatch(changeNotificationSettings(setting, userId));
    },
    changeSetting: (setting, userId) => {
      dispatch(changeGameSetting(setting, userId));
    },
    toggleAllNotifications: (newValue) => {
      dispatch(toggleAllNotificationSettings(newValue));
    },
    setDefault: () => {
      dispatch(setDefaultSettings());
    },
    fetchAutoPlay: () => {
      dispatch(getUserAutoPlay());
    },
    changeAutoPlay: (newValue) => {
      dispatch(toggleAutoPlay(newValue));
    },
    changeShowHint: (newValue) => {
      dispatch(toggleShowHint(newValue));
    },
    fetchShowHint: () => {
      dispatch(getUserShowHint());
    },
    setUserLoginStatisticsDispatch: (param1, param2, param3, param4) => {
      dispatch(setUserLoginStatistics(param1, param2, param3, param4, null, null));
    },
    handleSetThemeDispatch: (theme) => {
      dispatch(handleSetTheme(theme));
    },
    setAutoMergeRooms: (value) => {
      dispatch(updateAutoMergeRooms(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('settings')(UserSettings));
