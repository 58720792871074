import React, { Fragment, useEffect } from 'react';
// import { Row, Col, Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { filter } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Label from 'reactstrap/lib/Label';
// import Progress from 'reactstrap/lib/Progress';
import Spinner from 'reactstrap/lib/Spinner';

import { useSelector } from 'react-redux';

/*
import {
  isAndroid,
  isIOS,
  isIOS13,
} from 'react-device-detect'; */

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import config from '../../../../../constants/config';
import * as constants from '../../../../../constants/constants'; 
import { isMobileDevice } from '../../../../../utils/deviceUtils';
import { clickCampaign } from '../../../../../actions/member';
import { getCampaignLoadingBanner, getCampaignLobbyBanner } from '../../../../../actions/shop';

// import lvFlag from '../../../../../images/redesign/flags/lv.svg';
// import ruFlag from '../../../../../images/redesign/flags/ru.svg';
// import enFlag from '../../../../../images/redesign/flags/en.svg';

const Loading = ({ uid, loading, loadingProgress, campaignData, devMode, clickACampaign, fetchCampaign, adsHidden, loggedInUserDataFetched }) => { //screenMode

  const screenMode = useSelector(state => state.member.screenMode);

  console.log('loading screenMode', uid);

  const { i18n, t } = useTranslation('common');

  const helpLoadingEN = {
    helpLoadingTab: "en",
    helpLoadingHeader: "game does not load?",
    helpLoadingTitle: "What to do if the game does not load?",
    helpLoadingDescription1: "Reload the page by pressing Ctrl + F5",
    helpLoadingDescription2: "Open the site in a private / incognito mode",
    helpLoadingDescription3: "Clear the cache / cookies",
    helpLoadingDescription4: "Try from another browser (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const helpLoadingLV = {
    helpLoadingTab: "lv",
    helpLoadingHeader: "spēle neielādējas?",
    helpLoadingTitle: "Ko darīt, ja spēle neielādējas?",
    helpLoadingDescription1: "Pārlādēt lapu vairākas reizes, nospiežot Ctrl + F5.",
    helpLoadingDescription2: "Atvērt lapu privātā / incognito režīmā",
    helpLoadingDescription3: "Iztīrīt kešatmiņu / cookies",
    helpLoadingDescription4: "Pamēģināt no citas pārlūkprogrammas (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const helpLoadingRU = {
    helpLoadingTab: "ru",
    helpLoadingHeader: "игра не загружается?",
    helpLoadingTitle: "Что делать, если игра не загружается?",
    helpLoadingDescription1: "Перезагрузите страницу, нажав Ctrl + F5",
    helpLoadingDescription2: "Открыть сайт в приватном режиме/режиме инкогнито",
    helpLoadingDescription3: "Очистить кеш/куки",
    helpLoadingDescription4: "Попробуйте из другого браузера (Chrome, Firefox, Edge, Safari, Opera)"
  };

  const [toggleHelp, setToggleHelp] = React.useState(helpLoadingLV.helpLoadingTab);
  const [externalBannerSet, setExternalBannerSet] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    setToggleHelp(i18n.language);
    fetchCampaign('loading', 'new', devMode);

  //  if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (campaignData && campaignData[0] && campaignData[0]?.externalBanner) {
        const scriptTag = document.createElement("script");
        scriptTag.src = campaignData[0]?.externalBanner;
        scriptTag.async = true;

        console.log('campaignData[0]?.externalBanner', campaignData[0]?.externalBanner);

        // scriptTag.addEventListener('load', () => setLoaded(true));

        scriptTag.addEventListener('load', () => {
          const promise1 = new Promise((r) => setTimeout(r, 1000));
          console.log('scriptTag load inputs 1');

          try {
            Promise.all([promise1]).then(() => {
              const inputs = document.getElementsByTagName('iframe');
              let bannerIframe = null;

              console.log('scriptTag load inputs 2', inputs);

              console.log('inputs.length', inputs.length);
              if (inputs) {
                for (var i = 0; i < inputs.length; i++) {
                  console.log('input', inputs[i].clientHeight, inputs[i].clientWidth, inputs[i].offsetHeight);

                  if (inputs[i].clientHeight === 120 && inputs[i].clientWidth === 920) {
                    bannerIframe = inputs[i];
                  }
                }
              }
              console.log('scriptTag load bannerIframe', bannerIframe);

              if (bannerIframe && bannerIframe.contentDocument) {
                console.log('bannerIframe.contentDocument', bannerIframe.contentDocument);

                bannerIframe.contentDocument.body.addEventListener('mousedown', this.handleClickCampaignExternal2);
              } else if (bannerIframe && bannerIframe.document) {
                console.log('bannerIframe.document', bannerIframe.document);

                bannerIframe.document.addEventListener("click", this.handleClickCampaignExternal2);
              }
            });
          } catch (err) {
            console.log(err);
          }

          setLoaded(true);
        });

        const bannerRef3 = document.getElementById('bannerRef');

        console.log('bannerRef3', bannerRef3);

        if (bannerRef3 && campaignData[0]?.externalBanner) {
          bannerRef3.appendChild(scriptTag);
          setExternalBannerSet(true);
        }
      }
  //  }
  }, []);

  const helpLoadingToggle = (item) => {
    if (toggleHelp !== item) {
      setToggleHelp(item);
    }
  };

  const handleClickCampaign = (targetLink, campaignKey) => {
    window.open(targetLink, '_blank')
    clickACampaign(campaignKey, 'loading', 'new', devMode)
  }

  let campaignShow = false;
  // if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
    if (campaignData) {
      if (devMode && filter(campaignData.selectedEnvs, item => item.value === 'dev')[0]) {
        campaignShow = true;
      } else if (!devMode && filter(campaignData.selectedEnvs, item => item.value === 'prod')[0]) {
        campaignShow = true;
      }
      campaignShow = filter(campaignData[0]?.selectedDesigns, item => item?.value === 'new')[0] ? true : false;
    }
  // }

  // console.log('campaignShowcampaignShow', campaignShow, campaignData, devMode, loggedInUserDataFetched);

  return (
    <Fragment>
      <Helmet>
        <title>
          {t('home.zoleLoading')}
        </title>
      </Helmet>
      
      <div className="logo-wrapper loading">
        {/* <div className="logo loading" /> */}
      </div>
      <div className={`loadingScreen d-flex algin-items-center ${screenMode}-loadingScreen flex-column`}>
        <div className="mb-2" style={{ position: 'absolute', top: '0px', marginLeft: 'auto', marginRight: 'auto', zIndex: 999 }} >
          <a style={{ display: 'flex', justifyContent: 'center' }} width="100%" style={{ width: '100%' }} >
            <div id="bannerRef" />
          </a>
        </div>
        {
          (campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
            <div className="mb-2" style={{ position: 'absolute', top: '0px', marginLeft: '-15px', marginRight: '0px', zIndex: 999 }} >
              <a style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleClickCampaign(campaignData[0]?.pageToOpen, campaignData[0]?.key)} width="100%" style={{ width: '100%' }} >
                <Media style={{ objectFit: 'contain' }} src={i18n.language === 'lv' ? campaignData[0]?.imageUrl : i18n.language === 'en' ? campaignData[0]?.imageUrlEn : i18n.language === 'ru' ? campaignData[0]?.imageUrlRu : campaignData[0]?.imageUrl} width="100%" alt="" />
              </a>
            </div>
          )
        }
        <div style={{
          height: (config.isInAppFrame() && campaignData && campaignData[0]) ? '88vh' : '100vh',
          width: '100vw',
          position: 'fixed',
          left: 0,
          top: (campaignData && campaignData[0]) ? '12vh' : 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div className={`loadingScreen-loading ${isMobileDevice() ? 'loadingScreen-loading-mobile' : null} ${(config.isInAppFrame() && campaignData && campaignData[0]) ? 'loadingScreen-loading-iframe' : null}`}
          />
          <div className={`loadingScreen-domain ${(config.isInAppFrame() && campaignData && campaignData[0]) ? 'loadingScreen-domain-iframe' : null}`}>{constants.DOMAIN_NAME}</div>
          <div className={`loadingScreen-dg ${(config.isInAppFrame() && campaignData && campaignData[0]) ? 'loadingScreen-dg-iframe' : null}`} />
          <Row>
            <Col md={{ size: 4, offset: 12 }} className={`loadingScreen-spinner-col ${(config.isInAppFrame() && campaignData && campaignData[0]) ? 'loadingScreen-spinner-col-iframe' : null}`}>
              {/* <div>
                <Progress color="success" value={loadingProgress} />
              </div> */}
              <Spinner
                color={screenMode === constants.SCREEN_MODE.dark || screenMode === constants.SCREEN_MODE.normal ? constants.SCREEN_MODE.light : constants.SCREEN_MODE.dark}
                className={`loadingScreen-spinner ${(config.isInAppFrame() && campaignData && campaignData[0]) ? 'loadingScreen-spinner-iframe' : null}`}
              />
            </Col>
          </Row>
        {/* <Row className="loadingText">
          <Col md={{ size: 4, offset: 4 }}>
            {t('common.loading')}
          </Col>
        </Row> */}
        </div>
      </div>
      <div className="help-loading-section">
        <div className="help-loading-section-header">
          <Label className={classNames('help-loading-section-header-menu', { 'help-loading-section-header-menu-active': toggleHelp === helpLoadingLV.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingLV.helpLoadingTab)}>{helpLoadingLV.helpLoadingHeader}</Label>
          <Label className={classNames('help-loading-section-header-menu', { 'help-loading-section-header-menu-active': toggleHelp === helpLoadingEN.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingEN.helpLoadingTab)}>{helpLoadingEN.helpLoadingHeader}</Label>
          <Label className={classNames('help-loading-section-header-menu', { 'help-loading-section-header-menu-active': toggleHelp === helpLoadingRU.helpLoadingTab })} onClick={() => helpLoadingToggle(helpLoadingRU.helpLoadingTab)}>{helpLoadingRU.helpLoadingHeader}</Label>
        </div>
        <div className="help-loading-section-body mt-4">
          <ul className={classNames('help-loading-section-body-description', { 'help-loading-section-body-description-active': toggleHelp === helpLoadingLV.helpLoadingTab })}>
            <li>{helpLoadingLV.helpLoadingDescription1}</li>
            <li>{helpLoadingLV.helpLoadingDescription2}</li>
            <li>{helpLoadingLV.helpLoadingDescription3}</li>
            <li>{helpLoadingLV.helpLoadingDescription4}</li>
          </ul>
          <ul className={classNames('help-loading-section-body-description', { 'help-loading-section-body-description-active': toggleHelp === helpLoadingEN.helpLoadingTab })}>
            <li>{helpLoadingEN.helpLoadingDescription1}</li>
            <li>{helpLoadingEN.helpLoadingDescription2}</li>
            <li>{helpLoadingEN.helpLoadingDescription3}</li>
            <li>{helpLoadingEN.helpLoadingDescription4}</li>
          </ul>
          <ul className={classNames('help-loading-section-body-description', { 'help-loading-section-body-description-active': toggleHelp === helpLoadingRU.helpLoadingTab })}>
            <li>{helpLoadingRU.helpLoadingDescription1}</li>
            <li>{helpLoadingRU.helpLoadingDescription2}</li>
            <li>{helpLoadingRU.helpLoadingDescription3}</li>
            <li>{helpLoadingRU.helpLoadingDescription4}</li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
Loading.propTypes = {
  windowWidth: PropTypes.number,
  clickACampaign: PropTypes.func.isRequired,
  campaignData: PropTypes.shape([]),
  uid: PropTypes.string,
};

Loading.defaultProps = {
  campaignData: {},
  uid: null,
};

const mapStateToProps = state => ({
  campaignData: state.shop.campaignLoadingData || [],
  uid: state.member.uid || null,
  adsHidden: state.member.adsHidden || null,
  loggedInUserDataFetched: state.member.loggedInUserDataFetched || null,
});

const mapDispatchToProps = {
  fetchCampaign: getCampaignLoadingBanner,
  clickACampaign: clickCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
