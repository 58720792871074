import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import isEqual from 'react-fast-compare';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import CustomDate from '../../Components/Components/CustomDate';

import MenuRow from '../../Components/Components/MenuRow';
import * as constants from '../../../../../constants/constants';

import {
  unBlockUser,
  getIgnoredPlayers,
  getIgnoredByPlayers,
} from '../../../../../actions/member';

import unblockImage from '../../../../../images/redesign/common/unblock.png';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import coinImage from '../../../../../images/redesign/common/coin.svg';
import ratingImage from '../../../../../images/redesign/common/rating.svg';
import { Label, Spinner } from 'reactstrap';
import PlayerAvatar from '../../Components/Components/PlayerAvatar';
import buttonClickedSound from '../../../../../sounds/click_feedback.flac';

class IgnoredUsers extends React.Component {
  static propTypes = {
    ignoredUsers: PropTypes.shape({}),
    ignoredByUsers: PropTypes.shape({}),
    achievementsNotification: PropTypes.bool,
   // userSettings: PropTypes.shape({
   //   soundOn: PropTypes.bool,
   // }),
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchIgnoredByPlayers: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    ignoredSwitchChecked: PropTypes.bool.isRequired,
    toggleIgnoredSwitchChecked: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  userSettings: {},
    ignoredUsers: null,
    ignoredByUsers: null,
    achievementsNotification: false,
    uid: null,
    fontMode: constants.FONT_MODE.normal,
    screenMode: constants.SCREEN_MODE.dark,
    ignoredSwitchChecked: false,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  scrollPos: 0,
      ignoredUsers: null,
      ignoredByUsers: null,
    };

    this.inputRef = createRef();
    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentWillMount() {
    const { fetchIgnoredPlayers, fetchIgnoredByPlayers } = this.props;
    fetchIgnoredPlayers();
    fetchIgnoredByPlayers();
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      ignoredUsers: nextProps.ignoredUsers ? nextProps.ignoredUsers : null,
      ignoredByUsers: nextProps.ignoredByUsers ? nextProps.ignoredByUsers : null,
    })
  }

  unblock = (id) => {
    const { unBlockUserFunc, fetchIgnoredPlayers } = this.props;
    this.playButtonSound();
    unBlockUserFunc(id).then((res) => {
      if (res && res.status === 'success') {
        fetchIgnoredPlayers();
      }
    });
  }

  /*  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  } */

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

    if (soundOn) {
      this.buttonClickedAudio.play();
    }
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper">
      {!rating || rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active">{t('myInfo.pro')}</div>) : (null)}
    </div>
  );

  handleInputRef = () => {
    this.inputRef.current.click();
  };

  render() {
    const {
      changeTab, t, achievementsNotification, fontMode, screenMode, ignoredSwitchChecked, toggleIgnoredSwitchChecked,
    } = this.props;
    const { ignoredUsers, ignoredByUsers } = this.state;

    const sortedKeysIgnoredUsers = ignoredUsers ? Object.keys(ignoredUsers).sort((a, b) => {
      return ignoredUsers[b].date - ignoredUsers[a].date;
    }) : [];

    const sortedKeysIgnoredByUsers = ignoredByUsers ? Object.keys(ignoredByUsers).sort((a, b) => {
      return ignoredByUsers[b].date - ignoredByUsers[a].date;
    }) : [];

    const cutoffDate = 1717597859999; // dates 05-06-2024, 17:00 and before need to be displayed as "long time ago" for ignoredByUsers

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.ignoredPlayers')}
          </title>
        </Helmet>
        <div className="layout-body">
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('common:myInfo.myInfo')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('common:myInfo.friends')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text my-info-ignoredPlayers-button-desktop layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('common:myInfo.ignoredPlayers')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('common:myInfo.achievements')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('common:myInfo.results')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('common:myInfo.weeklyStatus')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('common:myInfo.gameLogs')}
                </Button>
              </div>
            </Row>
          </div>
          <div className="layout-body-main">
            <Row>
              <Col xs="6" className="my-info-ignored-users-switch-section">
                <Label className={(ignoredSwitchChecked) ? 'disable' : 'active'}>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.iIgnore}`)}</Label>
                <Label className="new-switch-wrapper" onClick={() => this.handleInputRef}>
                  <input
                    type="checkbox"
                    name="switchOption"
                    checked={ignoredSwitchChecked}
                    ref={this.inputRef}
                    onClick={() => toggleIgnoredSwitchChecked()}
                  />
                  <span
                    className={classNames('switch', {
                      'switch-best': ignoredSwitchChecked,
                      'switch-worst': !ignoredSwitchChecked,
                    })}
                  >
                    <span className="switch-handle" />
                  </span>
                </Label>
                <Label className={(ignoredSwitchChecked) ? 'active' : 'disable'}>{t(`ignoredUsers.${constants.IGNORED_USERS_SWITCH_OPTION.ignoresMe}`)}</Label>
              </Col>
            </Row>

            {ignoredSwitchChecked ? (
              <>
                <Row className="layout-body-main-table-header ignore-users-new-table-header m-0">
                  <Col xs="4" className="p-0">
                    {t('common.name')}
                  </Col>
                  <Col xs="2" className="p-0">
                    {t('common.level')}
                  </Col>
                  <Col className="p-0">
                    {t('common.ratings')}
                  </Col>
                  <Col className="p-0">
                    {t('common.balance')}
                  </Col>
                  <Col xs="2" className="p-0 text-center">
                    {t('common.date')}
                  </Col>
                </Row>
                { !ignoredByUsers ? (
                  <div className="top-table-spinner-loading-section d-flex align-items-center">
                    <Spinner color={screenMode === constants.SCREEN_MODE.light ? 'dark' : 'light'} style={{ width: '5rem', height: '5rem' }} />
                  </div>
                ) : (
                  sortedKeysIgnoredByUsers.length !== 0 ? (
                    <div
                      className="layout-body-main-scrollarea ignore-users-new-table"
                      contentClassName="layout-body-main-scrollarea-body"
                      show
                      rightOffset={0}
                      topOffset={30}
                      bottomOffset={0}
                    >
                      {(ignoredByUsers && sortedKeysIgnoredByUsers.length > 0) && sortedKeysIgnoredByUsers.map(key => (
                        ignoredByUsers[key].name
                        && ignoredByUsers[key].date && (
                        <Fragment>
                          {ignoredByUsers[key] && (
                          <MenuRow key={key}>
                            <Row key={key}>
                              <Col xs="4" className="menu-table-row-text">
                                <div className="menu-table-row-player">
                                  <PlayerAvatar screenMode={screenMode} photo={ignoredByUsers[key].photo} frameStyle={{marginRight: 8}} />
                                  {/* <div className="menu-table-row-player-image-frame">
                                    <Media className={`menu-table-row-player-image ${ignoredUsers[key].photo ? '' : 'menu-table-row-player-image-default'}`} src={ignoredUsers[key].photo || defaultImage} />
                                  </div> */}
                                  <div className={`menu-table-row-player-text menu-table-row-player-blocked-text-${fontMode}`}>
                                    {ignoredByUsers[key].name}
                                  </div>
                                </div>
                              </Col>
                              <Col xs="2">
                                {this.renderRating(t, ignoredByUsers[key].lvl || 1600)}
                              </Col>
                              <Col className="menu-table-row-text">
                                <div className="menu-table-row-player-icon-wrapper">
                                  <Media src={ratingImage} className="menu-table-row-player-icon" />
                                  <div className="menu-table-row-player-icon-text">
                                    {ignoredByUsers[key] ? (ignoredByUsers[key].rating || 1600) : '1600'}
                                  </div>
                                </div>
                              </Col>
                              <Col className="menu-table-row-text">
                                <div className="menu-table-row-player-icon-wrapper">
                                  <Media src={coinImage} className="menu-table-row-player-icon" />
                                  <div className="menu-table-row-player-icon-text">
                                    {ignoredByUsers[key] ? (ignoredByUsers[key].bal || 1600) : '1600'}
                                  </div>
                                </div>
                              </Col>
                              <Col xs="2" className="menu-table-row-text text-center">
                                {ignoredByUsers[key].date > cutoffDate ? (
                                  <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredByUsers[key].date} />
                                ) : (
                                  t('common.longTimeAgo')
                                )}
                              </Col>

                            </Row>
                          </MenuRow>
                          )}
                        </Fragment>
                      )))}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center h-100 my-info-ignored-users-empty-entries">{t('ignoredUsers.ignoredByUsersEmptyEntries')}</div>
                  )
                )}
              </>
            ) : (
              <>
                <Row className="layout-body-main-table-header ignore-users-new-table-header m-0">
                  <Col xs="3" className="p-0">
                    {t('common.name')}
                  </Col>
                  <Col xs="2" className="p-0">
                    {t('common.level')}
                  </Col>
                  <Col className="p-0">
                    {t('common.ratings')}
                  </Col>
                  <Col className="p-0">
                    {t('common.balance')}
                  </Col>
                  <Col xs="2" className="p-0 text-center">
                    {t('common.date')}
                  </Col>
                  <Col sm="1" className="p-0">
                    <div className="layout-float-right">
                      {t('ignoredUsers.unBlock')}
                    </div>
                  </Col>
                </Row>
                { !ignoredUsers ? (
                  <div className="top-table-spinner-loading-section d-flex align-items-center">
                    <Spinner color={screenMode === constants.SCREEN_MODE.light ? 'dark' : 'light'} style={{ width: '5rem', height: '5rem' }} />
                  </div>
                ) : (
                  sortedKeysIgnoredUsers.length !== 0 ? (
                    <div
                      className="layout-body-main-scrollarea ignore-users-new-table"
                      contentClassName="layout-body-main-scrollarea-body"
                      show
                      rightOffset={0}
                      topOffset={30}
                      bottomOffset={0}
                    >
                      {(ignoredUsers && sortedKeysIgnoredUsers.length > 0) && sortedKeysIgnoredUsers.map(key => (
                        ignoredUsers[key].name
                        && ignoredUsers[key].date && (
                        <Fragment>
                          {ignoredUsers[key] && (
                          <MenuRow key={key}>
                            <Row key={key}>
                              <Col xs="3" className="menu-table-row-text">
                                <div className="menu-table-row-player">
                                  <PlayerAvatar screenMode={screenMode} photo={ignoredUsers[key].photo} frameStyle={{marginRight: 8}} />
                                  {/* <div className="menu-table-row-player-image-frame">
                                    <Media className={`menu-table-row-player-image ${ignoredUsers[key].photo ? '' : 'menu-table-row-player-image-default'}`} src={ignoredUsers[key].photo || defaultImage} />
                                  </div> */}
                                  <div className={`menu-table-row-player-text menu-table-row-player-blocked-text-${fontMode}`}>
                                    {ignoredUsers[key].name}
                                  </div>
                                </div>
                              </Col>
                              <Col xs="2">
                                {this.renderRating(t, ignoredUsers[key].lvl || 1600)}
                              </Col>
                              <Col className="menu-table-row-text">
                                <div className="menu-table-row-player-icon-wrapper">
                                  <Media src={ratingImage} className="menu-table-row-player-icon" />
                                  <div className="menu-table-row-player-icon-text">
                                    {ignoredUsers[key] ? (ignoredUsers[key].rating || 1600) : '1600'}
                                  </div>
                                </div>
                              </Col>
                              <Col className="menu-table-row-text">
                                <div className="menu-table-row-player-icon-wrapper">
                                  <Media src={coinImage} className="menu-table-row-player-icon" />
                                  <div className="menu-table-row-player-icon-text">
                                    {ignoredUsers[key] ? (ignoredUsers[key].bal || 1600) : '1600'}
                                  </div>
                                </div>
                              </Col>
                              <Col xs="2" className="menu-table-row-text text-center">
                                <CustomDate format="DD-MM-YYYY, hh:mm" date={ignoredUsers[key].date} />
                              </Col>
                              <Col xs="1">
                                <Media className="menu-table-row-player-block layout-float-right" src={unblockImage} onClick={() => this.unblock(key)} />
                              </Col>
                            </Row>
                          </MenuRow>
                          )}
                        </Fragment>
                      )))}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center h-100 my-info-ignored-users-empty-entries">{t('ignoredUsers.ignoredUsersEmptyEntries')}</div>
                  )
                )}
              </>
            )}


          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  soundOn: state.userSettings.soundOn,
  ignoredUsers: state.member.ignoredUsers,
  ignoredByUsers: state.member.ignoredByUsers,
});

const mapDispatchToProps = {
  unBlockUserFunc: unBlockUser,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchIgnoredByPlayers: getIgnoredByPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(IgnoredUsers));
