import React, { Fragment, useParams } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Media, Button, Spinner } from 'reactstrap';

import {
  FONT_MODE,
} from '../../../constants/constants';
import CustomModal from './Components/Components/Modal';

import checkAction from '../../../images/redesign/icons/checkAction.svg';
import checkFalseAction from '../../../images/redesign/icons/checkFalseAction.svg';
import { setTransactionComplete } from "../../../actions/shop";

const NewInboxTransactionResult = ({ inboxPaymentCompleted, inboxPaymentStatus, status, paymentState, setTransactionCompleteFunc }) => {
  const { t } = useTranslation('common');

  // setTransactionCompleteFunc(true);

  return (
    <Fragment>
      <div>
        <CustomModal
          isOpen={true}
          toggle={() => this.toggleModal()}
          size="md"
          zIndex={'unset'}
          title={status !== null ? (
            status ? (
              inboxPaymentCompleted ? t('common.paymentSuccess') : t('common.inboxPaymentProcessing')
            ) : (
              paymentState === 'canceled' ? t('common.paymentCanceled') : t('common.paymentFailed')
            )
          ) : (
            t('home.stripeLoading')
          )}
          addClassName={status ? "payment-true-modal" : "payment-false-modal"}
          footerClose={true}
          body={
            <div className="d-flex align-items-center">
              <div className={status ? "payment-true-modal-content" : "payment-false-modal-content"}>
                {status === null
                  ? <Spinner className="payment-loading-spinner" children={false} />
                  : <Media src={status ? checkAction : checkFalseAction} className="contact-message-sent-success-image" />
                }
              </div>
            </div>
          }
        />
      </div>
    </Fragment>
  )
}

NewInboxTransactionResult.propTypes = {
  stripeStatus: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  fontMode: PropTypes.string,
};

NewInboxTransactionResult.defaultProps = {
  stripeStatus: false,
  isFullscreen: false,
  history: {},
  fontMode: FONT_MODE.normal,
};

const mapDispatchToProps = {
  setTransactionCompleteFunc: setTransactionComplete,
};

export default connect(null, mapDispatchToProps)(NewInboxTransactionResult);
