import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Select from 'react-select';

import classNames from 'classnames';

import { filter, map, orderBy, remove, size, sortBy } from 'lodash';
import Input from 'reactstrap/lib/Input';
import { Button, Col, Form, FormGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, PopoverBody, Row } from 'reactstrap';

import 'react-widgets/dist/css/react-widgets.css';
import { DateTimePicker } from 'react-widgets';

import { FORMAT_DATE_TYPE, SURVEY_TYPE } from '../../../constants/constants';
import pencilImg from '../../../images/icons/pencil.svg';
import cancelImg from '../../../images/icons/close_old.png';
import checkImg from '../../../images/icons/check.png';
import closeImg from '../../../images/icons/close.png';

import {
  getSurveys, createSurveyFunc, deleteSurveyFunc, editSurveyFunc
} from '../../../actions/admin';
import DateFormat from '../UI/DateFormat';
import { STATISTICS_DESIGN_MODE } from '../../../constants/statisticsTypes';
import { UncontrolledPopover } from 'reactstrap/lib';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'gray',
    padding: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

class CreateSurvey extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    allSurveys: PropTypes.shape({}),
    fetchAllSurveys: PropTypes.func.isRequired,
    addANewSurveyFunc: PropTypes.func.isRequired,
    editASurveyFunc: PropTypes.func.isRequired,
    deleteASurveyFunc: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allSurveys: {},
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      createSurveyModal: false,
      startDate: new Date(),
      endDate: new Date(),
      questionType: SURVEY_TYPE[0].id,
      question: '',
      description: '',
      devMode: false,
      currentPage: 0,
      pageSize: 50,
      selectedId: null,
      deleteModal: false,
      editModal: false,
      answerList: [],
      selectedDesigns: [],
      disableAnswer: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { fetchAllSurveys } = this.props;
    
    fetchAllSurveys();
    const answers = [
      {key: 1, id: 'answer1', value: ''},
      {key: 2, id: 'answer2', value: ''},
      {key: 3, id: 'answer3', value: ''},
    ];

    this.setState({
      answerList: answers
    })
  }

  toggleCreateSurveyModal = () => {
    this.setState(prevState => ({
      createSurveyModal: !prevState.createSurveyModal
    }))
  };

  closeCreateSurveyModal = () => {
    this.setState({
      createSurveyModal: false,
      editModal: false,
      selectedId: null,
      startDate: new Date(),
      endDate: new Date(),
      question: '',
      description: '',
      questionType: SURVEY_TYPE[0].id,
      answerList: [],
      selectedDesigns: [],
      devMode: false,
      disableAnswer: false,
    })
  };

  changeStartDate = (date) => {
    this.setState({
      startDate: date,
    })
  };

  changeEndDate = (date) => {
    this.setState({
      endDate: date,
    })
  };

  changeQuestionType = (e) => {
    const { questionType } = this.state;
    if (e.target.value !== questionType && e.target.value !== '') {
      this.setState({
        questionType: e.target.value
      })
    } else {
      this.setState({
        questionType: ''
      })
    }
  };

  openEditModal = (id) => {
    const { allSurveys } = this.props;
    this.setState({
      selectedId: id,
      startDate: new Date(allSurveys[id].startDate),
      endDate: new Date(allSurveys[id].endDate),
      question: allSurveys[id].question,
      description: allSurveys[id].description,
      questionType: allSurveys[id].questionType,
      devMode: allSurveys[id].devMode,
      answerList: allSurveys[id].answerList && size(allSurveys[id].answerList) > 0 ? [...allSurveys[id].answerList] : [],
      selectedDesigns: allSurveys[id].selectedDesigns? [...allSurveys[id].selectedDesigns] : [],
      editModal: true,
      disableAnswer: allSurveys[id].disableAnswer
    })
  };

  openDeleteModal = (id) => {
    this.setState({
      selectedId: id,
      deleteModal: true,
    })
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    })
  };

  handleCreateSurvey = () => {
    const { addANewSurveyFunc, showNotification, } = this.props;
    const { selectedId, startDate, endDate, question, description, questionType, selectedDesigns, disableAnswer, answerList, devMode } = this.state;
    if (!startDate) return showNotification('Error', 'please input the Start time.', 'danger');
    if (!endDate) return showNotification('Error', 'please input the End time.', 'danger');
    if (Date.parse(startDate) > Date.parse(endDate)) return showNotification('Error', 'End time cannot be before the start time.', 'danger');
    if (!question) return showNotification('Error', 'Please input the question', 'danger');
    if (!description) return showNotification('Error', 'Please input the description', 'danger');
    if (!questionType) return showNotification('Error', 'Please select the question type', 'danger');
    if (!selectedDesigns || size(selectedDesigns) === 0) return showNotification('Error', 'Please select the deisgn', 'danger');
    if (!disableAnswer && (questionType === 'type2' || questionType === 'type3')) {
      if (size(answerList) >= 3 && size(filter(answerList, item => !item.value || item.value === '')) > 0) return showNotification('Error', 'Please select the answer', 'danger');
    }

    // if (!disableAnswer && (questionType === 'type2' || questionType === 'type3')) {
    //   if (size(answerList) >= 3 && size(filter(answerList, item => !item.value || item.value === '')) > 0) return;
    // }
    addANewSurveyFunc({
      startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer,
    }).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', "Survey successfully added.", 'success');
      }

      if (res.status === 'error') {
        
        showNotification('Error', res.message, 'danger');
        return;
      }

      this.closeCreateSurveyModal();
    }).catch(error => showNotification('Error!', error.message, 'danger'));
  };

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: false,
      selectedId: null,
    })
  };

  handleDeleteSurvey = () => {
    const { showNotification, deleteASurveyFunc } = this.props;
    const { selectedId } = this.state;
    deleteASurveyFunc(selectedId).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Survey have been successfully deleted.', 'success');
      }

      if (res.status === 'error') {
        return showNotification('Error', res.message, 'danger');
      }
      this.setState({
        deleteModal: false,
        selectedId: '',
      })
    });
  };

  handleEditSurvey = () => {
    const { showNotification, editASurveyFunc } = this.props;
    const { selectedId, startDate, endDate, question, description, questionType, selectedDesigns, disableAnswer, answerList, devMode } = this.state;
    if (!selectedId) return;
    if (!startDate) return showNotification('Error', 'please input the Start time.', 'danger');
    if (!endDate) return showNotification('Error', 'please input the End time.', 'danger');
    if (Date.parse(startDate) > Date.parse(endDate)) return 
    if (!question) return showNotification('Error', 'Please input the question', 'danger');
    if (!description) return showNotification('Error', 'Please input the descirption', 'danger');
    if (!questionType) return showNotification('Error', 'Please select the question type', 'danger');
    if (!selectedDesigns || size(selectedDesigns) === 0) return showNotification('Error', 'Please select the deisgn', 'danger');
    // if (!disableAnswer && (questionType === 'type2' || questionType === 'type3')) {
    //   if (size(answerList) >= 3 && size(filter(answerList, item => !item.value || item.value === '')) > 0) return showNotification('Error', 'Please select the answer', 'danger');
    // }

    editASurveyFunc({
      selectedId, startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer,
    }).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success', 'Changes have been successfully saved', 'success');
      }

      if (res.status === 'error') {
        
        showNotification('Error', res.message, 'danger');
        return;
      }
      this.closeCreateSurveyModal();
    }).catch(error => showNotification('Error!', error.message, 'danger'));
  };

  handleQuestionAnswer = (e) => {
    const { answerList } = this.state;

    const answerField = e.target.name || '';
    const answer = e.target.value || '';

    const updatedList = map(answerList, (item) => {
      if (item.id === answerField) {
        return { ...item, value: answer };
      }
      return item;
    });

    this.setState({
      answerList: updatedList,
    })
  };

  handleAddQuestionAnswer = () => {
    const { answerList } = this.state;

    const currentAnswers = size(answerList);
    const currentAnswerIdx = currentAnswers + 1;
    const additionalAnswer = {
      key: currentAnswerIdx,
      id: `answer${currentAnswerIdx}`,
      value: '',
    };
    const answers = [...answerList];
    answers.push(additionalAnswer);

    this.setState({
      answerList: answers,
    })
  };

  handleRemoveQuestionAnswer = () => {
    const { answerList } = this.state;
    const currentAnswers = size(answerList);
    const answers = filter(answerList, item => parseInt(item.key, 10) < currentAnswers);
    this.setState({
      answerList: answers,
    })
  };

  handleChangeDesign = (design) => {
    this.setState({
      selectedDesigns: design,
    })
  };

  render() {
    const {
      t,
      loading,
      allSurveys,
    } = this.props;

    const {
      createSurveyModal,
      startDate,
      endDate,
      questionType,
      question,
      description,
      devMode,
      currentPage,
      pageSize,
      selectedId,
      deleteModal,
      editModal,
      answerList,
      selectedDesigns,
      disableAnswer,
    } = this.state;
  
    const pagesCount = Math.ceil(size(allSurveys) / pageSize);
    console.log(loading, "checkLoading")

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-create-survey">
          <PopoverBody className="popover-body">
            The admin can set up a survey that is displayed to the players. The purpose is to get feedback about some particular questions, possible features, and similar things. Each survey has to have a question and description in all listed languages. The survey can be a free-form question or with multiple answer options, also it can be displayed for each design separately. 
          </PopoverBody>
        </UncontrolledPopover>
        <h2>
          Surveys
          <Button className="admin-help-button" id="admin-help-button-create-survey">
            ?
          </Button>
        </h2>
        <div className="d-flex flex-column">
          <div
            className="mb-4"
            style={{
              width: '200px',
            }}
          >
            <Button color="secondary" onClick={() => this.toggleCreateSurveyModal()}>
              Create Survey
            </Button>
          </div>

          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Type
                </th>
                <th style={{ textAlign: 'center' }}>
                  Question
                </th>
                <th style={{ textAlign: 'center' }}>
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  Answers
                </th>
                <th style={{ textAlign: 'center' }}>
                  Design
                </th>
                <th style={{ textAlign: 'center', display: 'none' }}>
                  Disable Answer
                </th>
                <th style={{ textAlign: 'center' }}>
                  Only Dev
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {allSurveys && Object.keys(allSurveys)
                .sort((a, b) => allSurveys[b].endDate - allSurveys[a].endDate)
                .slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize,
                )
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={allSurveys[key].startDate} />
                      </td>
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={allSurveys[key].endDate} />
                      </td>
                      <td className='allUsers-table-col'>
                        {filter(SURVEY_TYPE, item => item.id === allSurveys[key].questionType)[0].label}
                      </td>
                      <td className="allUsers-table-col">
                        {allSurveys[key].question}
                      </td>
                      <td className="allUsers-table-col">
                        {allSurveys[key].description}
                      </td>
                      <td>
                        {map(allSurveys[key].answerList, item => <div>{item.value}</div> )}
                      </td>
                      <td className="allUsers-table-col flex-column">
                        <div className='d-flex flex-column'>
                          {allSurveys[key].selectedDesigns && map(allSurveys[key].selectedDesigns, item => (
                            <span className="mb-2">{item.label}</span>
                          ))}
                        </div>
                      </td>
                      <td className="allUsers-table-col" style={{display: 'none'}}>
                        {allSurveys[key].disableAnswer ? 'Yes' : 'No'}
                      </td>
                      <td className="allUsers-table-col">
                        {allSurveys[key].devMode ? 'Yes' : 'No'}
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openEditModal(key)}>
                          Edit
                        </Button>
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="primary" onClick={() => this.openDeleteModal(key)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              {pagesCount && Object.keys(allSurveys).length > pageSize && (
                <div className="pagination-wrapper">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                        {`‹‹‹`}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => this.handleClick(e, currentPage - 1)}
                        previous
                        href="#"
                      />
                    </PaginationItem>

                    {[...Array(pagesCount)].map((page, i) => {
                      if (i > currentPage - 3 && i < currentPage + 3) {
                        return (
                          <PaginationItem active={i === currentPage} key={page}>
                            <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      }

                      return null;
                    })}

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink
                        style={{ color: '#000' }}
                        onClick={e => this.handleClick(e, currentPage + 1)}
                        next
                        href="#"
                      />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                          {`›››`}
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal()} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal()}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal()} />
            }
          >
            Delete Survey
          </ModalHeader>
          <ModalBody className="notification-body">
            Are you really delete the survey question?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" disabled={loading} onClick={() => this.handleDeleteSurvey()}>
              {loading ? 'Loading' : 'Yes'}
            </Button>
            <Button type="button" className="btn notification-footer-button" disabled={loading}>
              {loading ? 'Loading' : 'No'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal container={'div > div'} isOpen={editModal} toggle={() => this.closeCreateSurveyModal()} className="notification">
          <ModalHeader
            toggle={() => this.closeCreateSurveyModal()}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closeCreateSurveyModal()} />
            }
          >
            Edit Survey
          </ModalHeader>
          <ModalBody className="notification-body">
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="question">
                  Question
                </Label>
                <Input
                  type="text"
                  name="question"
                  id="question"
                  value={question}
                  disabled={loading}
                  maxLength={100}
                  onChange={e => this.setState({ question: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  Description
                </Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  maxLength={300}
                  disabled={loading}
                  onChange={e => this.setState({ description: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="questionType">
                  Survey Type
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="questionType" className="survey-type" value={questionType} disabled onChange={this.changeQuestionType}>
                      {
                        map(SURVEY_TYPE, item => (
                          <option value={item.id} className="background-222">{item.label}</option>
                        ))
                      }
                    </Input>
                  </Col>
                </Row>
                <Row style={{display: 'none'}}>
                  <Col>
                    <FormGroup>
                      <Row>
                        <Col sm="12">
                          <Label className="event-type-input d-flex mt-2" style={{ marginLeft: 40 }}>
                            <Input type="checkbox" onClick={() => this.setState({ disableAnswer: !disableAnswer })} disabled checked={disableAnswer} readOnly />
                            <span className="checkmark" />
                            <div className="event-type-text">
                              Disable Answer
                            </div>
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                {
                  questionType === 'type2' || questionType === 'type3' ? (
                    <Row>
                      <Col md="12">
                        
                        <Button
                          className="btn notification-footer-button mb-1 mt-1"
                          disabled
                          onClick={() => this.handleAddQuestionAnswer()}
                          style={{
                            width: '40%',
                          }}
                        >
                          Add Answer
                        </Button>
                        {
                          size(answerList) > 3 && (
                            <Button
                              className="btn notification-footer-button mb-1 mt-1"
                              disabled
                              onClick={() => this.handleRemoveQuestionAnswer()}
                              style={{
                                width: '40%',
                              }}
                            >
                              Remove Answer
                            </Button>
                          )
                        }
                      </Col>
                      <Col md="12">
                        {
                          answerList && map(sortBy(answerList, ['key', 'asc']), (item, idx) => (
                            <Input
                              type="text"
                              name={item.id}
                              className={`answer-lit-${item.id} mb-1 mt-1`}
                              onChange={this.handleQuestionAnswer}
                              value={item.value}
                              disabled={disableAnswer || loading}
                            />
                          ))
                        }
                      </Col>
                    </Row>
                  ) : (null)
                }
              </FormGroup>
              <FormGroup>
                <Label for="designs">
                  Designs
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={this.handleChangeDesign} />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input d-flex" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={() => this.setState({ devMode: !devMode })} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" disabled={loading} onClick={() => this.handleEditSurvey()}>
              {loading ? 'Loading' : 'Save'}
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.closeCreateSurveyModal()} disabled={loading}>
              {loading ? 'Loading' : 'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal container={'div > div'} isOpen={createSurveyModal} toggle={() => this.closeCreateSurveyModal()} className="notification">
          <ModalHeader
            toggle={() => this.closeCreateSurveyModal()}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closeCreateSurveyModal()} />
            }
          >
            Create Survey
          </ModalHeader>
          <ModalBody className="notification-body">
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeEndDate}
                      value={endDate}
                      maxLength={100}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="question">
                  Question
                </Label>
                <Input
                  type="text"
                  name="question"
                  id="question"
                  value={question}
                  maxLength={300}
                  disabled={loading}
                  onChange={e => this.setState({question: e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  Description
                </Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  disabled={loading}
                  onChange={e => this.setState({description: e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label for="questionType">
                  Survey Type
                </Label>
                <Row>
                  <Col md="12">
                    <Input type="select" name="questionType" className="survey-type" value={questionType} disabled={loading} onChange={this.changeQuestionType}>
                      {
                        map(SURVEY_TYPE, item => (
                          <option value={item.id} className="background-222">{item.label}</option>
                        ))
                      }
                    </Input>
                  </Col>
                </Row>
                <Row style={{display: 'none'}}>
                  <Col>
                    <FormGroup>
                      <Row>
                        <Col sm="12">
                          <Label className="event-type-input d-flex mt-2" style={{ marginLeft: 40 }}>
                            <Input type="checkbox" onClick={() => this.setState({disableAnswer: !disableAnswer})} disabled={loading} checked={disableAnswer} readOnly />
                            <span className="checkmark" />
                            <div className="event-type-text">
                              Disable Answer
                            </div>
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                {
                  questionType === 'type2' || questionType === 'type3' ? (
                    <Row>
                      <Col md="12">
                        <Button
                          className="btn notification-footer-button mb-1 mt-1"
                          disabled={loading || disableAnswer}
                          onClick={() => this.handleAddQuestionAnswer()}
                          style={{
                            width: '40%',
                          }}
                        >
                          Add Answer
                        </Button>
                        {
                          size(answerList) > 2 && (
                            <Button
                              className="btn notification-footer-button mb-1 mt-1"
                              disabled={loading}
                              onClick={() => this.handleRemoveQuestionAnswer()}
                              style={{
                                width: '40%',
                              }}
                            >
                              Remove Answer
                            </Button>
                          )
                        }
                      </Col>
                      <Col md="12">
                        {
                          answerList && map(sortBy(answerList, ['key', 'asc']), (item, idx) => (
                            <Input
                              type="text"
                              name={item.id}
                              className={`answer-lit-${item.id} mb-1 mt-1`}
                              onChange={this.handleQuestionAnswer}
                              value={item.value}
                              disabled={loading || disableAnswer}
                            />
                          ))
                        }
                      </Col>
                    </Row>
                  ) : (null)
                }
              </FormGroup>
              <FormGroup>
                <Label for="designs">
                  Designs
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={this.handleChangeDesign} />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input d-flex" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={() => this.setState({devMode: !devMode})} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" disabled={loading} onClick={() => this.handleCreateSurvey()}>
              {loading ? 'Loading' : 'Save'}
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.closeCreateSurveyModal()} disabled={loading}>
              {loading ? 'Loading' : 'Cancel'}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };
};

const mapStateToProps = state => ({
  allSurveys: state.admin.allSurveys || {},
  loading: state.admin.surveysLoading || false,
});

const mapDispatchToProps = {
  fetchAllSurveys: getSurveys,
  addANewSurveyFunc: createSurveyFunc,
  deleteASurveyFunc: deleteSurveyFunc,
  editASurveyFunc: editSurveyFunc,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(CreateSurvey));
