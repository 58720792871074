import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import ReactTableContainer from 'react-table-container';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class MissedTurnsTable extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    missedTurnsCount: PropTypes.arrayOf(PropTypes.shape({})),
    missedTurnsData: PropTypes.shape({}),
    fetchmissedTurnsCount: PropTypes.func.isRequired,
    fetchmissedTurnsData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    missedTurnsCount: [],
    missedTurnsData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      openDataModal: false,
      currentPage: 0,
      pageSize: 50,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { fetchmissedTurnsCount } = this.props;

    fetchmissedTurnsCount();
  }

  getMissedTurnsData = (userId) => {
    const { fetchmissedTurnsData } = this.props;


    fetchmissedTurnsData(userId);
    this.setState({ openDataModal: true });
  }

  closeModal = () => {
    this.setState({ openDataModal: false });
  }

  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
  }

  table() {
    const {
      t,
      missedTurnsCount,
    } = this.props;
    const { currentPage, pageSize } = this.state;
    const pagesCount = Math.ceil(missedTurnsCount.length / pageSize);

    return (
      <Fragment>
        {missedTurnsCount && missedTurnsCount.length > 0 && missedTurnsCount
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((item, index) => (
            <Fragment key={item.key}>
              <tr key={item.key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  { item.key }
                </td>
                <td className="allUsers-table-col">
                  { item.name }
                </td>
                <td className="allUsers-table-col">
                  { item.count }
                </td>
                <td className="allUsers-table-col">
                <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                    {item.lastDate}
                </Moment>
                </td>
                <td className="allUsers-table-col">
                  <Button onClick={() => this.getMissedTurnsData(item.key)}>
                    Info
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
          {pagesCount && Object.keys(missedTurnsCount).length > pageSize && (
            <div className="pagination-wrapper">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 0}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                    {`‹‹‹`}
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pagesCount)].map((page, i) => {
                  if (i > currentPage - 3 && i < currentPage + 3) {
                    return (
                      <PaginationItem active={i === currentPage} key={page}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }

                  return null;
                })}

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                      {`›››`}
                    </PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
          )}
      </Fragment>
    );
  }

  render() {
    const { t, missedTurnsData } = this.props;
    const { openDataModal } = this.state;

    return (
      <Fragment>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            {/* {t('missedTurns')} */}
            Missed Turns
          </h2>
          <table style={{ width: '70%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th class="allUsers-table-col">
                  {/* {t('playerId')} */}
                  Player Id
                </th>
                <th class="allUsers-table-col">
                  {/* {t('name')} */}
                  Name
                </th>
                <th class="allUsers-table-col">
                  {/* {t('count')} */}
                  Count
                </th>
                <th class="allUsers-table-col">
                  {/* {t('lastDate')} */}
                  Last Date
                </th>
                <th class="allUsers-table-col">
                  {/* {t('info')} */}
                  Info
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>

        <Modal container={`div > div`} size="lg" isOpen={openDataModal} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>
            {/* {`${t('miss')} ${t('history')}`} */}
            Miss History
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <table className="user-history-table">
                  <thead>
                    <tr className="user-history-table-row">
                      <th className="user-history-table-col">
                        {/* {t('time')} */}
                        Time
                      </th>
                      <th className="user-history-table-col">
                        {/* {t('wasOn')} */}
                        Was Online
                      </th>
                      <th className="user-history-table-col">
                        {/* {t('triedToMove')} */}
                        Tried To Move
                      </th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <ReactTableContainer
                  width="100%"
                  height="420px"
                  scrollbarStyle={{
                    background: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },

                    backgroundFocus: {
                      background: 'transparent',
                      width: 1,
                      marginRight: 3,
                    },
                    foreground: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },

                    foregroundFocus: {
                      background: 'fff',
                      width: 4,
                      left: -1,
                    },
                  }}
                >
                  <table className="top-table">
                    <colgroup>
                      <col span="1" className="" />
                    </colgroup>
                    <thead />
                    <tbody>
                      {missedTurnsData && Object.keys(missedTurnsData).reverse().map((key, index) => (
                          <Fragment key={key}>
                            <tr key={key} className={`user-history-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                              <td className="user-history-table-col">
                                <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                                  {missedTurnsData[key].date}
                                </Moment>
                              </td>
                              <td className="user-history-table-col col-player">
                                {missedTurnsData[key].isOnline ? ('Online') : ('Offline')}
                              </td>
                              <td className="user-history-table-col">
                                {missedTurnsData[key].triedToMove ? ('Tried to move') : ("Didn't try to move")}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </ReactTableContainer>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default  withTranslation('admin')(MissedTurnsTable);
