import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import { withRouter } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";

import Joyride, {
  CallBackProps, STATUS,
} from 'react-joyride';

import {
  isMobileSafari,
} from 'react-device-detect';

import { Helmet } from 'react-helmet';

// import isEqual from 'react-fast-compare';
import { filter, map, size, } from 'lodash';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
// import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Spinner from 'reactstrap/lib/Spinner';
// import Popover from 'reactstrap/lib/Popover';
// import PopoverBody from 'reactstrap/lib/PopoverBody';

import Clock from '../UI/Clock';

import LoadingPage from '../UI/Loading';
import BlockedPage from '../UI/Blocked';
import TransactionSuccess from '../UI/TransactionSuccess';
import PopoverComponent from '../UI/PopoverComponent';
// import UpdateProfile from '../../../containers/UpdateProfile';

import RulesPage from './RulesPage/RulesPage';
import TermsOfUsePage from './TermsOfUsePage';
import PlayerInfo from './PlayerInfo';

import MyInfo from './MyInfo';
import NewGame from './NewGame';
import ShopBonus from './ShopBonus';
import RoomsTable from './RoomsTable/RoomsTable';
import BonusPage from './BonusPage';
import BuyPage from './BuyPage';
import TopPage from './TopPage';
import TopPageMillionaires from './TopPageMil';
import Tournaments from './Tournaments';
import TournamentsPlan from './TournamentsPlan';
import TournamentsHistory from './TournamentsHistory';
import TournamentResults from './TournamentResults';
import TournamentNotification from './TournamentNotification';
import TournamentTutorial from './TournamentTutorial';
import Feedback from './Feedback';
import PowerRankings from './PowerRankings';


import ContactSupport from '../ContactSupport';
import MoneyHistory from './MoneyHistory';
import PointsHistory from './PointsHistory';
import BannedUsers from './BannedUsers';
// import OnlineUsers from './OnlineUsers';
import Friends from './Friends';
import IgnoredUsers from './IgnoredUsers';
import Achievements from './Achievements';
import GamesHistory from './GamesHistory';
import WeeklyStatus from './WeeklyStatus';
import WeeklyStatusHome from './WeeklyStatusHome';
import GameLogs from './GameLogs';
import AchievementsModal from './AchievementsModal';

import HolidayModals from './HolidayModals';
import BonusModals from './BonusModals';
import EventModals from './EventModals';

// import CustomTooltip from './CustomTooltip';

import Notification from './Notification';

import UserSettings from './UserSettings';
import OnlineCount from './OnlineCount';

import JoyrideComponent from './JoyrideComponent';

import 'react-circular-progressbar/dist/styles.css';

import appStore from '../../../images/redesign/about-us/Appstore button.png';
import playStore from '../../../images/redesign/about-us/Playstore button.png';
import helpImg from '../../../images/icons/help2.png';
import quickHelpImg from '../../../images/icons/quick_help.png';
import moneyHistoryImg from '../../../images/icons/money_history.png';
import pointsHistoryImg from '../../../images/icons/points_history.png';
import blockedUsersImg from '../../../images/icons/blocked_users.png';
import gameHelpImg from '../../../images/icons/game_help.png';
// import coinImg from '../../../images/coin.svg';
import closeImg from '../../../images/icons/close.png';
import gearIcon from '../../../images/icons/gear.svg';

import config from '../../../constants/config';

import logoutImg from '../../../images/Menu/logout.svg';

import chatInSound from '../../../sounds/chat_notification.wav';
import buttonClickedSound from '../../../sounds/button.mp3';

import openFullscreen from '../../../images/icons/openFullscreenW.png';
import closeFullscreen from '../../../images/icons/closeFullscreenW.png';
import debuggerImg from '../../../images/debugIco.svg';

import NewVersion from './NewVersion';
// import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import * as constants from '../../../constants/constants';

import {
  getAchievements,
  claimAchievement,
  initEveryPayPayment,
  setUserScreenView,
  closeLevelNotification,
  // saveErrorCode,
} from '../../../actions/member';

import { returnToAdminReturnToken } from '../../../actions/users';

import defaultFont from '../../../images/redesign/top-row/defaultFont.png';
import largeFont from '../../../images/redesign/top-row/largeFont.png';
import xLargeFont from '../../../images/redesign/top-row/xLargeFont.png';
// import maxFont from '../../../images/redesign/top-row/maxFont.png';

import RoomEndedModal from './RoomEndedModal';
import PaymentTypeContent from '../Redesign/Menu/Modals/PaymentTypeContent';
import { resetRoomError, setRoomEnded, setTournamentRoomEnded } from '../../../actions/room';
import Hints from './Hints';
import { setActiveTab, setIgnoredSwitchChecked, setJoyrideEndedTrigger, setPwOption } from '../../../actions/state';
import { FormGroup, Input } from 'reactstrap';
import { getCampaignOpenRoomBanner, getCampaignLobbyBanner } from '../../../actions/shop';
import { CAMPAIGN_LOCATIOIN_OPTIONS } from '../../../constants/statisticsTypes';
import TournamentsHeader from './TournamentsHeader';
import NewHelpPage from './RulesPage/NewHelpPage';

const currentVersion = require('./../../../../package.json').version;

const ANDROID_PATH_lv = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=lv';
const ANDROID_PATH_en = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=en';
const ANDROID_PATH_ru = 'https://play.google.com/store/apps/details?id=com.zole.app&hl=ru';
const IOS_PATH_lv = 'https://apps.apple.com/lv/app/id1505526877';
const IOS_PATH_en = 'https://apps.apple.com/us/app/id1505526877';
const IOS_PATH_ru = 'https://apps.apple.com/ru/app/id1505526877';

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log('fallbackRender', error);

  // saveErrorCode(error);

  return (
    <div role="alert">
      <p>Kļūda:</p>
      <pre>{error.message}</pre>
      <pre>{error.code}</pre>
    </div>
  );
}

class Menu extends React.Component {
  static propTypes = {
    //  userSettings: PropTypes.shape(),
    //  tournaments: PropTypes.shape(),
    setRoomEndedFunction: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    hasActiveRegistration: PropTypes.bool,
    myTournamentsData: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    insufficientBalanceAmount: PropTypes.number,
    insufficientBalanceBet: PropTypes.string,
    soundOn: PropTypes.bool,
    joinRoom: PropTypes.func.isRequired,
    createRoom: PropTypes.func.isRequired,
    uid: PropTypes.string,
    loadingProgress: PropTypes.number.isRequired,
    member: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    resetErrorNotif: PropTypes.func.isRequired,
    errorNotification: PropTypes.string,
    ignoredMessageName: PropTypes.string,
    ignoredMessageUid: PropTypes.string,
    disableFirstTimeNotif: PropTypes.func.isRequired,
    disableTutorial: PropTypes.func.isRequired,
    setSupportAsRead: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    closePrivateRoomPassword: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    updateLastAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func.isRequired,
    fetchBalanceHistory: PropTypes.func.isRequired,
    fetchPointsHistory: PropTypes.func.isRequired,
    privateRoomPassword: PropTypes.arrayOf(PropTypes.shape()),
    showPrivateRoomPassword: PropTypes.func.isRequired,
    // activePath: PropTypes.string,
    emailVerifySuccess: PropTypes.bool,
    toggleNewDesign: PropTypes.func.isRequired,
    weeklyDataOpen: PropTypes.bool,
    closeWeeklyData: PropTypes.func.isRequired,
    resetRoomError: PropTypes.func.isRequired,
    userAchievements: PropTypes.shape(),
    // fetchAchievements: PropTypes.func.isRequired,
    claimedAchievements: PropTypes.shape(),
    claimAchievementFunc: PropTypes.func.isRequired,
    gamesPlayed: PropTypes.number,
    gamesWon: PropTypes.number,
    roomEnded: PropTypes.bool,
    tournamentRoomEnded: PropTypes.bool,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    balance: PropTypes.number,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    showNotification: PropTypes.bool,
    userSettings: PropTypes.shape(),
    isEventOption: PropTypes.bool,
    toggleEvent: PropTypes.func.isRequired,
    checkWeeklyDataOpenFunc: PropTypes.func.isRequired,
    setScreenView: PropTypes.func.isRequired,
    closeLevelUpNotification: PropTypes.func.isRequired,
    designPlatform: PropTypes.shape({}),
    platform: PropTypes.string,
    lastShopTab: PropTypes.string,
    transactionComplete: PropTypes.bool,
    fetchCampaign: PropTypes.func.isRequired,
    setActiveTabFunc: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    setPwOptionFunc: PropTypes.func.isRequired,
    pwOption: PropTypes.bool.isRequired,
    ignoredSwitchChecked: PropTypes.bool.isRequired,
    setIgnoredSwitchCheckedFunc: PropTypes.func.isRequired,
  }

  static defaultProps = {
    uid: '',
    errorNotification: '',
    ignoredMessageName: '',
    ignoredMessageUid: '',
    //  tournaments: {},
    hasActiveRegistration: false,
    myTournamentsData: {},
    notificationSettings: {},
    privateRoomPassword: [],
    //  userSettings: {},
    isFullscreen: false,
    isFullscreenEnabled: false,
    insufficientBalanceAmount: null,
    insufficientBalanceBet: null,
    soundOn: false,
    // activePath: constants.MENU_NAVIGATION.rooms,
    emailVerifySuccess: false,
    weeklyDataOpen: null,
    userAchievements: {},
    claimedAchievements: {},
    gamesPlayed: 0,
    gamesWon: 0,
    roomEnded: false,
    balance: 0,
    fontMode: constants.FONT_MODE.normal,
    paymentError: '',
    showNotification: null,
    userSettings: {},
    isEventOption: false,
    designPlatform: {},
    platform: constants.DWEB_PLATFORM,
    campaignData: {},
    activeTab: constants.MENU_NAVIGATION.rooms,
    pwOption: false,
    ignoredSwitchChecked: false,
  }

  constructor(props) {
    super(props);
    const { t } = props;

    let devMode = false;

    try {
      const { hostname } = window.location;
      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }
    } catch (err) { }

    this.state = {
      // activeTab: constants.MENU_NAVIGATION.rooms,
      rulesModalOpen: false,
      supportModalOpen: false,
      balanceHistoryModalOpen: false,
      pointsHistoryModalOpen: false,
      aboutModalOpen: false,
      editModalOpen: false,
      run: false,
      achievementsNotification: false,
      helpPageOpen: false,
      insufficientBalanceAmount: null,
      insufficientBalanceBet: null,
      showTournamentTutorial: false,
      stripeLoading: false,
      toggleVerifySuccess: false,
      holidayModalOpen: false,
      bonusModalOpen: false,
      openAchievementsModal: false,
      fontMode: constants.FONT_MODE.normal,
      claimAchieveProcess: false,
      popOverOpen: false,
      popOverTitle: '',
      popOverDescription: '',
      targetName: '',
      buyModal: false,
      paymentErrorModal: false,
      selectedProduct: {},
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      isOnline: navigator.onLine,
      devMode,
      checkedTerms: false,
      roomEndedModalBlock: false,
      openHolidayDebugger: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES[0]],
      holidayTest: false,
      openNewHelpModal: false,
    };

    this.chatInAudio = new Audio(chatInSound);
    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.onPaymentOptionClick = this.onPaymentOptionClick.bind(this);
  }

  componentWillMount() {
    const { member, emailVerifySuccess } = this.props;

    try {
      if (localStorage.getItem('allTours')) { //x
        localStorage.removeItem('allTours'); //x
      }
    } catch (err) {
      console.log(err);
    }

    if (emailVerifySuccess) {
      this.setState({
        toggleVerifySuccess: true,
      });
    }
  }

  componentDidMount() {
    const { checkWeeklyDataOpenFunc, member, setScreenView, fetchCampaign } = this.props;
    const { devMode } = this.state;

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);

    console.log('componentDidMount test', member);

    if (member && member.uid) {
      try {
        checkWeeklyDataOpenFunc();
      } catch (err) { }
    }

    console.log('fetchCampaign');
    fetchCampaign('lobby', 'old', devMode);

    // setScreenView('rooms');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      member,
      errorNotification,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      soundOn,
      joinRoomError,
      balance,
      weeklyDataOpen,
      checkWeeklyDataOpenFunc,
      joyrideEndedTrigger,
      setJoyrideEndedTriggerFunc,
      closeLevelUpNotification,
      tournamentRoomEnded,
      setTournamentRoomEndedFunction,
      lastShopTab,
      transactionComplete,
      activeTab,
      adminReturnToken,
      setActiveTabFunc,
    } = this.props;

    const { openModal, modalType, run } = prevState;
    const { member: oldMember, balance: oldBalance, joyrideEndedTrigger: oldJoyrideEndedTrigger } = prevProps;
    const { openModal: currentOpenModal } = this.state;
    //if (joinRoomError && !joinRoomError.roomId && modalType === 'noBalanceMenu') this.setState({modalType: 'noBalanceCreateRoom'}) //modalType = 'noBalanceCreateRoom'

    if (lastShopTab && transactionComplete && activeTab !== constants.MENU_NAVIGATION.buyPage) {
      setActiveTabFunc(constants.MENU_NAVIGATION.buyPage)
    }

    if (joyrideEndedTrigger && !oldJoyrideEndedTrigger) {
      setActiveTabFunc(constants.MENU_NAVIGATION.rooms);
      setJoyrideEndedTriggerFunc(false);
    }
    if (oldBalance !== balance) {
      this.setState({ roomEndedModalBlock: false });
    }
    if (tournamentRoomEnded && this.state.activeTab !== constants.MENU_NAVIGATION.tournaments) {
      this.toggle(constants.MENU_NAVIGATION.tournaments);
      setTournamentRoomEndedFunction(false);
    }
    if (errorNotification) {
      if (errorNotification === 'insufficient balance') {
        if (!(openModal && modalType === 'noBalanceMenu')) {
          this.UpdateModal(true, 'noBalanceMenu', null, insufficientBalanceAmount, insufficientBalanceBet);
          //  this.setState({ insufficientBalanceAmount, insufficientBalanceBet });
        }
      } else if (errorNotification === 'pro room') {
        if (!(openModal && modalType === 'proRoomMenu')) {
          this.UpdateModal(true, 'proRoomMenu', null, null, null);
        }
      } else if (errorNotification === 'pro bet') {
        if (!(openModal && modalType === 'proBetMenu')) {
          this.UpdateModal(true, 'proBetMenu', null, null, null);
        }
      } else if (errorNotification === 'insuf bal tournament') {
        if (!(openModal && modalType === 'noBalanceTournament')) {
          this.UpdateModal(true, 'noBalanceTournament', null, null, null);
        }
      }
      // else if (errorNotification === 'You ignored player') {
      //   if (!(openModal && modalType === 'youIgnoredPlayer')) {
      //     this.UpdateModal(true, 'youIgnoredPlayer', null, null, null);
      //   }
      // } 
      else if (errorNotification === 'Player ignored you') {
        if (!(openModal && modalType === 'playerIgnoredYou')) {
          this.UpdateModal(true, 'playerIgnoredYou', null, null, null);
        }
      } else if (errorNotification === 'email not verified') {
        if (!(openModal && modalType === 'emailNotVerified')) {
          this.UpdateModal(true, 'emailNotVerified', null, null, null);
        }
      } else if (errorNotification === 'tournament already registered') {
        if (!(openModal && modalType === 'tournamentAlreadyRegistered')) {
          this.UpdateModal(true, 'tournamentAlreadyRegistered', null, null, null);
        }
      } else if (errorNotification === 'tournament max players') {
        if (!(openModal && modalType === 'tournamentMaxPlayers')) {
          this.UpdateModal(true, 'tournamentMaxPlayers', null, null, null);
        }
      } else if (errorNotification === 'already registered in another tournament') {
        if (!(openModal && modalType === 'alreadyRegisteredAnotherTournament')) {
          this.UpdateModal(true, 'alreadyRegisteredAnotherTournament', null, null, null);
        }
      }
    } else if (joinRoomError) {
      if (joinRoomError.error === 'insufficient balance') {
        if (!(openModal && (modalType === 'noBalanceMenu' || modalType === 'noBalanceCreateRoom'))) {
          if (joinRoomError.roomId)
            this.UpdateModal(true, 'noBalanceMenu', null, joinRoomError.balNeeded, joinRoomError.bet);
          else
            this.UpdateModal(true, 'noBalanceCreateRoom', null, joinRoomError.balNeeded, joinRoomError.bet);
        }
      } else if (joinRoomError.error === 'pro room') {
        if (!(openModal && modalType === 'proRoomMenu')) {
          this.UpdateModal(true, 'proRoomMenu', null, null, null);
        }
      } else if (joinRoomError.error === 'pro bet') {
        if (!(openModal && modalType === 'proBetMenu')) {
          this.UpdateModal(true, 'proBetMenu', null, null, null);
        }
      }
      //  else if (joinRoomError.error === 'you ignored') {
      //   if (!(openModal && modalType === 'youIgnoredPlayer')) {
      //     this.UpdateModal(true, 'youIgnoredPlayer', null, null, null);
      //   }
      // } 
      else if (joinRoomError.error === 'ignored you') {
        if (!(openModal && modalType === 'playerIgnoredYou')) {
          this.UpdateModal(true, 'playerIgnoredYou', null, null, null);
        }
      } else if (joinRoomError.error === 'multiAccount') {
        if (!(openModal && modalType === 'multiAccount')) {
          this.UpdateModal(true, 'multiAccount', null, null, null);
        }
      } else if (joinRoomError.error === 'email not verified') {
        if (!(openModal && modalType === 'emailNotVerified')) {
          this.UpdateModal(true, 'emailNotVerified', null, null, null);
        }
      } else if (joinRoomError.error === 'wrong password') {
        if (!(openModal && modalType === 'wrongCode')) {
          this.UpdateModal(true, 'wrongCode', null, null, null);
        }
      }
    }

    if (member && member.lvlUpNotification && !adminReturnToken) {
      if (member.level === 1) {
        closeLevelUpNotification();
      } else {
        if (!(openModal && modalType === 'levelUp')) {
          this.UpdateModal(true, 'levelUp', member.level, null, null);
        }
      }
    }

    if (member && !member.lvlUpNotification && openModal && modalType === 'levelUp') {
      this.UpdateModal(false, '', null, null, null);
    }

    if (member && member.uid && !member.tutorialShown && !run) {
      try {
        if (member.socProvider === constants.SOC_PROVIDERS.password && JSON.parse(localStorage.getItem('emailStauts')) === member.email) { //x
          this.runJoyride();
          localStorage.removeItem('emailStatus'); //x
        }
      } catch (err) {
        console.log(err);
      }
      if (member.socProvider !== constants.SOC_PROVIDERS.password) {
        this.runJoyride();
      }
    }

    if (member && member.uid && member.uid !== oldMember.uid && !weeklyDataOpen) {
      checkWeeklyDataOpenFunc();
    }

    if (soundOn) {
      if ((!oldMember.supportChatStatus || oldMember.supportChatStatus.read === true) && member.supportChatStatus && member.supportChatStatus.read === false) {
        const playPromise = this.chatInAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  togglePwOption = () => {
    const { pwOption, setPwOptionFunc } = this.props;
    setPwOptionFunc(!pwOption);
  }

  toggleIgnoredSwitchChecked = () => {
    const { ignoredSwitchChecked, setIgnoredSwitchCheckedFunc } = this.props;
    setIgnoredSwitchCheckedFunc(!ignoredSwitchChecked);
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  runJoyride = () => {
    this.setState({
      run: true,
    });
  }

  logout = (e) => {
    e.preventDefault();
    const { logout, history, notificationSettings, isFullscreenEnabled, toggleFullscreen } = this.props;

    if (notificationSettings && notificationSettings.quitAccount === false) {
      logout().then(() => {
        history.push('/landing');

        if (isFullscreenEnabled) {
          toggleFullscreen();
        }

        window.location.reload();
      });
    } else {
      this.setState({ quitModal: true });
    }
  }

  closeQuitModal = () => {
    this.setState({ quitModal: false });
  }

  approveQuitModal = () => {
    const { logout, history, isFullscreenEnabled, toggleFullscreen } = this.props;

    this.setState({ quitModal: false });

    logout().then(() => {
      if (isFullscreenEnabled) {
        toggleFullscreen();
      }

      history.push('/landing');

      // window.location.reload();
    });
  }

  toggleEditModal = () => {
    const { editModalOpen } = this.state;

    console.log('toggleEditModal');

    if (!editModalOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Edit Profile',
      });
    }

    this.setState(prevState => ({ editModalOpen: !prevState.editModalOpen }));
  }

  toggleSupport = (e) => {
    const { supportModalOpen } = this.state;
    const { setSupportAsRead, member, playButtonSound } = this.props;

    if (!supportModalOpen && member.supportChatStatus && !member.supportChatStatus.read) {
      setSupportAsRead();
    }
    if (!supportModalOpen) {
      playButtonSound();

      ReactGA.event({
        category: 'Menu',
        action: 'Technical Support',
      });
    }
    this.setState({ supportModalOpen: !supportModalOpen });
  }

  toggleRules = () => {
    const { playButtonSound, updateLastAction } = this.props;
    const { rulesModalOpen } = this.state;

    playButtonSound();
    updateLastAction();
    this.setState({ rulesModalOpen: !rulesModalOpen });
  }

  toggleHelp = () => {
    const { playButtonSound } = this.props;
    const { helpPageOpen } = this.state;

    playButtonSound();

    if (!helpPageOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open help page',
      });
    }

    this.setState(prevState => ({
      helpPageOpen: !prevState.helpPageOpen,
      termsOfUseOpen: false,
    }));
  }

  toggleTermsOfUse = () => {
    const { playButtonSound } = this.props;
    const { termsOfUseOpen } = this.state;

    playButtonSound();

    if (!termsOfUseOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open terms of use page',
      });
    }

    this.setState(prevState => ({
      termsOfUseOpen: !prevState.termsOfUseOpen,
      helpPageOpen: false,
    }));
  }

  toggleAbout = () => {
    const { playButtonSound } = this.props;
    const { aboutModalOpen } = this.state;

    playButtonSound();

    if (!aboutModalOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open About Us',
      });
    }

    this.setState(prevState => ({
      aboutModalOpen: !prevState.aboutModalOpen,
    }));
  }

  UpdateModal = (openModal, modalType, newLevel, insufficientBalanceAmount, insufficientBalanceBet) => {
    this.setState({
      openModal, modalType, newLevel,
    });

    if (modalType === 'noBalanceMenu' || modalType === 'noBalanceCreateRoom') {
      this.setState({ insufficientBalanceAmount, insufficientBalanceBet });
    }
  }

  handleClickStart = (e) => {
    console.log('handleClickStart');
    const { playButtonSound } = this.props;
    e.preventDefault();

    playButtonSound();

    ReactGA.event({
      category: 'Menu',
      action: 'Open guide',
    });

    this.setState({
      run: true,
    });
  };

  toggle = (tab) => {
    const { playButtonSound, updateLastAction, setScreenView, setActiveTabFunc } = this.props;
    const { activeTab } = this.state;

    console.log('toggle', tab);

    if (activeTab !== tab) {
      /*  if (tab === '1') {
          console.log('fetchFreeRooms');
          fetchFreeRooms();
        } else {
          console.log('cancelRoomListeners');
          cancelRoomListeners();
        } */
      if (tab === constants.MENU_NAVIGATION.top) {
        setScreenView('top');

        ReactGA.event({
          category: 'Menu',
          action: 'Open top page',
        });
      } else if (tab === constants.MENU_NAVIGATION.topMillionaires) {
        setScreenView('top');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Top Millionaires',
        });
      } else if (tab === constants.MENU_NAVIGATION.powerRankings) {
        setScreenView('powerRankings');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Power Rankings',
        });
      } else if (tab === constants.MENU_NAVIGATION.myInfo) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open my info',
        });
      } else if (tab === constants.MENU_NAVIGATION.bonusPage) {
        setScreenView('bonusPage');

        ReactGA.event({
          category: 'Menu',
          action: 'Open bonus page',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPage || tab === constants.MENU_NAVIGATION.giftPage || tab === constants.MENU_NAVIGATION.resetBalancePage || tab === constants.MENU_NAVIGATION.moneyPacks) {
        setScreenView('shopPage');

        ReactGA.event({
          category: 'Menu',
          action: 'Open shop',
        });
      } else if (tab === constants.MENU_NAVIGATION.tournaments) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Active Tournaments',
        });
      } else if (tab === constants.MENU_NAVIGATION.futureTournaments) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Future Tournaments',
        });
      } else if (tab === constants.MENU_NAVIGATION.tournamentsHistory) {
        setScreenView('tournaments');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Tournaments History',
        });
      } else if (tab === constants.MENU_NAVIGATION.ignoredUsers) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open ignored players',
        });
      } else if (tab === constants.MENU_NAVIGATION.archievements) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open achievements',
        });
      } else if (tab === constants.MENU_NAVIGATION.gameHistory) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open My Info Results',
        });
      } else if (tab === constants.MENU_NAVIGATION.powerRankings) {
        setScreenView('powerRankings');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Power Rankings',
        });
      } else if (tab === constants.MENU_NAVIGATION.gameLogs) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Game Logs',
        });
      } else if (tab === constants.MENU_NAVIGATION.weeklyStatus) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Weekly Stats',
        });
      } else if (tab === constants.MENU_NAVIGATION.friends) {
        setScreenView('myInfo');

        ReactGA.event({
          category: 'Menu',
          action: 'Open Friends',
        });
      } else if (tab === constants.MENU_NAVIGATION.rooms) {
        // setScreenView('rooms');

        // setScreenView('createRoom');
      }

      try {
        if (!localStorage.getItem('joyrideActive')) { //x
          playButtonSound();
        }
      } catch (err) {
        console.log(err);
      }

      updateLastAction();
      // this.setState({
      //   activeTab: tab,
      // });
      setActiveTabFunc(tab);
    }
  }

  toggleBalanceHistory = () => {
    const {
      fetchBalanceHistory, playButtonSound, updateLastAction, member,
    } = this.props;
    const { balanceHistoryModalOpen } = this.state;

    playButtonSound();
    updateLastAction();

    if (member && member.offset) {
      fetchBalanceHistory('today', member.offset);
    } else {
      fetchBalanceHistory('today', 0);
    }

    if (!balanceHistoryModalOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open balance history',
      });
    }
    this.setState(prevState => ({
      balanceHistoryModalOpen: !prevState.balanceHistoryModalOpen,
    }));
  }

  togglePointsHistory = () => {
    const {
      fetchPointsHistory, playButtonSound, updateLastAction, member,
    } = this.props;
    const { pointsHistoryModalOpen } = this.state;

    playButtonSound();
    updateLastAction();

    if (member && member.offset) {
      fetchPointsHistory('today', member.offset);
    } else {
      fetchPointsHistory('today', 0);
    }

    if (!pointsHistoryModalOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open points history',
      });
    }
    this.setState(prevState => ({
      pointsHistoryModalOpen: !prevState.pointsHistoryModalOpen,
    }));
  }

  toggleBannedUsers = () => {
    const { playButtonSound } = this.props;
    const { bannedUsersModalOpen } = this.state;
    playButtonSound();

    if (!bannedUsersModalOpen) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open banned users',
      });
    }

    this.setState(prevState => ({
      bannedUsersModalOpen: !prevState.bannedUsersModalOpen,
    }));
  }

  closeModal = () => {
    const { resetErrorNotif, resetRoomError } = this.props;
    const { modalType } = this.state;

    if (modalType === 'alreadyRegisteredAnotherTournament' || modalType === 'tournamentMaxPlayers'
      || modalType === 'tournamentAlreadyRegistered' || modalType === 'noBalanceMenu' || modalType === 'noBalanceCreateRoom' || modalType === 'proRoomMenu' || modalType === 'emailNotVerified'
      || modalType === 'proBetMenu' || modalType === 'noBalanceTournament' || modalType === 'youIgnoredPlayer' || modalType === 'playerIgnoredYou' || modalType === 'multiAccount' || modalType === 'wrongCode') {
      resetErrorNotif();
      resetRoomError();
    }
    this.setState({
      openModal: false, modalType: '', insufficientBalanceAmount: null, insufficientBalanceBet: null,
    });
  }

  handleJoyrideCallback = (data: CallBackProps) => {
    const { disableTutorial, member } = this.props;
    const { status, action } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === 'close') {
      this.setState({ run: false });

      if (!member || !member.tutorialShown) {
        disableTutorial();
      }
    }
  };

  closeJoyride = () => {
    const { disableTutorial, member } = this.props;
    this.setState({
      run: false,
    });
    try {
      if (localStorage.getItem('allTours')) { //x
        localStorage.removeItem('allTours'); //x
      }
    } catch (err) {
      console.log(err);
    }
    if (member && !member.tutorialShown) {
      disableTutorial();
    }
  }

  closeFirstTime = () => {
    const { disableFirstTimeNotif, member } = this.props;

    if (!member || !member.firstTimeModal) {
      disableFirstTimeNotif();
      if (document.getElementById('top-tab') && document.getElementById('top-tab').isSameNode) {
        // this.getSteps();
        this.setState({
          run: true,
        });
      }
    }
  }

  setAchievementsNotification = (val) => {
    const { claimAchieveProcess, openAchievementsModal } = this.state;
    this.setState({
      achievementsNotification: val,
    });

    console.log('setAchievementsNotification', val, claimAchieveProcess);

    if (!claimAchieveProcess) {
      //  console.log({claimAchieveProcess}, {openAchievementsModal}, "claimProcess")
      this.setState({
        openAchievementsModal: true,
      });
    }
  }

  showUserSettingsModal = () => {
    const { showUserSettingsModal } = this.state;

    if (!showUserSettingsModal) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open Settings',
      });
      this.setState({
        popOverOpen: false,
        // popOverTitle: '',
        // popOverDescription: '',
        targetName: '',
      })
    }

    this.setState(prevState => ({
      showUserSettingsModal: !prevState.showUserSettingsModal,
    }));

  }

  toggleShowTournamentTutorial = () => {
    const { showTournamentTutorial } = this.state;

    if (!showTournamentTutorial) {
      ReactGA.event({
        category: 'Menu',
        action: 'Tournaments Help',
      });
    }

    this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
  }

  toggleTransactionSuccess = (transactionSuccess) => {
    this.setState({
      transactionSuccess,
    });
  }

  toggleStripeLoading = (stripeLoading) => {
    this.setState({
      stripeLoading,
    });
  };

  toggleVerifySuccessModal = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      toggleVerifySuccess: false,
    });
  }

  goToNextStep = () => {
    // e.preventDefault();
    // console.log({step});
    // this.setState({
    //   stepIndex: 4,
    // });
  }

  toggleNewVersion = () => {
    this.setState({
      newVersionOpen: false,
    });
  }

  toggleHolidayModal = (flag) => {
    this.setState({
      holidayModalOpen: flag,
    });
  };

  toggleBonusModal = (flag) => {
    this.setState({
      bonusModalOpen: flag,
    });
  }

  closeAchievementsModal = () => {
    const { userAchievements, claimedAchievements, claimAchievementFunc, gamesPlayed, gamesWon, } = this.props;

    this.setState({
      claimAchieveProcess: true,
      openAchievementsModal: false,
      roomEndedModalBlock: true,
    })

    let userAchieves = {};
    userAchieves = {
      ...userAchievements,
      gamesPlayed,
      gamesWon,
    }
    // console.log(userAchieves, "closeAchieve");

    let count = 0;
    map(userAchieves, async (item, key) => {
      if (size(constants.UNIQUE_ACHIEVEMNT_DATA[key]) > 0) {
        let reward = 0;

        if (item && !claimedAchievements[key]) {
          reward = constants.UNIQUE_ACHIEVEMNT_DATA[key].reward;
        }

        if (reward > 0) {
          await claimAchievementFunc(key);
        }
      } else if (size(constants.ACHIEVEMENT_DATA[key]) > 0) {
        let reward = 0;
        const claimedAchievementsVal = claimedAchievements[key] || 0;
        constants.ACHIEVEMENT_DATA[key].borders.map((borderItem, index) => {
          // console.log(key, item, borderItem, claimedAchievementsVal, index, "claimReward");
          if (item >= borderItem && claimedAchievementsVal <= index) {
            reward += constants.ACHIEVEMENT_DATA[key].rewards[index];
          }
          return null;
        });
        if (reward > 0) {
          await claimAchievementFunc(key);
        }
      }
      count++;
      if (count === size(userAchieves)) {
        // console.log({count}, size(userAchieves), "claimCheck")
        this.setState({
          claimAchieveProcess: false,
        })
      }
    });
  }

  openAchievements = () => {
    this.closeAchievementsModal();
    this.toggle(constants.MENU_NAVIGATION.archievements);
  }

  toggleFontFunc = () => {
    const { toggleFont, fontMode } = this.props;

    ReactGA.event({
      category: 'Menu',
      action: 'Change Font Size',
    });

    if (fontMode === constants.FONT_MODE.normal) {
      toggleFont(constants.FONT_MODE.large);
    } else if (fontMode === constants.FONT_MODE.large) {
      toggleFont(constants.FONT_MODE.xLarge);
    } else {
      toggleFont(constants.FONT_MODE.normal);
    }
  }

  onHover = (title, description, target) => {
    this.setState({
      popOverOpen: true,
    });
    if (title && description && target) {
      this.setState({
        popOverTitle: title,
        popOverDescription: description,
        targetName: target,
      })
    }
  }

  onHoverLeave = () => {
    this.setState({
      popOverOpen: false,
      targetName: '',
      // popOverTitle: '',
      // popOverDescription: '',
    });
  }

  toggleBuyModal = () => {
    this.setState({ buyModal: !this.state.buyModal });
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState({
      paymentErrorModal: !this.state.paymentErrorModal,
      paymentError,
    });
  }

  buyMoney = (prod) => {
    const {
      initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleTransactionSuccess, toggleStripeLoading, isFullscreen, isFullscreenEnabled, toggleFullscreen
    } = this.props;

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      this.setState({ selectedProduct: constants.BUY_PAGE_PRODUCTS[prod], selectedProductId: prod });
      this.toggleBuyModal();
    } else if (socProvider === constants.SOC_PROVIDERS.facebook) { // In App Iframe (fb/dra)
      this.playButtonSound();

      initFBPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.FB && socProvider === constants.SOC_PROVIDERS.facebook) {
              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: res.data.data.token, // optional, must be unique for each payment
              },
                (resp) => {
                  fbCallback(resp);
                  return null;
                });
            } else {
              //  console.log('no FB.ui or not facebook provider');
            }
          } else {
            //  console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.draugiem) {
      this.playButtonSound();
      initDraugiemPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === constants.SOC_PROVIDERS.draugiem) {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
              //  console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
            //    console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.inbox) {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
            //  console.log('error with product');
          }
        }
      });
    }
  }

  pay = () => {
    const {
      selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms,
    } = this.state;
    const { initEveryPay, t, i18n } = this.props;

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id, constants.PAYMENT_TYPES.textMessage.id].includes(selectedPaymentOption.id)) {

      this.toggleStripeLoading(true);

      initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
        const paymentLink = res.data.payment_link;
        if (paymentLink) {
          window.location.href = paymentLink;
        }
      }).catch((error) => {
        this.toggleStripeLoading(false);
        console.log(error);
      });
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }

  playButtonSound = () => {
    const { usersoundOnSettings, soundOn } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option
    });
  }

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms
    }));
  }

  handleAppStore = () => {
    const link =
      i18n.language === 'lv'
        ? IOS_PATH_lv
        : i18n.language === 'en'
          ? IOS_PATH_en
          : i18n.language === 'ru'
            ? IOS_PATH_ru
            : IOS_PATH_lv;
    window.open(link, '_blank');
  }

  handlePlayStore = () => {
    const link =
      i18n.language === 'lv'
        ? ANDROID_PATH_lv
        : i18n.language === 'en'
          ? ANDROID_PATH_en
          : i18n.language === 'ru'
            ? ANDROID_PATH_ru
            : ANDROID_PATH_lv;
    window.open(link, '_blank');
  }

  toggleHolidayDebuggerModal = () => {
    const { openHolidayDebugger } = this.setState;
    if (openHolidayDebugger) {
      this.setState({
        selectedTestHoliday: null,
      });
    }

    this.setState(prevState => ({
      openHolidayDebugger: !prevState.openHolidayDebugger,
    }));
  };

  handleSelectTestHoliday = (e) => {
    console.log(e.target.value, filter(constants.HOLIDAY_TYPES, item => item.id === e.target.value), "checkSelectHoliday")
    this.setState({
      selectedTestHoliday: filter(constants.HOLIDAY_TYPES, item => item.id === e.target.value),
    });
  }

  setHolidayTestModal = () => {
    const { selectedTestHoliday } = this.state;

    console.log(selectedTestHoliday, "checkSelectedTestHoliday!!!")
    if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'changeLog') {
      this.setState({
        openChangeLogVersionModal: true,
      })
    } else if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'newHelp') {
      this.setState({
        openNewHelpModal: true,
      })
    } else {
      this.setState({
        holidayTest: true,
      });
    }
    this.setState({
      openHolidayDebugger: false,
    });
  };

  cancelHolidayTest = () => {
    this.setState({
      holidayTest: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES[0]],
    });
  };

  closeTriggerChangeLogModal = () => {
    this.setState({
      openChangeLogVersionModal: false,
    })
  }

  returnToAdmin = () => {
    const { member, returnToAdmin, adminReturnToken, history, toggleNewDesign, toggleScreenMode, i18n } = this.props;

    console.log('call returnToAdmin', adminReturnToken);

    if (adminReturnToken) {
      returnToAdmin(adminReturnToken, toggleNewDesign, toggleScreenMode, i18n, member.uid).then((res) => {
        if (res === 'success') {
          history.push('/admin');
        }
      })
    }
  }

  toggleNewHelpModal = () => {
    this.setState(prevState => ({
      openNewHelpModal: !prevState.openNewHelpModal,
    }))
  }

  render() {
    const {
      member,
      loading,
      createRoom,
      loadingProgress,
      joinRoom,
      t,
      i18n,
      resetErrorNotif,
      ignoredMessageName,
      ignoredMessageUid,
      privateRoomPassword,
      showPrivateRoomPassword,
      closePrivateRoomPassword,
      playButtonSound,
      //  tournaments,
      hasActiveRegistration,
      myTournamentsData,
      isFullscreen,
      isFullscreenEnabled,
      toggleFullscreen,
      notificationSettings,
      showNotification,
      toggleNewDesign,
      weeklyDataOpen,
      closeWeeklyData,
      fontMode,
      joinRoomError,
      isInAppFrame,
      roomEnded,
      balance,
      setRoomEndedFunction,
      resetRoomError,
      isEventOption,
      toggleEvent,
      setScreenView,
      designPlatform,
      platform,
      lastShopTab,
      campaignData,
      adminReturnToken,
      activeTab,
      setActiveTabFunc,
      pwOption,
      ignoredSwitchChecked,
      soundOn,
    } = this.props;

    console.log('SET_SOUND soundOn test', soundOn);

    const {
      openModal,
      modalType,
      newLevel,
      supportModalOpen,
      aboutModalOpen,
      run,
      balanceHistoryModalOpen,
      pointsHistoryModalOpen,
      bannedUsersModalOpen,
      editModalOpen,
      helpPageOpen,
      termsOfUseOpen,
      insufficientBalanceAmount,
      insufficientBalanceBet,
      achievementsNotification,
      showUserSettingsModal,
      quitModal,
      showTournamentTutorial,
      transactionSuccess,
      stripeLoading,
      toggleVerifySuccess,
      holidayModalOpen,
      bonusModalOpen,
      openAchievementsModal,
      popOverOpen,
      popOverDescription,
      popOverTitle,
      targetName,
      buyModal,
      selectedProduct,
      selectedPaymentOption,
      paymentErrorModal,
      isOnline,
      devMode,
      checkedTerms,
      paymentError,
      roomEndedModalBlock,
      openHolidayDebugger,
      selectedTestHoliday,
      holidayTest,
      openChangeLogVersionModal,
      openNewHelpModal,
      toggleNewHelpModal,
    } = this.state;

   // console.log('openAchievementsModal', selectedTestHoliday, openAchievementsModal, achievementsNotification);

   // console.log('achievementsNotification && openAchievementsModal && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION', weeklyDataOpen, member.newVersion, constants.NEW_VERSION);

    const roomEndedModalOpen = devMode && roomEnded && balance < 200 && !roomEndedModalBlock && !(achievementsNotification && openAchievementsModal && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION);

    if (loading || (!member.uid)) {
      return (
        <Fragment>
          <LoadingPage fontMode={fontMode} isFullscreen={isFullscreen} loading={loading} loadingProgress={loadingProgress} devMode={devMode} />
        </Fragment>
      );
    }

    if (transactionSuccess) {
      return (
        <Fragment>
          <TransactionSuccess fontMode={fontMode} toggle={this.toggle} isFullscreen={isFullscreen} transactionSuccess={transactionSuccess} toggleTransactionSuccess={this.toggleTransactionSuccess} />
        </Fragment>
      );
    }

    if (stripeLoading) {
      return (
        <Fragment>
          <Helmet>
            <title>
              Zole - {t('home.loading')}
            </title>
          </Helmet>
          <div className='stripe-loading'>
            <Spinner color="warning" style={{ width: '5rem', height: '5rem' }} />
            <Label className='stripe-loading-content'>{t('home.paymentSystemLoading')}</Label>
          </div>
        </Fragment>
      );
    }

    if (member && member.blocked) {
      return (
        <BlockedPage
          t={t}
          toggleAboutUs={(e) => { e.preventDefault(); this.toggleAbout(e); }}
          toggleStripeLoading={this.toggleStripeLoading}
          toggleTransactionSuccess={this.toggleTransactionSuccess}
          transactionSuccess={transactionSuccess}
          switchToggle={this.toggle}
          parentActiveTab={activeTab}
          fontMode={fontMode}
          handleAppStore={this.handleAppStore}
          handlePlayStore={this.handlePlayStore}
          isFullscreen={isFullscreen}
          isFullscreenEnabled={isFullscreenEnabled}
          toggleFullscreen={toggleFullscreen}
        />
      );
    }
    //  console.log(member, "USER_CURRENT_DATA")

    if (termsOfUseOpen) {
      return (
        <>
          <Notification
            openModal={openModal}
            modalType={modalType}
            ignoredMessageName={ignoredMessageName}
            ignoredMessageUid={ignoredMessageUid}
            closeModal={this.closeModal}
            resetErrorNotif={resetErrorNotif}
            newLevel={newLevel}
            switchTabs={this.toggle}
            insufficientBalanceAmount={insufficientBalanceAmount}
            insufficientBalanceBet={insufficientBalanceBet}
            fontMode={fontMode}
          />

          <HolidayModals run={run} toggleHolidayModal={this.toggleHolidayModal} fontMode={fontMode} holidayTest={holidayTest} selectedTestHoliday={selectedTestHoliday[0]} cancelHolidayTest={this.cancelHolidayTest} />
          <BonusModals openModal={openModal} holidayModalOpen={holidayModalOpen} toggleBonusModal={this.toggleBonusModal} fontMode={fontMode} />
          <EventModals
            switchTabs={this.toggle}
            fontMode={fontMode}
            isEventOption={isEventOption}
            toggleEvent={toggleEvent}
            designPlatform={designPlatform}
            toggleNewDesign={toggleNewDesign}
          />
          {/* Stop for production mode */}
          {weeklyDataOpen ? (
            <Modal container={'div > div'} isOpen={weeklyDataOpen} className={`old-modal root-font-${fontMode}`} size="lg">
              <ModalHeader
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={closeWeeklyData} />
                }
              >
                {t('myInfo.weeklyStatus')}
              </ModalHeader>
              <ModalBody>
                <WeeklyStatusHome />
              </ModalBody>
              <ModalFooter>
                <Button className="modal-footer-button" color="secondary" onClick={closeWeeklyData}>
                  {t('common.ok')}
                </Button>
              </ModalFooter>
            </Modal>
          ) : (null)}
          {devMode && member && member.newVersion >= constants.NEW_VERSION && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && (
            <Hints fontMode={fontMode} />
          )}
          <div className="user-settings-wrapper">
            {!config.isInAppFrame() && (<a href="#" title={t('menu.logout')} className="logout-button" ><img src={logoutImg} id="menu_logout_button" onClick={this.logout} onMouseEnter={() => this.onHover(constants.LOGOUTTOOLTIPTITLE, constants.LOGOUTTOOLTIPDESCRIPTION, constants.LOGOUTTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} alt={t('menu.logout')} /></a>)}
            {!isMobileSafari ? (
              <div className="fullscreen-button">
                {isFullscreenEnabled ? (
                  <Media className="fullscreen-button-image" id='menu_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.EXITFULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={closeFullscreen} onClick={toggleFullscreen} />
                ) : (
                  <Media className="fullscreen-button-image" id='menu_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.FULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={openFullscreen} onClick={toggleFullscreen} />
                )}
              </div>
            ) : (null)}

            <img src={fontMode === constants.FONT_MODE.xLarge ? xLargeFont : fontMode === constants.FONT_MODE.large ? largeFont : defaultFont} className="user-settings-wrapper-font-mode cursor-pointer" id='menu_font_resize_button' onMouseEnter={() => this.onHover(constants.FONTRESIZETOOLTIPTITLE, constants.FONTRESIZETOOLTIPDESCRIPTION, constants.FONTRESIZETOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} alt="x" onClick={this.toggleFontFunc} />

            <img className="user-settings-button" src={gearIcon} alt={t('menu.settings')} id='menu_settings_button' onMouseEnter={() => this.onHover(constants.SETTINGSTOOLTIPTITLE, constants.SETTINGSTOOLTIPDESCRIPTION, constants.SETTINGSTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} onClick={this.showUserSettingsModal} />
          </div>
          {showUserSettingsModal ? (
            <Modal container={'div > div'} isOpen={showUserSettingsModal} toggle={this.showUserSettingsModal} className={`old-modal root-font-${fontMode}`} size="lg" style={{ maxWidth: 734 }}>
              <ModalHeader
                toggle={this.showUserSettingsModal}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.showUserSettingsModal} />
                }
              >
                {t('menu.settings')}
              </ModalHeader>
              <ModalBody>
                <UserSettings fontMode={fontMode} toggleNewDesign={toggleNewDesign} showUserSettingsModal={this.showUserSettingsModal} />
              </ModalBody>
              {/* <ModalFooter>
                <Button className="modal-footer-button" color="secondary" onClick={this.showUserSettingsModal}>
                  {t('common.save')}
                </Button>
              </ModalFooter> */}
            </Modal>
          ) : (null)}

          <Modal container={'div > div'} isOpen={quitModal} toggle={this.closeQuitModal} className={`notification old-modal root-font-${fontMode}`}>
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeQuitModal} />
              }
            >{t('home.confirm')}</ModalHeader>
            <ModalBody className="notification-body">
              {t('common.quitConfirm')}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button className="btn notification-footer-button" onClick={this.approveQuitModal}>
                {t('common.yes')}
              </Button>
              <Button type="button" className="btn notification-footer-button" onClick={this.closeQuitModal}>
                {t('common.no')}
              </Button>
            </ModalFooter>
          </Modal>

          {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && document.getElementById('top-tab') && document.getElementById('top-tab').isSameNode && (
            // <Joyride
            //   tooltipComponent={CustomTooltip}
            //   callback={this.handleJoyrideCallback}
            //   steps={steps}
            //   continuous
            //   showProgress
            //   showSkipButton
            //   scrollToFirstStep
            //   spotlightClicks
            //   spotlightPadding={0}
            //   run={run}
            //   styles={{
            //     options: {
            //       zIndex: 10000,
            //       primaryColor: 'linear-gradient(180deg,#b4ec51,#429321)',
            //     },
            //   }}
            // />
            <JoyrideComponent run={run} toggle={this.toggle} closeJoyride={this.closeJoyride} tutorialBonus={member?.tutorialBonus || false} fontMode={fontMode} />
          )}
          <PopoverComponent popOverOpen={popOverOpen} onHover={() => this.onHover} onHoverLeave={() => this.onHoverLeave} fontMode={fontMode} targetName={targetName} popOverTitle={popOverTitle} popOverDescription={popOverDescription} />

          <div>
            <TermsOfUsePage
              playButtonSound={playButtonSound}
              toggleHelp={this.toggleTermsOfUse}
              showUserSettingsModal={this.showUserSettingsModal}
            />
          </div>
          {/*  <div className="bottom-bar-rules-page">
            <Col sm="12" className="bottom-bar-links">
              <a
                href="#"
                className={`bottom-bar-links-help ${member.supportChatStatus && member.supportChatStatus.read === false ? 'incoming' : ''}`}
                onClick={(e) => { e.preventDefault(); this.toggleSupport(e); }}
              >
                <Media src={helpImg} />
                <span>
                  {t('menu.help')}
                </span>
                {supportModalOpen && (
                <ContactSupport
                  modalOpen={supportModalOpen}
                  toggle={this.toggleSupport}
                />
                )}
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.handleClickStart(e); }}>
                <Media src={quickHelpImg} />
                <span>
                  {t('menu.tutorial')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBalanceHistory(e); }}>
                <Media className="balance-hist-ico" src={moneyHistoryImg} />
                <span>
                  {t('menu.moneyHistory')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.togglePointsHistory(e); }}>
                <Media className="balance-hist-ico" src={pointsHistoryImg} />
                <span>
                  {t('menu.pointsHistory')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBannedUsers(e); }}>
                <Media src={blockedUsersImg} />
                <span>
                  {t('menu.bannedUsers')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.toggleHelp(e); }}>
                <Media src={gameHelpImg} />
                <span>
                  {t('menu.helpPage')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.toggleAbout(e); }}>
                <Media src={gameHelpImg} />
                <span>
                  {t('menu.aboutPage')}
                </span>
              </a>
              <a href="#" onClick={(e) => { e.preventDefault(); this.toggleTermsOfUse(e); }}>
                <Media src={gameHelpImg} />
                <span>
                  {t('home.termsOfUse')}
                </span>
              </a>
            </Col>
          </div>  */}
        </>
      );
    }
    // console.log(weeklyDataOpen, "fontMode-----")

    const tabsStyle = { marginTop: 90, padding: '25px 15px' };
    if (config.isInAppFrame() && !isFullscreen && fontMode === constants.FONT_MODE.xLarge) {
      tabsStyle.padding = '25px 15px 8px 15px';
    }
    // ...((config.isInAppFrame() && !isFullscreen && fontMode === constants.FONT_MODE.xLarge) ? {paddingBottom: 5 } : {})
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}
      >
      <Fragment>
        <Notification
          openModal={openModal}
          modalType={modalType}
          ignoredMessageName={ignoredMessageName}
          ignoredMessageUid={ignoredMessageUid}
          closeModal={this.closeModal}
          resetErrorNotif={resetErrorNotif}
          newLevel={newLevel}
          switchTabs={this.toggle}
          insufficientBalanceAmount={insufficientBalanceAmount}
          insufficientBalanceBet={insufficientBalanceBet}
          fontMode={fontMode}
        />
        <HolidayModals run={run} toggleHolidayModal={this.toggleHolidayModal} openModal={openModal} fontMode={fontMode} holidayTest={holidayTest} selectedTestHoliday={selectedTestHoliday[0]} cancelHolidayTest={this.cancelHolidayTest}/>
        <BonusModals openModal={openModal} holidayModalOpen={holidayModalOpen} toggleBonusModal={this.toggleBonusModal} fontMode={fontMode} />
        <EventModals
          switchTabs={this.toggle}
          fontMode={fontMode}
          isEventOption={isEventOption}
          toggleEvent={toggleEvent}
          designPlatform={designPlatform}
          toggleNewDesign={toggleNewDesign}
        />
        <Feedback fontMode={fontMode} />
          {/*  && member?.earnedInADay >= constants.WEEKLY_VALUE.oneWeek */}

          {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && weeklyDataOpen ? (
            <Modal container={'div > div'} isOpen={weeklyDataOpen} className={`old-modal root-font-${fontMode}`} size="lg">
              <ModalHeader
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={closeWeeklyData} />
                }
              >
                {t('myInfo.weeklyStatus')}
              </ModalHeader>
              <ModalBody>
                <WeeklyStatusHome />
              </ModalBody>
              <ModalFooter>

                <Button className="modal-footer-button" color="secondary" onClick={closeWeeklyData}>
                  {t('common.ok')}
                </Button>
              </ModalFooter>
            </Modal>
          ) : (null)}

          {devMode && member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && !weeklyDataOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && (
            <Hints fontMode={fontMode} />
          )}

          <div className="user-settings-wrapper">
            {!config.isInAppFrame() && (<a href="#" title={t('menu.logout')} className="logout-button" ><img src={logoutImg} id="menu_logout_button" onClick={this.logout} onMouseEnter={() => this.onHover(constants.LOGOUTTOOLTIPTITLE, constants.LOGOUTTOOLTIPDESCRIPTION, constants.LOGOUTTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} alt={t('menu.logout')} /></a>)}
            {!isMobileSafari ? (
              <div className="fullscreen-button">
                {isFullscreenEnabled ? (
                  <Media className="fullscreen-button-image" id='menu_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.EXITFULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={closeFullscreen} onClick={toggleFullscreen} />
                ) : (
                  <Media className="fullscreen-button-image" id='menu_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.FULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={openFullscreen} onClick={toggleFullscreen} />
                )}
              </div>
            ) : (null)}

            <img src={fontMode === constants.FONT_MODE.xLarge ? xLargeFont : fontMode === constants.FONT_MODE.large ? largeFont : defaultFont} className="user-settings-wrapper-font-mode cursor-pointer" id='menu_font_resize_button' onMouseEnter={() => this.onHover(constants.FONTRESIZETOOLTIPTITLE, constants.FONTRESIZETOOLTIPDESCRIPTION, constants.FONTRESIZETOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} alt="x" onClick={this.toggleFontFunc} />

            <img className="user-settings-button" src={gearIcon} alt={t('menu.settings')} id='menu_settings_button' onMouseEnter={() => this.onHover(constants.SETTINGSTOOLTIPTITLE, constants.SETTINGSTOOLTIPDESCRIPTION, constants.SETTINGSTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} onClick={this.showUserSettingsModal} />
          </div>
          {showUserSettingsModal ? (
            <Modal container={'div > div'} isOpen={showUserSettingsModal} toggle={this.showUserSettingsModal} className={`old-modal root-font-${fontMode}`} size="lg" style={{ maxWidth: 734 }}>
              <ModalHeader
                toggle={this.showUserSettingsModal}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.showUserSettingsModal} />
                }
              >
                {t('menu.settings')}
              </ModalHeader>
              <ModalBody>
                <UserSettings fontMode={fontMode} toggleNewDesign={toggleNewDesign} showUserSettingsModal={this.showUserSettingsModal} />
              </ModalBody>
            </Modal>
          ) : (null)}
          {openNewHelpModal && (
            <Modal container={'div > div'} isOpen={openNewHelpModal} toggle={this.toggleNewHelpModal}  className={`old-modal root-font-${fontMode}`} size="xlg">
              <ModalHeader
                toggle={this.toggleNewHelpModal}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleNewHelpModal} />
                }
              >
                Help Modal
              </ModalHeader>
              <ModalBody>
                <NewHelpPage
                  playButtonSound={playButtonSound}
                />
              </ModalBody>
            </Modal>
          )}
          {/*  <div className="lobby-background" />  */}
          {!helpPageOpen ? (
            <>
              {/* {
              stripeLoading && (
                <div className='stripe-loading'>
                  <Spinner color="warning" style={{ width: '5rem', height: '5rem' }} />
                  <Label className='stripe-loading-content'>{t('home.paymentSystemLoading')}</Label>
                </div>
              )
            } */}
              <div className="content">
                {/*  <Col xs="3" sm="3" lg={`${isFullscreen ? '2' : '3'}`} style={{ marginTop: 50, minWidth: 250, float: 'left' }}> */}
                <div className={`content-left ${config.isInAppFrame() && !isFullscreen && fontMode === constants.FONT_MODE.xLarge && `content-left-compact`}`}>
                  <PlayerInfo
                    fontMode={fontMode}
                    run={run}
                    openModal={openModal}
                    holidayModalOpen={holidayModalOpen}
                    bonusModalOpen={bonusModalOpen}
                    weeklyDataOpen={weeklyDataOpen}
                    toggle={this.toggle}
                    toggleEditModal={this.toggleEditModal}
                    editModalOpen={editModalOpen}
                    showNotification={showNotification}
                    member={member}
                    isInAppFrame={isInAppFrame}
                  />
                  <NewGame
                    createRoom={createRoom}
                    changeTab={this.toggle}
                    privateRoomPassword={privateRoomPassword}
                    showPrivateRoomPassword={showPrivateRoomPassword}
                    closePrivateRoomPassword={closePrivateRoomPassword}
                    fontMode={fontMode}
                    setScreenView={setScreenView}
                  />
                  <Row>
                    <OnlineCount />
                  </Row>
                  <Row>
                    <Col sm="12">
                      <ShopBonus changeTab={this.toggle} activeTab={activeTab} />
                    </Col>
                  </Row>
                </div>
                {/*  </Col>
              <Col xs="9" sm="9" lg={`${isFullscreen ? '10' : '9'}`} > */}
                <div className="content-right">
                  <div className="logo-wrapper logo-wrapper-section" style={{ zIndex: 1 }}>
                    <a className="logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
                    {
                      member && (member.role === 'admin' || member.role === 'tester') && (
                        <Media
                          src={debuggerImg}
                          width={32}
                          height={32}
                          alt='Holiday Debugger'
                          onClick={() => this.toggleHolidayDebuggerModal()}
                          className='debugger-holiday'
                        />
                      )
                    }

                </div>
                <div className="logo-domain-header">
                  {constants.DOMAIN_NAME}
                </div>
                {(member && adminReturnToken) && (
                  <Button
                    className="btn holiday-debugger-button"
                    onClick={() => this.returnToAdmin()}
                    style={{ zIndex: 999 }}
                  >
                    Return To Admin
                  </Button>
                )}
                {((member && member.role === 'admin') || (designPlatform && designPlatform?.new && ((designPlatform?.devMode && devMode) || !designPlatform?.devMode))) && (
                  <div className="logo-toggle-new-design-header">
                    <Button
                      className="btn holiday-debugger-button"
                      onClick={() => { toggleNewDesign(true); }}
                      style={{ zIndex: 999 }}
                    >
                      {t('home.newDesign')}
                    </Button>
                  </div>
                )}
                <Row className="menu-body">
                  <Col style={{ maxWidth: 1120 }} xs="12" sm={`${isFullscreen ? '12' : '12'}`} lg={`${isFullscreen ? '12' : '12'}`} xl={`${isFullscreen ? '10' : '12'}`}>
                    <Row className="tabs" style={tabsStyle}>
                      <Col sm={`${isFullscreen ? '12' : '12'}`} lg={`${isFullscreen ? '12' : '12'}`} xl={`${isFullscreen ? '12' : '12'}`}>
                        <Row style={{ border: 0 }}>

                            <Col className="menu-topTab menu-rooms-tab">
                              <div className={`menu-topTab-wrapper ${activeTab === constants.MENU_NAVIGATION.rooms && 'active'}`}>
                                <Button
                                  color="link"
                                  className="menu-topTab-link"
                                  onClick={() => { this.toggle(constants.MENU_NAVIGATION.rooms); }}
                                >
                                  {t('menu.rooms')}
                                </Button>
                              </div>
                            </Col>
                            <Col className="menu-topTab menu-top-tab">
                              <div className={`menu-topTab-wrapper ${(activeTab === constants.MENU_NAVIGATION.top || activeTab === constants.MENU_NAVIGATION.topMillionaires) && 'active'}`}>
                                <Button
                                  color="link"
                                  className="menu-topTab-link"
                                  onClick={() => { this.toggle(constants.MENU_NAVIGATION.top); }}
                                >
                                  {t('menu.top')}
                                </Button>
                              </div>
                            </Col>
                            {/* Stop for production mode */}

                            <Col className="menu-topTab power-rankings-tab">
                              <div className={`menu-topTab-wrapper ${((constants.POWER_RANKINGS_TABS).includes(activeTab)) && 'active'}`}>
                                <Button
                                  color="link"
                                  className="menu-topTab-link"
                                  onClick={() => { this.toggle(constants.MENU_NAVIGATION.powerRankings); }}
                                >
                                  {t('menu.powerRankings')}
                                </Button>
                              </div>
                            </Col>
                            <Col className="menu-topTab my-info-tab">
                              <div className={`menu-topTab-wrapper ${constants.MY_INFO_TABS.includes(activeTab) && 'active'}`}>
                                <Button
                                  color="link"
                                  // className={`menu-topTab-link ${achievementsNotification ? 'menu-topTab-link-notification' : ''}`}
                                  className={`menu-topTab-link`}
                                  onClick={() => { this.toggle(constants.MENU_NAVIGATION.myInfo); }}
                                >
                                  {t('menu.myInfo')}
                                </Button>
                              </div>
                            </Col>
                            <Col className="menu-topTab tournaments-tab">
                              <div className={`menu-topTab-wrapper menu-topTab-tournament ${(activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory || activeTab === constants.MENU_NAVIGATION.futureTournaments) && 'active'}`}>
                                <Button
                                  color="link"
                                  className={`menu-topTab-link ${(hasActiveRegistration) ? 'menu-topTab-link-notification' : ''}`}
                                  onClick={() => this.toggle(hasActiveRegistration ? constants.MENU_NAVIGATION.futureTournaments : constants.MENU_NAVIGATION.tournaments)}
                                >
                                  {t('menu.tournaments')}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <TabContent className="main-tab-content" activeTab={activeTab}>
                        <div className={`joyride-enter-room-zone-old ${config.isInAppFrame() && 'joyride-enter-room-zone-old-iframe'}`} />

                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.rooms}>
                          <Row>
                            <Col xs="12" sm="12" className="rooms-table-section">
                              {activeTab && activeTab === constants.MENU_NAVIGATION.rooms && (
                                <RoomsTable
                                  privateRoomPassword={privateRoomPassword}
                                  joinRoom={joinRoom}
                                  isFullscreen={isFullscreen}
                                  fontMode={fontMode}
                                  joinRoomError={joinRoomError}
                                  resetError={resetRoomError}
                                  setScreenView={setScreenView}
                                  campaignData={campaignData}
                                  devMode={devMode}
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.top}>
                          <Row>
                            <Col sm="12" className="top-page-section">
                              {activeTab && activeTab === constants.MENU_NAVIGATION.top && (
                                <TopPage toggle={this.toggle} notificationSettings={notificationSettings} fontMode={fontMode} />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.topMillionaires}>
                          <Row>
                            <Col sm="12">
                              {activeTab && activeTab === constants.MENU_NAVIGATION.topMillionaires && (
                                <TopPageMillionaires toggle={this.toggle} fontMode={fontMode} />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        {/* Stop for production mode */}
                        <div className="power-rankings-section-wrapper">
                          {activeTab && (constants.POWER_RANKINGS_TABS).includes(activeTab) && (
                            <TabPane className="main-tab-pane" tabId={/*constants.MENU_NAVIGATION.powerRankings*/activeTab}>
                              <Row>
                                <Col xs="12" sm="12" className="power-rankings-section">
                                    <PowerRankings parentActiveTab={activeTab} parentChangeTab={this.toggle} fontMode={fontMode} checked={pwOption} togglePWOption={this.togglePwOption} />
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                        </div>

                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.myInfo}>
                          <Row>
                            <Col xs="12" sm="12" className="power-rankings-section my-info-wrapper">
                              {activeTab && activeTab === constants.MENU_NAVIGATION.myInfo && (
                                <MyInfo
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  notificationSettings={notificationSettings}
                                  fontMode={fontMode}
                                //  isFullscreen={isFullscreen}
                                />
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.bonusPage}>
                          <Row>
                            <Col sm="12">
                              <BonusPage
                                activeTab={activeTab}
                                changeTab={this.toggle}
                                doRender={!!(activeTab && activeTab === constants.MENU_NAVIGATION.bonusPage)}
                                isFullscreen={isFullscreen}
                                fontMode={fontMode}
                              />
                            </Col>
                          </Row>
                        </TabPane>

                        {activeTab && constants.BUY_PAGE_TABS.includes(activeTab) &&
                          <TabPane className="main-tab-pane" tabId={activeTab}>
                            <Row>
                              <Col sm="12">
                                <BuyPage toggleAboutUs={(e) => { e.preventDefault(); this.toggleAbout(e); }} toggleStripeLoading={this.toggleStripeLoading} toggleTransactionSuccess={this.toggleTransactionSuccess} transactionSuccess={transactionSuccess} switchToggle={this.toggle} parentActiveTab={activeTab} isFullscreen={isFullscreen} isFullscreenEnabled={isFullscreenEnabled} toggleFullscreen={toggleFullscreen} showNotification={showNotification} loading={loading} fontMode={fontMode} />
                              </Col>
                            </Row>
                          </TabPane>
                        }

                        {activeTab && (activeTab === constants.MENU_NAVIGATION.tournaments || activeTab === constants.MENU_NAVIGATION.futureTournaments || activeTab === constants.MENU_NAVIGATION.tournamentsHistory) && (
                          <TabPane className="main-tab-pane" tabId={activeTab}>
                            <Row>
                              <Col sm="12">
                                <div className="tournaments">
                                  <TournamentsHeader
                                    activeTab={activeTab}
                                    changeTab={this.toggle}
                                    showTournamentTutorial={showTournamentTutorial}
                                    toggleShowTournamentTutorial={this.toggleShowTournamentTutorial}
                                  />
                                  {activeTab === constants.MENU_NAVIGATION.tournaments &&
                                    <Tournaments
                                      changeTab={this.toggle}
                                      showTournamentTutorial={showTournamentTutorial}
                                      toggleShowTournamentTutorial={this.toggleShowTournamentTutorial}
                                      fontMode={fontMode}
                                    />
                                  }
                                  {activeTab === constants.MENU_NAVIGATION.futureTournaments &&
                                    <TournamentsPlan
                                      changeTab={this.toggle}
                                      showTournamentTutorial={showTournamentTutorial}
                                      toggleShowTournamentTutorial={this.toggleShowTournamentTutorial}
                                      fontMode={fontMode}
                                      updateModal={this.UpdateModal}
                                      openModal={openModal}
                                      modalType={modalType}
                                    />
                                  }
                                  {activeTab === constants.MENU_NAVIGATION.tournamentsHistory &&
                                    <TournamentsHistory
                                      changeTab={this.toggle}
                                      showTournamentTutorial={showTournamentTutorial}
                                      toggleShowTournamentTutorial={this.toggleShowTournamentTutorial}
                                      fontMode={fontMode}
                                    />
                                  }
                                </div>

                              </Col>
                            </Row>
                          </TabPane>
                        )}

                        {(myTournamentsData && myTournamentsData.tournamentId) ? (
                          <TournamentNotification fontMode={fontMode} />
                        ) : (null)}

                        {activeTab && activeTab === constants.MENU_NAVIGATION.friends && (
                          <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.friends}>
                            <Row>
                              <Col sm="12">
                                <Friends
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  fontMode={fontMode}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        )}

                        {activeTab && activeTab === constants.MENU_NAVIGATION.ignoredUsers && (
                          <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.ignoredUsers}>
                            <Row>
                              <Col sm="12">
                                <IgnoredUsers
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  ignoredSwitchChecked={ignoredSwitchChecked}
                                  toggleIgnoredSwitchChecked={this.toggleIgnoredSwitchChecked}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        )}
                        <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.archievements}>
                          <Row>
                            <Col sm="12">
                              <Achievements
                                activeTab={activeTab}
                                changeTab={this.toggle}
                                achievementsNotification={achievementsNotification}
                                setAchievementsNotification={this.setAchievementsNotification}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        {activeTab && activeTab === constants.MENU_NAVIGATION.gameHistory && (
                          <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.gameHistory}>
                            <Row>
                              <Col sm="12">
                                <GamesHistory
                                  activeTab={activeTab}
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  isFullscreen={isFullscreen}
                                  isFullscreenEnabled={isFullscreenEnabled}
                                  innerHeight={window.innerHeight}
                                  windowHeight={window.height}
                                  fontMode={fontMode}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        )}
                        {activeTab && activeTab === constants.MENU_NAVIGATION.weeklyStatus && (
                          <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.weeklyStatus}>
                            <Row>
                              <Col sm="12">
                                <WeeklyStatus
                                  activeTab={activeTab}
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  isFullscreen={isFullscreen}
                                  isFullscreenEnabled={isFullscreenEnabled}
                                  innerHeight={window.innerHeight}
                                  windowHeight={window.height}
                                  fontMode={fontMode}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        )}
                        {activeTab && activeTab === constants.MENU_NAVIGATION.gameLogs && (
                          <TabPane className="main-tab-pane" tabId={constants.MENU_NAVIGATION.gameLogs}>
                            <Row>
                              <Col sm="12">
                                <GameLogs
                                  activeTab={activeTab}
                                  changeTab={this.toggle}
                                  achievementsNotification={achievementsNotification}
                                  isFullscreen={isFullscreen}
                                  isFullscreenEnabled={isFullscreenEnabled}
                                  fontMode={fontMode}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        )}
                      </TabContent>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="bottom-bar">
                <Col sm="12" className="bottom-bar-links">
                  <a
                    href="#"
                    className={`bottom-bar-links-help ${member.supportChatStatus && member.supportChatStatus.read === false ? 'incoming-old' : ''}`}
                    onClick={(e) => { e.preventDefault(); this.toggleSupport(e); }}
                  >
                    <Media src={helpImg} />
                    <span>
                      {t('menu.help')}
                    </span>
                  </a>
                  <a href="#" className="bottom-bar-links-tutorial" onClick={(e) => { e.preventDefault(); this.handleClickStart(e); }}>
                    <Media src={quickHelpImg} />
                    <span>
                      {t('menu.tutorial')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBalanceHistory(e); }}>
                    <Media className="balance-hist-ico" src={moneyHistoryImg} />
                    <span>
                      {t('menu.moneyHistory')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.togglePointsHistory(e); }}>
                    <Media className="balance-hist-ico" src={pointsHistoryImg} />
                    <span>
                      {t('menu.pointsHistory')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBannedUsers(e); }}>
                    <Media src={blockedUsersImg} />
                    <span>
                      {t('menu.bannedUsers')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleHelp(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.helpPage')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleAbout(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.aboutPage')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleTermsOfUse(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.termsOfUse')}
                    </span>
                  </a>
                </Col>
              </div>

              {supportModalOpen && (
                <ContactSupport
                  modalOpen={supportModalOpen}
                  toggle={this.toggleSupport}
                  showNotification={showNotification}
                  fontMode={fontMode}
                  platform={platform}
                />
              )}

              {member && member.showTournamentResults && member.showTournamentResults.tournamentId && (!achievementsNotification || !openAchievementsModal) && !weeklyDataOpen ? (
                <TournamentResults fontMode={fontMode} />
              ) : (
                null
              )}

              <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={this.toggleShowTournamentTutorial} fontMode={fontMode} />

              <Clock offset={member.offset} />
            </>
          ) : (
            <>
              <div>
                <RulesPage
                  playButtonSound={playButtonSound}
                  toggleHelp={this.toggleHelp}
                />
              </div>
              <div className="bottom-bar-rules-page">
                <Col sm="12" className="bottom-bar-links">
                  <a
                    href="#"
                    className={`bottom-bar-links-help ${member.supportChatStatus && member.supportChatStatus.read === false ? 'incoming-old' : ''}`}
                    onClick={(e) => { e.preventDefault(); this.toggleSupport(e); }}
                  >
                    <Media src={helpImg} />
                    <span>
                      {t('menu.help')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.handleClickStart(e); }}>
                    <Media src={quickHelpImg} />
                    <span>
                      {t('menu.tutorial')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBalanceHistory(e); }}>
                    <Media className="balance-hist-ico" src={moneyHistoryImg} />
                    <span>
                      {t('menu.moneyHistory')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.togglePointsHistory(e); }}>
                    <Media className="balance-hist-ico" src={pointsHistoryImg} />
                    <span>
                      {t('menu.pointsHistory')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleBannedUsers(e); }}>
                    <Media src={blockedUsersImg} />
                    <span>
                      {t('menu.bannedUsers')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleHelp(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.helpPage')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleAbout(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.aboutPage')}
                    </span>
                  </a>
                  <a href="#" onClick={(e) => { e.preventDefault(); this.toggleTermsOfUse(e); }}>
                    <Media src={gameHelpImg} />
                    <span>
                      {t('menu.termsOfUse')}
                    </span>
                  </a>
                </Col>
              </div>

              {supportModalOpen && (
                <ContactSupport
                  modalOpen={supportModalOpen}
                  toggle={this.toggleSupport}
                  showNotification={showNotification}
                  fontMode={fontMode}
                  platform={platform}
                />
              )}
            </>
          )}

          {console.log('test', { member: member.newVersion, openModal, holidayModalOpen, bonusModalOpen, weeklyDataOpen, toggleVerifySuccess, openAchievementsModal, achievementsNotification })}

          {member && member.newVersion >= constants.NEW_VERSION && !adminReturnToken && !openModal && !holidayModalOpen && !bonusModalOpen && !toggleVerifySuccess && (!openAchievementsModal || !achievementsNotification) && (
            <JoyrideComponent run={run} toggle={this.toggle} closeJoyride={this.closeJoyride} tutorialBonus={member?.tutorialBonus || false} fontMode={fontMode} />
          )}

          <PopoverComponent popOverOpen={popOverOpen} onHover={() => this.onHover} onHoverLeave={() => this.onHoverLeave} fontMode={fontMode} targetName={targetName} popOverTitle={popOverTitle} popOverDescription={popOverDescription} />

          <Modal container={'div > div'} isOpen={balanceHistoryModalOpen} toggle={this.toggleBalanceHistory} className={`old-modal root-font-${fontMode}`} size="lg" style={{ maxWidth: 600 }}>
            <ModalHeader
              toggle={this.toggleBalanceHistory}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleBalanceHistory} />
              }
            >
              {t('menu.moneyHistory')}
            </ModalHeader>
            <ModalBody>
              {balanceHistoryModalOpen && (
                <MoneyHistory />
              )}
            </ModalBody>
            <ModalFooter>
              <Button className="modal-footer-button" color="secondary" onClick={this.toggleBalanceHistory}>
                {t('common.close')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={pointsHistoryModalOpen} toggle={this.togglePointsHistory} className={`old-modal root-font-${fontMode}`} size="lg" style={{ maxWidth: 600 }}>
            <ModalHeader
              toggle={this.togglePointsHistory}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.togglePointsHistory} />
              }
            >
              {t('menu.pointsHistory')}
            </ModalHeader>
            <ModalBody>
              {pointsHistoryModalOpen && (
                <PointsHistory />
              )}
            </ModalBody>
            <ModalFooter>
              <Button className="modal-footer-button" color="secondary" onClick={this.togglePointsHistory}>
                {t('common.close')}
              </Button>
            </ModalFooter>
          </Modal>

          {bannedUsersModalOpen ? (
            <Modal container={'div > div'} isOpen={bannedUsersModalOpen} toggle={this.toggleBannedUsers} size="xl" className={`old-modal root-font-${fontMode}`} style={{ maxWidth: 720 }}>
              <ModalHeader
                toggle={this.toggleBannedUsers}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleBannedUsers} />
                }
              >
                {t('menu.bannedUsers')}
              </ModalHeader>
              <ModalBody>
                <BannedUsers />
              </ModalBody>
              <ModalFooter>
                <Button className="modal-footer-button" color="secondary" onClick={this.toggleBannedUsers}>
                  {t('common.close')}
                </Button>
              </ModalFooter>
            </Modal>
          ) : (null)}

          <Modal container={'div > div'} className={`about-modal old-modal root-font-${fontMode}`} size="lg" isOpen={aboutModalOpen} toggle={this.toggleAbout} >
            <ModalHeader
              className="about-modal-header"
              toggle={this.toggleAbout}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleAbout} />
              }
            >
              {t('menu.aboutPage').toUpperCase()}
              <div className="about-modal-header-version">
                {t('home.zoleGameVersion')}
                {currentVersion}
              </div>
            </ModalHeader>
            <ModalBody className="about-modal-body">
              <hr />

              <div className="about-modal-body-column">
                <div className="about-modal-body-column-section">
                  <p>{t('common.developer')}:</p>
                  <a target="_blank" rel="noopener noreferrer" href="https://draxogames.com/">SIA 'Draxo Games'</a>
                </div>
                <div className="about-modal-body-column-section">
                  <p>{t('common.regNum')}:</p>
                  <span>40103927834</span>
                </div>
                <div className="about-modal-body-column-section">
                  <p>{t('common.address')}:</p>
                  <span>Liepājas 49-6, Kuldīga, LV-3301</span>
                </div>
              </div>

              <div className="about-modal-body-column">
                <div className="about-modal-body-column-section">
                  <p>WEB:</p>
                  <a target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/">https://spelezoli.lv/</a>
                </div>
                <div className="about-modal-body-column-section">
                  <p>{t('home.email')}:</p>
                  <a target="_blank" rel="noopener noreferrer" href="mailto: info@draxogames.com">info@draxogames.com</a>
                </div>
                <div className="about-modal-body-column-section">
                  <p>{t('common.phoneNum')}:</p>
                  <a href="tel:+37126622677">+371 26 62 26 77</a>
                </div>
              </div>

              <hr />

              <div className="about-modal-apps">
                <Media src={appStore} onClick={this.handleAppStore} className="about-modal-apps-img" />
                <Media src={playStore} onClick={this.handlePlayStore} className="about-modal-apps-img" />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn notification-footer-button about-modal-close-button" onClick={this.toggleAbout}>
                {t('common.close')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={quitModal} toggle={this.closeQuitModal} className={`notification old-modal root-font-${fontMode}`}>
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeQuitModal} />
              }
            >{t('home.confirm')}</ModalHeader>
            <ModalBody className="notification-body">
              {t('common.quitConfirm')}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button className="btn notification-footer-button" onClick={this.approveQuitModal}>
                {t('common.yes')}
              </Button>
              <Button type="button" className="btn notification-footer-button" onClick={this.closeQuitModal}>
                {t('common.no')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={toggleVerifySuccess} toggle={this.toggleVerifySuccessModal} className={`notification old-modal root-font-${fontMode}`}>
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleVerifySuccessModal} />
              }
            >{t('common.congratulations')}</ModalHeader>
            <ModalBody className="notification-body">
              {t('home.verifySuccessContent')}
            </ModalBody>
            <ModalFooter className="notification-footer register-success-button">
              <Button className="btn notification-footer-button user-success-button" onClick={this.toggleVerifySuccessModal}>
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={achievementsNotification && openAchievementsModal && !adminReturnToken && !holidayModalOpen && !weeklyDataOpen && member && member.newVersion >= constants.NEW_VERSION && !openModal} toggle={this.closeAchievementsModal} className={`notification old-modal root-font-${fontMode}`} size="lg">
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeAchievementsModal} />
              }
            />
            <ModalBody className="notification-body">
              <AchievementsModal />
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button className="btn notification-footer-button" onClick={this.openAchievements}>
                {t('common.viewAll')}
              </Button>
              <Button type="button" className="btn notification-footer-button" onClick={this.closeAchievementsModal}>
                {t('common.close')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={!adminReturnToken && buyModal} size="lg" toggle={this.toggleBuyModal} className={`old-modal modal-vertically-centered root-font-${fontMode}`} >
            <ModalHeader
              toggle={this.toggleBuyModal}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleBuyModal} />
              }
            >
              {t('paymentType.chooseTitle')}
            </ModalHeader>
            <ModalBody>
              <PaymentTypeContent
                selectedProduct={selectedProduct}
                selectedPaymentOption={selectedPaymentOption}
                setSelectedPaymentOption={this.onPaymentOptionClick}
                checkedTerms={checkedTerms}
                toggleCheckedTerms={this.toggleCheckedTerms}
                fontMode={fontMode}
                t={t}
              />
            </ModalBody>
            <ModalFooter>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={this.pay} disabled={!isOnline}>
                {t('common.continue')}
              </Button>
              <hr className="payment-type-line-normal" />
              <p className="payment-type-footnote-text-normal">SIA 'Draxo Games'</p>
              <p className="payment-type-footnote-text-normal">Liepājas 49-6, Kuldīga</p>
              <p className="payment-type-footnote-text-normal">Latvija</p>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={roomEndedModalOpen} toggle={() => setRoomEndedFunction(false)} className={`notification old-modal root-font-${fontMode}`} size="xl">
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => setRoomEndedFunction(false)} />
              }
            />
            <ModalBody className="notification-body">
              <RoomEndedModal balance={balance} buyMoney={this.buyMoney} t={t} i18n={i18n} />
            </ModalBody>
          </Modal>

          <Modal container={'div > div'} isOpen={paymentErrorModal} size="lg" toggle={() => this.togglePaymentErrorModal('')} className={`old-modal root-font-${fontMode}`} >
            <ModalHeader
              toggle={() => this.togglePaymentErrorModal('')}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.togglePaymentErrorModal('')} />
              }
            >
              {t('common.zoleInfo')}
            </ModalHeader>
            <ModalBody className="text-center">
              {paymentError}
            </ModalBody>
            <ModalFooter>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.togglePaymentErrorModal('')}>
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal container={'div > div'} isOpen={openHolidayDebugger} size="lg" toggle={() => this.toggleHolidayDebuggerModal()} className={`old-modal root-font-${fontMode}`} >
            <ModalHeader
              toggle={() => this.toggleHolidayDebuggerModal('')}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleHolidayDebuggerModal()} />
              }
            >
              Deebugger
            </ModalHeader>
            <ModalBody className="text-center">
              <FormGroup>
                <Label>Debugger Type</Label>
                <Input type="select" name="selectedTestHoliday" className="open-page-select mt-4" value={selectedTestHoliday.id} disabled={loading} onChange={this.handleSelectTestHoliday}>
                  {
                    map(constants.HOLIDAY_TYPES, item => (
                      <option value={item.id} className="background-222">{item.label}</option>
                    ))
                  }
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={this.setHolidayTestModal}>
                Ok
              </Button>
              <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.toggleHolidayDebuggerModal()}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <NewVersion hidden={adminReturnToken} t={t} fontMode={fontMode} openChangeLogVersionModal={openChangeLogVersionModal} closeTriggerChangeLogModal={this.closeTriggerChangeLogModal} />
        </Fragment>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  claimedAchievements: state.member.claimedAchievements || {},
  userAchievements: state.member.userAchievements || {},
  gamesPlayed: state.member.gamesPlayed || 0,
  gamesWon: state.member.gamesWon || 0,
  roomEnded: state.rooms.roomEnded || false,
  tournamentRoomEnded: state.rooms.tournamentRoomEnded || false,
  balance: parseFloat(state.member.balance) || 0,
  joyrideEndedTrigger: state.state.joyrideEndedTrigger || false,
  lastShopTab: state.shop.lastShopTab || null,
  transactionComplete: state.shop.transactionComplete || false,
  campaignData: state.shop.campaignLobbyData || {},
  adminReturnToken: state.users.adminReturnToken || null,
  activeTab: state.state.activeTab,
  pwOption: state.state.pwOption,
  ignoredSwitchChecked: state.state.ignoredSwitchChecked,
  // soundOn: (state.userSettings[state.member.uid] && (state.userSettings[state.member.uid].soundOn || state.userSettings[state.member.uid].soundOn === false)) ? (state.userSettings[state.member.uid].soundOn) : (state.userSettings.default ? state.userSettings.default.soundOn : false),
});

const mapDispatchToProps = {
  fetchAchievements: getAchievements,
  claimAchievementFunc: claimAchievement,
  initEveryPay: initEveryPayPayment,
  setRoomEndedFunction: setRoomEnded,
  setJoyrideEndedTriggerFunc: setJoyrideEndedTrigger,
  setScreenView: setUserScreenView,
  closeLevelUpNotification: closeLevelNotification,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
  // fetchCampaign: getCampaignOpenRoomBanner,
  fetchCampaign: getCampaignLobbyBanner,
  returnToAdmin: returnToAdminReturnToken,
  setActiveTabFunc: setActiveTab,
  setPwOptionFunc: setPwOption,
  setIgnoredSwitchCheckedFunc: setIgnoredSwitchChecked,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(Menu)));
