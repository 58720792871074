import { Firebase, FirebaseRef } from '../lib/firebase';


export function getUserCount() {
  return dispatch => new Promise(resolve => FirebaseRef.child('onlineCount')
    .on('value', (snapshot) => {
      const onlineCount = snapshot.val() || 0;

      resolve(dispatch({
        type: 'USER_COUNT',
        data: onlineCount,
      }));
    }));
}


export function getRoomsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('roomsCount')
    .on('value', (snapshot) => {
      const roomsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ROOMS_COUNT', data: roomsCount }));
    })).catch((err) => { console.log(err.message); });
}


export function getBannedUsers(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('bans')
    .orderByChild('blocked')
    .equalTo(true)
    .limitToFirst(100)
    .once('value', (snapshot) => {
      const bans = snapshot.val() || {};
      return resolve(dispatch({ type: 'BANS_REPLACE', data: bans }));
    })).catch((err) => { console.log(err.message); });
}


export function getBannedUsersCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/bansCount')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'BANS_COUNT_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


/*
export function getOnlineUsersLazy(sortFilter, sortDirection, lastItem, lastKey) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    if (sortDirection === 'asc') {
      if (lastKey) {
        FirebaseRef.child('status')
          .orderByChild(sortFilter)
          .startAt(lastItem, lastKey.toString())
          .limitToFirst(10)
          .on('value', (snapshot) => {
            const users = {};
            let newLastKey = '';
            let newLastItem = '';
            snapshot.forEach((child) => {
              if (child.key !== 'onlineCount') {
                const value = child.val();

                users[child.key] = value;
                newLastKey = child.key;
                newLastItem = value[sortFilter];
              }
            });

            resolve(dispatch({
              type: 'ONLINE_USERS_LAZY', data: users, lastKey: newLastKey, lastItem: newLastItem,
            }));
          });
      } else {
        FirebaseRef.child('status')
          .orderByChild(sortFilter)
          .startAt('')
          .limitToFirst(15)
          .on('value', (snapshot) => {
            const users = {};
            let newLastKey = '';
            let newLastItem = '';
            snapshot.forEach((child) => {
              if (child.key !== 'onlineCount') {
                const value = child.val();

                users[child.key] = value;
                newLastKey = child.key;
                newLastItem = value[sortFilter];
              }
            });

            resolve(dispatch({
              type: 'ONLINE_USERS_LAZY', data: users, lastKey: newLastKey, lastItem: newLastItem,
            }));
          });
      }
    } else if (sortDirection === 'desc') {
      if (lastKey) {
        FirebaseRef.child('status')
          .orderByChild(sortFilter)
          .endAt(lastItem, lastKey.toString())
          .limitToLast(10)
          .on('value', (snapshot) => {
            const users = {};
            let newLastKey = '';
            let newLastItem = '';
            snapshot.forEach((child) => {
              if (child.key !== 'onlineCount') {
                const value = child.val();

                users[child.key] = value;

                if (!newLastKey) {
                  newLastKey = child.key;
                  newLastItem = value[sortFilter];
                }
              }
            });

            resolve(dispatch({
              type: 'ONLINE_USERS_LAZY', data: users, lastKey: newLastKey, lastItem: newLastItem,
            }));
          });
      } else {
        FirebaseRef.child('status')
          .orderByChild(sortFilter)
          .limitToLast(15)
          .on('value', (snapshot) => {
            const users = {};
            let newLastKey = '';
            let newLastItem = '';
            snapshot.forEach((child) => {
              if (child.key !== 'onlineCount') {
                const value = child.val();

                users[child.key] = value;

                if (!newLastKey) {
                  newLastKey = child.key;
                  newLastItem = value[sortFilter];
                }
              }
            });

            resolve(dispatch({
              type: 'ONLINE_USERS_LAZY', data: users, lastKey: newLastKey, lastItem: newLastItem,
            }));
          });
      }
    }

  //  resolve();
  }).catch((err) => { console.log(err.message); });
}


export function resetGetOnlineUsersLazy() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('status')
      .off();

    resolve(dispatch({
      type: 'ONLINE_USERS_LAZY_NEW', data: {}, lastKey: '', lastItem: '',
    }));

  //  resolve('success');
  }).catch((err) => { console.log(err.message); });
}
*/

export function updateOnlineState() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const { uid } = user;

      FirebaseRef.child('.info/connected')
        .on('value', (connectedSnapshot) => {
          const status = connectedSnapshot.val() || false;
          if (status) {
          //  FirebaseRef.child(`users/${uid}`)
          //    .once('value', (playerSnapshot) => {
          //      const playerData = playerSnapshot.val() || {};

            //  const {
            //    name, photo, lvl, bal,
            //  } = playerData;
            Firebase.database().ref(`status/${uid}`).onDisconnect().remove();

            Firebase.database().ref(`users/${uid}`).update({
              lastLogin: Firebase.database.ServerValue.TIMESTAMP,
            });
            /*  let lowerCaseName = '';
                let finalString = name || '';
                if (name) {
                  const punctuationless = name.replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '');
                  finalString = punctuationless.replace(/\s{2,}/g, ' ');

                  lowerCaseName = finalString.toLowerCase();
                } */

            /*    if (uid) {
                  Firebase.database().ref(`status/${uid}`).update({
                    status: true,
                  //  name: finalString,
                  //  lowerCaseName: lowerCaseName || '',
                  //  photo: photo || '',
                  //  lvl,
                  //  bal,
                  //  inRoom: false,
                  //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                    lastAction: Firebase.database.ServerValue.TIMESTAMP,
                  });
                } */
            //  });
          }
        });
    }
    return () => new Promise(() => resolve());
  })).catch((err) => { console.log(err.message); });
}


export function swapToUser(userId, adminNewDesign, adminScreenMode, adminLanguage) {
  console.log('swapToUser action', { userId, adminNewDesign, adminScreenMode, adminLanguage });

  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const swapToUserFunction = Firebase.app().functions().httpsCallable('swapToUser');

    FirebaseRef.child(`users/${userId.toString()}/name`).once('value', (snapshot) => {
      const name = snapshot.val() || {};
      if (name) {
        swapToUserFunction({
          userId: userId.toString(),
          userName: name,
        }).then(async (result) => {
          console.log('swapToUser result', result);

          if (result.data.customToken) {
            await FirebaseRef.child(`supportChat/activeChats/${userId.toString()}`).off();
            await FirebaseRef.child(`supportChat/messages/${userId.toString()}`).off();

            Firebase.auth().signInWithCustomToken(result.data.customToken).then((loginResult) => {

              dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: result.data.customReturnToken, adminNewDesign, adminScreenMode, adminLanguage });

              return resolve(result);
            });
          } else {
            dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: null, adminNewDesign: null, adminScreenMode: null, adminLanguage: null });

            return resolve(result);
          }


        });
      }
    });
  });
}

export function returnToAdminReturnToken(token, toggleNewDesign, toggleScreenMode, i18n, uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(async (resolve) => {
    const { users } = getState();

    console.log('returnToAdminReturnToken 1', users.adminReturnToken, uid);

    if (users && users.adminReturnToken) {
      console.log('returnToAdminReturnToken', token, users.adminReturnToken, users.adminNewDesign, users.adminScreenMode, users.adminLanguage);

      await FirebaseRef.child(`supportChat/activeChats/${uid}`).off();
      await FirebaseRef.child(`supportChat/messages/${uid}`).off();

      Firebase.auth().signInWithCustomToken(users.adminReturnToken).then((result) => {
        if (users.adminNewDesign || users.adminNewDesign === false || users.adminNewDesign === null) {
          toggleNewDesign(users.adminNewDesign, true);

          if (users.adminScreenMode || users.adminScreenMode === false || users.adminScreenMode === null) {
            toggleScreenMode(users.adminScreenMode);
          }
        }
        if (i18n && users.adminLanguage && (users.adminLanguage === 'lv' || users.adminLanguage === 'en' || users.adminLanguage === 'ru')) {
          i18n.changeLanguage(users.adminLanguage);
        }

        dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: null, adminNewDesign: null, adminScreenMode: null, adminLanguage: null });

        return resolve('success');
      });
    } else if (token) {
      console.log('returnToAdminReturnToken 2', token, users);

      await FirebaseRef.child(`supportChat/activeChats/${uid}`).off();
      await FirebaseRef.child(`supportChat/messages/${uid}`).off();

      Firebase.auth().signInWithCustomToken(token).then((result) => {
        if (users.adminNewDesign || users.adminNewDesign === false || users.adminNewDesign === null) {
          toggleNewDesign(users.adminNewDesign, true);
          if (users.adminScreenMode || users.adminScreenMode === false || users.adminScreenMode === null) {
            toggleScreenMode(users.adminScreenMode);
          }
        }

        if (i18n && users.adminLanguage && (users.adminLanguage === 'lv' || users.adminLanguage === 'en' || users.adminLanguage === 'ru')) {
          i18n.changeLanguage(users.adminLanguage);
        }

        dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: null, adminNewDesign: null, adminScreenMode: null });

        return resolve('success');
      });
    } else {
      dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: null, adminNewDesign: null, adminScreenMode: null });

      return resolve();
    }
  });
}

export function clearAdminReturnToken() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    dispatch({ type: 'SET_ADMIN_RETURN_TOKEN', data: null, adminNewDesign: null, adminScreenMode: null });

    return resolve('success');
  });
}
