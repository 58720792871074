import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filter, includes, map, size } from 'lodash';
import classNames from 'classnames';

import {
  Row,
  Col,
  TabPane,
  TabContent,
  NavLink,
  DropdownItem,
} from 'reactstrap/lib';
import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';
import CustomDropdown from '../../Components/Components/CustomDropdown';

import { sendLanguage } from '../../../../../constants/constants';
import { getNewHelpSections } from '../../../../../actions/member';
import TextEditor from '../../../TextEditor/TextEditor';

const NewHelpPage = ({ modalFlag, playButtonSound, title }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation('common');

  const newHelps = useSelector(state => state.member.newHelps);

  const [curLang, setCurLang] = useState(i18n.language || sendLanguage.lv);
  const [helpData, setHelpData] = useState([]);
  const [helpTabParent, setHelpTabParent] = useState(null);
  const [helpTab, setHelpTab] = useState(null);
  const [helpTabChild, setHelpTabChild] = useState(null);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const [customToggle, setCustomToggle] = useState('');
  const [pageHeight, setPageHeight] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    dispatch(getNewHelpSections());

    const updatePageHeight = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      setPageWidth(width);
      setPageHeight(height);
    };

    updatePageHeight();

    window.addEventListener('resize', updatePageHeight);
  }, []);

  useEffect(() => {
    if (curLang !== i18n.language) {
      setCurLang(i18n.language || sendLanguage.lv);
    }
  }, [i18n]);

  useEffect(() => {
    if (newHelps && newHelps[curLang] && curLang) {
      const helpSectionData = JSON.parse(newHelps[curLang].helpSection) || [];
      setHelpData(helpSectionData);
      
      setHelpTab(helpSectionData[0].id || null);

      setCustomToggle(`${helpSectionData[0].orderId}${helpSectionData[0].title}`)
    } else {
      setHelpData([]);
    }
  }, [curLang, newHelps]);

  const toggleHelpTab = (tab, index, isChild) => {
    playButtonSound();

    if (!isChild) {
      const selectedTab = helpData[index];

      if (selectedTab.subsections) {
        if (selectedTab.content && selectedTab.content !== '<p></p>\n') {
          if (helpTab !== tab) {
            setHelpTabParent(tab);
            setHelpTab(tab);
            setHelpTabChild(null);
          }
        } else if (helpTab !== tab) {
          setHelpTabParent(tab);
          setHelpTab(selectedTab.subsections[0].id);
          setHelpTabChild(selectedTab.subsections[0].id);
        }
      } else if (helpTab !== tab) {
        setHelpTab(tab);
        setHelpTabParent(null);
        setHelpTabChild(null);
      }
    } else if (helpTabChild !== tab) {
      setHelpTab(tab);
      setHelpTabChild(tab);
    }
  };

  const toggleTypeFilter = () => {
    setTypeFilterOpen(!typeFilterOpen);
  };

  const toggleType = (key) => {
    if (key && helpTab !== key) {
      setHelpTab(key);
      map(helpData, (item) => {
        if (item.id === key) {
          setCustomToggle(`${item.orderId}${item.title}`);
        } else if (item.subsections && filter(item.subsections, subItem => subItem.id === key)[0]) {
          setCustomToggle(`${filter(item.subsections, subItem => subItem.id === key)[0].orderId}${filter(item.subsections, subItem => subItem.id === key)[0].title}`);
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="zole-rules-container">
        <Row className="zole-rules-page">
          {title && (
            <Col md="12">
              <h1 className="zole-rules-page-title">{t(title)}</h1>
            </Col>
          )}
          <Col sm="12" className='zole-rules-page-mobile-header'>
            <div className="zole-rules-page-header-tab">
              {customToggle && (
                <CustomDropdown
                  toggle={customToggle}
                  toggleFunc={toggleTypeFilter}
                  isOpen={typeFilterOpen}
                  menu={(
                    <>
                      {map(helpData, item => (
                        <>
                          <DropdownItem key={item.id} onClick={() => toggleType(item.id)}>
                            {`${item.orderId}${t(item.title)}`}
                          </DropdownItem>
                          {item.subsections && map(item.subsections, subItem => (
                            <DropdownItem key={subItem.id} onClick={() => toggleType(subItem.id)}>
                              {`${subItem.orderId}${t(subItem.title)}`}
                            </DropdownItem>
                          ))}
                        </>
                      ))}
                    </>
                    )}
                  inlineMenuClassName={`landing-help-page-dropdown-list ${modalFlag && 'menu-rule-drop-down'}`}
                />
              )}
            </div>
          </Col>
          {size(helpData) > 0 && helpTab && map(helpData, tab => (
            (helpTab === tab.id) ? (
              <Col md="12">
                <h2 className="zole-rules-page-sub-title">{tab?.title}</h2>
              </Col>
            ) : (
              tab.subsections && size(tab.subsections) > 0 && map(tab.subsections, subItem => (
                (helpTab === subItem.id) ? (
                  <Col md="12">
                    <h2 className="zole-rules-page-sub-title">{subItem?.title}</h2>
                  </Col>
                ) : (null)
              ))
            )
          ))}
          <Col sm="3" className="zole-rules-page-header">
            <Row className="zole-rules-page-header-tab">
              <div
                className={`w-full contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content zole-rules-page-body-scroll-area ${!modalFlag && 'contact-support-chat-body-scroll-area-limited'}`}
                show
                rightOffset={5}
                topOffset={0}
                bottomOffset={0}
              >
                {helpData.map((tab, index) => (
                  <>
                    <Col sm={{ size: 10, offset: 1 }} className="zole-rules-page-tab">
                      <div className={`zole-rules-page-tab-wrapper ${helpTab === tab.id && 'active'}`}>
                        <NavLink
                          className={`zole-rules-page-header-link ${helpTab === tab.id && 'zole-rules-page-header-link-active'}`}
                          onClick={() => toggleHelpTab(tab.id, index, false)}
                        >
                          {`${tab.orderId}${tab.title}`}
                        </NavLink>
                      </div>
                    </Col>
                    {helpTabParent && helpTabParent === tab.id && tab.subsections ? (
                      <>
                        {tab.subsections.map((childTab, index2) => (
                          <Col sm={{ size: 10, offset: 2 }} className="zole-rules-page-tab zole-rules-page-tab-child">
                            <div className={`zole-rules-page-tab-wrapper ${helpTabChild === childTab.id && 'active'}`}>
                              <NavLink
                                className={`zole-rules-page-header-link ${helpTabChild === childTab.id && 'zole-rules-page-header-link-active'}`}
                                onClick={() => toggleHelpTab(childTab.id, index2, true)}
                              >
                                {`${childTab.orderId}${childTab.title}`}
                              </NavLink>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (null)}
                  </>
                ))}
              </div>
            </Row>
          </Col>
          <Col sm="9" className="zole-rules-page-content" style={{ paddingRight: 5 }}>
            <TabContent className="zole-rules-page-content-tab" activeTab={helpTab}>
              {map(helpData, item => (
                <>
                  <TabPane tabId={item.id}>
                    <div className={classNames({"help-content": !modalFlag, "menu-help-content": modalFlag})}>
                      <div
                        className="contact-support-chat-body-scroll-area contact-support-chat-body-scroll-area-content  zole-rules-page-body-scroll-area"
                        show
                        rightOffset={5}
                        topOffset={0}
                        bottomOffset={0}
                      >
                        <Row>
                          <Col className="zole-rules-page-content-wrapper">
                            <TextEditor viewEditorState={item.content} addEditorClassName="text-white" readOnly />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {!modalFlag && (
                      <div className="mobile-help-content">
                        <Row>
                          <Col className="zole-rules-page-content-wrapper">
                            <ScrollAreaWrapper
                              show
                              rightOffset={2}
                              topOffset={0}
                              bottomOffset={0}
                              vertical
                              height={pageWidth <= pageHeight ? pageHeight - 250 : pageHeight - 200}
                            >
                              <TextEditor viewEditorState={item.content} addEditorClassName="text-white" readOnly />
                            </ScrollAreaWrapper>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </TabPane>
                  {item.subsections && item.subsections.map(subTab => (
                    <>
                      <TabPane key={subTab.id} tabId={subTab.id}>
                        <div
                          className="contact-support-chat-body-scroll-area"
                          contentClassName="contact-support-chat-body-scroll-area-content"
                        >
                          <Row>
                            <Col className="rules-page-content-wrapper">
                              <TextEditor viewEditorState={subTab.content} addEditorClassName="text-white" readOnly />
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                      {!modalFlag && (
                        <div className="mobile-help-content">
                          <Row>
                            <Col className="zole-rules-page-content-wrapper">
                              <ScrollAreaWrapper
                                show
                                rightOffset={2}
                                topOffset={0}
                                bottomOffset={0}
                                vertical
                                height={pageWidth <= pageHeight ? pageHeight - 250 : pageHeight - 200}
                              >
                                <TextEditor viewEditorState={subTab.content} addEditorClassName="text-white" readOnly />
                              </ScrollAreaWrapper>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ))}
            </TabContent>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

NewHelpPage.propTypes = {
  playButtonSound: PropTypes.func.isRequired,
  title: PropTypes.string,
  modalFlag: PropTypes.bool,
};

NewHelpPage.defaultProps = {
  title: '',
  modalFlag: false,
};

export default NewHelpPage;
