import { filter, size, includes } from 'lodash';
import {
  isMobile,
} from 'react-device-detect';
import * as constants from '../constants/constants';
import {
  FirebaseStorage, Firebase, FirebaseRef, LeaderboardRef, StatusRef, UserStatsRef, TournamentsRef, AdminLogsRef, RoomsRef,
} from '../lib/firebase';

import config from '../constants/config';

export function getEventsBanner(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  // return dispatch => new Promise(resolve => FirebaseRef.child('events').orderByChild('showInBanner').equalTo(true).on('value', (snapshot) => {
  return dispatch => new Promise(resolve => FirebaseRef.child('events').orderByChild('endDate').startAt(Date.now()).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
    .on('value', (snapshot) => {
    const allEvents = snapshot.val() || {};
    const filterDate = Date.parse(new Date());
    const filterEvents = {};
    const array = [];

     console.log('allEventsallEvents', allEvents);

    Object.keys(allEvents).map((key) => {
      if (allEvents[key].showInBanner === true && allEvents[key].endDate >= filterDate && (allEvents[key].devMode === devMode || !allEvents[key].devMode)) {
        if (allEvents[key].startDate <= filterDate && allEvents[key].endDate >= filterDate) {
          array.push({ key, filterType: constants.CURRENT, ...allEvents[key] });
          return null;
        }
        if (allEvents[key].startDate > filterDate) {
          array.push({ key, filterType: constants.PLANNED, ...allEvents[key] });
          return null;
        }
      }
    });

    try {
      localStorage.setItem('eventsData', JSON.stringify(array)); //x
    } catch (err) {
      console.log(err);
    }
    resolve(dispatch({ type: 'EVENTS_BANNER_REPLACE', data: array }));
  }));
}

export function getDiscounts(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const filterDate = Date.parse(new Date());
  return dispatch => new Promise(resolve => FirebaseRef.child('discounts').orderByChild('endDate').startAt(filterDate).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
    .on('value', (snapshot) => {
      const allShopDiscount = snapshot.val() || {};
      const array = [];

      console.log('allShopDiscountallShopDiscount', allShopDiscount);

      Object.keys(allShopDiscount).map((key) => {
        console.log(filterDate, "checkFilterDate");
      if (allShopDiscount[key].endDate >= filterDate && allShopDiscount[key].devMode === devMode) {
          if (allShopDiscount[key].startDate <= filterDate && allShopDiscount[key].endDate >= filterDate) {
            array.push({ key, filterType: constants.CURRENT, ...allShopDiscount[key] });
            return null;
          }
          if (allShopDiscount[key].startDate > filterDate) {
            array.push({ key, filterType: constants.PLANNED, ...allShopDiscount[key] });
            return null;
          }
        }
      });
      array.sort((a, b) => (b.endDate - a.endDate));
      console.log(array, "discountArray")
      try {
        localStorage.setItem('discounts', JSON.stringify(array)); //x
      } catch (err) {
        console.log(err);
      }
      return resolve(dispatch({
        type: 'GET_DISCOUNTS',
        data: array,
      }));
    })).catch((err) => { throw err.message; });
}

export const setLastShopTab = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'LAST_SHOP_TAB', data: value })));
};

export const setTransactionComplete = (value) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TRANSACTION_COMPLETE', data: value })));
};

export function getCampaignOpenRoomBanner(type, showedDesign, devMode) {
  console.log('getCampaignOpenRoomBanner', type, showedDesign, devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  let localUserId = null;

  try {
    localUserId = localStorage.getItem('userId');//x
  } catch (err) {
    localUserId = null;
  }

  console.log("checkHistory====2", UID)
  if (!UID && !localUserId) return () => new Promise(resolve => resolve());
  
  const userId = UID || localUserId;


  let platform = '';
  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isMobile) {
    platform = constants.MOBILE_PLATFORM;
  } else if (isInDraugiemAppFrame) {
    platform = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    platform = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    platform = constants.INBOX_PLATFORM;
  } else {
    platform = constants.WEB_PLATFORM;
  }

  if (!platform) return () => new Promise(resolve => resolve());

  let nowDate = new Date();
  if (devMode) {
    // Set to fetch ended campaigns for testing only
   //  nowDate.setDate(nowDate.getDate() - 5);
  }

  if (UID === 'd6g0LfK9kqSXxoT1MmG68Nmn6u42' || UID === '1076904' || UID === 1076904) {
    nowDate.setDate(nowDate.getDate() - 10);
  }

  console.log("nowDate", nowDate);

  const filterDate = Date.parse(nowDate);

  return dispatch => new Promise(resolve => {
    FirebaseRef.child('campaigns').orderByChild('endDate').startAt(filterDate).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
      .on('value', (snapshot) => {
        const allCampaigns = snapshot.val() || {};
        const array = [];

        console.log('allCampaigns', allCampaigns);

        Object.keys(allCampaigns).map((key) => {
          if (!devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'prod')[0])) {
            return null;
          }

          if (devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'dev')[0])) {
            return null;
          }

          console.log('test 5', allCampaigns[key]);

          if (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate && filter(allCampaigns[key].selectedLocations, item => item.value === type)[0]) {
            console.log("test 6", (UID === 'd6g0LfK9kqSXxoT1MmG68Nmn6u42' || UID === '1076904' || UID === 1076904), size(filter(allCampaigns[key]?.selectedPlatforms, (item) => item.value === platform)) > 0)
            if ((UID === 'd6g0LfK9kqSXxoT1MmG68Nmn6u42' || UID === '1076904' || UID === 1076904) || size(filter(allCampaigns[key]?.selectedPlatforms, (item) => item.value === platform)) > 0) {
              console.log('test 7');
              array.push({ key, filterType: constants.CURRENT, ...allCampaigns[key] });
              FirebaseRef.child(`campaignShowedHistory/${key}`).push({
                key,
                uid: userId,
                showedPlatform: platform,
                showedLocation: 'openRoom',
                showedDesign,
                showedEnv: devMode ? 'dev' : 'prod',
                showedDate: Date.now(),
              });
              return null;
            }
          }
        });

        console.log('allCampaigns array', array, type);

        try {
          localStorage.setItem('campaignData', JSON.stringify(array));//x
        } catch (err) {
          // console.log(err);
        }
        dispatch({ type: 'CAMPAIGN_OPENROOM_BANNER_REPLACE', data: array });

        return resolve(array);
      });
  });
}

export function getCampaignLoadingBanner(type, showedDesign, devMode) {
  console.log('getCampaignLoadingBanner', type, showedDesign, devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  let localUserId = null;

  try {
    localUserId = localStorage.getItem('userId');//x
  } catch (err) {
    localUserId = 'userId';
  }

  if (!UID && !localUserId) return () => new Promise(resolve => resolve());
  
  const userId = UID || localUserId;
  console.log("checkHistory====1", UID)

  let platform = '';
  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isMobile) {
    platform = constants.MOBILE_PLATFORM;
  } else if (isInDraugiemAppFrame) {
    platform = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    platform = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    platform = constants.INBOX_PLATFORM;
  } else {
    platform = constants.WEB_PLATFORM;
  }

  if (!platform) return () => new Promise(resolve => resolve());

  let nowDate = new Date();
  if (devMode) {
    // Set to fetch ended campaigns for testing only
    // nowDate.setDate(nowDate.getDate() - 5);
  }

  if (UID === 'd6g0LfK9kqSXxoT1MmG68Nmn6u42' || UID === '1076904' || UID === 1076904) {
    nowDate.setDate(nowDate.getDate() - 10);
  }

  const filterDate = Date.parse(nowDate);

  return dispatch => new Promise(resolve => FirebaseRef.child('campaigns').orderByChild('endDate').startAt(filterDate).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
    .on('value', (snapshot) => {
    const allCampaigns = snapshot.val() || {};
    const array = [];

    console.log('allCampaigns', allCampaigns);

    Object.keys(allCampaigns).map((key) => {
      if (!devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'prod')[0])) {
        return null;
      }

      if (devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'dev')[0])) {
        return null;
      }

      console.log('allCampaigns[key].startDate <= filterDate', allCampaigns[key].startDate <= filterDate, allCampaigns[key].startDate);
      console.log('allCampaigns[key].endDate >= filterDate', allCampaigns[key].endDate >= filterDate, allCampaigns[key].endDate);


      if (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate && filter(allCampaigns[key].selectedLocations, item => item.value === type)[0]) {
        if (size(filter(allCampaigns[key]?.selectedPlatforms, (item) => item.value === platform)) > 0) {
          array.push({ key, filterType: constants.CURRENT, ...allCampaigns[key] });
          console.log("checkHistory====", key, showedDesign)
          FirebaseRef.child(`campaignShowedHistory/${key}`).push({
            key,
            uid: userId,
            showedPlatform: platform,
            showedLocation: 'loading',
            showedDesign,
            showedEnv: devMode ? 'dev' : 'prod',
            showedDate: Date.now(),
          })
          return null;
        }
      }
    });

    console.log('allCampaigns array', array, type);

    try {
      localStorage.setItem('campaignData', JSON.stringify(array));//x
    } catch (err) {
    //  console.log(err);
    }
    resolve(dispatch({ type: 'CAMPAIGN_LOADING_BANNER_REPLACE', data: array }));
  }));
}

export function getCampaignLobbyBanner(type, showedDesign, devMode) {
  console.log('getCampaignLobbyBanner', type, showedDesign, devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  let localUserId = null;

  try {
    localUserId = localStorage.getItem('userId');//x
  } catch (err) {
    localUserId = null;
  }

  if (!UID && !localUserId) return () => new Promise(resolve => resolve());
  
  const userId = UID || localUserId;

  let platform = '';
  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isMobile) {
    platform = constants.MOBILE_PLATFORM;
  } else if (isInDraugiemAppFrame) {
    platform = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    platform = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    platform = constants.INBOX_PLATFORM;
  } else {
    platform = constants.WEB_PLATFORM;
  }

  if (!platform) return () => new Promise(resolve => resolve());

  let nowDate = new Date();
  if (devMode) {
    // Set to fetch ended campaigns for testing only
   // nowDate.setDate(nowDate.getDate() - 5);
  }

  if (UID === 'd6g0LfK9kqSXxoT1MmG68Nmn6u42' || UID === '1076904' || UID === 1076904) {
    nowDate.setDate(nowDate.getDate() - 10);
  }

  const filterDate = Date.parse(nowDate);

  console.log('allCampaigns UID', UID, filterDate);

  return dispatch => new Promise(resolve => FirebaseRef.child('campaigns').orderByChild('endDate').startAt(filterDate).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
    .on('value', (snapshot) => {
    const allCampaigns = snapshot.val() || {};
    const array = [];

      console.log('allCampaigns', allCampaigns);

    Object.keys(allCampaigns).map((key) => {
      if (!devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'prod')[0])) {
        return null;
      }

      if (devMode && (!allCampaigns[key].selectedEnvs || !filter(allCampaigns[key].selectedEnvs, item => item.value === 'dev')[0])) {
        return null;
      }

      if (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate && filter(allCampaigns[key].selectedLocations, item => item.value === type)[0]) {
        if (size(filter(allCampaigns[key]?.selectedPlatforms, (item) => item.value === platform)) > 0) {
          array.push({ key, filterType: constants.CURRENT, ...allCampaigns[key] });
          try {
            FirebaseRef.child(`campaignShowedHistory/${key}`).push({
              key,
              uid: userId,
              showedPlatform: platform,
              showedLocation: 'lobby',
              showedDesign,
              showedEnv: devMode ? 'dev' : 'prod',
              showedDate: Date.now(),
            }).catch((e) => {
              return null;
            })
          } catch {
            return null;
          }
          return null;
        }
      }
    });

    console.log('allCampaigns array', array, type);

    try {
      localStorage.setItem('campaignData', JSON.stringify(array));//x
    } catch (err) {
    //  console.log(err);
    }
    resolve(dispatch({ type: 'CAMPAIGN_LOBBY_BANNER_REPLACE', data: array }));
  }));
}
