import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';
import {
  Button,
  Input,
  Label,
  Media, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap/lib';
import { useDispatch, useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import HelpSection from './HelpSection';
// eslint-disable-next-line import/named
import { updateHelpEditor, getHelpEditor, updateHelpEditorDevMode } from '../../../../actions/admin';
import { LANGUAGE_TYPE, sendLanguage } from '../../../../constants/constants';

import closeImg from '../../../../images/icons/close.png';

const HelpEditorSection = ({ showNotification }) => {
  const dispatch = useDispatch();

  const helpSections = useSelector(state => state.admin.helpSections || {});
  const loadingProps = useSelector(state => state.admin.helpEditorLoading || false);

  const [helpData, setHelpData] = useState([]);
  const [loading, setLoading] = useState(loadingProps);
  const [language, setLanguage] = useState(sendLanguage.en);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const [devMode, setDevMode] = useState(false);

  const [movedSectionIndex, setMovedSectionIndex] = useState(null);
  const [movedDragIndex, setMovedDragIndex] = useState(null);
  const [movedHoverIndex, setMovedHoverIndex] = useState(null);
  const [openDragSectionConfirmModal, setOpenDragSectionConfirmModal] = useState(false);
  const [openDragSubsectionConfirmModal, setOpenDragSubsectionConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(getHelpEditor());
  }, []);

  useEffect(() => {
    if (helpSections && helpSections[language] && language) {
      const helpSectionData = JSON.parse(helpSections[language].helpSection) || [];
      setHelpData(helpSectionData);
    } else {
      setHelpData([]);
    }

    if (helpSections && helpSections?.devMode) {
      setDevMode(true);
    } else {
      setDevMode(false);
    }
  }, [helpSections]);

  useEffect(() => {
    if (loading !== loadingProps) {
      setLoading(loadingProps);
    }
  }, [loadingProps]);

  useEffect(() => {
    if (helpSections && helpSections[language] && language) {
      setHelpData(JSON.parse(helpSections[language].helpSection) || []);
    } else {
      setHelpData([]);
    }
  }, [language]);


  const handleTitleChange = (id, newTitle) => {
    const newHelpData = helpData.map(section => (section.id === id ? { ...section, title: newTitle } : section));
    setHelpData(newHelpData);
  };

  const handleContentChange = (id, newContent) => {
    const rawContentState = convertToRaw(newContent.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const newHelpData = helpData.map(section => (section.id === id ? { ...section, content: markup } : section));
    setHelpData(newHelpData);

    setEditorState(newContent);
  };

  const handleSubsectionTitleChange = (sectionId, subsectionId, newTitle) => {
    const newHelpData = helpData.map((section) => {
      if (section.id === sectionId) {
        const newSubsections = section.subsections.map(subsection => (subsection.id === subsectionId ? { ...subsection, title: newTitle, } : subsection));
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setHelpData(newHelpData);
  };

  const handleSubsectionContentChange = (sectionId, subsectionId, newContent) => {
    const rawContentState = convertToRaw(newContent.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const newHelpData = helpData.map((section) => {
      if (section.id === sectionId) {
        const newSubsections = section.subsections.map(subsection => (subsection.id === subsectionId ? { ...subsection, content: markup } : subsection));
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setHelpData(newHelpData);
    setEditorState(newContent);
  };

  const handleAddSection = () => {
    const content = EditorState.createEmpty();
    const rawContentState = convertToRaw(content.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const newSection = {
      id: uuidv4(),
      orderId: `${helpData.length + 1}.`,
      title: 'New Section',
      content: markup,
      subsections: [],
    };
    const helpSection = [...helpData, newSection];
    setHelpData(helpSection);
    dispatch(updateHelpEditor(helpSection, language));
    showNotification('Success', 'Section was successfully added', 'success');
  };

  const handleEditCancel = () => {
    setIsEdit(false);
    setSelectedId(null);
    const helpSectionData = JSON.parse(helpSections[language].helpSection) || [];
    setHelpData(helpSectionData);
    setEditorState(null);
  };

  const handleUpdateSection = () => {
    dispatch(updateHelpEditor(helpData, language));
    handleEditCancel();
    showNotification('Success', 'Changes were successfully saved', 'success');
  };

  const handleDeleteSection = (id) => {
    const newHelpData = helpData.filter(section => section.id !== id);
    const updatedHelpData = newHelpData.map((section, index) => ({
      ...section,
      orderId: `${index + 1}.`,
      title: section.title,
      subsections: section.subsections.map((subsection, subIndex) => ({
        ...subsection,
        orderId: `${index + 1}.${subIndex + 1}.`,
        title: subsection.title,
      })),
    }));
    setHelpData(updatedHelpData);
    dispatch(updateHelpEditor(updatedHelpData, language));
    showNotification('Success', 'Section was successfully deleted', 'success');
  };

  const handleAddSubsection = (sectionId) => {
    const content = EditorState.createEmpty();
    const rawContentState = convertToRaw(content.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const newSubsection = { id: uuidv4(), title: '', content: markup };
    const newHelpData = helpData.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = [...section.subsections, newSubsection].map((subsection, index) => ({
          ...subsection,
          orderId: `${section.orderId}${index + 1}.`,
          title: subsection.title || 'New Subsection',
          content: subsection.content,
        }));
        return { ...section, subsections: updatedSubsections };
      }
      return section;
    });
    setHelpData(newHelpData);
    dispatch(updateHelpEditor(newHelpData, language));
    showNotification('Success', 'Subsection was successfully added', 'success');
  };

  const handleDeleteSubsection = (sectionId, subsectionId) => {
    const newHelpData = helpData.map((section) => {
      if (section.id === sectionId) {
        const newSubsections = section.subsections.filter(subsection => subsection.id !== subsectionId);
        const updatedSubsections = newSubsections.map((subsection, index) => ({
          ...subsection,
          orderId: `${section.orderId}${index + 1}.`,
          title: subsection.title,
        }));
        return { ...section, subsections: updatedSubsections };
      }
      return section;
    });
    setHelpData(newHelpData);
    dispatch(updateHelpEditor(newHelpData, language));
    showNotification('Success', 'Subsection was successfully deleted', 'success');
  };

  const moveSection = (dragIndex, hoverIndex) => {
    setOpenDragSectionConfirmModal(true);
    setMovedDragIndex(dragIndex);
    setMovedHoverIndex(hoverIndex);
  };

  const moveSubsection = (sectionIndex, dragIndex, hoverIndex) => {
    setOpenDragSubsectionConfirmModal(true);
    setMovedSectionIndex(sectionIndex);
    setMovedDragIndex(dragIndex);
    setMovedHoverIndex(hoverIndex);
  };

  const changeLanguage = (e) => {
    if (!e.target.value) return;
    if (isEdit) {
      showNotification('Warning', 'Please complete Edit State', 'warning');
      return;
    }

    setLanguage(e.target.value);
  };

  const toggleEdit = (id, content) => {
    if (isEdit) {
      setSelectedId(null);
      setEditorState(null);
    } else {
      setSelectedId(id);
      if (content) {
        const blocksFromHtml = htmlToDraft(content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        if (contentState) {
          setEditorState(EditorState.createWithContent(contentState));
        }
      } else {
        setEditorState(EditorState.createEmpty());
      }
    }

    setIsEdit(!isEdit);
  };

  const handleExpand = (id) => {
    if (selectedId === id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };

  const toggleDevMode = () => {
    setDevMode(!devMode);
    dispatch(updateHelpEditorDevMode(!devMode))
  }

  const toggleDragSectionConfirmModal = () => {
    setOpenDragSectionConfirmModal(false);
    setOpenDragSubsectionConfirmModal(false);
    setMovedDragIndex(null)
    setMovedHoverIndex(null);
    setMovedSectionIndex(null);
  }

  const handleDragSectionSurvey = () => {
    const dragSection = helpData[movedDragIndex];
    const newHelpData = [...helpData];
    newHelpData.splice(movedDragIndex, 1);
    newHelpData.splice(movedHoverIndex, 0, dragSection);
    const updatedHelpData = newHelpData.map((section, index) => ({
      ...section,
      orderId: `${index + 1}.`,
      title: section.title,
      subsections: section.subsections.map((subsection, subIndex) => ({
        ...subsection,
        orderId: `${index + 1}.${subIndex + 1}.`,
        title: subsection.title,
      })),
    }));
    setHelpData(updatedHelpData);
    dispatch(updateHelpEditor(updatedHelpData, language));
    showNotification('Success', 'Moved Success', 'success');
    toggleDragSectionConfirmModal();
  }

  const handleDragSubsectionSurvey = () => {
    const section = helpData[movedSectionIndex];
    const dragSubsection = section.subsections[movedDragIndex];
    const newSubsections = [...section.subsections];
    newSubsections.splice(movedDragIndex, 1);
    newSubsections.splice(movedHoverIndex, 0, dragSubsection);
    const updatedSubsections = newSubsections.map((subsection, index) => ({
      ...subsection,
      orderId: `${section.orderId}${index + 1}.`,
      title: subsection.title,
    }));
    const newHelpData = helpData.map((sec, idx) => (idx === movedSectionIndex ? { ...sec, subsections: updatedSubsections } : sec));
    setHelpData(newHelpData);
    dispatch(updateHelpEditor(newHelpData, language));
    showNotification('Success', 'Moved Success', 'success');
    toggleDragSectionConfirmModal();
  }

  return (
    <div className="d-flex flex-column gap-20">
      <div>
        <h1>Help Editor</h1>
        <div className="d-flex flex-row gap-20">
          <Button size="lg" color="primary" className="w-fit" onClick={handleAddSection} disabled={isEdit}>Add Section</Button>
          <div>
            <Input type="select" name="show" className="game-bet-select" value={language} onChange={changeLanguage}>
              {map(LANGUAGE_TYPE, (item, key) => (
                <option style={{ backgroundColor: '#222' }} value={key}>{item}</option>
              ))}
            </Input>
          </div>
          <div>
          {/* <Label className="event-type-input" style={{ marginLeft: 40 }}>
            <Input type="checkbox" onClick={() => toggleDevMode()} disabled={loading} checked={devMode} readOnly />
            <span className="checkmark" />
            <div className="event-type-text">
              Only Dev
            </div>
          </Label> */}
        </div>
        </div>
      </div>

      {helpData && size(helpData) > 0 && helpData.map((section, index) => (
        <HelpSection
          key={section.id}
          index={index}
          section={section}
          isEdit={isEdit}
          selectedId={selectedId}
          onTitleChange={handleTitleChange}
          onContentChange={handleContentChange}
          onSubsectionTitleChange={handleSubsectionTitleChange}
          onSubsectionContentChange={handleSubsectionContentChange}
          onAddSubsection={handleAddSubsection}
          onDeleteSection={handleDeleteSection}
          onDeleteSubsection={handleDeleteSubsection}
          moveSection={moveSection}
          moveSubsection={moveSubsection}
          handleUpdateSection={handleUpdateSection}
          handleEditCancel={handleEditCancel}
          showNotification={showNotification}
          toggleEdit={toggleEdit}
          editorState={editorState}
          handleExpand={handleExpand}
        />
      ))}

      <Modal container={'div > div'} isOpen={openDragSectionConfirmModal} toggle={() => toggleDragSectionConfirmModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDragSectionConfirmModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDragSectionConfirmModal()} />
          }
        />
        <ModalBody className="notification-body">
        Do you want to save the change in sections order?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => handleDragSectionSurvey()}>
            Save
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDragSectionConfirmModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal container={'div > div'} isOpen={openDragSubsectionConfirmModal} toggle={() => toggleDragSectionConfirmModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDragSectionConfirmModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDragSectionConfirmModal()} />
          }
        />
        <ModalBody className="notification-body">
        Do you want to save the change in sub sections order?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => handleDragSubsectionSurvey()}>
            Save
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDragSectionConfirmModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

HelpEditorSection.propTypes = {
  showNotification: PropTypes.func.isRequired,
};

HelpEditorSection.defaultProps = {
};

export default HelpEditorSection;
