import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
// import ScrollArea from 'react-scrollbar';
import { round, trim } from 'lodash';
import ScrollArea from 'react-scrollbar';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Media from 'reactstrap/lib/Media';
import ScrollAreaButtons from '../../../UI/ScrollAreaButtons';

import CustomModal from '../../Components/Components/Modal';

import Message from '../SupportMessage';
import * as constants from '../../../../../constants/constants';

// import closeImg from '../../../../images/icons/close.png';
import attachImg from '../../../../../images/icons/attach.svg';

import {
  sendSupportMessage,
  setSupportAsRead,
  supportMessageFileUpload,
} from '../../../../../actions/member';
import config from '../../../../../constants/config';
import IconPopover from '../../Components/Components/IconPopover';

class ContactSupport extends React.Component {
  static propTypes = {
    //  supportChatStatus: PropTypes.shape({
    //    read: PropTypes.bool,
    //  }),
    supportChat: PropTypes.shape(),
    uid: PropTypes.string,
    name: PropTypes.string,
    //  chatMessages: PropTypes.shape(),
    closeErrorSubmit: PropTypes.bool.isRequired,
    modalOpen: PropTypes.bool,
    resetClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    sendSupportMsg: PropTypes.func.isRequired,
    //  setSupportRead: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
  };

  static defaultProps = {
    //  chatMessages: {},
    uid: null,
    name: '',
    supportChat: null,
    //  supportChatStatus: null,
    modalOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      //  modalOpen: false,
      inputMessage: '',
      status: '',
      statusMessage: '',
      loading: false,
      inlineMessageVisible: false,
      ableToSend: false,
      fileName: '',
      fileUploadUrl: '',
      fileMessage: '',
      filePopoverOpen: false,
    };
    this.hiddenFileInput = React.createRef();
    this.handleFileClick = this.handleFileClick.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
    this.contactMessageRef = React.createRef();
  }

  onFileHover = () => {
    if (!this.state.filePopoverOpen) {
      this.setState({filePopoverOpen: true});
    }
  }
  onFileHoverLeave = () => {
    this.setState({filePopoverOpen: false});
  }

  componentDidMount() {
    const {
      supportChat
    } = this.props;
    if (supportChat && supportChat.length > 0) {
      this.scrollToBottom();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      closeErrorSubmit, resetClose, supportChat, modalOpen,
    } = this.props;
    const prevSupportChat = prevProps.supportChat;
    const prevModalOpen = prevProps.modalOpen;

    if (closeErrorSubmit) {
      resetClose();
      this.closeModal();
    }

    if (
      supportChat
      && (!prevSupportChat
        || (prevSupportChat
          && Object.keys(prevSupportChat).length !== Object.keys(supportChat).length))
    ) {
      this.scrollToBottom();
    }

    if (modalOpen && modalOpen !== prevModalOpen) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    setTimeout(() => {
      if (this.messagesScrollbar) {
        this.messagesScrollbar.scrollBottom();
      }
    }, 200);
  };

  closeModal = () => {
    //  this.setState({ modalOpen: false });
  };

  /* toggle = () => {
    const { modalOpen } = this.state;
    const { setSupportRead, supportChatStatus } = this.props;
 
    if (!modalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportRead();
    }
  }; */

  handleChange = (event) => {
    const { inlineMessageVisible } = this.state;

    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : (event.target.value).slice(0, 300);
      if (inlineMessageVisible) {
        this.setState({
          inlineMessageVisible: false,
          status: '',
          statusMessage: '',
        });
      }
      this.setState({
        [event.target.name]: value,
      });
    }
  };

  handleEnter = (event) => {
    const { inputMessage } = this.state;
    if (event.key === 'Enter' && !event.shiftKey && trim(inputMessage) !== '') {
      event.preventDefault();
      this.submitMessage();
    }
  };

  submitMessage = () => {
    const { sendSupportMsg, name, supportChat } = this.props;
    const { inputMessage, fileName, fileUploadUrl, inlineMessageVisible } = this.state;
    if (inlineMessageVisible) {
      this.setState({
        inlineMessageVisible: false,
        status: '',
        statusMessage: '',
      });
    }

    if (supportChat && Object.keys(supportChat).length && Object.keys(supportChat).length >= 1000) {
      this.setState({ inputMessage: '' });
      return null;
    }
    
    let screen = 'dWeb'
    if (window.innerWidth <= 1280 && window.innerWidth > 832) {
      screen = 'tablet'
    } else if (window.innerWidth <= 832) {
      screen = 'mWeb'
    }
    console.log({screen}, window.innerWidth, "device check new mobile: --- 4")

    this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
    // if (inputMessage !== '' || fileUploadUrl !== '') {
    return sendSupportMsg({ message: inputMessage, name, fileUploadUrl, fileName, screen: 'mWeb', design: 'new' }); // .then(() => {
    // }
    //  });
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  handleFileClick = (e) => {
    console.log(this.hiddenFileInput, 'trigger check');
    this.hiddenFileInput.click();
  };

  chooseFile = (e) => {
    e.preventDefault();

    const { t } = this.props;
    const target = e.target.files[0];
    const allowTypes = constants.ALLLOW_FILE.allowTypes;
    if (target) {
      this.setState({
        loading: true,
      });
      console.log(target.type, "chck size");
      var typeList = ".jpg,.jpeg,.png,.pdf,.msword ,.doc,.rtf,.txt,ain";
      if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == "") {
        this.setState({
          inlineMessageVisible: true,
          status: constants.FROUNT_SIDE_STATUS.error,
          statusMessage: t('support.unsupportedFile'),
          fileUploadUrl: '',
          fileName: '',
          loading: false,
        });
        return;
      }

      if (target.size / constants.ALLLOW_FILE.unitSize > constants.ALLLOW_FILE.allowSize) {
        this.setState({
          inlineMessageVisible: true,
          status: constants.FROUNT_SIDE_STATUS.error,
          statusMessage: t('support.cantUpload'),
          fileUploadUrl: '',
          fileName: '',
          loading: false,
          inputMessage: `${target.name}(${round(target.size / constants.ALLLOW_FILE.mbSize, 1)}) mb`,
        });
      } else {
        this.setState({
          ableToSend: false,
        });
        const { supportMessageFileUpload } = this.props;
        supportMessageFileUpload(target).then((data) => {
          console.log(target.name, target.size, " upload success check");
          this.setState({
            inlineMessageVisible: true,
            status: constants.FROUNT_SIDE_STATUS.success,
            statusMessage: t('support.attachedSuccess'),
            fileUploadUrl: data.url,
            fileName: target.name,
            fileMessage: target.name,
            inputMessage: '',
            loading: false,
          });
        });
      }
    }
    // else {
    //   this.setState({
    //     inlineMessageVisible: false,
    //     status: null,
    //     statusMessage: null,
    //     fileUploadUrl: '',
    //     fileName: '',
    //     loading: false,
    //   });
    // }
  }

  toggleModal() {
    const { toggle } = this.props;
    this.setState({
      inlineMessageVisible: false,
      status: null,
      statusMessage: null,
      fileUploadUrl: '',
      fileName: '',
      loading: false,
      inputMessage: '',
      fileMessage: '',
    });
    toggle();
  }

  render() {
    const { inputMessage, fileMessage, scrollPos, inlineMessageVisible, statusMessage, status, loading, fileUploadUrl, fileName, filePopoverOpen, } = this.state;

    const {
      t, modalOpen, toggle, supportChat, uid, screenMode,
    } = this.props;

    if (!modalOpen) {
      return null;
    }
    const isInAppFrame = config.isInAppFrame();
    return (
      <Row className="contact-support">
        <CustomModal
          isOpen={modalOpen}
          addClassName="contact-admin-modal"
          toggle={() => this.toggleModal()}
          size="lg"
          title={t('support.contact')}
          body={(
            <>
              <Row className="contact-support-chat-description">
                <Col xs="12">
                  <div>
                    {t('support.description1')}<br />
                    {' '}
                    {t('support.description2')}<br />
                    {' '}
                    {t('support.description3')}<br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={` ${isInAppFrame ? 'contact-support-chat-body-wrapper-in-app-frame' : 'contact-support-chat-body-wrapper'}`} style={{ height: 320 }} md="12">
                  <ScrollAreaButtons
                    scrollPos={scrollPos}
                    show={this.messagesScrollbar && this.messagesScrollbar.state && this.messagesScrollbar.state.realHeight > this.messagesScrollbar.state.containerHeight ? true : null}
                    scroll={this.scroll}
                    speed={30}
                    rightOffset={1}
                    topOffset={-1}
                    bottomOffset={-2}
                  />
                  <ScrollArea
                    className="contact-support-chat-body-scroll-area"
                    verticalContainerStyle={{
                      background: 'transparent',
                      opacity: 1,
                      width: 11,
                    }}
                    verticalScrollbarStyle={{
                      background: '#fff',
                      borderRadius: 1,
                      width: 10,
                      //  minScrollSize: 25,
                    }}
                    minScrollSize={30}
                    horizontal={false}
                    onScroll={this.updateScrollPos}
                    ref={(el) => { this.messagesScrollbar = el; }}
                  >
                    {supportChat
                      && Object.keys(supportChat).map(key => (
                        <Row key={key}>
                          <Col md="12">
                            <Message
                              uid={uid}
                              messageId={supportChat[key].messageId || null}
                              message={supportChat[key].message || null}
                              translationKey={supportChat[key].translationKey || null}
                              translationKeyData={supportChat[key].translationKeyData || {}}
                              filteredMessage={supportChat[key].filteredMessage || null}
                              fileUploadUrl={supportChat[key].fileUpload || null}
                              fileName={supportChat[key].fileName || null}
                              isFiltered={supportChat[key].isFiltered || false}
                              userUid={supportChat[key].userUid || null}
                              date={supportChat[key].date || null}
                              screenMode={screenMode}
                              t={t}
                            />
                          </Col>
                        </Row>
                      ))}
                  </ScrollArea>
                </Col>
              </Row>
              <div className="contact-support-chat-footer">
                <div xs="12" className="contact-support-chat-footer-input-wrapper">
                  <Form onSubmit={this.submitMessage}>
                    <Input
                      className="contact-support-chat-footer-input"
                      type="textarea"
                      name="inputMessage"
                      id="inputMessage"
                      autoComplete="off"
                      placeholder={`${t('common.writeMessage')}...`}
                      value={fileUploadUrl ? fileMessage : inputMessage}
                      onChange={this.handleChange}
                      onKeyPress={this.handleEnter}
                      disabled={loading || (fileUploadUrl && fileName)}
                    />
                    {
                      inlineMessageVisible && (
                        <div className={`contact-support-chat-footer-input-wrapper-inputInlineMessage-${status}`}>
                          <p>{statusMessage}</p>
                        </div>
                      )
                    }
                  </Form>
                  <Media
                    onClick={this.handleFileClick}
                    className="contact-support-chat-footer-input-attach-icon"
                    disabled={loading}
                    src={attachImg}
                    alt=""
                    id="attach-file-id"
                    onMouseEnter={this.onFileHover}
                    onMouseLeave={this.onFileHoverLeave}
                  />
                  <IconPopover text={t('support.attachFile')} targetId="attach-file-id" popoverOpen={filePopoverOpen} placement="top" />
                  <input
                    type="file"
                    id="filesDialog"
                    ref={(ref) => { this.hiddenFileInput = ref; }}
                    className="chat-footer-input admin-file-upload-input"
                    accept=".jpg,.png,.pdf,.doc,.rtf,.txt"
                    onChange={this.chooseFile}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    hidden
                  />
                </div>
              </div>
            </>
          )}
          footer={
            <Button color="link" disabled={(!inputMessage && !fileUploadUrl) || loading || status === constants.FROUNT_SIDE_STATUS.error} className={`layout-default-button layout-default-button-${screenMode} `} onClick={this.submitMessage}>{loading ? t('common.loading') : t('common.send')}</Button>
          }
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || '',
  name: state.member.name || '',
  supportChat: state.member.supportChat || null,
  supportChatStatus: state.member.supportChatStatus || null,
});

const mapDispatchToProps = {
  sendSupportMsg: sendSupportMessage,
  setSupportRead: setSupportAsRead,
  supportMessageFileUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(ContactSupport));
