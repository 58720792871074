import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import { map } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { ModalBody, ModalHeader, Modal, ModalFooter, Media } from 'reactstrap';

import Moment from 'react-moment';
// import { count } from 'lodash';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import * as constants from '../../../constants/constants';
import closeImg from '../../../images/icons/close.png';
// import defaultImage from '../../../images/redesign/normal-mode/common/default_image.webp';
import { trimString } from '../../../utils/stringUtils';
import { getCardImage } from '../../../utils/cardInfoUtils';

const PartyLog = React.memo(({
  party,
  partyRoomData,
  openPartyModal,
  closePartyLog,
  togglePartyLog,
  roomPlayerData,
  roomPlayerImgData,
  fontMode,
}) => {
  const { i18n, t } = useTranslation('admin');
  if (Object.keys(party).length === 0) {
    return null;
  }

  let playerNames;
  const playerData = [];

  if (partyRoomData[0]) {
    playerNames = partyRoomData[0];
  }

  if (partyRoomData[1]) {
    const players = partyRoomData[1];
    Object.keys(partyRoomData[1]).map((key) => {
      if (players[key].type === constants.PLAYERJOINED) {
        playerData[players[key].data.joinedPlayerUid] = players[key.toString()].data.joinedPlayerName ? players[key.toString()].data.joinedPlayerName : (playerNames.player1 ? playerNames.player1 : '');
      }
    });
  }

  const partyArray = Object.entries(party || {});
  partyArray.sort((a, b) => a[1].time - b[1].time);
  const sortedParty = Object.fromEntries(partyArray);

  /*
  const partyArray = [];
  Object.keys(party).map((key) => {
    partyArray.push({ ...party[key], key });
  });

  partyArray.sort((a, b) => b.time - a.time);

  let sortedParty = {};
  partyArray.map((item) => {
    sortedParty = Object.assign({ [item.key]: item }, sortedParty);
  }); */

  console.log('sortedParty', sortedParty);
  const colorCards = (str) => {
    return str.split(/(♥|♦)/)
    .map((part, index) => {
      if (part === '♥' || part === '♦') {
        return (<span key={index} style={{ color: 'red' }}>{part}</span>);
      }
      return <span key={index}>{part}</span>;
    })
  }

  return (
    <Modal container={'div > div'} isOpen={openPartyModal} toggle={closePartyLog} className={`old-modal root-font-${fontMode}`} style={{maxWidth: partyRoomData[0].player4 ? '785px' : '645px'}}>
      <ModalHeader
        toggle={closePartyLog}
        close={
          <Media src={closeImg} className="notification-header-close" alt="X" onClick={closePartyLog} />
        }
      >{t('roundLogs')}</ModalHeader>
      <ModalBody className="user-party-modal">
        <ScrollAreaWrapper
          className="my-info-user-party-table-scrollarea"
          contentClassName="user-party-table-body"
          show
          rightOffset={0}
          topOffset={23}
          bottomOffset={-3}
        >
          {party && Object.keys(sortedParty)
            .map((partyKey) => {
              const action = party[partyKey];

              console.log('party[partyKey]', party[partyKey], partyKey, roomPlayerData);

              if (partyKey === 'gameResult') {
                return null;
              }
              if (action.type === 'missedTurn') {
                console.log("action penalty", action);
              }
              if (action.type === 'roundOver') {
                return null;
              }

              if (action.type === 'updatePoints') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('updatePoints')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {`${action.data.type === 'updatePointsPules' ? t('endRoomPules') : ''} ${t('player')} ${action.data.playerName}`}
                      </div>
                      <div>
                        {`${t('oldBal')}: ${action.data.oldBal}, ${t('newBal')}: ${action.data.newBal}`}
                      </div>
                      <div>
                        {`${t('oldPnts')}: ${action.data.oldPnts}, ${t('newPnts')}: ${action.data.newPnts}`}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'cardPlayed') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('cardPlayed')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      {/* <div>
                        {t('cardPlayedText', { player: playerNames ? playerNames[action.data.player] : action.data.player, playedCard: action.data.playedCard })}
                      </div> */}
                      <div>
                        {colorCards(t('cardPlayedText', { player: playerNames ? playerNames[action.data.player] : action.data.player, playedCard: action.data.playedCard }))}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'cardPlayedAutomated') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('cardPlayedAutomated')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {colorCards(t('cardPlayedText', { player: playerNames ? playerNames[action.data.player] : action.data.player, playedCard: action.data.playedCard }))}
                      </div>
                    </Col>
                  </Row>
                );
              }


              if (action.type === 'determineStrongest') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('determineStrongest')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div className='game-log-description'>
                        {colorCards(t('determineStrongestText', { card: action.data.card, winPlayer: action.data.winPlayer, tablePoints: action.data.tablePoints ? (`${action.data.tablePoints}`) : ('0') }))}

                        {/*    {`Strongest card is ${action.data.card} played by ${action.data.winPlayer}. ${action.data.tablePoints ? (`Take ${action.data.tablePoints} points`) : ('')} `}  */}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'selectType') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('selectType')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {
                          action.data.type === 'garam' ? (
                            action.data.action !== 'galdins' ? (
                              t('passedText', {player: action.data.player})
                            ) : (
                              t('passedTextGaldins', {player: action.data.player})
                            )
                          ) : (
                            (i18n.language === constants.sendLanguage.lv) ? (
                              (action.data.type === 'zole'/* || action.data.type === 'parasta'*/) ? (
                               `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (action.data.type === 'regular') ? (
                                `${t('player')} ${action.data.player} ${t('regular')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            ) : (
                              (action.data.type === 'zole') ? (
                                `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            )
                          )
                        }
                        {/* {`${t('selectTypeText1', { player: action.data.player })} ${t(action.data.type)}`} */}
                        {/* {`${t('player')} ${action.data.player} paņēma galdu`} */}
                        {/* {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                        {action.data.type === 'garam' && action.data.action === 'galdins' && (`${t('galdinsPlayed')}`)}
                        {action.data.type === 'garam' && action.data.action === 'nextRound' && (`${t('nextRoundPlayed')}`)} */}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'selectTypeAutomated') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('selectTypeAutomated')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                      {
                          action.data.type === 'garam' ? (
                            action.data.action !== 'galdins' ? (
                              t('passedText', {player: action.data.player})
                            ) : (
                              t('passedTextGaldins', {player: action.data.player})
                            )
                          ) : (
                            (i18n.language === constants.sendLanguage.lv) ? (
                              (action.data.type === 'zole'/* || action.data.type === 'parasta'*/) ? (
                               `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (action.data.type === 'regular') ? (
                                `${t('player')} ${action.data.player} ${t('regular')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            ) : (
                              (action.data.type === 'zole') ? (
                                `${t('player')} ${action.data.player} ${t('selectZole')}`
                              ) : (action.data.type === 'maza') ? (
                                `${t('player')} ${action.data.player} ${t('selectSmZole')}`
                              ) : (
                                `${t('player')} ${action.data.player} ${t('takeTable')}`
                              )
                            )
                          )
                        }
                        {/* {`${t('selectTypeText1', { player: action.data.player })} ${t(action.data.type)}`} */}
                        {/* {`${t('player')} ${action.data.player} paņēma galdu`} */}
                        {/* {action.data.type === 'garam' && action.data.action === 'next' && (' ')}
                        {action.data.type === 'garam' && action.data.action === 'galdins' && (`${t('galdinsPlayed')}`)}
                        {action.data.type === 'garam' && action.data.action === 'nextRound' && (`${t('nextRoundPlayed')}`)} */}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'burriedCard') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('burriedCard')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {colorCards(t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts }))}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'burriedCardAutomated') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('burriedCardAutomated')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {colorCards(t('burriedCardText', { burriedCard: action.data.burriedCard, newPnts: action.data.newPnts }))}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'roundResult') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('roundResult')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {action.data.type === 'galdins' ? (
                          <Fragment>
                            {t('lostGaldins', { player: action.data.winner, tricks: action.data.tablePoints, points: action.data.largePoints })}
                            {/* {`${action.data.winner} ${t('lostGame')} ${t(action.data.type)}`} */}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {`${t('largePlayer')} (${action.data.largePlayer}) ${action.data.winner === 'large' ? t('wonGame') : t('lostGame')} ${action.data.type !== 'parasta' ? t(action.data.type) : ''}`}
                            {(action.data.type === 'zole' || action.data.type === 'parasta') && ` ${t('points2', { points: action.data.largePoints })}`}

                            {action.data.scoreType === 'jani' && (` ${t('withJani')}`)}
                            {action.data.scoreType === 'bezstikis' && (` ${t('withBezstikis')}`)}
                          </Fragment>
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'playerQuit') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('playerQuit')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {t('playerQuit2', { player: action.data.player })}
                        {/* {t('playerQuitText', { player: action.data.player, roundType: action.data.roundType })} */}
                      </div>
                    </Col>
                  </Row>
                );
              }

              // if (action.type === 'roundOver') {
              //   return (
              //     <Row className="d-flex flex-row">
              //       {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
              //         <b>
              //           {t('roundOver')}
              //         </b>
              //       </Col> */}
              //       <Col sm="4" style={{ borderBottom: '1px solid gray' }}>
              //         <Moment format="HH:mm:ss" locale="lv">
              //           {action.time}
              //         </Moment>
              //       </Col>
              //       <Col sm="8" style={{ borderBottom: '1px solid gray' }}>
              //         <div />
              //       </Col>
              //     </Row>
              //   );
              // }

              if (action.type === 'newPule') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('puleAdded')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {action.data.type ? t('commonPuleAdded') : t('privatePuleAdded', { player: action.data.player })}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'puleChange') {
                return (
                  <Row className="d-flex flex-row">
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        { action.data.changeTo === 'P-' ? (
                          t('roundPuleChanged2', { game: action.data.party, pule: action.data.changeTo })
                        ) : (
                          t('roundPuleChangedPersonal', { game: action.data.party, pule: action.data.changeTo })
                        )}
                        
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'roomClosed') {
                return (
                  <Row className="d-flex flex-row">
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      {action.data.type === 'tournamentEnd' && (
                        <div>
                          {`${t('roomWasClosed')} ${t('tournamentEnd')}`}
                        </div>
                      )}
                      {action.data.type === 'lastRound' && (
                        <div>
                          {(action.data.playerUid === 'tournament') ? (
                            `${t('roomWasClosed')} ${action.data.player} ${t('lastRoundTournamentLast')}`
                          ) : (
                            `${t('roomWasClosed')} ${action.data.player} ${t('lastRound')}`
                          )}
                        </div>
                      )}
                      {action.data.type === 'missedTurn' && (
                        <div>
                          {`${t('roomWasClosed')} ${action.data.player} ${t('missedTurn')}`}
                        </div>
                      )}
                      {action.data.type === 'leftRoom' && (
                        <div>
                          {`${t('roomWasClosed')} ${action.data.player} ${t('leftRoom')}`}
                        </div>
                      )}
                      {action.data.type === 'lastPlayerLeftRoom' && (
                        <div>
                          {`${t('roomWasClosed')} ${t('lastPlayerLeftRoom')}`}
                        </div>
                      )}
                      {action.data.type === 'endAutomatedRoom' && (
                        <div>
                          {`${t('endAutomatedRoom', { player: action.data.player })}`}
                        </div>
                      )}

                    </Col>
                  </Row>
                );
              }

              if (action.type === constants.LEFTROOMPENALTY || action.type === constants.MINGAMESPENALTY || action.type === constants.MISSEDTURNPENALTY) {
                return (
                  <Row className="d-flex flex-row">
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      {action.data.data && Object.keys(action.data.data)
                        .map((actionKey) => {
                          console.log('action.data', action.data);

                          const actionDataPlayerName = playerData[actionKey] ? playerData[actionKey] : (playerNames.player1 ? playerNames.player1 : '');

                          if (action.data.fullData) {
                            console.log('render action.data.fullData');
                            return (
                              <>
                                <div>
                                  {actionDataPlayerName}
                                </div>
                                <div>
                                  {`${t('oldBal')}: ${action.data.fullData[actionKey].old}, ${t('newBal')}: ${action.data.fullData[actionKey].new}`}
                                </div>
                                {(action.data.fullData[actionKey].oldPoints && action.data.fullData[actionKey].newPoints) ? (
                                  <div>
                                    {`${t('oldPnts')}: ${action.data.fullData[actionKey].oldPoints}, ${t('newPnts')}: ${action.data.fullData[actionKey].newPoints}`}
                                  </div>
                                ) : (null)}
                              </>
                            );
                          }

                          return (
                            <div>
                              <b style={{ display: 'inline-block' }}>
                                {actionDataPlayerName}
                              </b>
                              {`: ${action.data.playerUid === actionKey ? (action.data.data[actionKey] > 0 ? -action.data.data[actionKey] : action.data.data[actionKey]) : action.data.data[actionKey]} `}
                            </div>
                          );
                        })}
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'startAutomated') {
                console.log('startAutomated action', action);
                return (
                  <Row className="d-flex flex-row">
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {t('startAutomated', { player: action.data.playerName ? action.data.playerName : action.data.player })}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'endAutomated') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('endAutomated')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {`${action.data.playerName ? action.data.playerName : action.data.player}`}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'cardsDealt') {
                return (
                  <>
                  <Row className="d-flex flex-row">
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      {t('dealtCards')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="py-2">
                      { Object.keys(partyRoomData[0]).map(key => {
                        return (
                          <div key={key} className="end-result-party-modal-cards">
                            <div className="end-result-party-modal-cards-info">
                              <div className="end-result-party-modal-cards-info-title">
                                {trimString(partyRoomData[0][key], 14)}
                              </div>
                              <div className="end-result-party-modal-cards-info-card-container">
                                {action.data[key].cards ? action.data[key].cards.map((cardKey, cardIndex) => {
                                  return (
                                    <div key={`${key}-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                      <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                    </div>
                                  );
                                }):(null)}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className="end-result-party-modal-cards-table">
                        <div className="end-result-party-modal-cards-info-table">
                          <div className="end-result-party-modal-cards-info-table-title">
                            {t('previousRound:tableCards')}
                          </div>
                          <div className="end-result-party-modal-cards-info-card-container">
                              {action.data.cardsOnTable ? action.data.cardsOnTable.map((cardKey, cardIndex) => {
                              return (
                                <div key={`cardsOnTable-${cardIndex}`} className={`end-result-party-modal-cards-info-card-container-wrapper end-result-party-modal-cards-info-card-container-wrapper-${cardIndex}`}>
                                  <Media src={getCardImage(cardKey)} className="end-result-party-modal-cards-info-card-container-wrapper-card" />
                                </div>
                              );
                            }):(null)}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  </>
                );
              }

              if (action.type === 'endRoomPules') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('endRoomPules')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {action.data.player1 ? (`${(action.data.player1.name || action.data.player1.uid)} ${t('endRoomPules')}: ${action.data.player1.points}, ${t('newPnts')} ${action.data.player1.newPoints || ''}`) : ('')}
                      </div>
                      <div>
                        {action.data.player2 ? (`${action.data.player2.name || action.data.player2.uid} ${t('endRoomPules')}: ${action.data.player2.points}, ${t('newPnts')} ${action.data.player2.newPoints || ''}`) : ('')}
                      </div>
                      <div>
                        {action.data.player3 ? (`${action.data.player3.name || action.data.player3.uid} ${t('endRoomPules')}: ${action.data.player3.points}, ${t('newPnts')} ${action.data.player3.newPoints || ''}`) : ('')}
                      </div>
                      <div>
                        {action.data.player4 ? (`${action.data.player4.name || action.data.player4.uid} ${t('endRoomPules')}: ${action.data.player4.points}, ${t('newPnts')} ${action.data.player4.newPoints || ''}`): ('')}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'setLast') {
                return (
                  ((action.data?.joinedPlayerName && action.data?.joinedPlayerName !== 'tournament') || (roomPlayerData[action.data?.playerUid] && roomPlayerData[action.data?.playerUid] !== 'tournament')) ? (
                    <Row className="d-flex flex-row">
                      {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                        <b>
                          {t('choseLastRound')}
                        </b>
                      </Col> */}
                      <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                        <Moment format="HH:mm:ss" locale="lv">
                          {action.time}
                        </Moment>
                      </Col>
                      <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                        <div>
                          {`${t('player')} ${action.data?.joinedPlayerName || roomPlayerData[action.data?.playerUid]} ${t('choseLastRound')}`}
                        </div>
                      </Col>
                    </Row>
                  ) : (null)
                );
              }

              if (action.type === 'playerJoined') {
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('playerJoined')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {`${t('player')} ${action.data.joinedPlayerName || roomPlayerData[action.data.joinedPlayerUid]} ${t('joined')}`}
                      </div>
                    </Col>
                  </Row>
                );
              }

              if (action.type === 'playerLeft') {
                console.log('action.data');
                return (
                  <Row className="d-flex flex-row">
                    {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <b>
                        {t('playerLeft')}
                      </b>
                    </Col> */}
                    <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                      <Moment format="HH:mm:ss" locale="lv">
                        {action.time}
                      </Moment>
                    </Col>
                    <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                      <div>
                        {`${t('player')} ${playerData[action.data.playerUid]} ${t('left')}`}
                      </div>
                    </Col>
                  </Row>
                );
              }

              return (
                <Row className="d-flex flex-row" key={partyKey}>
                  {/* <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                    <b>
                      {action.message}
                    </b>
                  </Col> */}
                  <Col sm="2" style={{ borderBottom: '1px solid gray' }}>
                    <Moment format="DD-MM-YYYY, HH:mm:ss SS" locale="lv">
                      {action.time}
                    </Moment>
                  </Col>
                  <Col sm="10" style={{ borderBottom: '1px solid gray' }}>
                    {action.data && Object.keys(action.data)
                      .map((actionKey) => {
                        const actionData = action.data[actionKey];
                        return (
                          <div>
                            <b style={{ display: 'inline-block' }}>
                              {[actionKey]}
                            </b>
                            {`: ${actionData} `}
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              );
            })}
        </ScrollAreaWrapper>
      </ModalBody>
      <ModalFooter>
        <Button className="notification-footer-button" color="link" onClick={closePartyLog}>{t('close')}</Button>
      </ModalFooter>
    </Modal>
  );
});

export default PartyLog;
