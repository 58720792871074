import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

import coin from '../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../images/redesign/common/rating.svg';
import proIcon from '../../../../../images/icons/proIcon.svg';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import MenuRow from '../../Components/Components/MenuRow';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';

import { SCREEN_MODE } from '../../../../../constants/constants';
import { areArraysEqual } from '../../../../../utils/arrayUtils';
import ErrorNotification from '../../Components/Components/ErrorNotification';
import config from '../../../../../constants/config';
// import { getTournamentPlayersAlias } from '../../../../../utils/tournamentUtils';
class TournamentLeaderboard extends React.Component {
  static propTypes = {
    leaderboard: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    uid: PropTypes.string,
    screenMode: PropTypes.string,
    tournament: PropTypes.shape(),
  }

  static defaultProps = {
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      //  currentPage: 0,
      //  pageSize: 20,
      leaderboard: [],
      scrollPos: 0,
      leaderboardPositions: [],
    };
  }

  updateFinalPosition = (arr) => {
    let counter = 2;

    for (let i = 1; i < arr.length; i++) {
      const prevFinalPos = arr[i - 1].finalPosition;
      const finalPos = arr[i].finalPosition;

      if (finalPos !== prevFinalPos) {
        arr[i].finalPosition = counter;
      }
      counter++;
    }
    
    return arr;
  }

  
  formatLeaderboard = (leaderboard, uid) => {
    const { tournament } = this.props;

    if (leaderboard) {
      let leaderboardArr = Object.keys(leaderboard).map(key => ({
        uid: key,
        name: leaderboard[key].name,
        photo: leaderboard[key].photo,
        totalRoomPoints: leaderboard[key].finalPosition ? leaderboard[key].totalRoomPoints : 0,
        tournamentPoints: leaderboard[key].finalPosition ? leaderboard[key].tournamentPoints : 0,
        lvl: leaderboard[key].lvl,
        joinTime: leaderboard[key].joinTime,
        winnings: leaderboard[key].finalPosition ? leaderboard[key].winnings : 0,
        finalPosition: leaderboard[key].finalPosition || 0,
        balance: leaderboard[key].balance || 0,
      })).sort((a, b) => a.finalPosition - b.finalPosition);

      console.log('leaderboardArr', leaderboardArr, tournament);

      if (tournament && tournament.currentState && (tournament.currentState === 'registration' || tournament.currentState === 'pending')) {
        leaderboardArr.sort((a, b) => a.joinTime - b.joinTime);
      }

      let finalPositionsAvailable = 0;
      for (const player of leaderboardArr) {
        if (player.finalPosition) {
          finalPositionsAvailable++;
        }
      }

      const leaderboardPositions = [leaderboardArr[0]?.finalPosition, leaderboardArr[1]?.finalPosition, leaderboardArr[2]?.finalPosition];
      this.setState({ finalPositionsAvailable, leaderboard: leaderboardArr, leaderboardPositions });
    }
  }

  componentWillMount() {
    const { leaderboard, uid } = this.props;
    this.formatLeaderboard(leaderboard, uid);
  }


  componentWillReceiveProps(nextProps) {
    const { leaderboard, uid } = nextProps;
    this.formatLeaderboard(leaderboard, uid);
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  renderRating = (t, ratingone) => (
    <div className="tournament-player-info-ratings-step-wrapper">
      {ratingone < 10 ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-weak tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.weak')}</div>) : (null)}
      {(ratingone >= 10 && ratingone < 20) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-beginner tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.beginner')}</div>) : (null)}
      {(ratingone >= 20 && ratingone < 30) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-untrained tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.untrained')}</div>) : (null)}
      {(ratingone >= 30 && ratingone < 40) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-medium tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.medium')}</div>) : (null)}
      {(ratingone >= 40 && ratingone < 50) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-good tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.good')}</div>) : (null)}
      {(ratingone >= 50 && ratingone < 60) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-great tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.great')}</div>) : (null)}
      {(ratingone >= 60 && ratingone < 70) ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-fan tournament-player-info-ratings-step-active power-rankings-level-step">{t('myInfo.fan')}</div>) : (null)}
      {ratingone >= 70 ? (<div className="tournament-player-info-ratings-step tournament-player-info-ratings-step-pro tournament-player-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
    </div>
  );

  renderPodium = (place, leaderboardIndex, leaderboard, screenMode, t) => (
    <Col cs="4" className='pl-0'>
      <div className="tournament-player-info">
        <div className={`tournament-player-info-userlevel-badge tournament-player-info-userlevel-badge-${place}`}>{place}</div>
        <div className={`tournament-player-info-background-${place}`} />

        <div className={`tournament-player-info-player tournament-player-info-player-${place}`}>
          <div className="tournament-player-info-player-background">
            <div className="tournament-player-info-player-image-wrapper">
              <div className="tournament-player-info-player-image-background" />
              <div style={{ backgroundImage: `url(${leaderboard[leaderboardIndex]?.photo || (screenMode === SCREEN_MODE.light ? lightDefaultImage : defaultImage)})`, backgroundPosition: 'center', backgroundSize: leaderboard[leaderboardIndex]?.photo ? '100%, 100%' : '80%, 93%' }} className="tournament-player-info-player-image" />
              <div className="tournament-player-info-player-image-overlay" />
              {/* <div className="tournament-player-info-player-image-emotions" /> */}
              <div className="tournament-player-info-player-image-level">
                {this.renderRating(t, leaderboard[leaderboardIndex]?.lvl || 10)}
              </div>
            </div>
            <div className="tournament-player-info-player-name-wrapper">
              <div className="tournament-player-info-player-name">
                {leaderboard[leaderboardIndex] ? leaderboard[leaderboardIndex].name : ''}
              </div>
            </div>
            <div className="tournament-player-info-player-wrapper">
              <Media src={coin} className="tournament-player-info-player-balance-icon" />
              <div className="tournament-player-info-player-balance">
                {leaderboard[leaderboardIndex]?.winnings || 0}
              </div>
              <Media src={rating} className="tournament-player-info-player-rating-icon" />
              <div className="tournament-player-info-player-rating">
                {leaderboard[leaderboardIndex]?.rating || 0}
              </div>
            </div>

          </div>
        </div>
      </div>
    </Col>
  )

  renderAllPodiums = (leaderboardPositions, leaderboard, screenMode, t) => {
    const { tournament } = this.props;

    if (areArraysEqual(leaderboardPositions, [1, 2, 3])) {
      return (
        <>
          {this.renderPodium(2, 1, leaderboard, screenMode, t)}
          {this.renderPodium(1, 0, leaderboard, screenMode, t)}
          {this.renderPodium(3, 2, leaderboard, screenMode, t)}
        </>
      );
    } else if (areArraysEqual(leaderboardPositions, [1, 2, 2])) {
      return (
        <>
          {this.renderPodium(2, 1, leaderboard, screenMode, t)}
          {this.renderPodium(1, 0, leaderboard, screenMode, t)}
          {this.renderPodium(2, 2, leaderboard, screenMode, t)}
        </>
      );
    } else if (areArraysEqual(leaderboardPositions, [1, 1, 3])) {
      return (
        <>
          {this.renderPodium(1, 0, leaderboard, screenMode, t)}
          {this.renderPodium(1, 1, leaderboard, screenMode, t)}
          {this.renderPodium(3, 2, leaderboard, screenMode, t)}
        </>
      );
    } else if (areArraysEqual(leaderboardPositions, [1, 1, 1])) {
      return (
        <>
          {this.renderPodium(1, 0, leaderboard, screenMode, t)}
          {this.renderPodium(1, 1, leaderboard, screenMode, t)}
          {this.renderPodium(1, 2, leaderboard, screenMode, t)}
        </>
      );
    } else {
      if (tournament.currentState !== 'closed') {
        return (
          <>
            {this.renderPodium(2, 1, leaderboard, screenMode, t)}
            {this.renderPodium(1, 0, leaderboard, screenMode, t)}
            {this.renderPodium(3, 2, leaderboard, screenMode, t)}
          </>
        );
      }
      return (
        <div className="d-flex justify-content-center align-items-center text-center w-100">
          <ErrorNotification
            text={t('tournaments.cancelled')}
            isVisible
          />
        </div>
      );
    }
  }

  render() {
    const {
      t,
      uid,
      screenMode,
      tournament,
    } = this.props;

    const {
      //  currentPage,
      //  pageSize,
      leaderboard,
      leaderboardPositions,
      scrollPos,
      finalPositionsAvailable,
    } = this.state;

    console.log('finalPositionsAvailable', finalPositionsAvailable);

    //  const pagesCount = Math.ceil(leaderboard.length / pageSize);

    //  leaderboard && leaderboard.slice(
    //    currentPage * pageSize,
    //    (currentPage + 1) * pageSize,
    //  )
    //    .map((leaderboardPlayer, index) => (
    let currentPlayerLeaderboard = null;
    for (const item of leaderboard) {
      if (item.uid === uid) {
        currentPlayerLeaderboard = item;
        break;
      }
    }
    const isInAppFrame = config.isInAppFrame();

    // const alias = getTournamentPlayersAlias(tournament);

    return (
      <Fragment>
        {/*  <ScrollAreaButtons
          scrollPos={scrollPos}
        //  show={this.tableScrollbar && this.tableScrollbar.state && this.tableScrollbar.state.containerHeight < this.tableScrollbar.state.realHeight ? true : null}
          show={leaderboard && leaderboard.length > 7 ? true : null}
          scroll={this.scroll}
          speed={30}
          rightOffset={13}
          topOffset={2}
          bottomOffset={2}
        />
        <ScrollArea
          speed={0.55}
          className="tournaments-scrollarea"
          contentClassName="tournaments-scrollarea-body"
          smoothScrolling
          stopScrollPropagation
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 11,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 10,
            minHeight: 10,
            minScrollSize: 25,
          }}
          horizontal={false}
          onScroll={this.updateScrollPos}
          ref={(el) => {
            this.tableScrollbar = el;
          }}
        > */}
        <div className={`tournament-leaderboard-top3-scroll-box ${finalPositionsAvailable !== 0 ? 'tournament-leaderboard-top3-scroll-box-in-app-frame' : 'h-auto'}`}>
          <Row className="tournament-leaderboard-top3" style={leaderboardPositions[0] === 0 ? { height: 150 } : {}}>
            {this.renderAllPodiums(leaderboardPositions, leaderboard, screenMode, t)}
          </Row>
          {finalPositionsAvailable !== 0 &&
            <Row className="tournament-leaderboard-table-wrapper">
              <Col xs="12">
  
              <MenuRow compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}`}>
                <Row className="font-weight-bold">
                  <Col xs="2" style={{maxWidth: '13.5%'}}>
                    <div className="text-center">
                      {t('tournaments.position')}
                    </div>
                  </Col>
                  <Col>
                    {t('tournaments.name')}
                  </Col>
                  <Col className="text-center">
                    {t('tournaments.tournamentPoints')}
                  </Col>
                  <Col className="text-center">
                    {t('tournaments.totalRoomPoints')}
                  </Col>
                  <Col className="text-center">
                    {t('tournaments.winnings')}
                  </Col>
                </Row>
                </MenuRow>
                {currentPlayerLeaderboard && (
                  <div className="tournament-scrollarea tournament-leaderboard" style={{height: 'unset'}}>
                    <MenuRow key={currentPlayerLeaderboard.uid} compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}-first`}>
                      <Row key={currentPlayerLeaderboard.uid}>
                        <Col xs="2" style={{maxWidth: '13.5%'}}>
                          <div className="text-center">
                            {currentPlayerLeaderboard.finalPosition}
                          </div>
                        </Col>
                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {/* <div style={{ display: 'inline-block' }}> */}
                            {currentPlayerLeaderboard.name}
                          {/* </div> */}
                        </Col>
                        <Col className="text-center">
                          {currentPlayerLeaderboard.tournamentPoints}
                        </Col>
                        <Col className="text-center">
                          {currentPlayerLeaderboard.totalRoomPoints}
                        </Col>
                        <Col className="text-center">
                          {currentPlayerLeaderboard.winnings || 0}
                        </Col>
                      </Row>
                    </MenuRow>
                  </div>
                )}
                <div
                  className="tournament-scrollarea tournament-leaderboard tournament-history-scrollarea"
                  //style={currentPlayerLeaderboard ? {maxHeight: 'calc(100% - 126px)'} : {}}
                  show
                  rightOffset={15}
                  topOffset={40}
                  bottomOffset={0}
                >
                  {leaderboard && leaderboard.length ? (leaderboard.map((leaderboardPlayer) => (
                    <MenuRow key={leaderboardPlayer.uid} compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}`}>
                      <Row key={leaderboardPlayer.uid}>
                        <Col xs="2" style={{maxWidth: '13.5%'}}>
                          <div className="text-center">
                            {leaderboardPlayer.finalPosition}
                          </div>
                        </Col>
                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {/* <div style={{ display: 'inline-block' }}> */}
                            {leaderboardPlayer.name}
                          {/* </div> */}
                        </Col>
                        <Col className="text-center">
                          {leaderboardPlayer.tournamentPoints}
                        </Col>
                        <Col className="text-center">
                          {leaderboardPlayer.totalRoomPoints}
                        </Col>
                        <Col className="text-center">
                          {leaderboardPlayer.winnings || 0}
                        </Col>
                      </Row>
                    </MenuRow>
                  ))) : (
                    <div />
                  )}
                </div>
              </Col>
            </Row>
          }

        </div>
      </Fragment>
    );
  }
}

export default withTranslation('common')(TournamentLeaderboard);
