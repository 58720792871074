import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { filter, map, size, includes, intersection } from 'lodash';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Lottie from 'react-lottie';

import ReactGA from 'react-ga';
import Iframe from 'react-iframe';
// import Spinner from 'reactstrap/lib/Spinner';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import moment from 'moment';
import Moment from 'react-moment';

// import { loadStripe } from '@stripe/stripe-js';

// import ScrollArea from 'react-scrollbar';
// import ScrollAreaButtons from '../UI/ScrollAreaButtons';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import CustomDate from '../UI/CustomDate';
import Discounts from './Discounts';

import config from '../../../constants/config';

import InviteFriend from './InviteFriend';
import * as constants from '../../../constants/constants';

import coins1 from '../../../images/BuyMoney/veikals_paka_1.png';
import coins2 from '../../../images/BuyMoney/veikals_paka_2.png';
import coins3 from '../../../images/BuyMoney/veikals_paka_3.png';
import coins4 from '../../../images/BuyMoney/veikals_paka_4.png';
import coins5 from '../../../images/BuyMoney/veikals_paka_5.png';

import shopImg from '../../../images/icons/shoping_cart.png';

import bonus1 from '../../../images/icons/money_bonus_1.png';
import bonus2 from '../../../images/icons/money_bonus_2.png';
import bonus3 from '../../../images/icons/money_bonus_3.png';
import bonus4 from '../../../images/icons/money_bonus_4.png';

import coinImg from '../../../images/coin.svg';
import glitterAnimImg from '../../../images/BuyMoney/glitter.json';

import balanceResetImg from '../../../images/BuyMoney/resetBalance.png';
import pointsResetImg from '../../../images/BuyMoney/resetPoints.png';
import profileResetImg from '../../../images/BuyMoney/resetProfile.png';
import mobileImg from '../../../images/BuyMoney/mobile.png';
import hideAdsMonth from '../../../images/BuyMoney/hideAdsMonth.png';
import hideAdsYear from '../../../images/BuyMoney/hideAdsYear.png';

import infoImg from '../../../images/icons/info-icon-3.png';
import closeImg from '../../../images/icons/close.png';

import buttonClickedSound from '../../../sounds/click_feedback.flac';
import PaymentTypeContent from '../Redesign/Menu/Modals/PaymentTypeContent';

import {
  initFBPayment,
  initDraugiemPayment,
  initInboxPayment,
  fbPaymentCallback,
  initStripePayment,
  initEveryPayPayment,
  getTransactionsHistory,
  claimGiftCode,
  verifyGiftCode,
} from '../../../actions/member';

import {
  getDiscounts, setLastShopTab, setTransactionComplete,
} from '../../../actions/shop';

import {
  errorMessages,
} from '../../../constants/messages';
import Timer from '../Redesign/Components/Components/Timer';

class BuyPage extends React.Component {
  static propTypes = {
    initFBPay: PropTypes.func.isRequired,
    fbCallback: PropTypes.func.isRequired,
    initDraugiemPay: PropTypes.func.isRequired,
    initInboxPay: PropTypes.func.isRequired,
    initStripePay: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchTransactionsHistory: PropTypes.func.isRequired,
    totalPoints: PropTypes.number,
    balance: PropTypes.number,
    parentActiveTab: PropTypes.string,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func,
    socProvider: PropTypes.string,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
  //  }),
    claimGift: PropTypes.func.isRequired,
    verifyGift: PropTypes.func.isRequired,
    // showNotification: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }),
    toggleTransactionSuccess: PropTypes.func.isRequired,
    switchToggle: PropTypes.func.isRequired,
    toggleStripeLoading: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({
      filterType: PropTypes.string,
      discountPrice: PropTypes.string,
      selectedDiscountPackage: PropTypes.string,
      type: PropTypes.string,
      specialPrice: PropTypes.string,
    })),
    fetchDiscounts: PropTypes.func.isRequired,
    toggleAboutUs: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    setLastShopTabFunc: PropTypes.func.isRequired,
    lastShopTab: PropTypes.string,
    setTransactionCompleteFunc: PropTypes.func.isRequired,
    transactionComplete: PropTypes.bool,
  }

  static defaultProps = {
    totalPoints: null,
    balance: null,
    parentActiveTab: null,
    isFullscreen: false,
  //  userSettings: {},
    socProvider: null,
    discounts: [],
    i18n: {},
    fontMode: constants.FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    const { parentActiveTab } = this.props;

    this.state = {
      // activeTab: parentActiveTab === constants.MENU_NAVIGATION.giftPage ? constants.SHOP_NAVIGATION.discountEvent : parentActiveTab === constants.MENU_NAVIGATION.resetBalancePage ? constants.SHOP_NAVIGATION.premiumPacks : constants.SHOP_NAVIGATION.moneyPacks,
      pauseGlitter: [true, true, true, true, true],
      processing: false,
      activeHover: null,
      showInboxPayment: false,
      inboxIframeLink: null,
      historyData: [],
      scrollPos: 0,
      code: '',
      redeemGiftModal: false,
      loading: false,
      selectCode: '',
      selectValue: '',
      redeemFlag: false,
      verifiedCode: '',
      giftStatus: '',
      giftMessage: '',
      // curDate: new Date(),
      buyModal: false,
      paymentErrorModal: false,
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      selectedProduct: {},
      selectedProductId: 0,
      devMode: false,
      isOnline: navigator.onLine,
      checkedTerms: false,
      paymentError: '',
      smsPaymentModal: false,
      curState: null,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.handleChange = this.handleChange.bind(this);
    this.onPaymentOptionClick = this.onPaymentOptionClick.bind(this);
    this.codeRef = React.createRef();
  }

  componentDidMount() {
    // const { t } = this.props;
    const { pauseGlitter } = this.state;
    const pauseGlitterCopy = [...pauseGlitter];

    const { fetchDiscounts, discounts } = this.props;
    const { hostname } = window.location;

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    this.setState({ devMode });

    fetchDiscounts(devMode);
    /*
    this.intervalID = setInterval(() => {
      this.setState({
        curDate: Date.now(),
      });
    }, 1000); */

    setTimeout(() => {
      pauseGlitterCopy[0] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 0);

    setTimeout(() => {
      pauseGlitterCopy[4] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 270);

    setTimeout(() => {
      pauseGlitterCopy[1] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 500);

    setTimeout(() => {
      pauseGlitterCopy[3] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 750);

    setTimeout(() => {
      pauseGlitterCopy[2] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 1000);

    this.setState({
      giftMessage: errorMessages.enterGiftCode,
    });
    this.fetchTransactionsHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab: prevActiveTab } = prevState;
    const { parentActiveTab: prevParentActiveTab } = prevProps;
    const { parentActiveTab, lastShopTab, transactionComplete, setTransactionCompleteFunc, switchToggle } = this.props;
    const { activeTab } = this.state;

    if (lastShopTab && transactionComplete) {
      switchToggle(lastShopTab === 'moneyPacks' ? constants.MENU_NAVIGATION.buyPageMoneyPacks : constants.MENU_NAVIGATION.buyPagePremiumPacks,);
      setTransactionCompleteFunc(false);
    }
    // if (prevParentActiveTab === constants.MENU_NAVIGATION.giftPage) {
    //   if (activeTab !== constants.MENU_NAVIGATION.buyPage) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.discountEvent,
    //     });
    //   }
    // }
    // if (prevParentActiveTab !== parentActiveTab) {

    //   if (parentActiveTab === constants.MENU_NAVIGATION.resetBalancePage) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.premiumPacks,
    //     });
    //   }
    //   if (parentActiveTab === constants.MENU_NAVIGATION.moneyPacks) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.moneyPacks,
    //     });
    //   }
    // }

    // if (activeTab !== prevActiveTab && activeTab === constants.SHOP_NAVIGATION.redeemGiftCode) {
    //   this.setState({
    //     giftStatus: '',
    //     giftMessage: errorMessages.enterGiftCode,
    //   });
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
    clearInterval(this.intervalID);
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({ [e.target.name]: value });
  }

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option,
    });
  }


  toggle = (tab) => {
    const { switchToggle, parentActiveTab } = this.props;
    // const { activeTab } = this.state;
    if (parentActiveTab !== tab) {
      // this.setState({
      //   activeTab: tab,
      // });
      switchToggle(tab);

      if (tab === constants.MENU_NAVIGATION.buyPageMoneyPacks) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Money Packages',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPagePremiumPacks) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Premium Packages',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPageHistory) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open Purchase History',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPageRedeemCode) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Redeem Gift',
        });
      }
    }
  }

  verifyGift = () => {
    const {
      verifyGift,
      // showNotification,
      // t,
    } = this.props;

    const {
      code,
    } = this.state;

    this.setState({ loading: true });
    let giftMessage = '';
    let giftStatus = '';
    if (code.length > 10 || code.length <= 3) {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: errorMessages.giftCodeLongFailed,
      });
      return;
    }
    verifyGift(code).then((res) => {
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        // showNotification(t('common.success'), t('common.verifySuccess'), 'success');
        this.setState({
          loading: false,
          selectCode: res.selCode,
          selectValue: res.selVal,
          verifiedCode: code,
          giftStatus: 'success',
          // giftMessage: t('common.verifySuccessMessage', { amount: res.selVal }),
          giftMessage: errorMessages.verifySuccessMessage
        });
      }
      if (res.status === constants.FROUNT_SIDE_STATUS.error) {
        giftStatus = constants.FROUNT_SIDE_STATUS.error;
        if (res.error === constants.GIFT_CODE_ERROR_STATUS.missingGiftCode) {
          giftMessage = errorMessages.enterGiftCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.wrongCode) {
          giftMessage = errorMessages.wrongCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.codeExpired) {
          giftMessage = errorMessages.codeExpire;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.limitClaim) {
          giftMessage = errorMessages.limitClaim;
        } else {
          giftMessage = res.error;
          // showNotification(t('common.error!'), res.error, 'danger');
        }
        this.setState({
          loading: false,
          giftStatus,
          giftMessage,
        });
      } else {
        this.claimGift();
      }
    }).catch((error) => {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: error.message,
      });
    });
  }

  claimGift = () => {
    const {
      claimGift,
      // showNotification,
      // t,
    } = this.props;

    const {
      verifiedCode,
      // selectValue,
      // selectCode,
      code,
    } = this.state;
    this.setState({ loading: true });
    let giftMessage = '';
    let giftStatus = '';
    if (code.length > 10) {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: errorMessages.giftCodeLongFailed,
      });
      return;
    }
    claimGift(verifiedCode).then((res) => {
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        this.setState({
          redeemFlag: true,
          verifiedCode: '',
          giftStatus: constants.FROUNT_SIDE_STATUS.completed,
          // giftMessage: t('common.claimSuccessMessage', { code: selectCode }),
          giftMessage: errorMessages.claimSuccessMessage,
          code: '',
          loading: false,
        });
      }
      if (res.status === constants.FROUNT_SIDE_STATUS.error) {
        giftStatus = constants.FROUNT_SIDE_STATUS.error;
        if (res.error === constants.GIFT_CODE_ERROR_STATUS.missingGiftCode) {
          giftMessage = errorMessages.enterGiftCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.wrongCode) {
          giftMessage = errorMessages.wrongCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.codeExpired) {
          giftMessage = errorMessages.codeExpire;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.limitClaim) {
          giftMessage = errorMessages.limitClaim;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.alreadyClaimed) {
          giftMessage = errorMessages.alreadyClaimed;
        } else {
          giftMessage = res.error;
        }
        this.setState({
          loading: false,
          giftStatus,
          giftMessage,
        });
      }
    }).catch((error) => {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: error.message,
      });
      // showNotification(t('common.error!'), error.message, 'danger');
    });
  }

  toggleBuyModal = () => {
    this.setState(prevState => ({ buyModal: !prevState.buyModal }));
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState(prevState => ({
      paymentErrorModal: !prevState.paymentErrorModal,
      paymentError,
    }));
  }

  pay = () => {
    const {
      selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms,
    } = this.state;
    const {
      toggleStripeLoading, initEveryPay, t, i18n,
    } = this.props;

    console.log('selectedProduct, selectedPaymentOption, selectedProductId', selectedProduct, selectedPaymentOption, selectedProductId);

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id].includes(selectedPaymentOption.id)) {

      if (constants.PAYMENT_TYPES.textMessage.id !== selectedPaymentOption.id) {
        toggleStripeLoading(true);

        initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
          const paymentLink = res.data.payment_link;
          if (paymentLink) {
            window.location.href = paymentLink;
          }
        }).catch((error) => {
          toggleStripeLoading(false);
          console.log(error);
        });
      } else {
        this.toggleSmsPaymentModal();
      }
      
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }

  buyMoney = (prod) => {
    const {
      initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleTransactionSuccess, toggleStripeLoading, discounts, isFullscreen, isFullscreenEnabled, toggleFullscreen, setLastShopTabFunc,
    } = this.props;

    const { devMode, curState } = this.state;

    if (prod <= 5) {
      setLastShopTabFunc('moneyPacks');
    } else {
      setLastShopTabFunc('premiumPacks');
    }

    console.log('buyMoney', devMode);

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      if (devMode || !devMode) {
        const selectedProduct = { ...constants.BUY_PAGE_PRODUCTS[prod] };
        if (discounts && discounts[0] && discounts[0].type === constants.SPECIALPRICE && curState === constants.CURRENT) {
          selectedProduct.realPrice = discounts[0].specialPrice[`money${prod}`];
          selectedProduct.discountType = constants.SPECIALPRICE;
        } else if (discounts && discounts[0] && discounts[0].type === constants.DISCOUNTPRICE && curState === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, item => item.id === prod)[0];
          selectedProduct.discountType = constants.DISCOUNTPRICE;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = parseFloat(productMoney.realPrice - ((productMoney.realPrice / 100) * discounts[0].discountPrice)).toFixed(2)
          }
        } else if (discounts && discounts[0] && discounts[0].type === constants.BONUS && curState === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, item => item.id === prod)[0];
          selectedProduct.discountType = constants.BONUS;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = discounts[0].discountPrice;
          }
        }
        this.setState({ selectedProduct, selectedProductId: prod });
        this.toggleBuyModal();
      } else {
        /* const { initStripePay } = this.props;
        toggleStripeLoading(true);
        //  const failTxt = t('menu.stripeShopNotWorking');

        initStripePay(prod).then((result) => {
          if (result.data && result.data.data.status && result.data.data.status === constants.FROUNT_SIDE_STATUS.success && result.data.data.id) {
            const paymentSessionId = result.data.data.id;

            loadStripe(config.stripeBublicKey).then((stripe) => {
              stripe.redirectToCheckout({
                sessionId: paymentSessionId,
              }).then(() => {
                toggleTransactionSuccess(true);
                toggleStripeLoading(false);

                this.setState({ processing: false });
              });
            }).catch(() => {
            //  alert(failTxt);
              this.setState({ processing: false });
              toggleStripeLoading(false);
            });
          } else if (result.data.data && !result.data.data.status) {
            //  alert(failTxt);
            toggleStripeLoading(false);
            this.setState({ processing: false });
          } else if (result.data.data && result.data.data.status && result.data.data.status === 'error') {
            //  alert(failTxt);
            toggleStripeLoading(false);
            this.setState({ processing: false });
          }
        }).catch(() => {
        //  alert(failTxt);
        //  console.log(e);
          toggleStripeLoading(false);
          this.setState({ processing: false });
        }); */
      }

      // return;
    } else if (socProvider === constants.SOC_PROVIDERS.facebook) { // In App Iframe (fb/dra)
      this.playButtonSound();

      initFBPay(prod).then(async (res) => {
        console.log('initFBPay', res);
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product, token } = res.data.data;

          console.log('product', product, token);

          if (product && socProvider === constants.SOC_PROVIDERS.facebook) {
            if (window.FB) {
              await window.FB.init({
                appId: '570004516363887',
                autoLogAppEvents: true,
                xfbml: true,
                cookie: false,
                status: true,
                version: 'v19.0'
              });

              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: token, // optional, must be unique for each payment
              }, (resp) => {
                console.log('window.FB.ui resp', resp);
                fbCallback(resp);
                return null;
              });
            } else {
            //  console.log('no FB.ui or not facebook provider');
            }
          } else {
          //  console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.draugiem) {
      this.playButtonSound();
      initDraugiemPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === constants.SOC_PROVIDERS.draugiem) {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
            //  console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
          //    console.log('error with product');
          }
        }
      });
    } else if (socProvider === constants.SOC_PROVIDERS.inbox) {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        if (res.data && res.data.data && res.data.data.status === constants.FROUNT_SIDE_STATUS.success) {
          const { product } = res.data.data;

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
          //  console.log('error with product');
          }
        }
      });
    }
  }

  toggleRedeemGift = () => {
    this.setState({redeemGiftModal: false, redeemFlag: false});
  }

  closeInboxModal = () => {
    this.setState({ showInboxPayment: false, inboxIframeLink: null });
  }

  mouseEnter = (key) => {
    this.setState({ activeHover: key });
  }

  mouseLeave = () => {
    this.setState({ activeHover: null });
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
              console.log('audio played auto');
            })
            .catch(() => {
              // Auto-play was prevented
              // Show paused UI.
              console.log('playback prevented');
            });
        }
      }
  //  }
  }

  fetchTransactionsHistory = () => {
    const { fetchTransactionsHistory } = this.props;

    fetchTransactionsHistory().then((res) => {
      if (res) {
        const array = [];

        Object.keys(res).map((key) => {
          array.push({ key, ...res[key] });
          return null;
        });

        array.sort((a, b) => (b.dateInitiated - a.dateInitiated));

        this.setState({ openHistory: false, historyData: array });

      //  this.toggle('3');
      } else {
        this.setState({ openHistory: false, historyData: [] });

      //  this.toggle('3');
      }
    });
  }

  closeHistory = () => {
    this.setState({ openHistory: false, historyData: null });
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }


  renderSpecialAndDiscount = (product, discount, processing) => {
    const { balance, totalPoints } = this.props;
    const { curState } = this.state;
    const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === product; })[0];

    if (includes(discount.selectedDiscountPackage, productMoney.label)) {
      if (discount.type === constants.SPECIALPRICE) {
        return (
          <>
            <Button disabled={curState === constants.CURRENT} style={{ textDecorationLine: 'line-through' }} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(product)}>
              <div className="buy-page-coins-button-text">
                {`${productMoney.realPrice} EUR`}
              </div>
            </Button>
            <Button
              disabled={
                product === 6 ? (processing || curState === constants.PLANNED || (!balance || (balance > 0)))
                  : product === 7 ? (processing || curState === constants.PLANNED || (!totalPoints || (totalPoints > 0)))
                    : processing || curState === constants.PLANNED
              }
              color="link"
              className="buy-page-coins-button"
              onClick={() => this.buyMoney(product)}
            >
              <div className="buy-page-coins-button-text">
                {`${discount.specialPrice[product < 10 ? `money${product}` : product === 10 ? 'hideAdsMonth' : 'hideAdsYear']} EUR`}
              </div>
            </Button>
          </>
        );
      }
      return (
        <>
          <Button disabled={curState === constants.CURRENT} style={{ textDecorationLine: curState === constants.CURRENT ? 'line-through' : '' }} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(product)}>
            <div className="buy-page-coins-button-text">
              {`${productMoney.realPrice} EUR`}
            </div>
          </Button>
          <Button
            disabled={
              product === 6 ? (processing || curState === constants.PLANNED || (!balance || (balance > 0)))
                : product === 7 ? (processing || curState === constants.PLANNED || (!totalPoints || (totalPoints > 0)))
                  : processing || curState === constants.PLANNED
            }
            color="link"
            className="buy-page-coins-button"
            onClick={() => this.buyMoney(product)}
          >
            <div className="buy-page-coins-button-text">
              {`${parseFloat(productMoney.realPrice - ((productMoney.realPrice / 100) * discount.discountPrice)).toFixed(2)} EUR`}
            </div>
          </Button>
        </>
      );
    }

    return (
      <Button
        disabled={
          product === 6 ? (processing || (!balance || (balance > 0)))
            : product === 7 ? (processing || (!totalPoints || (totalPoints > 0)))
              : processing
        }
        color="link"
        className="buy-page-coins-button"
        onClick={() => this.buyMoney(product)}
      >
        <div className="buy-page-coins-button-text">
          {`${productMoney.realPrice} EUR`}
        </div>
      </Button>
    );
  }

  renderBonus = (product, discount, processing) => {
    return (
      <div className="buy-page-coins-wrapper-bonus-coins-amount">
        <Media src={coinImg} className="buy-page-coins-coin" />
        <span>{`+${discount.discountPrice}`}</span>
      </div>
    );
  };

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms,
    }));
  };

  toggleSmsPaymentModal = () => {
    console.log( "checkPPPP")
    this.setState(prevState => ({
      smsPaymentModal: !prevState.smsPaymentModal,
    }));
  };

  handleCurState = (state) => {
    this.setState({
      curState: state
    })
  }

  render() {
    const {
      t,
      socProvider,
      balance,
      totalPoints,
      parentActiveTab,
      isFullscreen,
      discounts,
      fontMode,
      // toggleAboutUs,
      switchToggle,
      adsHidden,
    } = this.props;

    console.log('adsHidden', adsHidden);

    const {
      // activeTab,
      pauseGlitter,
      processing,
      activeHover,
      showInboxPayment,
      inboxIframeLink,
      openHistory,
      historyData,
      // scrollPos,
      code,
      // message,
      loading,
      redeemGiftModal,
      redeemFlag,
      selectCode,
      selectValue,
      giftMessage,
      giftStatus,
      buyModal,
      paymentErrorModal,
      selectedPaymentOption,
      selectedProduct,
      isOnline,
      checkedTerms,
      paymentError,
      smsPaymentModal,
      devMode,
      curState
    } = this.state;

    if (this.codeRef.current) {
      this.codeRef.current.focus();
    }
    console.log(discounts, "checkDiscounts===")

    return (
      <>
        <Fragment>
          {(parentActiveTab && (constants.BUY_PAGE_TABS.includes(parentActiveTab))) ? (
            <Helmet>
              <title>
                {`Zole - ${t('route.buyPage')}`}
              </title>
            </Helmet>
          ) : (null)}
          <Row className="buy-page">
            <Col sm="12" className="buy-page-block">
              <Row className="buy-page-header">
                <Col xs="3" sm="3">
                  <Media src={shopImg} className="buy-page-header-image" />
                  <div className="buy-page-header-text">
                    {t('buyPage.buyPage')}
                  </div>
                </Col>
                <Fragment>
                  <Col className="buy-page-header-button-wrapper">
                    <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': parentActiveTab === constants.MENU_NAVIGATION.buyPage || parentActiveTab === constants.MENU_NAVIGATION.buyPageMoneyPacks })} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageMoneyPacks)}>
                      {t('buyPage.moneyPacks')}
                    </Button>
                  </Col>
                  <Col className="buy-page-header-button-wrapper">
                    <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': parentActiveTab === constants.MENU_NAVIGATION.buyPagePremiumPacks })} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPagePremiumPacks)}>
                      {t('buyPage.premiumPacks')}
                    </Button>
                  </Col>
                  <Col className="buy-page-header-button-wrapper">
                    <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': parentActiveTab === constants.MENU_NAVIGATION.buyPageHistory })} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageHistory)}>
                      {t('common.transactionsHistory')}
                    </Button>
                  </Col>
                  <Col className="buy-page-header-button-wrapper">
                    <Button color="link" className={classNames('buy-page-header-button', { 'buy-page-header-button-active': parentActiveTab === constants.MENU_NAVIGATION.buyPageRedeemCode })} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageRedeemCode)}>
                      {t('common.redeemGiftCode')}
                    </Button>
                  </Col>
                {/*  <Col className="buy-page-header-button-wrapper">
                    <Button color="link" className={`buy-page-header-button  ${activeTab === constants.SHOP_NAVIGATION.discountEvent ? 'buy-page-header-button-active' : null}`} onClick={() => this.toggle('5')}>
                      {t('common.discountEvent')}
                    </Button>
                  </Col> */}
                  <Col xs="1" sm="1" />
                </Fragment>
              </Row>
              <Row className="buy-page-main">
                <Col>
                  <TabContent className="buy-page-tabContent" activeTab={parentActiveTab}>
                    <TabPane tabId={constants.MENU_NAVIGATION.buyPageMoneyPacks} className="buy-page-tab">
                      <div className="buy-page-block-container">
                        <Row className="d-flex flex-column align-items-center buy-page-end-time-section mt-2">
                          {
                            discounts.length > 0 && (size(intersection(discounts[0].selectedDiscountPackage, constants.MONEYPACKS_TYPES)) > 0) && map(discounts, item => (
                              <Col className="d-flex flex-row align-items-center">
                                <Timer item={item} handleCurState={this.handleCurState} curState={curState} />
                              </Col>
                            ))
                          }
                        </Row>
                        <Row style={{margin: '0px 10px'}}>
                          <Col xs={`${isFullscreen ? constants.SCREEN_MODE_TYPE.xs : constants.SCREEN_MODE_TYPE.none}`} sm={`${isFullscreen ? constants.SCREEN_MODE_TYPE.sm : constants.SCREEN_MODE_TYPE.none}`} md={`${isFullscreen ? constants.SCREEN_MODE_TYPE.md : constants.SCREEN_MODE_TYPE.none}`} lg="">
                            <Row>
                              <Col sm="12">
                                <div className="buy-page-coins-imageWrapper">
                                  <div className="buy-page-coins-background" />
                                  <Media className="buy-page-coins-image1" src={coins1} alt="" />

                                  <div
                                    className="glitter-anim-image"
                                    style={{ top: 30, left: 16 }}
                                  >
                                    <Lottie
                                      options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: glitterAnimImg,
                                        rendererSettings: {
                                          preserveAspectRatio: 'xMidYMid slice',
                                        },
                                      }}
                                      height={42}
                                      width={42}
                                      isStopped={false}
                                      isPaused={pauseGlitter[0]}
                                    />
                                  </div>


                                </div>
                                <div className="buy-page-coins-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money1') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                                    )
                                  }
                                  <Media src={coinImg} className="buy-page-coins-coin" />
                                  <div className="buy-page-coins-amount">
                                    250
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    size(discounts) > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money1'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing,)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                                          <div className="buy-page-coins-button-text">
                                            0.85 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money1)}>
                                        <div className="buy-page-coins-button-text">
                                          0.85 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={`${isFullscreen ? constants.SCREEN_MODE_TYPE.xs : constants.SCREEN_MODE_TYPE.none}`} sm={`${isFullscreen ? constants.SCREEN_MODE_TYPE.sm : constants.SCREEN_MODE_TYPE.none}`} md={`${isFullscreen ? constants.SCREEN_MODE_TYPE.md : constants.SCREEN_MODE_TYPE.none}`} lg="">
                            <Row>
                              <Col sm="12">
                                <div className="buy-page-coins-imageWrapper">
                                  <div className="buy-page-coins-background" />
                                  <Media className="buy-page-coins-image2" src={coins2} alt="" />
                                  <Media className="buy-page-coins-bonus-image" src={bonus1} alt="" />

                                  <div
                                    className="glitter-anim-image"
                                    style={{ top: 38, left: 21 }}
                                  >
                                    <Lottie
                                      options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: glitterAnimImg,
                                        rendererSettings: {
                                          preserveAspectRatio: 'xMidYMid slice',
                                        },
                                      }}
                                      height={57}
                                      width={57}
                                      isStopped={false}
                                      isPaused={pauseGlitter[1]}
                                    />
                                  </div>

                                </div>
                                <div className="buy-page-coins-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money2') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money2, discounts[0], processing)
                                    )
                                  }
                                  <Media src={coinImg} className="buy-page-coins-coin" />
                                  <div className="buy-page-coins-amount">
                                    500
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money2'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money2, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                                          <div className="buy-page-coins-button-text">
                                            1.41 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money2)}>
                                        <div className="buy-page-coins-button-text">
                                          1.41 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={`${isFullscreen ? constants.SCREEN_MODE_TYPE.xs : constants.SCREEN_MODE_TYPE.none}`} sm={`${isFullscreen ? constants.SCREEN_MODE_TYPE.sm : constants.SCREEN_MODE_TYPE.none}`} md={`${isFullscreen ? constants.SCREEN_MODE_TYPE.md : constants.SCREEN_MODE_TYPE.none}`} lg="">
                            <Row>
                              <Col sm="12">
                                <div className="buy-page-coins-imageWrapper">
                                  <div className="buy-page-coins-background" />
                                  <Media className="buy-page-coins-image3" src={coins3} alt="" />
                                  <Media className="buy-page-coins-bonus-image" src={bonus2} alt="" />

                                  <div
                                    className="glitter-anim-image"
                                    style={{ top: 33, left: 8 }}
                                  >
                                    <Lottie
                                      options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: glitterAnimImg,
                                        rendererSettings: {
                                          preserveAspectRatio: 'xMidYMid slice',
                                        },
                                      }}
                                      height={72}
                                      width={72}
                                      isStopped={false}
                                      isPaused={pauseGlitter[2]}
                                    />
                                  </div>

                                </div>
                                <div className="buy-page-coins-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money3') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money3, discounts[0], processing)
                                    )
                                  }
                                  <Media src={coinImg} className="buy-page-coins-coin" />
                                  <div className="buy-page-coins-amount">
                                    1500
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money3'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money3, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                                          <div className="buy-page-coins-button-text">
                                            2.85 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money3)}>
                                        <div className="buy-page-coins-button-text">
                                          2.85 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={`${isFullscreen ? constants.SCREEN_MODE_TYPE.xs : constants.SCREEN_MODE_TYPE.none}`} sm={`${isFullscreen ? constants.SCREEN_MODE_TYPE.sm : constants.SCREEN_MODE_TYPE.none}`} md={`${isFullscreen ? constants.SCREEN_MODE_TYPE.md : constants.SCREEN_MODE_TYPE.none}`} lg="">
                            <Row>
                              <Col sm="12">
                                <div className="buy-page-coins-imageWrapper">
                                  <div className="buy-page-coins-background" />
                                  <Media className="buy-page-coins-image4" src={coins4} alt="" />
                                  <Media className="buy-page-coins-bonus-image" src={bonus3} alt="" />

                                  <div
                                    className="glitter-anim-image"
                                    style={{ top: 19, left: 9 }}
                                  >
                                    <Lottie
                                      options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: glitterAnimImg,
                                        rendererSettings: {
                                          preserveAspectRatio: 'xMidYMid slice',
                                        },
                                      }}
                                      height={87}
                                      width={87}
                                      isStopped={false}
                                      isPaused={pauseGlitter[3]}
                                    />
                                  </div>

                                </div>
                                <div className="buy-page-coins-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money4') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money4, discounts[0], processing)
                                    )
                                  }
                                  <Media src={coinImg} className="buy-page-coins-coin" />
                                  <div className="buy-page-coins-amount">
                                    4000
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money4'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money4, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                                          <div className="buy-page-coins-button-text">
                                            4.27 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money4)}>
                                        <div className="buy-page-coins-button-text">
                                          4.27 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={`${isFullscreen ? constants.SCREEN_MODE_TYPE.xs : constants.SCREEN_MODE_TYPE.none}`} sm={`${isFullscreen ? constants.SCREEN_MODE_TYPE.sm : constants.SCREEN_MODE_TYPE.none}`} md={`${isFullscreen ? constants.SCREEN_MODE_TYPE.md : constants.SCREEN_MODE_TYPE.none}`} lg="">
                            <Row>
                              <Col sm="12">
                                <div className="buy-page-coins-imageWrapper">
                                  <div className="buy-page-coins-background" />
                                  <Media className="buy-page-coins-image5" src={coins5} alt="" />
                                  <Media className="buy-page-coins-bonus-image" src={bonus4} alt="" />

                                  <div
                                    className="glitter-anim-image"
                                    style={{ top: 18, left: -14 }}
                                  >
                                    <Lottie
                                      options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: glitterAnimImg,
                                        rendererSettings: {
                                          preserveAspectRatio: 'xMidYMid slice',
                                        },
                                      }}
                                      height={102}
                                      width={102}
                                      isStopped={false}
                                      isPaused={pauseGlitter[4]}
                                    />
                                  </div>

                                </div>
                                <div className="buy-page-coins-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money5') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money5, discounts[0], processing)
                                    )
                                  }
                                  <Media src={coinImg} className="buy-page-coins-coin" />
                                  <div className="buy-page-coins-amount">
                                    15000
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money5'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money5, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                                          <div className="buy-page-coins-button-text">
                                            14.23 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-coins-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money5)}>
                                        <div className="buy-page-coins-button-text">
                                          14.23 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId={constants.MENU_NAVIGATION.buyPagePremiumPacks} className="buy-page-tab">
                      <div className="buy-page-block-container">
                        <Row className="d-flex flex-column align-items-center buy-page-end-time-section mt-2">
                          {
                            discounts.length > 0 && (includes(discounts[0].selectedDiscountPackage, 'money6') || includes(discounts[0].selectedDiscountPackage, 'money7') || includes(discounts[0].selectedDiscountPackage, 'money8') || includes(discounts[0].selectedDiscountPackage, 'hideAdsMonth') || includes(discounts[0].selectedDiscountPackage, 'hideAdsYear')) && map(discounts, item => (
                              <Col className="d-flex flex-row align-items-center">
                                <Timer item={item} handleCurState={this.handleCurState} curState={curState} />
                              </Col>
                            ))
                          }
                        </Row>
                        <Row style={{margin: '0px 10px'}}>
                          <Col>
                            <Row>
                              <Col xs="12">
                                <div className="buy-page-premium-imageWrapper">
                                  <Media className="buy-page-premium-image1" src={balanceResetImg} alt="" />
                                </div>
                                <div className="buy-page-premium-wrapper">
                                  <div className="buy-page-premium-amount">
                                    {t('buyPage.balanceReset')}
                                  </div>
                                  <div onMouseEnter={() => this.mouseEnter(constants.SHOP_PRODUCT_NAMES.money6)} onMouseLeave={() => this.mouseLeave(constants.SHOP_PRODUCT_NAMES.money6)} className="buy-page-info-imageWrapper">
                                    <Media className="buy-page-info-image" src={infoImg} alt="" />
                                    <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.money6 ? null : 'display-none'}`}>
                                      {t('buyPage.balanceResetDesc')}
                                    </div>
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money6') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money6, discounts[0], processing)
                                    )
                                  }
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money6'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money6, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing || (!balance || (balance > 0))} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                                          <div className="buy-page-premium-button-text">
                                            5.00 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing || (!balance || (balance > 0))} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money6)}>
                                        <div className="buy-page-premium-button-text">
                                          5.00 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col xs="12">
                                <div className="buy-page-premium-imageWrapper">
                                  <Media className="buy-page-premium-image2" src={pointsResetImg} alt="" />
                                </div>
                                <div className="buy-page-premium-wrapper">
                                  <div className="buy-page-premium-amount">
                                    {t('buyPage.pointsReset')}
                                  </div>
                                  <div onMouseEnter={() => this.mouseEnter(constants.SHOP_PRODUCT_NAMES.money7)} onMouseLeave={() => this.mouseLeave(constants.SHOP_PRODUCT_NAMES.money7)} className="buy-page-info-imageWrapper">
                                    <Media className="buy-page-info-image" src={infoImg} alt="" />
                                    <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.money7 ? null : 'display-none'}`}>
                                      {t('buyPage.pointsResetDesc')}
                                    </div>
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money7') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money7, discounts[0], processing)
                                    )
                                  }
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money7'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money7, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing || (!totalPoints || (totalPoints > 0))} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                                          <div className="buy-page-premium-button-text">
                                            5.00 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing || (!totalPoints || (totalPoints > 0))} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money7)}>
                                        <div className="buy-page-premium-button-text">
                                          5.00 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col xs="12">
                                <div className="buy-page-premium-imageWrapper">
                                  <Media className="buy-page-premium-image3" src={profileResetImg} alt="" />
                                </div>
                                <div className="buy-page-premium-wrapper">
                                  <div className="buy-page-premium-amount">
                                    {t('buyPage.profileRestart')}
                                  </div>
                                  <div onMouseEnter={() => this.mouseEnter(constants.SHOP_PRODUCT_NAMES.money8)} onMouseLeave={() => this.mouseLeave(constants.SHOP_PRODUCT_NAMES.money8)} className="buy-page-info-imageWrapper">
                                    <Media className="buy-page-info-image" src={infoImg} alt="" />
                                    <div className={`buy-page-info-hover-2 ${activeHover === constants.SHOP_PRODUCT_NAMES.money8 ? null : 'display-none'}`}>
                                      {t('buyPage.profileRestartDesc')}
                                    </div>
                                  </div>
                                </div>
                                <div className="buy-page-coins-button-wrapper">
                                  {
                                    discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money8') && (
                                      this.renderBonus(constants.SHOP_PRODUCT_NAMES.money8, discounts[0], processing)
                                    )
                                  }
                                  {
                                    discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                      (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money8'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                        this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money8, discounts[0], processing)
                                      ) : (
                                        <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                                          <div className="buy-page-premium-button-text">
                                            5.00 EUR
                                          </div>
                                        </Button>
                                      )
                                    ) : (
                                      <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.money8)}>
                                        <div className="buy-page-premium-button-text">
                                          5.00 EUR
                                        </div>
                                      </Button>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          {!config.isInAppFrame() ? (
                            <Col>
                              <Row>
                                <Col xs="12">
                                  <div className="buy-page-premium-imageWrapper">
                                    <Media className="buy-page-premium-image1" src={hideAdsMonth} alt="" />
                                  </div>
                                  <div className="buy-page-premium-wrapper">
                                    <div className="buy-page-premium-amount">
                                      {`${t('buyPage.hideAdsMonth')}`}
                                      {adsHidden ? (
                                        <>
                                          <span> {`(${t('buyPage.activeUntil')}`} </span>
                                          <span>
                                            <Moment format="DD.MM.YYYY" locale="lv">{adsHidden}</Moment>
                                          </span>
                                          <span>)</span>
                                        </>
                                      ) : ''}
                                    </div>
                                    <div onMouseEnter={() => this.mouseEnter(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)} onMouseLeave={() => this.mouseLeave(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)} className="buy-page-info-imageWrapper no-margin">
                                      <Media className="buy-page-info-image" src={infoImg} alt="" />
                                      <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.hideAdsMonth ? null : 'display-none'}`}>
                                        {t('buyPage.hideAdsMonthDesc')}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="buy-page-coins-button-wrapper">
                                    {
                                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'hideAdsMonth') && (
                                        this.renderBonus(constants.SHOP_PRODUCT_NAMES.hideAdsMonth, discounts[0], processing)
                                      )
                                    }
                                    {
                                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['hideAdsMonth'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.hideAdsMonth, discounts[0], processing)
                                        ) : (
                                            <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                                            <div className="buy-page-premium-button-text">
                                              3.00 EUR
                                            </div>
                                          </Button>
                                        )
                                      ) : (
                                          <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsMonth)}>
                                          <div className="buy-page-premium-button-text">
                                            3.00 EUR
                                          </div>
                                        </Button>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          ):(null)}


                          {!config.isInAppFrame() ? (
                            <Col>
                              <Row>
                                <Col xs="12">
                                  <div className="buy-page-premium-imageWrapper">
                                    <Media className="buy-page-premium-image1" src={hideAdsYear} alt="" />
                                  </div>
                                  <div className="buy-page-premium-wrapper">
                                    <div className="buy-page-premium-amount">
                                      {`${t('buyPage.hideAdsYear')}`}
                                      {adsHidden ? (
                                        <>
                                          <span> {`(${t('buyPage.activeUntil')}`} </span>
                                          <span>
                                            <Moment format="DD.MM.YYYY" locale="lv">{adsHidden}</Moment>
                                          </span>
                                          <span>)</span>
                                        </>
                                      ) : ''}
                                    </div>
                                    <div onMouseEnter={() => this.mouseEnter(constants.SHOP_PRODUCT_NAMES.hideAdsYear)} onMouseLeave={() => this.mouseLeave(constants.SHOP_PRODUCT_NAMES.hideAdsYear)} className="buy-page-info-imageWrapper no-margin">
                                      <Media className="buy-page-info-image" src={infoImg} alt="" />
                                      <div className={`buy-page-info-hover ${activeHover === constants.SHOP_PRODUCT_NAMES.hideAdsYear ? null : 'display-none'}`}>
                                        {t('buyPage.hideAdsYearDesc')}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="buy-page-coins-button-wrapper">
                                    {
                                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'hideAdsYear') && (
                                        this.renderBonus(constants.SHOP_PRODUCT_NAMES.hideAdsYear, discounts[0], processing)
                                      )
                                    }
                                    {
                                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['hideAdsYear'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.hideAdsYear, discounts[0], processing)
                                        ) : (
                                            <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                                            <div className="buy-page-premium-button-text">
                                              25.00 EUR
                                            </div>
                                          </Button>
                                        )
                                      ) : (
                                          <Button disabled={processing} color="link" className="buy-page-premium-button" onClick={() => this.buyMoney(constants.SHOP_PRODUCT_NAMES.hideAdsYear)}>
                                          <div className="buy-page-premium-button-text">
                                            25.00 EUR
                                          </div>
                                        </Button>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          ):(null)}

                        </Row>
                      </div>
                    </TabPane>
                    {/*  ):(
                    <TabPane tabId="2" className="buy-page-tab">
                      <div className="buy-page-block-container">
                        <div className="buy-page-block-background" />
                      </div>
                    </TabPane>
                  )} */}
                    <TabPane tabId={constants.MENU_NAVIGATION.buyPageHistory} className="buy-page-tab">
                      <div className="buy-page-block-container" style={{ height: isFullscreen ? '60vh' : '300px', minHeight: isFullscreen ? '250px' : '300px' }}>
                        <Row className="money-history-table-header pb-2">
                          <Col sm="3" className="money-history-table-header-col">
                            {t('moneyHistory.time')}
                          </Col>
                          <Col sm="2" className="money-history-table-header-col">
                            {t('moneyHistory.purchase')}
                          </Col>
                          <Col sm="3" className="money-history-table-header-col">
                            {t('moneyHistory.amount')}
                          </Col>
                          <Col sm="2" className="money-history-table-header-col">
                            {t('moneyHistory.paymentMethod')}
                          </Col>
                          <Col sm="2" className="money-history-table-header-col">
                            {t('moneyHistory.status')}
                          </Col>
                        </Row>
                        {/*  <ScrollAreaButtons
                          scrollPos={scrollPos}
                        //  show
                          show={historyData && Object.keys(historyData).length > 7 ? true : null}
                          scroll={this.scroll}
                          speed={30}
                          rightOffset={-1}
                          bottomOffset={-32}
                          topOffset={16}
                        />
                        <ScrollArea
                          speed={0.65}
                          className="chat-body-scroll-area"
                          contentClassName="online-users-ReactTableContainer"
                        //  onScroll={this.handleScroll}
                          smoothScrolling
                          stopScrollPropagation
                          verticalContainerStyle={{
                            background: 'transparent',
                            opacity: 1,
                            width: 11,
                          }}
                          verticalScrollbarStyle={{
                            background: '#fff',
                            borderRadius: 1,
                            width: 10,
                            minScrollSize: 25,
                          }}
                          horizontal={false}
                          onScroll={this.updateScrollPos}
                          ref={(el) => { this.messagesScrollbar = el; }}
                        > */}

                        <ScrollAreaWrapper
                          className="layout-body-main-scrollarea weekly-status-scrollbar h-100"
                          contentClassName="online-users-ReactTableContainer"
                          show={historyData && size(historyData) > 7 ? true : null}
                          rightOffset={-1}
                          topOffset={23}
                          bottomOffset={-24}
                          disableAutoHeight={historyData && historyData.length === 0}
                        >
                          {(historyData && historyData.length > 0) ? historyData.sort((a, b) => (b.dateInitiated - a.dateInitiated)).map((data, index) => (
                            <Row key={data.key} className={`buy-page-history-row ${index % 2 === 0 ? 'odd' : 'even'} pt-2 pb-1`}>
                              <Col sm="3" className="money-history-table-col">
                                <CustomDate
                                  format={constants.FORMAT_DATE_TYPE.pointDate}
                                  date={data.dateInitiated}
                                />
                              </Col>
                              <Col sm="2" className="money-history-table-col">
                                {
                                  constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].price
                                }
                              </Col>
                              <Col sm="3" className="money-history-table-col">
                                {(constants.SHOP_PRODUCT_TYPES && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id) && (
                                  <>
                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money6) && (
                                      t('buyPage.balanceReset')
                                    )}
                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money7) && (
                                      t('buyPage.pointsReset')
                                    )}
                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money8) && (
                                      t('buyPage.profileRestart')
                                    )}
                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money9) && (
                                      t('buyPage.paymentUnBan')
                                    )}
                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.hideAdsMonth) && (
                                      `${t('buyPage.hideAdsMonth')}`
                                    )}

                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.hideAdsYear) && (
                                      `${t('buyPage.hideAdsYear')}`
                                    )}

                                    {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money1
                                      || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money2
                                      || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money3
                                      || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money4
                                      || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money5) && (
                                      <div className="buy-page-coins-wrapper">
                                        <Media src={coinImg} className="buy-page-coins-coin" />
                                        <div className="buy-page-coins-amount">
                                          {constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].coin}
                                        </div>
                                      </div>
                                    )}
                                      

                                    {/* {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money6
                                        && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money7
                                        && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money8
                                        && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money9) ?
                                        (
                                          <div className="buy-page-coins-wrapper">
                                            <Media src={coinImg} className="buy-page-coins-coin" />
                                            <div className="buy-page-coins-amount">
                                              {constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].coin}
                                            </div>
                                          </div>
                                        )
                                        : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money6 ? (
                                          t('buyPage.balanceReset')
                                        )
                                          : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money7 ? (
                                            t('buyPage.pointsReset')
                                          )
                                            : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money8 ? (
                                              t('buyPage.profileRestart')
                                            )
                                              : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money9 ? (
                                                t('buyPage.paymentUnBan')
                                              ) : (null)} */}
                                    </>
                                  )}
                              </Col>
                              <Col sm="2" className="money-history-table-col">
                                {(data.provider === constants.SOC_PROVIDERS.facebook
                                    && t('buyPage.facebook'))
                                || (data.provider === constants.SOC_PROVIDERS.draugiem
                                  && t('buyPage.draugiem'))
                                  || (data.provider === constants.SOC_PROVIDERS.inbox
                                  && t('buyPage.inbox'))
                                || (data.provider === constants.SOC_PROVIDERS.mobile
                                    && t('buyPage.mobile'))
                                || (data.provider === constants.SOC_PROVIDERS.stripe
                                    && t('buyPage.stripe'))
                                || (data.provider === constants.SOC_PROVIDERS.everyPay
                                  && data.paymentOptionName)
                                }
                              </Col>
                              <Col sm="2" className="money-history-table-col">
                                { data.status === constants.FROUNT_SIDE_STATUS.completed
                                  ? t('buyPage.finished')
                                  : t('buyPage.unfinished')
                                }
                              </Col>
                            </Row>
                          )) : <div className="d-flex align-items-center h-100 shop-transactions-history-empty-row">{t('buyPage.purchaseHistoryNoEntries')}</div>
                        }
                        </ScrollAreaWrapper>
                      </div>
                    </TabPane>
                    <TabPane tabId={constants.MENU_NAVIGATION.buyPageRedeemCode} className="buy-page-tab">
                      <div className="buy-page-block-container gift-code-container">
                        <Row>
                          <Col className="buy-page-block-container-center">
                            <div className="buy-page-gift-message gift-message-box">
                              <Row>
                                <Col>
                                  <Label className="text-center">
                                    {loading ? t('home.loading') : (giftStatus === constants.FROUNT_SIDE_STATUS.completed ? (
                                      <>
                                        <p>
                                          {
                                            giftMessage === errorMessages.verifySuccessMessage ? (
                                              t(`common.${giftMessage}`, { code: selectCode })
                                            ) : giftMessage === errorMessages.claimSuccessMessage ? (
                                              t(`common.${giftMessage}`, { code: selectCode })
                                            ) : (
                                              t(`common.${giftMessage}`)
                                            )
                                          }
                                        </p>
                                        <p>{t('common.claimSuccessMessageMore', { amount: selectValue })}</p>
                                      </>
                                    ) : (
                                      giftMessage === errorMessages.verifySuccessMessage ? (
                                        t(`common.${giftMessage}`, { amount: selectValue })
                                      ) : giftMessage === errorMessages.claimSuccessMessage ? (
                                        t(`common.${giftMessage}`, { amount: selectValue })
                                      ) : (
                                        t(`common.${giftMessage}`)
                                      )
                                    )) }
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                            <div className="buy-page-gift-code">
                              <Row>
                                <Col>
                                  <Input
                                    className="gift-code-input"
                                    type="text"
                                    id="code"
                                    name="code"
                                    placeholder={t('common.enterGiftCode')}
                                    disabled={loading || giftStatus === constants.FROUNT_SIDE_STATUS.success}
                                    value={code}
                                    onChange={this.handleChange}
                                    innerRef={this.codeRef}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="buy-page-gift-sumit">
                              <Row>
                                <Col>
                                  <Button disabled={loading} color="link" className="buy-page-premium-button full-width" onClick={this.verifyGift}>
                                    {loading ? t('home.loading') : t('common.redeemGiftCode')}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId={constants.MENU_NAVIGATION.buyPageDiscountEvent} className="buy-page-tab">
                      <Discounts parentActiveTab={parentActiveTab} activeTab={parentActiveTab} />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              {/* <Row className="buy-page-send">
                <Col>
                  <InviteFriend socProvider={socProvider} />
                </Col>
              </Row> */}
            </Col>
            {socProvider && socProvider === constants.SOC_PROVIDERS.inbox ? (
              <Modal container={'div > div'} returnFocusAfterClose={false} isOpen={showInboxPayment} size="xl" className={`inbox-payment-modal old-modal root-font-${fontMode}`} toggle={this.closeInboxModal} >
                <ModalHeader
                  toggle={this.closeInboxModal}
                  close={
                    <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeInboxModal} />
                  }
                />
                <ModalBody>
                  <Iframe
                    url={inboxIframeLink}
                    width="100%"
                    height="630px"
                    id="inboxPaymentIframe"
                    className="inboxPaymentIframe"
                    display="initial"
                    position="relative"
                  />
                </ModalBody>
              </Modal>
            ) : (null)}

            <Modal container={'div > div'} size="lg" className={`friends-modal old-modal root-font-${fontMode}`} isOpen={openHistory} toggle={this.closeHistory}>
              <ModalHeader
                className="friends-modal-header"
                toggle={this.closeHistory}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeHistory} />
                }
              >
                {t('common.transactionsHistory')}
              </ModalHeader>
              <ModalBody className="friends-modal-body">
                <Row className="money-history-table-header">
                  <Col sm="3" className="money-history-table-header-col">
                    {t('moneyHistory.time')}
                  </Col>
                  <Col sm="2" className="money-history-table-header-col">
                    {t('moneyHistory.purchase')}
                  </Col>
                  <Col sm="3" className="money-history-table-header-col">
                    {t('moneyHistory.amount')}
                  </Col>
                  <Col sm="2" className="money-history-table-header-col">
                    {t('moneyHistory.paymentMethod')}
                  </Col>
                  <Col sm="2" className="money-history-table-header-col">
                    {t('moneyHistory.status')}
                  </Col>
                </Row>
                {historyData ? historyData.sort((a, b) => (b.dateInitiated - a.dateInitiated)).map((data, index) => (
                  <Row key={data.key} className={classNames('buy-page-history-row', { 'odd': index % 2 === 0, 'even': index % 2 !== 0 })}>
                    <Col sm="3" className="money-history-table-col">
                      <CustomDate
                        format={constants.FORMAT_DATE_TYPE.fullDateMinute}
                        date={data.dateInitiated}
                      />
                    </Col>
                    <Col sm="2" className="money-history-table-col">
                      {
                        constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].price
                      }
                    </Col>

                    <Col sm="3">
                      {(constants.SHOP_PRODUCT_TYPES && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id) && (
                        <>
                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money6) && (
                            t('buyPage.balanceReset')
                          )}
                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money7) && (
                            t('buyPage.pointsReset')
                          )}
                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money8) && (
                            t('buyPage.profileRestart')
                          )}
                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money9) && (
                            t('buyPage.paymentUnBan')
                          )}
                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.hideAdsMonth) && (
                            t('buyPage.hideAdsMonth')
                          )}

                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.hideAdsYear) && (
                            t('buyPage.hideAdsYear')
                          )}

                          {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money1
                            || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money2
                            || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money3
                            || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money4
                            || constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money5) && (
                              <div className="buy-page-coins-wrapper">
                                <Media src={coinImg} className="buy-page-coins-coin" />
                                <div className="buy-page-coins-amount">
                                  {constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].coin}
                                </div>
                              </div>
                            )}

                        {/* {(constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money6
                          && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money7
                          && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money8
                          && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money9) ?
                            (
                              <div className="buy-page-coins-wrapper">
                                <Media src={coinImg} className="buy-page-coins-coin" />
                                <div className="buy-page-coins-amount">
                                  {constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].coin}
                                </div>
                              </div>
                            )
                              : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money6 ? (
                                t('buyPage.balanceReset')
                              )
                                : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money7 ? (
                                  t('buyPage.pointsReset')
                                )
                                  : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money8 ? (
                                    t('buyPage.profileRestart')
                                  )
                                    : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money9 ? (
                                      t('buyPage.paymentUnBan')
                                    ) : (null)} */}
                      </>
                    )}
                    </Col>

                    {/*
                    <Col sm="3" className="money-history-table-col">
                      {
                        constants.SHOP_PRODUCT_TYPES && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id && (
                          constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money6
                          && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money7
                          && constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id !== constants.SHOP_PRODUCT_NAMES.money8
                          && data.productNr !== constants.SHOP_PRODUCT_NAMES.money9 ?
                            (
                              <div className="buy-page-coins-wrapper">
                                <Media src={coinImg} className="buy-page-coins-coin" />
                                <div className="buy-page-coins-amount">
                                  {constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].coin}
                                </div>
                              </div>
                            )
                            : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money6 ? (
                              t('buyPage.balanceReset')
                            )
                              : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money7 ? (
                                t('buyPage.pointsResetbuyPage')
                              )
                                : constants.SHOP_PRODUCT_TYPES.filter(productType => productType.id === data.productNr)[0].id === constants.SHOP_PRODUCT_NAMES.money8 ? (
                                  t('buyPage.profileRestart')
                                ) : data.productNr === constants.SHOP_PRODUCT_NAMES.money9 ? (
                                  t('buyPage.paymentUnBan')
                                ) : (null)
                        )}
                    </Col> */}
                    <Col sm="2" className="money-history-table-col">
                      {(data.provider === constants.SOC_PROVIDERS.facebook
                          && t('buyPage.facebook'))
                      || (data.provider === constants.SOC_PROVIDERS.draugiem
                          && t('buyPage.draugiem'))
                      || (data.provider === constants.SOC_PROVIDERS.mobile
                          && t('buyPage.mobile'))
                      || (data.provider === constants.SOC_PROVIDERS.stripe
                          && t('buyPage.stripe'))
                      }
                    </Col>
                    <Col sm="2" className="money-history-table-col">
                      {(data.status === constants.FROUNT_SIDE_STATUS.initiated
                          && t('buyPage.unfinished'))
                      || (data.status === constants.FROUNT_SIDE_STATUS.completed
                          && t('buyPage.finished'))
                      }
                    </Col>
                  </Row>
                )) : null}

              </ModalBody>
              <ModalFooter className="friends-modal-footer">
                <Button color="link" className="my-info-header-button" style={{ width: '30%' }} onClick={this.closeHistory}>{t('common.close')}</Button>
              </ModalFooter>
            </Modal>
            <Modal container={'div > div'} isOpen={redeemGiftModal} toggle={this.toggleRedeemGift} className={`old-modal root-font-${fontMode}`} size="lg">
              <ModalHeader
                toggle={this.toggleRedeemGift}
                close={
                  <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleRedeemGift} />
                }
              >
                {t('common.redeemGiftCode')}
              </ModalHeader>
              <ModalBody>
                {
                  !redeemFlag ? (
                    <div className="redeem-code-section">
                      <div className="redeem-code-header">
                        <div>{t('common.code')}</div>
                        <div>{t('common.value')}</div>
                        <div />
                      </div>
                      <div className="redeem-code-body">
                        <div>{selectCode}</div>
                        <div>{selectValue}</div>
                        <div>
                          <Button color="link" className="buy-page-premium-button full-width" onClick={() => this.claimGift()}>
                            {t('common.redeemGiftCode')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="redeem-code-section">
                      {t('common.claimSuccess', { code: selectCode, amount: selectValue })}
                    </div>
                  )
                }
              </ModalBody>
            </Modal>
          </Row>
        </Fragment>
        <Modal container={'div > div > div'} isOpen={buyModal} size="lg" toggle={this.toggleBuyModal} className={`old-modal modal-vertically-centered root-font-${fontMode}`}>
          <ModalHeader
            toggle={this.toggleBuyModal}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleBuyModal} />
            }
          >
            {t('paymentType.chooseTitle')}
          </ModalHeader>
          <ModalBody>
            <PaymentTypeContent
              selectedProduct={selectedProduct}
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={this.onPaymentOptionClick}
              checkedTerms={checkedTerms}
              toggleCheckedTerms={this.toggleCheckedTerms}
              fontMode={fontMode}
              t={t}
              devMode={devMode}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={this.pay} disabled={!isOnline}>
              {t('common.continue')}
            </Button>
            <hr className="payment-type-line-normal" />
            <p className="payment-type-footnote-text-normal">SIA 'Draxo Games'</p>
            <p className="payment-type-footnote-text-normal">Liepājas 49-6, Kuldīga</p>
            <p className="payment-type-footnote-text-normal">Latvija</p>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div > div'} isOpen={paymentErrorModal} size="md" toggle={() => this.togglePaymentErrorModal('')} className={`old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={() => this.togglePaymentErrorModal('')}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.togglePaymentErrorModal('')} />
            }
          >
            {t('common.zoleInfo')}
          </ModalHeader>
          <ModalBody className="text-center">
            {paymentError}
          </ModalBody>
          <ModalFooter>
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.togglePaymentErrorModal('')}>
              {t('common.ok')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div > div'} isOpen={smsPaymentModal} size="md" toggle={() => this.toggleSmsPaymentModal()} className={`old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={() => this.toggleSmsPaymentModal('')}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleSmsPaymentModal('')} />
            }
          >
            {t('common.zoleInfo')}
          </ModalHeader>
          <ModalBody className="text-center">
            <div className="payment-type-scrollarea">
              <div className="d-flex justify-content-center flex-column payment-type-message">
                <div className="d-flex justify-content-center">
                  {t('paymentType.youArePurchasing')}
                  {selectedProduct.type === 'gameMoney' ? (
                    <div className='payment-type-message-coin-wrapper'>
                      <Media src={coinImg} className="payment-type-message-coin" />
                      <span className="payment-type-coin-amount">{selectedProduct.amount}</span>
                      {' '}
                    </div>
                  ) : (
                    t(`paymentType.${selectedProduct.type}`)
                  )}

                  &nbsp;{t('paymentType.for')}
                  {
                    selectedProduct.discountType &&
                      (selectedProduct.discountType === constants.SPECIALPRICE || selectedProduct.discountType === constants.DISCOUNTPRICE) &&
                      selectedProduct.realPrice ? (
                        <>
                          &nbsp;
                          <span className="payment-type-old-price">
                            {selectedProduct.price}
                            &nbsp;
                            EUR
                          </span>
                          &nbsp;
                          <span className="payment-type-new-price">
                            {selectedProduct.realPrice}
                            &nbsp;EUR
                          </span>
                        </>
                      ) : selectedProduct.discountType && selectedProduct.discountType === constants.BONUS && selectedProduct.realPrice ? (
                        <>
                          &nbsp;
                          <span className="payment-type-price">
                            {selectedProduct.price}
                            &nbsp;
                            EUR
                          </span>
                          &nbsp;
                          <span className="payment-type-new-price">
                            {`+${selectedProduct.realPrice}`}
                            <Media src={coinImg} className="buy-page-coins-coin" />
                          </span>
                        </>
                      ) : (
                        <>
                          &nbsp;{selectedProduct.price}
                          &nbsp;EUR
                        </>
                      )
                  }
                </div>
                <div>
                  {selectedProduct.descriptionId && (
                    t(selectedProduct.descriptionId)
                  )}
                </div>
              </div>

              <Row className="payment-type-row d-flex align-items-center">
                <Media src={mobileImg} />
              </Row>
              <Row className="payment-type-row pt-1">
                <Col className="payment-type-col">
                    {t('paymentType.smsInfo1')}
                </Col>
              </Row>
              <Row className="payment-type-row pt-1">
                <Col className="payment-type-col">
                    {t('paymentType.smsInfo2', {price: selectedProduct.price})}
                </Col>
              </Row>
              <Row className="payment-type-row pt-2">
                <Col className="payment-type-col">
                  {t('paymentType.smsInfo3')}
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.toggleSmsPaymentModal('')}>
              {t('common.ok')}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  // userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  socProvider: state.member.socProvider,
  balance: parseFloat(state.member.balance),
  totalPoints: state.member.totalPoints,
  uid: state.member.uid || '',
  adsHidden: state.member.adsHidden || null,
  discounts: state.shop.discounts || {},
  lastShopTab: state.shop.lastShopTab || null,
  transactionComplete: state.shop.transactionComplete || false,
});

const mapDispatchToProps = {
  initFBPay: initFBPayment,
  fbCallback: fbPaymentCallback,
  initDraugiemPay: initDraugiemPayment,
  initInboxPay: initInboxPayment,
  initStripePay: initStripePayment,
  initEveryPay: initEveryPayPayment,
  claimGift: claimGiftCode,
  verifyGift: verifyGiftCode,
  fetchTransactionsHistory: getTransactionsHistory,
  fetchDiscounts: getDiscounts,
  setLastShopTabFunc: setLastShopTab,
  setTransactionCompleteFunc: setTransactionComplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(BuyPage));
