import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Spinner from 'reactstrap/lib/Spinner';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';
import { map, filter } from 'lodash';

import CampaignStatistics from './CampaignStatistics';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';

import {
  getAllCampaigns,
  addNewCampaign,
  supportMessageFileUpload,
  deleteCampaign,
  editSelectCampaign,
  getCampaignHistory,
  getCampaignShowedHistory,
  getSingleCampaignShowedHistory,
  getSingleCampaignShowedHistory2,
  closeSingleCampaignShowedHistory,
} from '../../../actions/admin';
import { CAMPAIGN_LOCATIOIN_OPTIONS, STATISTICS_DESIGN_MODE, STATISTICS_ENV_MODE, CAMPAIGN_PLATFORMS, STATISTICS_SCREEN_SIZE_PLATFORM } from '../../../constants/statisticsTypes';
import FileUploadImage from './components/FileUploadImage';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class Campaigns extends React.Component {
  static propTypes = {
    loading: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    supportAMessageFileUpload: PropTypes.func.isRequired,
    allCampaigns: PropTypes.shape([]),
    fetchAllCampaigns: PropTypes.func.isRequired,
    addANewCampaign: PropTypes.func.isRequired,
    editASelectCampaign: PropTypes.func.isRequired,
    deleteACampaign: PropTypes.func.isRequired,
    fetchCampaignHistory: PropTypes.func.isRequired,
    fetchCampaignShowedHistory: PropTypes.func.isRequired,
    fetchSingleCampaignShowedHistory: PropTypes.func.isRequired,
    stopSingleCampaignShowedHistory: PropTypes.func.isRequired,
    campaignHistory: PropTypes.shape({}),
    campaignShowedHistory: PropTypes.shape({}),
  }

  static defaultProps = {
    allCampaigns: [],
    campaignHistory: {},
    campaignShowedHistory: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      filterType: 'ALL',
      currentPage: 0,
      pageSize: 50,
      preview: null,
      previewEn: null,
      previewRu: null,
      imageUrl: '',
      imageUrlEn: '',
      imageUrlRu: '',
      externalBanner: '',
      overflowBackgroundColor: null,
      title: '',
      startDate: new Date(),
      endDate: new Date(),
      pageToOpen: '',
      uploadFile: null,
      uploadFileEn: null,
      uploadFileRu: null,
      ableToSave: false,
      newCampaignModalOpen: false,
      deleteModal: false,
      selectCampaignId: '',
      openModal: false,
      oldData: {},
      selectedLocations: [],
      selectedDesigns: [],
      selectedEnvs: [],
      selectedPlatforms: [],
      adFormMeasure: '',
      statisticsCampaignId: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.hiddenFileInput = React.createRef(null);
    this.hiddenFileInputEn = React.createRef(null);
    this.hiddenFileInputRu = React.createRef(null);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.handleEditPhotoEn = this.handleEditPhotoEn.bind(this);
    this.handleEditPhotoRu = this.handleEditPhotoRu.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.onCropEn = this.onCropEn.bind(this);
    this.onCropRu = this.onCropRu.bind(this);
  }

  componentDidMount() {
    const { fetchAllCampaigns, fetchCampaignHistory, fetchCampaignShowedHistory } = this.props;
    const { filterType } = this.state;

    console.log('campaigns componentDidMount');

    fetchAllCampaigns(filterType);
    // fetchCampaignHistory();
    // fetchCampaignShowedHistory();
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  // edit existing admin functions
  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectCampaignId: key,
    }));
  }

  changePageToOpen = (e) => {
    this.setState({ pageToOpen: e.target.value });
  }

  deleteCampaign = () => {
    const { deleteACampaign, showNotification, t } = this.props;
    const { selectCampaignId } = this.state;

    if (selectCampaignId !== '') {
      deleteACampaign(selectCampaignId).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          return showNotification('Error', res.message, 'danger');
        }
        this.setState({
          deleteModal: false,
          selectCampaignId: '',
        });
      });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }


  openModal = (id) => {
    const { allCampaigns } = this.props;
    const selectEvent = allCampaigns[id];
    this.setState({
      selectCampaignId: allCampaigns[id].key,
      startDate: new Date(selectEvent?.startDate) || null,
      endDate: new Date(selectEvent?.endDate) || null,
      imageUrl: selectEvent?.imageUrl || '',
      imageUrlEn: selectEvent?.imageUrlEn || '',
      imageUrlRu: selectEvent?.imageUrlRu || '',
      externalBanner: selectEvent?.externalBanner || '',
      overflowBackgroundColor: selectEvent?.overflowBackgroundColor || null,
      title: selectEvent?.title || '',
      pageToOpen: selectEvent?.pageToOpen || '',
      adFormMeasure: selectEvent?.adFormMeasure || '',
      selectedLocations: selectEvent?.selectedLocations ? [...selectEvent?.selectedLocations] : [],
      selectedDesigns: selectEvent?.selectedDesigns ? [...selectEvent?.selectedDesigns] : [],
      selectedPlatforms: selectEvent?.selectedPlatforms ? [...selectEvent?.selectedPlatforms] : [],
      selectedEnvs: selectEvent?.selectedEnvs ? [...selectEvent?.selectedEnvs] : [],
      openModal: true,
      deleteModal: false,
      newCampaignModalOpen: false,
      oldData: allCampaigns[id],
    });
  }

  // Add new admin functions
  openAddEventModal = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      imageUrl: null,
      imageUrlEn: null,
      imageUrlRu: null,
      externalBanner: null,
      overflowBackgroundColor: null,
      title: null,
      preview: null,
      previewEn: null,
      previewRu: null,
      showCrop: false,
      showCropEn: false,
      showCropRu: false,
      selectedDesigns: [],
      selectedEnvs: [],
      selectedLocations: [],
      selectedPlatforms: [],
      ableToSave: false,
      pageToOpen: '',
      adFormMeasure: false,
      openModal: false,
      newCampaignModalOpen: true,
      oldData: {},
    });
  }

  closeNewEventModal = () => {
    this.setState({ newCampaignModalOpen: false });
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  initStateValue = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      imageUrl: null,
      imageUrlEn: null,
      imageUrlRu: null,
      externalBanner: null,
      overflowBackgroundColor: null,
      title: null,
      preview: null,
      previewEn: null,
      previewRu: null,
      showCrop: false,
      showCropEn: false,
      showCropRu: false,
      showInBanner: false,
      devMode: false,
      ableToSave: false,
      pageToOpen: '',
      adFormMeasure: '',
      selectedDesigns: [],
      selectedLocations: [],
      selectedEnvs: [],
      selectedPlatforms: [],
      newCampaignModalOpen: false,
      oldData: {},
    });
  }

  addNewCampaign = () => {
    const { addANewCampaign, showNotification, t } = this.props;
    const {
      startDate,
      endDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor,
      pageToOpen,
      showCrop,
      showCropEn,
      showCropRu,
      ableToSave,
      adFormMeasure,
      title,
    } = this.state;

    console.log(startDate,
      endDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      pageToOpen,
      showCrop,
      showCropEn,
      showCropRu,
      ableToSave,
      title,
      adFormMeasure, "checkDataFull");

    /* 
    if (!selectedEnvs || !selectedEnvs.length) {
      showNotification('Error', "Missed the Environments.", 'danger');
    }

    if (!selectedPlatforms || !selectedPlatforms.length) {
      showNotification('Error', "Missed the platforms.", 'danger');
    }

    if (!selectedDesigns || !selectedDesigns.length) {
      showNotification('Error', "Missed the designs.", 'danger');
    } */

    // return null;

    if (!showCrop && !showCropEn && !showCropRu && !ableToSave) {
      addANewCampaign({
        startDate, endDate, selectedLocations, selectedPlatforms, selectedDesigns, selectedEnvs, imageUrl, imageUrlEn, imageUrlRu, pageToOpen, adFormMeasure, externalBanner, overflowBackgroundColor, title,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', "Campaign successfully added.", 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLocations) {
            showNotification('Error', "Missed the pages to show the banner.", 'danger');
          } else if (res.message === errorMessages.missingPlatforms) {
            showNotification('Error', "Missed the platforms.", 'danger');
          } else if (res.message === errorMessages.missingDesigns) {
            showNotification('Error', "Missed the designs.", 'danger');
          } else if (res.message === errorMessages.missingEnvs) {
            showNotification('Error', "Missed the Environments.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the campaign image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new Campaign.", 'warning');
    }
  }

  editSelectCampaign = () => {
    const { editASelectCampaign, showNotification, t } = this.props;
    const {
      selectCampaignId,
      startDate,
      endDate,
      selectedDesigns,
      selectedLocations,
      selectedPlatforms,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor,
      pageToOpen,
      showCrop,
      showCropEn,
      showCropRu,
      ableToSave,
      adFormMeasure,
      oldData,
      title,
    } = this.state;

    console.log(startDate,
      endDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      pageToOpen,
      showCrop,
      showCropEn,
      showCropRu,
      ableToSave,
      title,
      adFormMeasure, selectCampaignId, "checkDataFull");

    if (!showCrop && !showCropEn && !showCropRu && !ableToSave) {
      editASelectCampaign({
        selectCampaignId, startDate, endDate, selectedLocations, selectedEnvs, selectedDesigns, selectedPlatforms, imageUrl, imageUrlEn, imageUrlRu, pageToOpen, oldData, adFormMeasure, externalBanner, overflowBackgroundColor, title,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Success', 'success');
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error', "Missed the data.", 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLocations) {
            showNotification('Error', "Missed the pages to show the banner.", 'danger');
          } else if (res.message === errorMessages.missingPlatforms) {
            showNotification('Error', "Missed the platforms.", 'danger');
          } else if (res.message === errorMessages.missingDesigns) {
            showNotification('Error', "Missed the designs.", 'danger');
          } else if (res.message === errorMessages.missingEnvs) {
            showNotification('Error', "Missed the Environments.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error', "Please upload the Campaign image.", 'danger');
          } else {
            showNotification('Error', res.message, 'danger');
          }
          return;
        }

        this.initStateValue();
        this.setState({
          openModal: false,
        });
      }).catch(error => showNotification('Error!', error.message, 'danger'));
    } else {
      showNotification('Warning', "If you don't apply or close uploaded image, you can't add new Campaign.", 'warning');
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  selectType = (e) => {
    const { fetchAllCampaigns } = this.props;
    this.setState({ filterType: e.target.value });

    fetchAllCampaigns(e.target.value);
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  onCropEn = (previewEn) => {
    this.setState({ previewEn });
  }

  onCropRu = (previewRu) => {
    this.setState({ previewRu });
  }

  handleEditPhoto = (value) => {
    console.log(value);
    this.setState({ showCrop: !value });
  }

  handleEditPhotoEn = (value) => {
    console.log(value);
    this.setState({ showCropEn: !value });
  }

  handleEditPhotoRu = (value) => {
    console.log(value);
    this.setState({ showCropRu: !value });
  }

  uploadCampaignFile = (imageType) => {
    const { supportAMessageFileUpload } = this.props;
    const { uploadFile, uploadFileEn, uploadFileRu } = this.state;

    this.setState({ ableToSave: true });
    if (imageType === 'LV') {

      if (uploadFile !== null) {
        supportAMessageFileUpload(uploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            imageUrl: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            preview: null,
            ableToSave: false,
          });
        });
      }
    } else if (imageType === 'EN') {
      if (uploadFileEn !== null) {
        supportAMessageFileUpload(uploadFileEn).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            imageUrlEn: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            previewEn: null,
            ableToSave: false,
          });
        });
      }
    } else {
      if (uploadFileRu !== null) {
        supportAMessageFileUpload(uploadFileRu).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            imageUrlRu: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            previewRu: null,
            ableToSave: false,
          });
        });
      }
    }
  }

  handleImageClick = () => {
    this.hiddenFileInput.current.click();
  };

  handleImageClickEn = () => {
    this.hiddenFileInputEn.current.click();
  };

  handleImageClickRu = () => {
    this.hiddenFileInputRu.current.click();
  };

  _handleImageChange = (e, imageType) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error!', "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      if (imageType === 'LV') {
        reader.onloadend = () => {
          this.setState({
            preview: reader.result,
            uploadFile: file,
          });
        };
      } else if (imageType === 'EN') {
        reader.onloadend = () => {
          this.setState({
            previewEn: reader.result,
            uploadFileEn: file,
          });
        };
      } else {
        reader.onloadend = () => {
          this.setState({
            previewRu: reader.result,
            uploadFileRu: file,
          });
        };
      }
      
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  }

  toggleShowStatisticsModal = (key) => {
    const { fetchSingleCampaignShowedHistory, campaignShowedHistory } = this.props;

    console.log('campaignShowedHistorycampaignShowedHistory', campaignShowedHistory);

    if (!campaignShowedHistory || !campaignShowedHistory[key]) {
      this.setState({ statisticsSpinner: true });

      fetchSingleCampaignShowedHistory(key).then(() => {
        this.setState({ statisticsSpinner: false });
      });
    }

    this.setState({
      openStatisticsModal: true,
      statisticsCampaignId: key,
    });
  }

  closeCampaignStatisticsModal = () => {
    const { stopSingleCampaignShowedHistory } = this.props;
    const { statisticsCampaignId } = this.state;

    stopSingleCampaignShowedHistory(statisticsCampaignId);
    this.setState({
      openStatisticsModal: false,
      statisticsCampaignId: '',
    })
  }

  table() {
    const {
      t,
      allCampaigns,
      i18n,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allCampaigns).length / pageSize);
    console.log(allCampaigns, "checkAllCampaigns")

    return (
      <Fragment>
        {allCampaigns && Object.keys(allCampaigns)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allCampaigns[key].startDate} />
                </td>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allCampaigns[key].endDate} />
                </td>
                <td className="allUsers-table-col">
                  {allCampaigns[key].title}
                </td>
                <td className="allUsers-table-col ">
                  <div className='d-flex flex-column'>
                    {allCampaigns[key].selectedLocations && map(allCampaigns[key].selectedLocations, item => (
                      <span className='mb-2'>{item.label}</span>
                    ))}
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <div className='d-flex flex-column'>
                    {allCampaigns[key].selectedPlatforms && map(allCampaigns[key].selectedPlatforms, item => (
                      <span className='mb-2'>{item.label}</span>
                    ))}
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <div className='d-flex flex-column'>
                    {allCampaigns[key].selectedDesigns && map(allCampaigns[key].selectedDesigns, item => (
                      <span className='mb-2'>{item.label}</span>
                    ))}
                  </div>
                </td>
                <td className="allUsers-table-col">
                  <div className='d-flex flex-column'>
                    {allCampaigns[key].selectedEnvs && map(allCampaigns[key].selectedEnvs, item => (
                      <span className='mb-2'>{item.label}</span>
                    ))}
                  </div>
                </td>
                <td className="allUsers-table-col">
                  {allCampaigns[key].pageToOpen}
                </td>
                <td className="allUsers-table-col">
                  <div className='d-flex flex-column align-items-center'>
                    <Media className='mb-2' src={allCampaigns[key].imageUrl} width={100} height={20} alt="" />
                    <Media className='mb-2' src={allCampaigns[key].imageUrlEn} width={100} height={20} alt="" />
                    <Media className='mb-2' src={allCampaigns[key].imageUrlRu} width={100} height={20} alt="" />
                  </div>
                </td>
                <td className="allUsers-table-col">
                  {allCampaigns[key].externalBanner}
                </td>
                <td className="allUsers-table-col">
                  {allCampaigns[key].overflowBackgroundColor}
                </td>

                <td className="allUsers-table-col">
                  {allCampaigns[key].adFormMeasure}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(key)}>
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.toggleDeleteModal(allCampaigns[key].key)}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="warning" onClick={() => this.toggleShowStatisticsModal(allCampaigns[key].key)}>
                    {/* {t('delete')} */}
                    Statistics
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allCampaigns).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  handleChangeLocation = (location) => {
    console.log('handleChangeLocation', location);

    this.setState({
      selectedLocations: [location],
    })
  }

  handleChangePlatform = (platform) => {
    this.setState({
      selectedPlatforms: platform,
    })
  }

  handleChangeDesign = (design) => {
    this.setState({
      selectedDesigns: design,
    })
  }

  handleChangeEnv = (env) => {
    this.setState({
      selectedEnvs: env,
    })
  }

  handlePreviewPhoto = (previewImg, imageType) => {
    console.log("previewImage");
    if (imageType === 'LV') {
      this.setState({
        preview: previewImg,
      });
    } else if(imageType === 'EN') {
      this.setState({
        previewEn: previewImg,
      });
    } else {
      this.setState({
        previewRu: previewImg,
      });
    }
  }

  handleReSetImage = (imageType) => {
    if (imageType === 'LV') {
      this.setState({
        preview: '',
        imageUrl: ''
      });
    } else if(imageType === 'EN') {
      this.setState({
        previewEn: '',
        imageUrlEn: '',
      });
    } else {
      this.setState({
        previewRu: '',
        imageUrlRu: '',
      });
    }
  }

  handleReSetPreviewImage = (imageType) => {
    if (imageType === 'LV') {
      this.setState({
        preview: null,
      });
    } else if(imageType === 'EN') {
      this.setState({
        previewEn: null,
      });
    } else {
      this.setState({
        previewRu: null,
      });
    }
  }

  render() {
    const {
      t,
      loading,
      campaignHistory,
      allCampaigns,
      campaignShowedHistory,
    } = this.props;

    const {
      newCampaignModalOpen,
      filterType,
      enTitle,
      enDescription,
      preview,
      previewEn,
      previewRu,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor,
      startDate,
      endDate,
      showCrop,
      showCropEn,
      showCropRu,
      showInBanner,
      pageToOpen,
      deleteModal,
      openModal,
      lvTitle,
      ruTitle,
      lvDescription,
      ruDescription,
      devMode,
      bannerImageType,
      externalLink,
      selectedLocations,
      selectedDesigns,
      selectedPlatforms,
      selectedEnvs,
      adFormMeasure,
      statisticsCampaignId,
      title,
      statisticsSpinner,
    } = this.state;

    console.log('allCampaigns', allCampaigns);

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'gray',
        padding: 10,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }
    
    console.log(campaignHistory, "campaignHistory===")
    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-campaigns">
          <PopoverBody className="popover-body">
            An admin can create an advertisement campaign in one of the listed locations, also it's possible to choose the platform, design, and environment. There are two types of campaigns: a static image with a target URL or an external banner URL. It's also possible to add the AdForm measurement code to track the impressions and clicks on the client's side. Every campaign has a statistic of all impressions and clicks per design and platform.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col sm="4">
              <h2>
                Campaigns
                <Button className="admin-help-button" id="admin-help-button-campaigns">
                  ?
                </Button>
              </h2>
            </Col>
            <Col sm="4">
              <Input type="select" className="event-type-select" value={filterType} onChange={this.selectType}>
                <option value="ALL" className="background-222">All Campaigns</option>
                <option value="PAST" className="background-222">Past Campaigns</option>
                <option value="CURRENT" className="background-222">Current Campaigns</option>
                <option value="PLAN" className="background-222">Planned Campaigns</option>
              </Input>
            </Col>
            <Col sm="4">
              <Button color="primary" onClick={this.openAddEventModal}>
                Add Campaign
              </Button>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12 }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  Locations
                </th>
                <th style={{ textAlign: 'center' }}>
                  Platforms
                </th>
                <th style={{ textAlign: 'center' }}>
                  Designs
                </th>
                <th style={{ textAlign: 'center' }}>
                  Environments
                </th>
                <th style={{ textAlign: 'center' }}>
                  Open Page
                </th>
                <th style={{ textAlign: 'center' }}>
                  Banner
                </th>
                <th style={{ textAlign: 'center' }}>
                  External Banner
                </th>
                <th style={{ textAlign: 'center' }}>
                  Overflow background
                </th>
                <th style={{ textAlign: 'center' }}>
                  AdForm Measurement
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Delete
                </th>
                <th style={{ textAlign: 'center' }}>
                  Statistics
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
        {/* Delete Event modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to delete the event?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteCampaign}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleDeleteModal('')}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Event modal */}
        <Modal container={'div > div'} size="lg" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="title">
                  Title
                </Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={title}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="startDate">
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="location">
                  Location
                </Label>
                <Select closeMenuOnSelect={true} options={CAMPAIGN_LOCATIOIN_OPTIONS} styles={customStyles} value={selectedLocations} onChange={this.handleChangeLocation} />
              </FormGroup>
              <FormGroup>
                <Label for="platforms">
                  Platforms
                </Label>
                <Select closeMenuOnSelect={false} options={CAMPAIGN_PLATFORMS} isMulti styles={customStyles} value={selectedPlatforms} onChange={this.handleChangePlatform} />
              </FormGroup>
              <FormGroup>
                <Label for="designs">
                  Designs
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={this.handleChangeDesign} />
              </FormGroup>
              <FormGroup>
                <Label for="environemtn">
                  Environment
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_ENV_MODE} isMulti styles={customStyles} value={selectedEnvs} onChange={this.handleChangeEnv} />
              </FormGroup>
              <FormGroup className="tournaments-image-option">
                <FileUploadImage 
                  preview={preview}
                  imageUrl={imageUrl}
                  showCrop={showCrop}
                  hiddenFileInput={this.hiddenFileInput}
                  handleImageClick={this.handleImageClick}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhoto}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType={'LV'}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={previewEn}
                  imageUrl={imageUrlEn}
                  showCrop={showCropEn}
                  hiddenFileInput={this.hiddenFileInputEn}
                  handleImageClick={this.handleImageClickEn}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhotoEn}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType='EN'
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={previewRu}
                  imageUrl={imageUrlRu}
                  showCrop={showCropRu}
                  hiddenFileInput={this.hiddenFileInputRu}
                  handleImageClick={this.handleImageClickRu}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhotoRu}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType='RU'
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
              </FormGroup>
              
              <FormGroup>
                <Label for="role">
                  External banner URL
                </Label>
                <Input
                  type="text"
                  name="externalBanner"
                  id="externalBanner"
                  value={externalBanner}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  Banner overflow background color
                </Label>
                <Input
                  type="text"
                  name="overflowBackgroundColor"
                  id="overflowBackgroundColor"
                  value={overflowBackgroundColor}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  Target URL
                </Label>
                <Input
                  type="text"
                  name="pageToOpen"
                  id="pageToOpen"
                  value={pageToOpen}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  AdForm measurement
                </Label>
                <Input
                  type="text"
                  name="adFormMeasure"
                  id="adFormMeasure"
                  value={adFormMeasure}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.editSelectCampaign}>Save</Button>
            <Button className="modal-footer-button" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add Event modal */}
        <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={newCampaignModalOpen} toggle={this.closeNewEventModal}>
          <ModalHeader toggle={this.closeNewEventModal}>
            Add Campaign
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="title">
                  Title
                </Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={title}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="startDate">
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format={constants.FORMAT_DATE_TYPE.pointDate}
                      culture={constants.sendLanguage.lv}
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="location">
                  Location
                </Label>
                <Select closeMenuOnSelect={true} options={CAMPAIGN_LOCATIOIN_OPTIONS} styles={customStyles} value={selectedLocations} onChange={this.handleChangeLocation} />
              </FormGroup>
              <FormGroup>
                <Label for="platforms">
                  Platforms
                </Label>
                <Select closeMenuOnSelect={false} options={CAMPAIGN_PLATFORMS} isMulti styles={customStyles} value={selectedPlatforms} onChange={this.handleChangePlatform} />
              </FormGroup>
              <FormGroup>
                <Label for="designs">
                  Designs
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_DESIGN_MODE} isMulti styles={customStyles} value={selectedDesigns} onChange={this.handleChangeDesign} />
              </FormGroup>
              <FormGroup>
                <Label for="environemtn">
                  Environment
                </Label>
                <Select closeMenuOnSelect={false} options={STATISTICS_ENV_MODE} isMulti styles={customStyles} value={selectedEnvs} onChange={this.handleChangeEnv} />
              </FormGroup>
              <FormGroup className="tournaments-image-option">
                <FileUploadImage 
                  preview={preview}
                  imageUrl={imageUrl}
                  showCrop={showCrop}
                  hiddenFileInput={this.hiddenFileInput}
                  handleImageClick={this.handleImageClick}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhoto}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType={'LV'}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={previewEn}
                  imageUrl={imageUrlEn}
                  showCrop={showCropEn}
                  hiddenFileInput={this.hiddenFileInputEn}
                  handleImageClick={this.handleImageClickEn}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhotoEn}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType='EN'
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={previewRu}
                  imageUrl={imageUrlRu}
                  showCrop={showCropRu}
                  hiddenFileInput={this.hiddenFileInputRu}
                  handleImageClick={this.handleImageClickRu}
                  handleImageChange={this._handleImageChange}
                  handleEditPhoto={this.handleEditPhotoRu}
                  uploadEventFile={this.uploadCampaignFile}
                  imageType='RU'
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  External banner URL
                </Label>
                <Input
                  type="text"
                  name="externalBanner"
                  id="externalBanner"
                  value={externalBanner}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  Banner overflow background color
                </Label>
                <Input
                  type="text"
                  name="overflowBackgroundColor"
                  id="overflowBackgroundColor"
                  value={overflowBackgroundColor}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  Target URL
                </Label>
                <Input
                  type="text"
                  name="pageToOpen"
                  id="pageToOpen"
                  value={pageToOpen}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="role">
                  AdForm measurement
                </Label>
                <Input
                  type="text"
                  name="adFormMeasure"
                  id="adFormMeasure"
                  value={adFormMeasure}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.addNewCampaign}>Save</Button>
            <Button className="modal-footer-button" onClick={this.closeNewEventModal}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal container={'div > div'} returnFocusAfterClose={false} size="xlg" isOpen={statisticsCampaignId} toggle={this.closeCampaignStatisticsModal}>
          <ModalHeader toggle={this.closeCampaignStatisticsModal}>
            Campaign Statistics
          </ModalHeader>
          <ModalBody>
            {statisticsSpinner ? (
              <Spinner color="primary" style={{ marginLeft: 'auto', marginRight: 'auto', width: '100px', height: '100px' }} />
            ) : (
              <CampaignStatistics statisticsCampaignId={statisticsCampaignId} campaignData={filter(allCampaigns, item => item.key === statisticsCampaignId)[0]} campaignHistory={campaignHistory[statisticsCampaignId]} campaignShowedHistory={campaignShowedHistory[statisticsCampaignId]} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" onClick={this.closeCampaignStatisticsModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allCampaigns: state.admin.allCampaigns || [],
  campaignHistory: state.admin.campaignHistory || {},
  campaignShowedHistory: state.admin.campaignShowedHistory || {},
});

const mapDispatchToProps = {
  fetchAllCampaigns: getAllCampaigns,
  addANewCampaign: addNewCampaign,
  supportAMessageFileUpload: supportMessageFileUpload,
  deleteACampaign: deleteCampaign,
  editASelectCampaign: editSelectCampaign,
  fetchCampaignHistory: getCampaignHistory,
  fetchCampaignShowedHistory: getCampaignShowedHistory,
  fetchSingleCampaignShowedHistory: getSingleCampaignShowedHistory2,
  stopSingleCampaignShowedHistory: closeSingleCampaignShowedHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(Campaigns));
