import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

import Media from 'reactstrap/lib/Media';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classNames from 'classnames';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Button from 'reactstrap/lib/Button';
import * as constants from '../../../constants/constants';

import defaultImage from '../../../images/Game/defaultImage.jpg';
import pencilImg from '../../../images/icons/pencil.svg';
import coinImg from '../../../images/coin.svg';
import lowZole from '../../../images/icons/low_zole.svg';
import negativeZole from '../../../images/icons/negative_zole.svg';

import UpdateProfile from '../../../containers/UpdateProfile';
// import member from '../../../store/member';

const PlayerInfo = React.memo(({
  fontMode, run, newVersion, openModal, holidayModalOpen, bonusModalOpen, weeklyDataOpen, toggle, toggleEditModal, editModalOpen, photo, level, socProvider, firstName, lastName, email, name, contactEmail, verifiedEmail, balance, showNotification, activeGifts, gifts, birthday, isInAppFrame,
}) => {
  const { t } = useTranslation('playerInfo');
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [pencilPopOverOpen, setPencilPopOverOpen] = React.useState(false);

  const STYLE_TOP_TABLE = classNames({
    'menu-player-balance-text': balance > 200,
    'menu-player-balance-text-orange': balance >= 0 && balance <= 200,
    'menu-player-balance-text-red': balance < 0,
  });

  const STYLE_COIN_BLINK = classNames({
    'menu-player-balance-coin': balance > 200,
    'menu-player-balance-coin blink': balance <= 200,
  });

  const onHover = () => {
    if (balance <= 200) {
      setPopoverOpen(true);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  const switchTab = (key) => {
    console.log('switchTab', key);

    toggle(key);
    setPopoverOpen(false);
  };

  const onHoverPencil = () => {
    setPencilPopOverOpen(true);
  };

  const onHoverLeavePencil = () => {
    setPencilPopOverOpen(false);
  };

  return (
    <Row className="menu-player-info">
      <Col xs="6" sm="6" className="menu-player-info-left">
        <div className="menu-player-avatar">
          <img src={photo || defaultImage} alt="" />
        </div>
        <div className="menu-player-level-wrapper">
          <div className="menu-player-level">
            <div className="menu-player-level-text">
              {level}
            </div>
          </div>
        </div>
        {socProvider && (
          <Fragment>
            <div className="menu-player-edit-wrapper">
              <div className="menu-player-edit">
                <Media
                  onClick={toggleEditModal}
                  src={pencilImg}
                  className="menu-player-edit-image"
                  id="menu-player-edit-pencil-image"
                  onMouseEnter={() => onHoverPencil()}
                  onMouseLeave={() => onHoverLeavePencil()}
                />
                <Popover
                  container={'div > div'}
                  placement="bottom"
                  isOpen={pencilPopOverOpen}
                  target="menu-player-edit-pencil-image"
                  popperClassName={`popover popover-font-${fontMode}`}
                  onMouseEnter={() => onHoverPencil()}
                  onMouseLeave={() => onHoverLeavePencil()}
                  className="menu-player-balance-popover menu-player-balance-popover-edit menu-popover menu-edit-pencil"
                >
                  <PopoverBody
                    onMouseLeave={() => onHoverLeavePencil()}
                    className="menu-player-balance-popover-body"
                  >
                    <div className="menu-player-balance-popover-body-header">
                      {t('common:home.editProfileTooltipTitle')}
                    </div>
                    <div className="menu-player-balance-popover-body-main">
                      <div>
                        {t('common:home.editProfileTooltipDescription')}
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
            <UpdateProfile
              fontMode={fontMode}
              newVersion={newVersion}
              run={run}
              openModal={openModal}
              holidayModalOpen={holidayModalOpen}
              bonusModalOpen={bonusModalOpen}
              weeklyDataOpen={weeklyDataOpen}
              toggle={toggleEditModal}
              modalOpen={editModalOpen}
              firstName={firstName}
              lastName={lastName}
              name={name}
              email={email}
              photo={photo || null}
              contactEmail={contactEmail}
              verifiedEmail={verifiedEmail}
              birthday={birthday}
              showNotification={showNotification}
              socProvider={socProvider}
              isInAppFrame={isInAppFrame}
            />
          </Fragment>
        )}
        {
          gifts && activeGifts && get(activeGifts, 'giftId', '') && (
            <>
              <Media className="menu-player-info-left-active-gift" id="menu-player-info-active-gift" src={gifts[activeGifts.giftId].image} alt="x" trigger="hover" />
              <UncontrolledTooltip
                className="player-receivedGift-tooltip-old"
                placement="bottom"
                target="menu-player-info-active-gift"
                container={'div > div'}
                //trigger="click" // use for debug only
              >
                <div className="player-receivedGift-tooltip-from">
                  {activeGifts.fromName || ''}
                </div>
                <div className="player-receivedGift-tooltip-comment">
                  {activeGifts.comment || ''}
                </div>
              </UncontrolledTooltip>
            </>
          )
        }
      </Col>
      <Col xs="6" sm="6" className="menu-player-info-right">
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          <div className="menu-player-name">{name}</div>
        </Row>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
          {(balance || balance === 0) && (
            <div className="menu-player-balance" onMouseEnter={() => onHover()} onMouseLeave={() => onHoverLeave()}>
              <Media
                src={balance > 200 ? coinImg : (balance >= 0 && balance <= 200 ? lowZole : negativeZole)}
                className={STYLE_COIN_BLINK}
                id="menu-player-balance-popover"
              />
              <div className={STYLE_TOP_TABLE}>
                {balance}
              </div>
              <Popover
                container={'div > div'}
                placement="bottom"
                isOpen={popoverOpen}
                target="menu-player-balance-popover"
                popperClassName={`popover popover-font-${fontMode}`}
                onMouseEnter={() => onHover()}
                onMouseLeave={() => onHoverLeave()}
                className={`menu-player-balance-popover menu-player-balance-popover-${fontMode}`}
              >
                <PopoverBody
                  onMouseLeave={() => onHoverLeave()}
                  className="menu-player-balance-popover-body"
                >
                  <div className="menu-player-balance-popover-body-header">
                    { balance < 0
                      ? t('negativeBalance')
                      : t('lowBalance')
                    }
                  </div>
                  <div className="menu-player-balance-popover-body-main">
                    <div>
                      { balance < 0
                        ? t('negativeBalanceContent')
                        : t('lowBalanceContent')
                      }
                    </div>
                  </div>
                  <div className="menu-player-balance-popover-body-footer">
                    <Button className="menu-player-balance-popover-body-footer-purchase-button" onClick={() => switchTab(constants.MENU_NAVIGATION.buyPageMoneyPacks)}>{t('purchase')}</Button>
                    {
                      balance < 0 && (
                        <Button className="menu-player-balance-popover-body-footer-reset-button" onClick={() => switchTab(constants.MENU_NAVIGATION.buyPagePremiumPacks)}>{t('deleteNegativeBalance')}</Button>
                      )
                    }
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
});

PlayerInfo.propTypes = {
  photo: PropTypes.string,
  level: PropTypes.number,
  socProvider: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  contactEmail: PropTypes.string,
  verifiedEmail: PropTypes.string,
  name: PropTypes.string,
  balance: PropTypes.number,
  editModalOpen: PropTypes.bool,
  toggleEditModal: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  activeGifts: PropTypes.shape({
    fromName: PropTypes.string,
    comment: PropTypes.string,
  }),
  gifts: PropTypes.shape({}),
  run: PropTypes.bool,
  newVersion: PropTypes.string,
  openModal: PropTypes.bool,
  holidayModalOpen: PropTypes.bool,
  bonusModalOpen: PropTypes.bool,
  weeklyDataOpen: PropTypes.bool,
  birthday: PropTypes.number,
  fontMode: PropTypes.string,
};

PlayerInfo.defaultProps = {
  photo: null,
  level: null,
  socProvider: null,
  firstName: null,
  lastName: null,
  email: null,
  contactEmail: null,
  verifiedEmail: null,
  name: null,
  balance: 0,
  editModalOpen: false,
  activeGifts: null,
  gifts: null,
  run: false,
  newVersion: '',
  openModal: false,
  holidayModalOpen: false,
  bonusModalOpen: false,
  weeklyDataOpen: false,
  birthday: null,
  fontMode: constants.FONT_MODE.normal,
};

const mapStateToProps = state => ({
  photo: state.member.photo || null,
  level: state.member.level || null,
  socProvider: state.member.socProvider || null,
  firstName: state.member.firstName || null,
  lastName: state.member.lastName || null,
  email: state.member.email || null,
  contactEmail: state.member.contactEmail || null,
  verifiedEmail: state.member.verifiedEmail || null,
  name: state.member.name || null,
  balance: parseFloat(state.member.balance) || 0,
  activeGifts: state.member.activeGifts || null,
  gifts: state.rooms.gifts,
  newVersion: state.member.newVersion || '',
  birthday: state.member.birthday || null,
});

export default connect(mapStateToProps, null)(PlayerInfo);
