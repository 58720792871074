import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  isMobileSafari,
} from 'react-device-detect';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';

import { Helmet } from 'react-helmet';

import MenuContainer from '../../../containers/Menu/Menu';
import MenuComponent from '../Menu/Menu';

import Menu2Container from '../../../containers/Menu2.0/Menu';
import Menu2Component from '../Redesign/Menu/Menu';

import { afterEmailLogin } from '../../../actions/member';

import * as constants from '../../../constants/constants';
import config from '../../../constants/config';

//rooms-table-section noņemt height dinamisko

//menu-topTab-link overwrite defaulto nav-link padding

const isInAppFrame = config.isInAppFrame();


// const Template = React.memo(({ pageTitle, containerClassName, isLoading, children, isFullscreen, notificationSettings }) => {
const Template = React.memo((props) => {
  const { designPlatform, platform, fontMode, toggleFont, pNewDesign, newDesign, toggleNewDesign, pScreenMode, screenMode, toggleScreenMode, notificationSettings, isFullscreen,
    isFullscreenEnabled, isWebVersion, isLoading, pageTitle, showNotification, toggleFullscreen, activePath, emailVerifySuccess, afterLogin, authChecked, isEventOption, toggleEvent } = props;
  // if (emailVerifySuccess) {
  //   afterLogin();
  // }

 // if (!authChecked) {
//    return null;
 // }

  // SET TO FALSE, TO TEST DRAUGIEM VIEW
//  const isWebVersion = false;
//  const isFullscreen = false;
//  const isFullscreenEnabled = false;

  if (newDesign) {  //(notificationSettings && notificationSettings.newDesign) || 
    return (
      // <div className={`root-class-design20 root-class-design20-${screenMode}`} style={(isWebVersion || isFullscreen) ? { height: '100vh', width: '100vw ' } : {}}>
      <div className={`root-class-design20 root-class-design20-${screenMode} root-font-${fontMode} ${isInAppFrame ? 'root-is-app-frame' : 'root-is-not-app-frame'}`} style={(isWebVersion || isFullscreen) ? (isMobileSafari ? { height: '100%', width: '100vw ' } : { height: '100vh', width: '100vw ' }) : {}}>
        <div
          className={classNames({
            'overflow-manage-redesign': (isWebVersion || isFullscreen),
          })}
          style={{ overflow: 'none' }}
        >
          <Container
            fluid
            className={classNames({
              'root-class-design20-fullscreen root-class-design20-fullscreen-menu': (isWebVersion || isFullscreen),
            })}
          >
            <div className={classNames('game-bg', {
              'loading-bg': isLoading,
            })} />
            {
              !isLoading && (
                <div className='lobby-bg-table' />
              )
            }
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>

            <Row style={{ height: '100%', zIndex: 2, position: 'relative' }}>
              {
                fontMode === constants.FONT_MODE.xLarge ? (
                  <Col className="page-wrapper" xs="12" md="10" xl="10" style={{ padding: 0, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="menu-background-bg" />
                    <Menu2Container
                      {...props}
                      Layout={Menu2Component}
                      isNewDesign
                      showNotification={showNotification}
                      toggleFullscreen={toggleFullscreen}
                      isFullscreen={isFullscreen}
                      isFullscreenEnabled={isFullscreenEnabled}
                      toggleNewDesign={toggleNewDesign}
                      screenMode={screenMode}
                      toggleScreenMode={toggleScreenMode}
                      fontMode={fontMode}
                      toggleFont={toggleFont}
                      isInAppFrame={isInAppFrame}
                      isEventOption={isEventOption}
                      toggleEvent={toggleEvent}
                      designPlatform={designPlatform}
                      platform={platform}
                    />
                  </Col>
                ) : (
                  <Col className="page-wrapper" xs="12" md="10" xl="8" style={{ padding: 0, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="menu-background-bg" />
                    <Menu2Container
                      {...props}
                      Layout={Menu2Component}
                      isNewDesign
                      showNotification={showNotification}
                      toggleFullscreen={toggleFullscreen}
                      isFullscreen={isFullscreen}
                      isFullscreenEnabled={isFullscreenEnabled}
                      toggleNewDesign={toggleNewDesign}
                      screenMode={screenMode}
                      toggleScreenMode={toggleScreenMode}
                      fontMode={fontMode}
                      toggleFont={toggleFont}
                      isInAppFrame={isInAppFrame}
                      isEventOption={isEventOption}
                      toggleEvent={toggleEvent}
                      designPlatform={designPlatform}
                      platform={platform}
                    />
                  </Col>
                )
              }

            </Row>
          </Container>
        </div>
      </div>
    );
  }

  const { hostname } = window.location;

  return (
    <div className="test" style={(isWebVersion || isFullscreen) ? { height: '100vh', width: '100vw ' } : {}}>
      <div className={`${(isWebVersion || isFullscreen) ? 'overflow-manage' : ''}`} style={{ overflow: 'none' }}>
        <Container
          className={classNames(`app-wrapper ${config.isInInboxAppFrame() ? 'app-wrapper-inbox' : ''} ${config.isInFacebookAppFrame() ? 'app-wrapper-facebook' : ''} ${isInAppFrame ? 'root-is-app-frame' : 'root-is-not-app-frame'} root-font-${fontMode} `, {
            'app-wrapper-fullscreen app-wrapper-fullscreen-menu': isWebVersion || isFullscreen,
          })}
        >
          <div className={`game-bg ${isLoading ? 'loading-bg' : ''}`} />
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <Row style={{ height: '100%' }}>
            <Col sm="12" style={{ padding: 0 }}>
              <MenuContainer
                {...props}
                Layout={MenuComponent}
                showNotification={showNotification}
                toggleFullscreen={toggleFullscreen}
                isFullscreen={isFullscreen}
                isFullscreenEnabled={isFullscreenEnabled}
                activePath={activePath}
                toggleNewDesign={toggleNewDesign}
                toggleScreenMode={toggleScreenMode}
                fontMode={fontMode}
                toggleFont={toggleFont}
                isInAppFrame={isInAppFrame}
                isEventOption={isEventOption}
                toggleEvent={toggleEvent}
                designPlatform={designPlatform}
                platform={platform}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
});

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  isLoading: PropTypes.bool,
  activePath: PropTypes.string,
  emailVerifySuccess: PropTypes.bool,
  afterLogin: PropTypes.func.isRequired,
  toggleNewDesign: PropTypes.func.isRequired,
  toggleScreenMode: PropTypes.func.isRequired,
  newDesign: PropTypes.bool,
  pNewDesign: PropTypes.bool,
  screenMode: PropTypes.string,
  pScreenMode: PropTypes.string,
  fontMode: PropTypes.string,
  toggleFont: PropTypes.func.isRequired,
  isEventOption: PropTypes.bool,
  toggleEvent: PropTypes.func.isRequired,
  designPlatform: PropTypes.shape({}),
  platform: PropTypes.string,
};

Template.defaultProps = {
  pageTitle: 'Zole',
//  containerClassName: '',
  isLoading: false,
  activePath: '',
  emailVerifySuccess: false,
  newDesign: false,
  screenMode: constants.SCREEN_MODE.normal,
  fontMode: constants.FONT_MODE.normal,
  pNewDesign: false,
  pScreenMode: constants.SCREEN_MODE.normal,
  isEventOption: false,
  designPlatform: {},
  platform: constants.DWEB_PLATFORM,
};

const mapStateToProps = (state, props) => {
  let newDesign;
  try {
    newDesign = state.member?.newDesign || localStorage.getItem('newDesign'); //x
  } catch (err) {
    ({ newDesign } = state.member);
  }

  return ({
    isLoading: state.state.isLoading || false,
    notificationSettings: state.userSettings ? (state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : {})) : {},
    screenMode: state.member.screenMode ? state.member.screenMode : props.pScreenMode ? props.pScreenMode : constants.SCREEN_MODE.normal,
    newDesign: newDesign || state.member?.newDesign,
  });
};

const mapDispatchToProps = {
  afterLogin: afterEmailLogin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Template));
